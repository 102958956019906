import { FC, useContext } from "react"
import { CloseTaskWorkflow, Props, getTaskActionLabel } from "../../../Tasks/CloseTaskWorkflow"
import { SingleDrawerContext } from "../Elements/Drawer"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

export const CloseTaskDrawer: FC<Props> = ({ onError, onSuccess, project, task, taskAction = "completeTask" }) => {
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <>
      <DrawerHeader href={`/projects/${project?.id}/${task.id}/${getTaskActionLabel(taskAction)}`} />
      <DrawerBody>
        <CloseTaskWorkflow
          onError={() => {
            onError?.()
          }}
          onSuccess={() => {
            onSuccess?.()
            handleClose()
          }}
          onCancel={() => {
            handleClose()
          }}
          project={project}
          task={task}
          taskAction={taskAction}
          isDrawer
        />
      </DrawerBody>
    </>
  )
}
