import { TimeEntry } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { getSecondsFromRange } from "../dateAndTime/getSecondsFromRange"

export function getTimeEntryArrayTotalSeconds<T extends PickPlus<TimeEntry, "startAt" | "endAt">>(
  timeEntries: T[],
  minDate?: Date,
  maxDate?: Date
) {
  return timeEntries
    .map((timeEntry) => {
      const bookendAdjustedTimeEntry = { ...timeEntry }

      if (minDate && minDate > timeEntry.startAt) {
        bookendAdjustedTimeEntry.startAt = minDate
      }

      if (maxDate && timeEntry.endAt && maxDate < timeEntry.endAt) {
        bookendAdjustedTimeEntry.endAt = maxDate
      }

      return bookendAdjustedTimeEntry
    })
    .reduce((totalSeconds, timeEntry) => {
      return totalSeconds + getSecondsFromRange(timeEntry)
    }, 0)
}
