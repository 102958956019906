import { IconButton, Tooltip } from "@mui/material"
import { FC, useContext } from "react"
import { FaCheckCircle, FaRegCircle } from "react-icons/fa"
import { green } from "tailwindcss/colors"
import { TaskListItem, useMarkTaskAsIncompleteMutation } from "../graphql/generated/client-types-and-hooks"
import { useReassignAndCloseTaskWorkflow } from "../hooks/useReassignAndCloseTaskWorkflow"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext } from "../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../providers/TaskListProvider/taskListReducer"
import { PickPlus } from "../types/helpers"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { ProjectExpectation } from "../types/ProjectExpectation"

type Props = {
  taskListItem: PickPlus<TaskListItem, "taskId" | "name" | "isComplete" | "userCount" | "assetCount">
  project: ProjectExpectation
  taskGroupId?: TaskListItem["taskGroupId"]
  className?: string
  iconClassName?: string
  testLabel?: string
}

export const ToggleCompletionButton: FC<Props> = ({
  taskListItem,
  project,
  taskGroupId,
  className,
  iconClassName,
  testLabel,
}) => {
  const { taskId, isComplete, name } = taskListItem
  const [, markTaskIncompleteMutation] = useMarkTaskAsIncompleteMutation()
  const markTaskCompleteWorkflow = useReassignAndCloseTaskWorkflow()
  const isProjectArchivedOrComplete = !!project?.isArchived || !!project?.isComplete
  const dispatch = useContext(TaskListDispatchContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const tooltipOverride = isProjectArchivedOrComplete || null
  const tooltipAttributes = {
    title: tooltipOverride ? undefined : isComplete ? "Reopen task" : "Complete task",
  }

  return (
    <>
      {isComplete ? (
        <Tooltip {...tooltipAttributes}>
          <span>
            <IconButton
              disabled={isProjectArchivedOrComplete || !hasPermissionTo("task:reopen")}
              test-label={testLabel}
              className={className}
              onClick={() => {
                markTaskIncompleteMutation({ id: taskId! }).then((result) => {
                  if (result.error) {
                    console.error(result.error)
                    errorSnack(`${name} could not be reopened. Please try again.`)
                  } else {
                    dispatch({
                      type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                      payload: { taskId: taskId!, groupId: taskGroupId!, isComplete: false },
                    })
                  }
                })
              }}
              color="white"
            >
              <FaCheckCircle className={iconClassName} style={{ color: green[600] }} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip {...tooltipAttributes}>
          <span>
            <IconButton
              disabled={isProjectArchivedOrComplete}
              test-label={testLabel}
              className={className}
              onClick={() => {
                markTaskCompleteWorkflow({
                  taskListItem: { ...taskListItem, project: project! },
                  onSuccessCallback: () => {
                    // ToggleCompletionButton has two potential parent components:
                    // 1. tasks.tsx, which has TaskListDispatchContext as a provider. That provider contains 'dispatch'.
                    // 2. _layout.tsx, which does not have the same provider, and as a result, does not have 'dispatch'.
                    // This is a temporary solution until we can do some refactoring to make this more consistent.
                    if (typeof dispatch === "function") {
                      dispatch({
                        type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                        payload: { taskId: taskId!, groupId: taskGroupId!, isComplete: true },
                      })
                    }
                    successSnack("Success!")
                  },
                  onErrorCallback: () => {
                    errorSnack(`${name} completion error; please try again`)
                  },
                })
              }}
              color="white"
            >
              <FaRegCircle className={iconClassName} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  )
}
