import { FC } from "react"
import { aggregateUnitGoals } from "./helpers/aggregateUnitGoals"
import { CustomDetailPanelToggleColDef } from "../DataGrid/CustomDetailPanelToggle"
import { StackedHorizontalBarChart } from "../../StackedHorizontalBarChart"
import {
  UNITS_ACTIVE_COLORS,
  UNITS_INACTIVE_COLORS,
  createSource,
} from "../../Widgets/SummaryWidgets/WidgetSourceColors"
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid"
import { pluralize } from "../../../helpers/strings/pluralize"
import { BiRuler } from "react-icons/bi"
import { LinearProgress, Switch } from "@mui/material"
import { getFriendlyFloat } from "../../../helpers/numbers/getFriendlyFloat"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { defaultColumnProps } from "./Sections/ProjectAndTaskSummaryTeamTable"
import { UnitTableDetailPanel } from "./ProjectSummaryUnitsTableDropDown"
import { DateRangeFilterString } from "./types"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { titleCase } from "../../../helpers/strings/titleCase"

type RowData = {
  id: string
  unitType: string
  unitTotal: string
  totalProgress: number
  previousProgress: number
  currentProgress: number
  recentProgress: number
  taskCount: number
  color: string
}

type UnitGoals = {
  id: string
  isPrimary: boolean
  targetQuantity?: number | null | undefined
  totalProgress: number
  previousProgress: number
  progressInDateRange: number
  deliverableUnit: {
    id: string
    description: string
    unitOfMeasure: string
    color: string
  }
}

type SummaryUnitsTableProps = {
  goals: UnitGoals[]
  entityType: "project" | "task"
  displayUnits?: "primary" | "additional"
  dateRangeType: DateRangeFilterString
  unitsVisibility: Record<string, boolean>
  toggleUnitVisibility: (unitDescription: string) => void
}

export const SummaryUnitsTable: FC<SummaryUnitsTableProps> = ({
  goals,
  entityType,
  displayUnits = "primary",
  dateRangeType,
  unitsVisibility,
  toggleUnitVisibility,
}) => {
  
  const aggregatedUnits = aggregateUnitGoals(goals)

  const columns = [
    {
      field: "unitType",
      headerName: "Type",
      flex: 1.5,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex">
            <BiRuler
              className="p-1 mr-2 size-6 rounded-md text-neutral-200 "
              style={{ backgroundColor: params.row.color }}
            />
            <p className="mr-1">{params.value}</p>
          </div>
        )
      },
    },
    {
      field: "taskCount",
      headerName: "Tasks",
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        const { taskCount } = params.row
        return <div>{`${taskCount} ${pluralize("task", taskCount)}`}</div>
      },
    },
    { field: "unitTotal", headerName: "Total", flex: 1, minWidth: 150 },
    {
      field: "unitProgress",
      headerName: "Progress",
      flex: 2,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => {
        const { previousProgress, recentProgress, currentProgress, targetQuantity } = params.row
        const sources =
          currentProgress === 0
            ? [createSource(previousProgress + recentProgress, UNITS_INACTIVE_COLORS)]
            : [
                createSource(previousProgress, UNITS_INACTIVE_COLORS),
                createSource(currentProgress, UNITS_ACTIVE_COLORS),
                createSource(recentProgress, UNITS_INACTIVE_COLORS),
              ]
        if (params.row) {
          return (
            <StackedHorizontalBarChart
              sources={sources}
              expectedMaximum={targetQuantity}
              expectedMaximumClassName="w-60"
              onlyBar
            />
          )
        }
        return null
      },
    },
    { ...CustomDetailPanelToggleColDef },
  ]

  if (displayUnits === "primary") {
    columns.splice(0, 0, {
      field: "chartFilter",
      headerName: "Chart",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        const showUnit = unitsVisibility[params.row.unitType]
        return (
          <Switch
            checked={showUnit}
            onClick={() => {
              toggleUnitVisibility(params.row.unitType)
            }}
          />
        )
      },
    })
  }

  if (dateRangeType && dateRangeType !== "all-time") {
    columns.splice(2, 0, {
      field: "progressInDateRange",
      flex: 0.75,
      minWidth: 100,
      headerName: titleCase(dateRangeType),
    })
  }

  const rows: RowData[] = Object.entries(aggregatedUnits).map(([description, details], index) => {
    return {
      id: index.toString(),
      unitType: description,
      progressInDateRange: `${getFriendlyFloat(details.currentProgress)} ${details.unitType}`,
      unitTotal: `${getFriendlyFloat(details.totalProgress, 2)} / ${getFriendlyFloat(details.targetQuantity)} ${
        details.unitType
      }`,
      totalProgress: details.totalProgress,
      currentProgress: details.currentProgress,
      targetQuantity: details.targetQuantity,
      previousProgress: details.previousProgress,
      taskCount: details.tasks.length,
      color: details.color,
      recentProgress: details.recentProgress,
    }
  })

  const projectDetailProps =
    entityType === "project"
      ? {
          getDetailPanelHeight: (): "auto" => "auto",
          getDetailPanelContent: (params: GridRowParams) => {
            const row = params.row as RowData
            const tasksForUnit = aggregatedUnits[row.unitType]?.tasks || []
            return <UnitTableDetailPanel tasks={tasksForUnit} dateRangeType={dateRangeType} />
          },
        }
      : {}

  // Don't render if there are no additional units
  if (displayUnits === "additional" && !goals.length) return null

  return (
    <DataGridPro
      autoHeight
      pagination
      disableRowSelectionOnClick
      loading={!goals}
      rows={rows}
      columns={columns
        .map((column) => ({ ...defaultColumnProps, ...column }))
        .filter((column) => entityType !== "task" || column.field !== "taskCount")}
      pageSizeOptions={[10]}
      initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
      {...projectDetailProps}
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: () => <EmptyStateBlock label="No units" />,
      }}
    />
  )
}
