import { clsx } from "clsx"
import { useRouter } from "next/router"
import { FC } from "react"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { SkeletonH2 } from "../../Skeletons/SkeletonH2"
import { SkeletonH3 } from "../../Skeletons/SkeletonH3"
import { SkeletonIcon } from "../../Skeletons/SkeletonIcon"
import { SkeletonTextSmall } from "../../Skeletons/SkeletonTextSmall"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { Row } from "../../Table/Row"
import { Table } from "../../Table/Table"

const SkeletonRow: FC = () => {
  return (
    <Row
      className={clsx(
        "grid auto-rows-min grid-cols-12 grid-flow-col gap-y-6",
        "md:grid-flow-row md:grid-cols-12 md:grid-rows-1 md:items-center md:gap-x-6"
      )}
    >
      <div className="col-span-10 md:col-span-3 flex gap-x-4 items-center">
        <SkeletonElement className="size-10" />
        <div className="flex flex-col gap-y-3">
          <SkeletonElement className="h-4 w-36" />
          <SkeletonTextSmall className="w-40" />
        </div>
      </div>
      <div className="col-span-10 md:col-span-5">
        <div className="flex flex-col gap-y-3">
          <SkeletonElement className="h-3 w-16 md:hidden" />
          <SkeletonElement className="h-4 w-40" />
        </div>
      </div>
      <div className="col-span-10 md:col-span-3">
        <div className="flex flex-col gap-y-3">
          <SkeletonElement className="h-3 w-32 md:hidden" />
          <SkeletonElement className="h-4 w-52" />
        </div>
      </div>
      <div className="row-span-3 col-span-2 justify-self-end md:row-auto md:col-span-1">
        <SkeletonIcon />
      </div>
    </Row>
  )
}

export const RevisedProjectSummarySkeleton: FC = () => {
  // Get the query param filter from the URL
  const router = useRouter()
  const filter = (router.query["filter"] as string) || "all"
  const all = filter === "all"

  return (
    <SkeletonContainer>
      <div className="flex justify-between items-center mb-6">
        <SkeletonH2 />
        <SkeletonIcon />
      </div>
      <div className="flex mb-4 gap-x-4">
        <SkeletonElement className="h-8 w-16" />
        <SkeletonElement className="h-8 w-20" />
        <SkeletonElement className="h-8 w-20" />
        <SkeletonElement className="h-8 w-28" />
      </div>
      <div className="flex mb-10 gap-x-4">
        <SkeletonElement className="h-8 w-60" />
        <SkeletonElement className="h-8 w-20" />
        <SkeletonElement className="h-8 w-20" />
      </div>
      <div className="flex mb-10 gap-x-6">
        <SkeletonElement className="h-6 w-16" />
        <SkeletonElement className="h-6 w-20" />
        <SkeletonElement className="h-6 w-20" />
        <SkeletonElement className="h-6 w-16" />
        <SkeletonElement className="h-6 w-16" />
        <SkeletonElement className="h-6 w-28" />
      </div>
      {all ||
        (filter === "overview" && (
          <div className="pb-8">
            <SkeletonH3 /> {/* Overview" */}
            <div className="flex flex-nowrap lg:grid lg:grid-cols-4 gap-6">
              <SkeletonBlock className="h-40 w-56" />
              <SkeletonBlock className="h-40 w-56" />
              <SkeletonBlock className="h-40 w-56" />
              <SkeletonBlock className="h-40 w-56" />
            </div>
          </div>
        ))}
      {all ||
        (filter === "injury" && (
          <div className="pb-8">
            <SkeletonH3 /> {/* Injury Reports" */}
            <SkeletonTextSmall className="place-self-center w-full pb-4" />
          </div>
        ))}

      {all ||
        (filter === "tasks" && (
          <div className="pb-8">
            <SkeletonH3 /> {/* Task */}
            <ColumnTitles>
              <SkeletonElement className="col-span-3 h-6 w-28" />
              <SkeletonElement className="col-span-5 h-6 w-16 hidden md:block" />
              <SkeletonElement className="col-span-4 h-6 w-32 hidden md:block" />
            </ColumnTitles>
            <Table>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </Table>
            <div className="flex justify-center pt-8">
              <SkeletonTextSmall />
            </div>
          </div>
        ))}

      {all ||
        (filter === "team" && (
          <div className="pb-8">
            <SkeletonH3 /> {/* Team */}
            <ColumnTitles>
              <SkeletonElement className="col-span-3 h-6 w-28" />
              <SkeletonElement className="col-span-5 h-6 w-16 hidden md:block" />
              <SkeletonElement className="col-span-4 h-6 w-32 hidden md:block" />
            </ColumnTitles>
            <Table>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </Table>
            <div className="flex justify-center pt-8">
              <SkeletonTextSmall />
            </div>
          </div>
        ))}

      {all ||
        (filter === "assets" && (
          <div className="pb-8">
            <SkeletonH3 /> {/* Assets */}
            <ColumnTitles>
              <SkeletonElement className="col-span-3 h-6 w-28" />
              <SkeletonElement className="col-span-5 h-6 w-16 hidden md:block" />
              <SkeletonElement className="col-span-4 h-6 w-32 hidden md:block" />
            </ColumnTitles>
            <Table>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </Table>
            <div className="flex justify-center pt-8">
              <SkeletonTextSmall />
            </div>
          </div>
        ))}
    </SkeletonContainer>
  )
}
