import { clsx } from "clsx"
import { FC } from "react"
import { BiChevronDown, BiChevronUp } from "react-icons/bi"

type Props = {
  isAscending: boolean
  isActive: boolean
}

export const SortChevron: FC<Props> = ({ isAscending, isActive }) => {
  return isAscending ? (
    <BiChevronDown className={clsx("size-6", !isActive && "text-gray-300")} />
  ) : (
    <BiChevronUp className={clsx("size-6", !isActive && "text-gray-300")} />
  )
}
