import React, { FC, useState } from "react"
import { Avatar, AvatarGroup, Paper, Skeleton, styled, Typography } from "@mui/material"
import { format } from "date-fns"
import { SectionHeader } from "../../../../PageSectionHeader"
import { BiEditAlt, BiTrash } from "react-icons/bi"
import { defaultTimeEntryColumnProps } from "../DataGrid/TimeEntryDaysDataGrid"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { DrawerLink } from "../../../Drawer/components/Elements/DrawerLink"
import { TaskDrawer } from "../../../Drawer/components/Task/TaskDrawer"
import Link from "next/link"
import clsx from "clsx"
import {
  TimeEntryWrapper,
  useDeleteTimeEntryMutation,
  User,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { TotalTimeWithEdits } from "../TotalTimeWithEdits"
import { QuickMenuDotsHorizontal } from "../../../../QuickMenu/QuickMenuDotsHorizontal"
import { QuickMenuMui } from "../../../../QuickMenuMui"
import { useModalProps } from "../../../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"
import { MuiModal } from "../../../../Modals/components/Elements/MuiModal"
import { Day } from "./TimeEntryDayDrawer"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { PickPlus } from "../../../../../types/helpers"
import { timeEntryChangesetToString } from "./auditHistoryHelpers"
import { DevelopmentFeatureFlag } from "../../../../DevelopmentFeatureFlag"
import { EditDayActivityForm } from "../../EditDayActivityForm/EditDayActivityForm"
import DeprecatedModal from "../../../../deprecated/StandardModal"

export type Changeset = Exclude<Day["timeEntryWrappers"], null | undefined>[number]["history"][number]
type TimeEntry = Exclude<Day["timeEntryWrappers"], null | undefined>[number]["timeEntry"]

export const TimeEntryTable: FC<{
  day: Day | undefined
  user: PickPlus<User, "id" | "taskId" | "currentTaskId">
  loading: boolean
}> = ({ day, user, loading }) => {
  const timeEntries = day?.timeEntryWrappers?.map(({ timeEntry }) => timeEntry) || []

  const editTimeEntryModalProps = useModalProps("Edit Time Entry")
  const deleteTimeEntryModalProps = useModalProps("Delete Time Entry")

  const [selectedTimeEntry, setSelectedTimeEntry] = useState<TimeEntry | undefined>()

  const [, deleteTimeEntryMutation] = useDeleteTimeEntryMutation()

  const deleteTimeEntry = async (id: TimeEntry["id"]) => {
    try {
      const { error } = await deleteTimeEntryMutation({ id })
      if (error) throw error.message
      successSnack("Deleted time entry")
      deleteTimeEntryModalProps.handleCloseModal()
    } catch (error) {
      console.error(error)
      errorSnack("Could not delete time entry")
    }
  }

  const columns: GridColDef[] = [
    {
      ...defaultTimeEntryColumnProps,
      field: "project",
      headerName: "Project",
      renderCell: ({ row: { timeEntry } }: GridRenderCellParams<TimeEntryWrapper>) => (
        <Link
          className={clsx(
            "whitespace-normal text-ellipsis line-clamp-2 hover:underline",
            timeEntry.isBreak ? "text-violet-600 hover:text-violet-700" : "text-gray-800"
          )}
          href={`/projects/${timeEntry.projectId}`}
        >
          {timeEntry.project.name}
        </Link>
      ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "task",
      headerName: "Task",
      renderCell: ({ row: { timeEntry } }: GridRenderCellParams<TimeEntryWrapper>) =>
        timeEntry.isBreak ? (
          <div className="whitespace-normal text-ellipsis line-clamp-2 text-violet-600 hover:text-violet-700">
            {timeEntry.task.name}
          </div>
        ) : (
          <DrawerLink
            className="whitespace-normal text-ellipsis line-clamp-2 text-gray-800 hover:underline"
            component={<TaskDrawer taskId={timeEntry.taskId} />}
            drawerName="Task"
            href={`/projects/${timeEntry.projectId}/${timeEntry.taskId}`}
          >
            {timeEntry.task.name}
          </DrawerLink>
        ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "startAt",
      headerName: "Start",
      valueGetter: ({ row: { timeEntry, splitStartAt, splitEndAt } }: GridRenderCellParams<TimeEntryWrapper>) => {
        const regularTimeFormat = "h:mm a"
        const splitTimeFormat = "h:mm a EEE, MMM do"
        const hasSplitTime = Boolean(splitStartAt || splitEndAt)
        const timeFormat = hasSplitTime ? splitTimeFormat : regularTimeFormat
        const startAt = format(timeEntry.startAt, timeFormat)
        return startAt
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "endAt",
      headerName: "End",
      cellClassName: ({ row: { timeEntry } }) => (!timeEntry.endAt ? "text-blue-600" : "text-gray-800"),
      valueGetter: ({ row: { timeEntry, splitStartAt, splitEndAt } }: GridRenderCellParams<TimeEntryWrapper>) => {
        const regularTimeFormat = "h:mm a"
        const splitTimeFormat = "h:mm a EEE, MMM do"
        const hasSplitTime = Boolean(splitStartAt || splitEndAt)
        const timeFormat = hasSplitTime ? splitTimeFormat : regularTimeFormat
        const endAt = timeEntry.endAt ? format(timeEntry.endAt, timeFormat) : "Clocked in"
        return endAt
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "duration",
      headerName: "Total",
      renderCell: ({ row: { timeEntry, totalEditedTimeInSeconds } }: GridRenderCellParams<TimeEntryWrapper>) => {
        return (
          <TotalTimeWithEdits
            totalTimeInSeconds={timeEntry.durationInSeconds}
            totalEditedTimeInSeconds={totalEditedTimeInSeconds}
            isClockedIn={!timeEntry.endAt}
            showTimeDiff
            className="flex-col"
          />
        )
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "id",
      headerName: "Actor",

      renderCell: ({ row: { actors, history } }: GridRenderCellParams<TimeEntryWrapper>) => {
        // console.log(history)
        return (
          <DevelopmentFeatureFlag
            name="Time Cards Audit History"
            fallbackComponent={
              <AvatarGroup>
                {actors.map((actor) => {
                  const name = actor.firstName + " " + actor.lastName
                  const initials = (actor.firstName[0] + actor.lastName[0]).toUpperCase()

                  return (
                    <Tooltip title={name} key={actor.id}>
                      <Avatar className="size-8 text-base" alt={name} src={actor.imageUrl || ""}>
                        {initials}
                      </Avatar>
                    </Tooltip>
                  )
                })}
              </AvatarGroup>
            }
          >
            <StyledTooltip title={<ToolTipContent history={history} user={user} />}>
              <AvatarGroup>
                {actors.map((actor) => {
                  const name = actor.firstName + " " + actor.lastName
                  const initials = (actor.firstName[0] + actor.lastName[0]).toUpperCase()

                  return (
                    <Avatar className="size-8 text-base" key={actor.id} alt={name} src={actor.imageUrl || ""}>
                      {initials}
                    </Avatar>
                  )
                })}
              </AvatarGroup>
            </StyledTooltip>
          </DevelopmentFeatureFlag>
        )
      },
    },
    {
      field: "quickActionsMenu",
      headerName: "",
      renderCell: ({ row }: GridRenderCellParams<TimeEntryWrapper>) => (
        <>
          <QuickMenuMui
            buttonShape="round"
            items={[
              [
                {
                  requiredPermission: "timeEntry:update",
                  value: "Edit time entry",
                  onClick: () => {
                    setSelectedTimeEntry(row.timeEntry)
                    editTimeEntryModalProps.handleOpenModal()
                  },
                  Icon: BiEditAlt,
                },
                {
                  requiredPermission: "timeEntry:delete",
                  value: "Delete time entry",
                  color: "red",
                  onClick: () => {
                    setSelectedTimeEntry(row.timeEntry)
                    deleteTimeEntryModalProps.handleOpenModal()
                  },
                  Icon: BiTrash,
                },
              ],
            ]}
          >
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        </>
      ),
    },
  ]

  const rows = loading ? [] : day?.timeEntryWrappers || []

  return (
    <div className="mb-8">
      <SectionHeader title="Time Entries" />

      <DataGridPro
        autoHeight
        density="comfortable"
        loading={loading}
        columns={columns}
        rows={rows}
        disableRowSelectionOnClick
        hideFooter
        slots={{
          loadingOverlay: () => (
            <div>
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
            </div>
          ),
        }}
      />

      {editTimeEntryModalProps.isOpen && selectedTimeEntry && (
        <DeprecatedModal {...editTimeEntryModalProps}>
          <EditDayActivityForm
            onCancel={editTimeEntryModalProps.handleCloseModal}
            onSuccess={() => {
              editTimeEntryModalProps.handleCloseModal()
            }}
            timeEntries={timeEntries}
            user={user}
            selectedTimeEntryId={selectedTimeEntry.id}
          />
        </DeprecatedModal>
      )}
      {deleteTimeEntryModalProps.isOpen && selectedTimeEntry && (
        <MuiModal
          {...deleteTimeEntryModalProps}
          submitButtonColor="error"
          submitButtonText="Delete"
          submitForm={() => deleteTimeEntry(selectedTimeEntry.id)}
          variant="small"
        >
          <Typography className="pt-6 px-6">
            Are you sure you want to delete this time from <b>{format(selectedTimeEntry.startAt, "h:mm a MMM do")}</b>{" "}
            to <b>{format(selectedTimeEntry.endAt || new Date(), "h:mm a MMM do")}</b> ? This process cannot be undone.
          </Typography>
        </MuiModal>
      )}
    </div>
  )
}

const ToolTipContent: FC<{ history: Changeset[]; user: PickPlus<User, "id"> }> = ({ history, user }) => {
  return (
    <Paper elevation={3} className="p-2">
      {history
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
        .map((timeEntryChangeset) => {
          return (
            <div key={timeEntryChangeset.id} className="mb-2 flex gap-2">
              <div className="border border-gray-200 rounded bg-gray-50 min-w-[100px] text-center h-4 mt-[2px] whitespace-nowrap">
                {format(timeEntryChangeset.timestamp, "h:mm a MMM do")}
              </div>
              <Typography color="inherit" variant="caption">
                {timeEntryChangesetToString(timeEntryChangeset, user)}
              </Typography>
            </div>
          )
        })}
    </Paper>
  )
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}))
