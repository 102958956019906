import { Typography } from "@mui/material"
import { useStore } from "@tanstack/react-store"
import { useRouter } from "next/router"
import { FC, useContext, useEffect } from "react"
import { BiListPlus, BiPlus, BiSortAlt2 } from "react-icons/bi"
import { AssetCategory, AssetStatus } from "../../../../graphql/generated/client-types-and-hooks"
import { assetCategoryOptions } from "../../../../helpers/assets/assetCategory"
import { assetStatusList } from "../../../../helpers/assets/assetStatus"
import { assetListStore, assetListStoreActions } from "../../../../stores/assetList"
import { SearchBar } from "../../../deprecated/SearchBar"
import { DevelopmentFeatureFlag } from "../../../DevelopmentFeatureFlag"
import { HeaderActions } from "../../../HeaderActions"
import { TransferAssetToUser } from "../../../Modals/components/TransferAssetModal/TransferAssetToUser"
import { useModalProps } from "../../../Modals/hooks/useModalProps"
import { MenuItem } from "../../../QuickMenuMui"
import { RenderIf } from "../../../RenderIf"
import { AssetCreationDrawer } from "../../Drawer/components/Asset/AssetCreationDrawer"
import { ReassignAssetDrawer } from "../../Drawer/components/Asset/ReassignAssetDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"
import { AssetQuickAddTable } from "../AssetQuickAdd"
import { AssetFilterBillingClassification } from "./AssetFilters/AssetFilterBillingClassification"
import { AssetFilterState } from "./AssetFilters/AssetFilterState"
import { AssetFilterStatusAndCategory } from "./AssetFilters/AssetFilterStatusAndCategory"
import { AssetFilterType } from "./AssetFilters/AssetFilterType"

export const AssetListHeader: FC<{
  projectId?: string
  taskId?: string
  userId?: string
  isComplete?: boolean
  handleAssignAsset?: () => void
}> = ({ projectId, taskId, userId, isComplete, handleAssignAsset }) => {
  const router = useRouter()
  const { push: pushDrawer } = useContext(DrawerContext)

  const selectedAssets = useStore(assetListStore, (s) => s.selectedAssets)
  const filters = useStore(assetListStore, (s) => s.filters)

  const toggleStatus = (status: AssetStatus) => {
    assetListStoreActions.setListFilterItems({
      statuses: filters.statuses?.includes(status)
        ? filters.statuses.filter((s) => s !== status)
        : [...(filters.statuses || ([] as AssetStatus[])), status],
    })
  }

  const toggleCategory = (category: AssetCategory) => {
    assetListStoreActions.setListFilterItems({
      categories: filters.categories?.includes(category)
        ? filters.categories.filter((s) => s !== category)
        : [...(filters.categories || ([] as AssetCategory[])), category],
    })
  }

  const hasProjectOrTaskId = !!projectId || !!taskId

  const assetQuickAddModalProps = useModalProps("Add Assets")
  const assetModalProps = useModalProps("Transfer Asset")

  const assetStatusListOptions = assetStatusList
    .filter((option) => {
      if (filters.isArchived) return option.canBeArchived
      return option.canBeActive
    })
    .map((option) => ({
      label: option.label,
      onSelect: () => toggleStatus(option.value),
      onDeselect: () => toggleStatus(option.value),
      value: option.value,
    }))
  const statusFilterOptions = [
    {
      label: "STATUS",
      items: assetStatusListOptions,
    },
  ]
  const filterAssetStatusesThatAreNoLongerOptions = filters.statuses?.filter(
    (status) => !assetStatusListOptions.find((option) => option.value === status)
  )

  useEffect(() => {
    if (filterAssetStatusesThatAreNoLongerOptions?.length) {
      assetListStoreActions.setListFilterItems({
        statuses: filters.statuses?.filter((status) => !filterAssetStatusesThatAreNoLongerOptions.includes(status)),
      })
    }
  }, [filterAssetStatusesThatAreNoLongerOptions, filters.statuses])

  const assetCategoryListOptions = assetCategoryOptions.map((option) => ({
    label: option.label,
    onSelect: () => toggleCategory(option.value),
    onDeselect: () => toggleCategory(option.value),
  }))
  const categoryFilterOptions = [
    {
      label: "CATEGORY",
      items: assetCategoryListOptions,
    },
  ]

  const headerActions: MenuItem[][] = [[]]

  if (selectedAssets.length) {
    headerActions[0].push({
      value: <Typography>Transfer</Typography>,
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
      requiredPermission: "asset:transfer",
      isDisabled: isComplete,
      onClick: () =>
        pushDrawer(
          <ReassignAssetDrawer assetsIdsToReassign={selectedAssets.map((asset) => asset.id)} />,
          "ReassignAsset"
        ),
    })
  }
  if (!hasProjectOrTaskId && !filters.isArchived && !selectedAssets.length) {
    headerActions[0].push({
      value: <Typography>Transfer</Typography>,
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
      requiredPermission: "asset:update",
      onClick: () => assetModalProps.handleOpenModal(),
    })
  }
  // We want these buttons to show up on the assets page, but not the assets tabs on the team pages
  if (
    !hasProjectOrTaskId &&
    !filters.isArchived &&
    router.route.includes("/assets") &&
    !router.route.includes("/team")
  ) {
    headerActions[0].push(
      {
        value: "Quick add",
        onClick: () => assetQuickAddModalProps.handleOpenModal(),
        Icon: BiListPlus,
        iconStyles: "w-5 h-5",
        requiredPermission: "asset:create",
      },
      {
        value: "Add asset",
        onClick: () => pushDrawer(<AssetCreationDrawer />, "AssetCreation"),
        Icon: BiPlus,
        iconStyles: "w-5 h-5",
        requiredPermission: "asset:create",
      }
    )
  }
  if (hasProjectOrTaskId && !selectedAssets.length) {
    headerActions[0].push({
      value: <Typography>Transfer to project</Typography>,
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
      requiredPermission: "asset:transfer",
      onClick: handleAssignAsset,
    })
  }

  return (
    <div>
      <div className="flex gap-x-8 items-center flex-wrap justify-between">
        <Typography fontSize={hasProjectOrTaskId ? 24 : 36} fontWeight={600} marginBottom={1}>
          Assets
        </Typography>
      </div>
      {/* SUB-HEADER */}
      <div className="grid grid-cols-12 gap-x-2 gap-y-4 md:gap-x-3 md:gap-y-5">
        {/* SEARCH BAR */}
        <div className="col-span-7 md:col-span-5">
          <SearchBar
            fullWidth
            onChange={(value) => {
              assetListStoreActions.setListFilterItems({
                searchText: value,
              })
            }}
            onCancel={() => {
              assetListStoreActions.setListFilterItems({
                searchText: null,
              })
            }}
          />
        </div>
        {/* ACTIONS */}
        <div className="col-span-5 md:col-span-7">
          <HeaderActions items={headerActions} />
        </div>

        {/* FILTER */}
        <div className="col-span-12 flex gap-2">
          <RenderIf permissionsInclude="asset:read">
            <AssetFilterState />
          </RenderIf>
          {!filters.isArchived && <AssetFilterType />}
          <AssetFilterStatusAndCategory extraFilters={statusFilterOptions} filterType="Status" />

          <AssetFilterStatusAndCategory extraFilters={categoryFilterOptions} filterType="Category" />

          <DevelopmentFeatureFlag name="Billing Classifications">
            <AssetFilterBillingClassification />
          </DevelopmentFeatureFlag>
        </div>
      </div>

      <AssetQuickAddTable {...assetQuickAddModalProps} />
      {assetModalProps.isOpen && (
        <TransferAssetToUser formModalProps={assetModalProps} selectedUserIds={[userId || ""]} />
      )}
    </div>
  )
}
