import { isAfter, isBefore } from "date-fns"
import { TimeEntry } from "../../types/TimeEntry"
import { PickPlus } from "../../types/helpers"

type DoubleBooking<T extends PickPlus<TimeEntry, "startAt" | "endAt">> = {
  timeEntry: T
  reason:
    | "invalid proposed startAt"
    | "invalid proposed endAt"
    | "proposed startAt missing required endAt"
    | "proposed startAt cannot be added after time entry that is not clocked out"
}

type DoubleBookingReason =
  | "invalid proposed startAt"
  | "invalid proposed endAt"
  | "proposed startAt missing required endAt"
  | "proposed startAt cannot be added after time entry that is not clocked out"

export function getDoubleBookings<T extends PickPlus<TimeEntry, "startAt" | "endAt">>(
  timeEntries: T[],
  startAt: Date,
  endAt?: Date
): DoubleBooking<T>[] {
  return timeEntries.reduce(
    (doubleBookings, timeEntry) => {
      if (!timeEntry.endAt && isAfter(startAt, timeEntry.startAt)) {
        doubleBookings.push({
          timeEntry,
          reason: "proposed startAt cannot be added after time entry that is not clocked out" as DoubleBookingReason,
        })
      }

      if (isBefore(startAt, timeEntry.startAt) && !endAt) {
        doubleBookings.push({
          timeEntry,
          reason: "proposed startAt missing required endAt" as DoubleBookingReason,
        })
      }

      if (endAt && timeEntry.endAt) {
        const invalidEndDate = {
          timeEntry,
          reason: "invalid proposed endAt" as DoubleBookingReason,
        }
        const invalidStartAt = {
          timeEntry,
          reason: "invalid proposed startAt" as DoubleBookingReason,
        }

        if (isBefore(startAt, timeEntry.startAt) && isAfter(endAt, timeEntry.startAt)) {
          doubleBookings.push(invalidEndDate)
        }

        if (isBefore(startAt, timeEntry.endAt) && isAfter(endAt, timeEntry.endAt)) {
          doubleBookings.push(invalidStartAt)
        }

        if (isAfter(startAt, timeEntry.startAt) && isBefore(endAt, timeEntry.endAt)) {
          doubleBookings.push(invalidStartAt, invalidEndDate)
        }
      }
      return doubleBookings
    },
    [] as { reason: DoubleBookingReason; timeEntry: T }[]
  )
}
