import { TabContext, TabPanel } from "@mui/lab"
import {
  Box,
  InputAdornment,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro"
import { clsx } from "clsx"
import Link from "next/link"
import { FC, Fragment, useContext, useState } from "react"
import { BiSubdirectoryRight } from "react-icons/bi"
import { useQuery } from "urql"
import {
  Asset,
  AssetAssignableType,
  AssetStatus,
  ReassignAssetsSelectUsersTableQuery,
  User,
  useTransferAssetsMutation,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { sortByFieldAscending } from "../../../../../helpers/sorts/sortByFieldAscending"
import { useAssetListGroupConsolidation } from "../../../../../hooks/useAssetListGroupConsolidation"
import { useHandleError } from "../../../../../hooks/useHandleError"
import { useTableSorting } from "../../../../../hooks/useTableSorting"
import { SelectableUser } from "../../../../../types/User"
import { PickPlus } from "../../../../../types/helpers"
import { AssetBadge } from "../../../../AssetBadge"
import { LoadingContainer } from "../../../../Loading/LoadingContainer"
import { ModalBody } from "../../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../../Modals/components/Elements/ModalFooter"
import { errorSnack, infoSnack, successSnack, warningSnack } from "../../../../Notistack/ThemedSnackbars"
import { PageTitle } from "../../../../PageTitle"
import { ProjectBadge } from "../../../../ProjectBadge"
import { ColumnTitle } from "../../../../Table/ColumnTitle"
import { ColumnTitles } from "../../../../Table/ColumnTitles"
import { Row } from "../../../../Table/Row"
import { Table } from "../../../../Table/Table"
import { UserBadge } from "../../../../UserBadge"
import { ButtonFilled, ButtonHollow, H2 } from "../../../../deprecated"
import DeprecatedModal, { ModalTitle } from "../../../../deprecated/StandardModal"
import { AssetAssignmentTableSkeleton } from "../../../Assets/AssetAssignmentTable.skeleton"
import { AssetReassignmentRow } from "../../../Assets/AssetReassignmentRow"
import { AssetTransferSkeleton } from "../../../Assets/AssetTransfer.skeleton"
import { CustomDetailPanelToggle } from "../../../DataGrid/CustomDetailPanelToggle"
import { ProjectAssignmentTableSkeleton } from "../../../Projects/ProjectAssignmentTable.skeleton"
import { SingleDrawerContext } from "../Elements/Drawer"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

export type AssetGroupReassignmentFilterDefinition = {
  assetGroupId: NonNullable<Asset["assetGroupId"]>
  assignableId: NonNullable<Asset["assignableId"]>
  assignableType: NonNullable<Asset["assignableType"]>
  status: NonNullable<AssetStatus>
  compositeKey: string
  count: number
  groupParent: Omit<AssetExpectation, "assignableId" | "assignableType" | "status">
}

type DrawerProps = {
  assetsIdsToReassign?: string[]
  assetGroupToReassign?: AssetGroupReassignmentFilterDefinition | null
  onSuccess?: () => void
}

type TransferProps = DrawerProps & {
  onCancel?: () => void
  onSuccess?: () => void
}

export const ReassignAssetDrawer: FC<DrawerProps> = (props) => {
  const { handleClose } = useContext(SingleDrawerContext)
  return (
    <>
      <DrawerHeader />

      <DrawerBody>
        <AssetTransfer
          {...props}
          onCancel={handleClose}
          onSuccess={() => {
            props.onSuccess?.()
            handleClose()
          }}
        />
      </DrawerBody>
    </>
  )
}

type AssetExpectation = PickPlus<
  Asset,
  | "id"
  | "active"
  | "assetChildCount"
  | "assignableId"
  | "assignableType"
  | "assignedUserId"
  | "companyAssetNumber"
  | "imageUrl"
  | "isAssetGroup"
  | "name"
  | "ownershipType"
  | "status"
> & {
  assignedUser?: PickPlus<User, "id" | "firstName" | "lastName"> | null
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  ".MuiToggleButtonGroup-grouped": {
    padding: "10px 16px",
  },
}))

const AssetTransferDocument = graphql(`
  query AssetTransferQuery($assetIds: [String!], $assetGroupIds: [String!]) {
    assets(deleted: false, assetIds: $assetIds) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      compositeKey
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetsForAssetGroups: assets(assetGroupIds: $assetGroupIds) {
      id
      assetGroupId
      assignableId
      assignableType
      compositeKey
      status
    }
  }
`)

const tabs = ["User", "Asset", "Project"]

const AssetTransfer: FC<TransferProps> = ({ assetGroupToReassign, assetsIdsToReassign = [], onCancel, onSuccess }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const [assetTransferConfirmationModalIsOpen, setAssetTransferConfirmationModalIsOpen] = useState(false)
  const [assetModalProps, setAssetModalProps] = useState<{
    selectedAssignable: AssetExpectation | SelectableUser | AssignableTask
    selectedAssignableType: AssetAssignableType
  } | null>(null)
  const [groupIdLimit, setGroupIdLimit] = useState<number>(0)

  const [{ data: queryData, fetching: assetsIsLoading, error: assetsError }] = useQuery({
    query: AssetTransferDocument,
    variables: {
      assetIds: assetsIdsToReassign,
      assetGroupIds: assetGroupToReassign ? [assetGroupToReassign.assetGroupId] : undefined,
    },
  })

  useHandleError(assetsError, "There was an error loading assets.")

  const [{ fetching: transferAssetIsLoading, error: transferAssetError }, transferAssetsMutation] =
    useTransferAssetsMutation()
  useHandleError(transferAssetError, "There was an error transferring assets.")

  const groupIdsToReassign = [...(queryData?.assetsForAssetGroups || [])]
    .filter(
      (asset) =>
        asset.assetGroupId === assetGroupToReassign?.assetGroupId &&
        asset.assignableId === assetGroupToReassign?.assignableId &&
        asset.assignableType === assetGroupToReassign.assignableType &&
        asset.status === assetGroupToReassign?.status
    )
    .map((asset) => asset.id)

  const assetsToReassign = useAssetListGroupConsolidation({
    assets: queryData?.assets || [],
    assetGroups: assetGroupToReassign ? [assetGroupToReassign] : [],
  })

  const reassignmentCandidates = [...assetsToReassign, assetGroupToReassign]

  const nestedAssetsExist = assetsToReassign.some((asset) => asset.assetChildCount > 0)

  const assignAssets = (
    selectedAssignable: AssetExpectation | SelectableUser | AssignableTask,
    selectedAssignableType: AssetAssignableType
  ) => {
    if (assetGroupToReassign && groupIdLimit < 1) return warningSnack("Please choose a quantity to assign")
    const selectedGroupAssets = groupIdsToReassign.slice(0, groupIdLimit || groupIdsToReassign.length)
    const assetGroupBeingReassigned = assetGroupToReassign
      ? {
          assetGroupId: assetGroupToReassign?.assetGroupId,
          assignableId: assetGroupToReassign.assignableId,
          assignableType: assetGroupToReassign.assignableType,
          status: assetGroupToReassign.status,
          count: Number(groupIdLimit),
        }
      : null

    transferAssetsMutation({
      assetIds: [...assetsIdsToReassign, ...selectedGroupAssets],
      assignableId: selectedAssignable.id,
      assignableType: selectedAssignableType,
      assetGroupReassignments: assetGroupBeingReassigned
        ? [{ filter: assetGroupBeingReassigned, ids: selectedGroupAssets }]
        : null,
    }).then((result) => {
      if (result.error) {
        errorSnack("There was an error reassigning assets.")
        return
      }
      successSnack("Asset successfully reassigned.")
      onSuccess?.()
    })
  }

  const handleAssign = (
    selectedAssignable: AssetExpectation | SelectableUser | AssignableTask,
    selectedAssignableType: AssetAssignableType
  ) => {
    if (nestedAssetsExist) {
      setAssetModalProps({
        selectedAssignable,
        selectedAssignableType,
      })
      setAssetTransferConfirmationModalIsOpen(true)
      return
    }

    assignAssets(selectedAssignable, selectedAssignableType)
  }

  const [activeTab, setActiveTab] = useState(
    reassignmentCandidates.at(0)?.assignableType === "Task"
      ? "Project"
      : reassignmentCandidates.at(0)?.assignableType || AssetAssignableType.User
  )

  if (assetsIdsToReassign.length === 0 && !assetGroupToReassign) {
    infoSnack("No assets to reassign")
    onCancel?.()
  }

  return assetsIsLoading ? (
    <AssetTransferSkeleton />
  ) : (
    <>
      <PageTitle title="Transfer Assets" />
      <div className="grid gap-7">
        <H2 className="mb-0">Transfer Asset</H2>
        <div className="md:pb-6">
          {!assetGroupToReassign &&
            assetsToReassign.map((assetToReassign) => {
              return <AssetBadge key={assetToReassign.id} asset={assetToReassign} />
            })}
          {assetGroupToReassign && (
            <div className="pt-4 md:pt-0 flex flex-row items-center gap-4">
              <AssetBadge asset={assetGroupToReassign.groupParent} />

              <div className="shrink-0">
                <TextField
                  type="number"
                  value={groupIdLimit.toString()}
                  onChange={(e) => {
                    setGroupIdLimit(+e.target.value)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">of {groupIdsToReassign.length}</Typography>
                      </InputAdornment>
                    ),
                    inputProps: {
                      className: "pr-0",
                      min: 1,
                      max: groupIdsToReassign.length,
                      step: 1,
                    },
                  }}
                  sx={{
                    "& input": {
                      minWidth: "26px",
                      width: `${groupIdsToReassign.length.toString().length * 10 + 18 + "px"}`,
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between md:items-center gap-4 md:pb-3">
          <StyledToggleButtonGroup
            value={activeTab}
            color="primary"
            exclusive
            onChange={(_, value) => value !== null && setActiveTab(value)}
          >
            {tabs.map((tab) => (
              <ToggleButton key={tab} value={tab}>
                {tab}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <TextField
            size="small"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-72"
          />
        </div>
        <TabContext value={activeTab}>
          <TabPanel value="User" className="min-h-screen">
            <SelectUserTable assetsToReassign={assetsToReassign} onAssign={handleAssign} searchTerm={searchTerm} />
          </TabPanel>
          <TabPanel value="Asset" className="min-h-screen">
            {assetsIsLoading ? (
              <AssetAssignmentTableSkeleton />
            ) : (
              <SelectAssetTable
                assetsToReassign={assetsToReassign}
                handleAssign={handleAssign}
                searchTerm={searchTerm}
              />
            )}
          </TabPanel>
          <TabPanel value="Project" className="min-h-screen">
            <SelectProjectTable
              assetsToReassign={assetsToReassign}
              onAssign={handleAssign}
              searchTerm={searchTerm}
              muiEnabled
            />
          </TabPanel>
        </TabContext>
      </div>
      {assetModalProps && (
        <DeprecatedModal
          isOpen={assetTransferConfirmationModalIsOpen}
          contentLabel={<ModalTitle>Reassign Asset</ModalTitle>}
          handleCloseModal={() => {
            setAssetTransferConfirmationModalIsOpen(false)
          }}
        >
          <ModalBody>
            <LoadingContainer isLoading={transferAssetIsLoading}>
              <p>
                Are you sure you want to assign {groupIdLimit ? groupIdLimit : assetsToReassign?.length ?? 0}{" "}
                {assetsToReassign.map((asset) => (
                  <AssetDisplayName key={`reassign-${asset?.id}`} asset={asset} />
                ))}{" "}
                with {assetsToReassign.reduce((acc, asset) => (acc += asset?.assetChildCount || 0), 0)} linked assets to{" "}
                {assetModalProps.selectedAssignableType === AssetAssignableType.User && (
                  <UserDisplayName user={assetModalProps.selectedAssignable as User} />
                )}
                {assetModalProps.selectedAssignableType === AssetAssignableType.Asset && (
                  <AssetDisplayName asset={assetModalProps.selectedAssignable as AssetExpectation} />
                )}
                {assetModalProps.selectedAssignableType === AssetAssignableType.Task && (
                  <TaskDisplayName task={assetModalProps.selectedAssignable as AssignableTask} />
                )}
                ?
              </p>
            </LoadingContainer>
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={() => setAssetTransferConfirmationModalIsOpen(false)} disabled={false}>
              Cancel
            </ButtonHollow>
            <ButtonFilled
              type="button"
              disabled={!!transferAssetIsLoading}
              onClick={() => assignAssets(assetModalProps!.selectedAssignable, assetModalProps!.selectedAssignableType)}
            >
              Confirm
            </ButtonFilled>
          </ModalFooter>
        </DeprecatedModal>
      )}
    </>
  )
}

const ReassignAssetsSelectUsersTableQueryDocument = graphql(`
  query ReassignAssetsSelectUsersTable {
    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
      isClockedIn
      project {
        id
        name
      }
      task {
        id
        name
      }
    }
  }
`)

type UserExpectation = ReassignAssetsSelectUsersTableQuery["usersList"][0]

const SelectUserTable: FC<{
  assetsToReassign: AssetExpectation[]
  onAssign: (selectedAssignable: SelectableUser, selectedAssignableType: AssetAssignableType) => void
  searchTerm: string
}> = ({ assetsToReassign, onAssign, searchTerm }) => {
  const [{ data, fetching }] = useQuery({ query: ReassignAssetsSelectUsersTableQueryDocument })

  const { tableSortingState, sortByKey } = useTableSorting<UserExpectation>(
    data?.usersList,
    {
      name: sortByFieldAscending((entry: UserExpectation) => `${entry.firstName} ${entry.lastName}`),
      project: sortByFieldAscending((entry: UserExpectation) => entry.project.name),
      task: sortByFieldAscending((entry: UserExpectation) => entry.task.name),
    },
    "name"
  )

  if (fetching) {
    return <AssetAssignmentTableSkeleton />
  }

  return (
    <div>
      <ColumnTitles>
        <ColumnTitle className="col-span-4" sortByKey={sortByKey} sortKey="name" tableSortingState={tableSortingState}>
          Name
        </ColumnTitle>
        <ColumnTitle
          className="col-span-3"
          tableSortingState={tableSortingState}
          sortByKey={sortByKey}
          sortKey="project"
        >
          Project
        </ColumnTitle>
        <ColumnTitle className="col-span-3" tableSortingState={tableSortingState} sortByKey={sortByKey} sortKey="task">
          Task
        </ColumnTitle>
      </ColumnTitles>
      <Table>
        {tableSortingState.sortedData
          // if all the assets are already assigned to this user, don't show the user
          .filter((user) => {
            return assetsToReassign.some((assetToReassign) => {
              if (assetToReassign.assignableType !== AssetAssignableType.User) return true
              return assetToReassign.assignedUserId !== user.id
            })
          })
          // search filter
          .filter((user) => {
            return `${user.firstName} ${user.lastName} ${user.jobTitle}`
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          })
          .map((user) => {
            return (
              <Row
                key={user.id}
                className={clsx(
                  "grid grid-cols-12 md:gap-y-6 group",
                  "md:grid-flow-row md:grid-rows-1 md:items-center md:gap-x-6 md:py-3"
                )}
              >
                <div className="col-span-12 flex justify-between gap-2 md:col-span-4">
                  <div className="flex gap-x-3 items-center truncate">
                    <UserBadge user={user} />
                  </div>
                </div>
                <div className="col-span-12 mt-6 md:mt-0 font-medium text-sm text-gray-400 md:hidden uppercase">
                  Project
                </div>
                <div className="col-span-12 md:col-span-3 mt-2 md:mt-0">{user.project.name}</div>
                <div className="col-span-12 mt-6 md:mt-0 font-medium text-sm text-gray-400 md:hidden uppercase">
                  Task
                </div>
                <div className="col-span-12 md:col-span-3 mt-2 md:mt-0">{user.task?.name}</div>
                <div className="col-span-12 md:col-span-2 mt-6 md:mt-0 flex justify-end">
                  <ButtonFilled
                    className="py-3"
                    color="primary"
                    type="button"
                    onClick={() => onAssign(user, AssetAssignableType.User)}
                  >
                    Transfer
                  </ButtonFilled>
                </div>
              </Row>
            )
          })}
      </Table>
    </div>
  )
}

const SelectAssetTableQueryDocument = graphql(`
  query SelectAssetTable {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
  }
`)
const SelectAssetTable: FC<{
  assetsToReassign: AssetExpectation[]
  handleAssign: (selectedAssignable: AssetExpectation, selectedAssignableType: AssetAssignableType) => void
  searchTerm: string
}> = ({ assetsToReassign, handleAssign, searchTerm }) => {
  const [{ data }] = useQuery({ query: SelectAssetTableQueryDocument })
  const assets = data?.assets || []

  const { tableSortingState, sortByKey } = useTableSorting<AssetExpectation>(
    assets || [],
    {
      name: sortByFieldAscending((entry: AssetExpectation) => `${entry.name}`),
      assignment: sortByFieldAscending(
        (entry: AssetExpectation) => `${entry?.assignedUser?.firstName} ${entry?.assignedUser?.lastName}`
      ),
      status: sortByFieldAscending((entry: AssetExpectation) => entry.name),
    },
    "name"
  )

  return (
    <div>
      <ColumnTitles>
        <ColumnTitle className="col-span-4" sortByKey={sortByKey} sortKey="name" tableSortingState={tableSortingState}>
          Name
        </ColumnTitle>
        <ColumnTitle
          className="col-span-3"
          tableSortingState={tableSortingState}
          sortByKey={sortByKey}
          sortKey="assignment"
        >
          Assignee
        </ColumnTitle>
        <ColumnTitle
          className="col-span-3"
          tableSortingState={tableSortingState}
          sortByKey={sortByKey}
          sortKey="status"
        >
          Status
        </ColumnTitle>
      </ColumnTitles>
      <Table>
        {tableSortingState.sortedData
          .filter((assetRowData) => assetRowData.assignableType !== AssetAssignableType.Asset)
          .map((assetRowData) => {
            return (
              <AssetReassignmentRow
                asset={assetRowData}
                activeAssets={assets}
                key={assetRowData.id}
                assignableAssets={assetsToReassign}
                onAssign={handleAssign}
                forceOpen={searchTerm.length > 0}
                searchTerm={searchTerm}
              />
            )
          })}
      </Table>
    </div>
  )
}

const ALL_TASK_TYPES = ["task", "taskGroup"] as const
type TaskTypeTuple = typeof ALL_TASK_TYPES
type TaskType = TaskTypeTuple[number]

type AssignableTask = {
  id: string // task id
  groupId?: string | null
  projectCode?: string | null
  projectId: string
  projectImageUrl?: string | null
  projectIsArchived?: Date | null
  projectIsComplete?: Date | null
  projectName: string
  taskName: string
  sortOrder?: number | null
  type: TaskType
}

const AssetProjects = graphql(`
  query AssetProjects {
    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
      tasks {
        id
        name
        groupId
        projectId
        sortOrder
      }
      taskGroups {
        id
        name
        projectId
        sortOrder
      }
    }
  }
`)

type AssetToReassign = PickPlus<Asset, "id" | "assignableType" | "assignedTaskId" | "assignableId">

const SelectProjectTable: FC<{
  assetsToReassign: AssetToReassign[]
  onAssign: (selectedAssignable: AssignableTask, selectedAssignableType: AssetAssignableType) => void
  searchTerm: string
  muiEnabled?: boolean
}> = ({ assetsToReassign, onAssign, searchTerm }) => {
  const [{ data, error: projectsError, fetching: projectsIsLoading }] = useQuery({
    query: AssetProjects,
  })
  useHandleError(projectsError, "There was an error loading projects.")

  const projects = data?.projectsByStatus || []

  const taskList: AssignableTask[] = []

  projects.forEach(
    ({
      id: projectId,
      name: projectName,
      isArchived: projectIsArchived,
      isComplete: projectIsComplete,
      imageUrl: projectImageUrl,
      code: projectCode,
      tasks,
      taskGroups,
    }) => {
      const combinedTaskList: {
        id: string
        name: string
        groupId?: string | null
        sortOrder?: number | null
        type: TaskType
      }[] = [
        ...tasks.map((t) => ({ ...t, type: "task" as TaskType })),
        ...taskGroups.map((t) => ({ ...t, type: "taskGroup" as TaskType })),
      ].filter((t) => t.name.toLowerCase().includes(searchTerm.toLowerCase()))
      combinedTaskList.forEach(({ id, name: taskName, groupId, sortOrder, type }) => {
        taskList.push({
          projectId,
          projectName,
          id,
          groupId,
          taskName,
          projectIsArchived,
          projectIsComplete,
          projectImageUrl,
          projectCode,
          sortOrder,
          type,
        })
      })
    }
  )

  const apiRef = useGridApiRef()

  if (projectsIsLoading || !projects) {
    return <ProjectAssignmentTableSkeleton />
  }

  const AssignmentTableRow = ({
    assetsToReassign: assets,
    task,
  }: {
    assetsToReassign: AssetToReassign[]
    task: AssignableTask
  }) => {
    return (
      <TableRow>
        <TableCell>
          <Box display="flex" gap="1rem">
            {task.groupId && (
              <Box paddingLeft="0.5rem">
                <BiSubdirectoryRight className="size-6" />
              </Box>
            )}
            <Typography fontSize="1rem">{task.taskName}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box minHeight={40}>
            {task.type === "task" && (
              <ButtonFilled
                disabled={assets.every((asset) => asset.assignableId === task.id)}
                className="py-3"
                color="primary"
                type="button"
                onClick={() => onAssign(task, AssetAssignableType.Task)}
              >
                Transfer
              </ButtonFilled>
            )}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  const getDetailPanelContent = (params: GridRowParams<AssignableTask>) => {
    const tasks = taskList.filter((task) => !task.groupId && task.projectId === params.id)

    return (
      <div className="mx-4 lg:mx-8 mt-4 mb-8">
        <MuiTable width="100%">
          <TableHead>
            <TableRow>
              <TableCell width="100%">
                <Box paddingBottom="0.5rem">
                  <Typography fontWeight={600}>Task</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box paddingBottom="0.5rem">
                  <Typography fontWeight={600}>Assign</Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => {
              const subTasks = taskList.filter((t) => t.groupId === task.id)
              return (
                <Fragment key={task.id}>
                  <AssignmentTableRow assetsToReassign={assetsToReassign} key={task.id} task={task} />
                  {subTasks.map((subTask) => (
                    <AssignmentTableRow assetsToReassign={assetsToReassign} key={subTask.id} task={subTask} />
                  ))}
                </Fragment>
              )
            })}
          </TableBody>
        </MuiTable>
      </div>
    )
  }

  return (
    <DataGridPro
      apiRef={apiRef}
      sx={{
        // disable border on table
        border: "none",
        // line up the left side to the edge
        "& .MuiDataGrid-cell": {
          paddingLeft: 0,
        },
      }}
      columns={[
        {
          field: "project",
          headerName: "Project Name",
          flex: 4,
          sortable: false,
          disableColumnMenu: true,
          resizable: false,
          renderCell: ({ row: project }) => {
            return (
              <ProjectBadge
                project={{
                  id: project.id,
                  name: project.name,
                  code: project.code,
                  isArchived: project.isArchived,
                  isComplete: project.isComplete,
                  imageUrl: project.imageUrl,
                }}
              />
            )
          },
        },
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          width: 64,
          resizable: false,
          renderCell: ({ id, value }) => <CustomDetailPanelToggle id={id} value={value} />,
        },
      ]}
      onRowClick={(params) => {
        // toggle the detail panel
        apiRef.current.toggleDetailPanel(params.id)
      }}
      disableRowSelectionOnClick
      rowHeight={64}
      autoHeight
      rows={projects}
      getDetailPanelHeight={() => "auto"}
      getDetailPanelContent={(params) => getDetailPanelContent(params)}
    />
  )
}

const AssetDisplayName = ({ asset }: { asset: PickPlus<Asset, "id" | "name" | "companyAssetNumber"> }) => {
  return (
    <Link className="text-blue-600 transition-colors hover:text-blue-900" href={`/assets/${asset.id}`}>
      {asset.name}
      {asset.companyAssetNumber && ` ${asset.companyAssetNumber}`}
    </Link>
  )
}

const UserDisplayName = ({ user }: { user: User }) => {
  return (
    <Link className="text-blue-600 transition-colors hover:text-blue-900" href={`/team/${user.id}`}>
      {user.firstName} {user.lastName}
    </Link>
  )
}

const TaskDisplayName = ({ task }: { task: AssignableTask }) => {
  return (
    <Link
      className="text-blue-600 transition-colors hover:text-blue-900"
      href={`/projects/${task.projectId}/${task.id}`}
    >
      {task.taskName}
    </Link>
  )
}
