import { Form, Formik } from "formik"
import { FC, useRef, useState } from "react"
import * as Yup from "yup"
import { MuiModal } from "./Elements/MuiModal"
import { ModalProps } from "../hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { TextField } from "../../Formik/TextField"
import { User, useCreateInjuryReportMutation } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { Typography } from "@mui/material"
import { ProjectAndTasksMultiSelects } from "../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import ReactSignatureCanvas from "react-signature-canvas"
import { SignaturePad } from "../../SignaturePad"
import { signatureCanvasToBlob } from "../../ClockInOut/workflows/InjurySignature"
import { v4 } from "uuid"
import { getInjuryReportSignedPutUrl } from "../../../data/api"

type Props = {
  modalProps: ModalProps
  user: PickPlus<User, "id" | "firstName" | "lastName" | "currentTaskId" | "currentProjectId">
}

type InjuryReportValues = {
  notes: string
  assignment: {
    selectedProjectId: string[]
    selectedTaskId: string[]
  }
}

const defaultContentType = "image/webp"

const uploadInjurySignature = async (file: Blob, injuryReportId: string): Promise<string | undefined> => {
  try {
    const { url, path } = await getInjuryReportSignedPutUrl(defaultContentType, injuryReportId)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": defaultContentType,
      },
      body: file,
    })

    return path
  } catch (e) {
    errorSnack("There was a problem uploading your image. Please try again.")
  }
}

export const InjuryReportModal: FC<Props> = ({ modalProps, user }) => {
  const [_, createInjuryReport] = useCreateInjuryReportMutation()
  const [signaturePadTouched, setSignaturePadTouched] = useState(false)
  const signatureCanvasRef = useRef<ReactSignatureCanvas>(null)

  const handleSubmit = async (values: InjuryReportValues, resetForm: () => void) => {
    try {
      const injuryReportId = v4()
      const signatureBlob = await signatureCanvasToBlob(signatureCanvasRef)
      const signaturePath = await uploadInjurySignature(signatureBlob, injuryReportId)
      if (!signaturePath) throw new Error("Error: signature path is missing")

      const {
        selectedProjectId: [projectId],
        selectedTaskId: [taskId],
      } = values.assignment

      const response = await createInjuryReport({
        id: injuryReportId,
        userId: user.id,
        notes: values.notes,
        projectId,
        taskId,
        signaturePath,
      })
      if (response.error) {
        console.error(response.error)
        throw new Error("Error submitting the Injury Report")
      }
      resetForm()
      modalProps.handleCloseModal()
      successSnack("Injury report submitted successfully")
    } catch (error) {
      errorSnack(error)
    }
  }

  return (
    <Formik<InjuryReportValues>
      enableReinitialize
      initialValues={{
        notes: "",
        assignment: { selectedProjectId: [user.currentProjectId], selectedTaskId: [user.currentTaskId] },
      }}
      validationSchema={Yup.object().shape({
        notes: Yup.string(),
        assignment: Yup.object().shape({
          selectedProjectId: Yup.array(Yup.string()).min(1, "A project must be selected."),
          selectedTaskId: Yup.array(Yup.string()).min(1, "A task must be selected."),
        }),
      })}
      onSubmit={async (values, { resetForm }) => await handleSubmit(values, resetForm)}
    >
      {({ submitForm, resetForm, isSubmitting }) => (
        <MuiModal
          contentLabel="Injury Report"
          submitButtonText="Report Injury"
          isOpen={modalProps.isOpen}
          handleCloseModal={() => {
            resetForm()
            modalProps.handleCloseModal()
          }}
          disabled={isSubmitting || !signaturePadTouched}
          submitForm={submitForm}
        >
          <Form className="h-full flex flex-col">
            <Typography variant="body1" className="pb-3">
              Submit injury report for{" "}
              <span className="font-medium">
                {user.firstName} {user.lastName}
              </span>
            </Typography>

            <ProjectAndTasksMultiSelects
              formGroupId="assignment"
              includeCurrentAssignment
              withErrorHandling
              clearable={false}
            />

            <TextField className="mt-5" placeholder="Injury description" name="notes" multiline rows={4} />

            <SignaturePad
              ref={signatureCanvasRef}
              onEnd={() => setSignaturePadTouched(true)}
              onClear={() => setSignaturePadTouched(false)}
            />
          </Form>
        </MuiModal>
      )}
    </Formik>
  )
}
