import { Typography } from "@mui/material"
import { UserNotificationModelType } from "@prisma/client"
import { clsx } from "clsx"
import { FC } from "react"
import { BiHash, BiLink, BiPurchaseTag, BiX } from "react-icons/bi"
import { Asset } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"
import { AssetImage } from "./AssetImage"
import { AssetDrawer } from "./Partials/Drawer/components/Asset/AssetDrawer"
import { DrawerLink } from "./Partials/Drawer/components/Elements/DrawerLink"
import { NotificationBubble } from "./UserNotifications/NotificationBubble"

type AssetBadgeAssetType = PickPlus<
  Asset,
  | "id"
  | "name"
  | "assetGroupId"
  | "companyAssetNumber"
  | "isAssetGroup"
  | "assetChildCount"
  | "ownershipType"
  | "groupQuantity"
>

type AssetBadgeProps = {
  asset: AssetBadgeAssetType & { activeCount?: number; missingCount?: number; depth?: number }
  className?: string
  showDepth?: boolean
}

export const AssetBadge: FC<AssetBadgeProps> = ({ asset, className, showDepth }) => {
  const {
    id,
    name,
    assetGroupId,
    companyAssetNumber,
    isAssetGroup,
    assetChildCount,
    ownershipType,
    groupQuantity,
    activeCount = 0,
    missingCount = 0,
  } = asset || {}

  const isAssetChildStack = !!assetGroupId || assetChildCount > 0
  const assetStackQuantity = activeCount + missingCount

  const getAssetDisplayValue = () => {
    if (isAssetGroup) {
      return assetChildCount || groupQuantity || assetStackQuantity
    }

    return assetChildCount
  }

  return (
    <div className="flex gap-2">
      {showDepth && !!asset?.depth && asset?.depth > 0 && (
        <div className="flex items-center gap-[5px]">
          {[...Array(asset.depth)].map((_e, i) => (
            <div
              key={i}
              className={clsx(
                "w-[5px] h-12 md:h-10 rounded-full",
                i + 1 === asset.depth ? "bg-gray-800" : "bg-gray-200"
              )}
            />
          ))}
        </div>
      )}
      <DrawerLink
        href={`/assets/${id}/details`}
        component={<AssetDrawer assetId={id} />}
        className={clsx("flex gap-1.5 items-center", className)}
        drawerName="Asset"
      >
        <AssetImage width="w-12 lg:w-9" height="h-12 lg:h-9" padding="p-0" asset={asset} />
        <div className="grid content-center gap-y-0.5 overflow-hidden">
          <div className="flex items-center">
            <NotificationBubble
              className="mt-1"
              shouldRender={(notifications) => {
                return !!notifications.filter(
                  (n) => n.modelType === UserNotificationModelType.Asset && n.asset?.id === asset.id
                ).length
              }}
            />
            <Typography variant="h6" className="mt-1 h-[17px] text-ellipsis line-clamp-1 break-all">
              {name}
            </Typography>
          </div>
          <div className="flex gap-2 items-center text-xs text-gray-600 truncate">
            {ownershipType === "RENT" && (
              <div className="flex items-center gap-px text-orange-600">
                <BiPurchaseTag />
                <div>Rental</div>
              </div>
            )}
            {(isAssetGroup || isAssetChildStack) && (
              <div className="flex items-center gap-px">
                {isAssetGroup || assetGroupId ? <BiX /> : <BiLink />}
                <div>{getAssetDisplayValue()}</div>
              </div>
            )}
            {companyAssetNumber && (
              <div className="flex items-center gap-px">
                <BiHash />
                <div className="truncate">{companyAssetNumber}</div>
              </div>
            )}
          </div>
        </div>
      </DrawerLink>
    </div>
  )
}
