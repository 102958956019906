import { useEffect, useReducer } from "react"
import { usePrevious } from "./usePrevious"

type SortCallbackMap<T> = {
  [sortKey: string]: (a: T, b: T) => number
}

type TableSorting<T> = {
  tableSortingState: TableSortingState<T>
  sortByKey: (key: string) => void
}

type TableSortingStateAction = {
  type: "sort" | "setup"
  payload?: {
    sortKey: string
  }
}

export type TableSortingState<T> = {
  sortKeys: {
    [sortKey: string]: boolean
  }
  sortedData: T[]
  currentSortKey: string
}

function handleSort<T>(
  action: TableSortingStateAction,
  state: TableSortingState<T>,
  sortCallbackMap: SortCallbackMap<T>,
  data: T[]
) {
  if (!action?.payload?.sortKey) {
    return state
  }

  // if the sort function you called doesn't exist, return the original state
  const sortFunction = sortCallbackMap[action.payload.sortKey]
  if (!sortFunction) {
    return state
  }

  // set up the sort keys
  const currentKeySortShouldBeAscending =
    state.currentSortKey === action.payload.sortKey ? !state.sortKeys[action.payload.sortKey] : true
  const sortKeys = { ...state.sortKeys, [action.payload.sortKey]: currentKeySortShouldBeAscending }

  // sort the data correctly
  const defaultSortedData = [...data].sort(sortFunction)
  const sortedData = sortKeys[action.payload.sortKey] ? defaultSortedData : [...defaultSortedData].reverse()

  // set the current sort key
  const currentSortKey = action.payload.sortKey

  return {
    sortKeys,
    sortedData,
    currentSortKey,
  }
}

function handleSetup<T>(state: TableSortingState<T>, sortCallbackMap: SortCallbackMap<T>, data: T[]) {
  // set the default state
  const setupSortFunction = sortCallbackMap[state.currentSortKey]
  const setupSortedData = state.sortKeys[state.currentSortKey]
    ? [...data].sort(setupSortFunction)
    : [...data].sort(setupSortFunction).reverse()

  // if the sorted data didn't change, return the old state
  if (JSON.stringify(state.sortedData) === JSON.stringify(setupSortedData)) {
    return state
  }

  return {
    sortKeys: { ...state.sortKeys },
    sortedData: setupSortedData,
    currentSortKey: state.currentSortKey,
  }
}

export function useTableSorting<T>(
  data: T[] = [],
  sortCallbackMap: SortCallbackMap<T>,
  initialSortKey: string
): TableSorting<T> {
  const [tableSortingState, dispatchTableSortingState] = useReducer<
    (state: TableSortingState<T>, action: TableSortingStateAction) => TableSortingState<T>
  >(reducer, {
    sortKeys: Object.keys(sortCallbackMap).reduce(
      (acc: { [sortKey: string]: boolean }, curr: string) => ((acc[curr] = true), acc),
      {}
    ),
    sortedData: <T[]>[],
    currentSortKey: initialSortKey,
  })

  function reducer(state: TableSortingState<T>, action: TableSortingStateAction) {
    switch (action.type) {
      case "sort":
        return handleSort(action, state, sortCallbackMap, data)

      case "setup":
        return handleSetup(state, sortCallbackMap, data)
    }
  }

  const previousData = usePrevious(data)
  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(previousData)) {
      dispatchTableSortingState({
        type: "setup",
      })
    }
  }, [data, previousData])

  const sortByKey = (sortKey: string) => {
    dispatchTableSortingState({
      type: "sort",
      payload: {
        sortKey,
      },
    })
  }

  return {
    tableSortingState,
    sortByKey,
  }
}
