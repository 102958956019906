import { DataGridPro, GridColDef, GridRowParams, GridValueFormatterParams } from "@mui/x-data-grid-pro"
import { differenceInDays, format, isToday } from "date-fns"
import { FC, useMemo } from "react"
import { BiSolidCheckCircle } from "react-icons/bi"
import { useQuery } from "urql"
import { ReportedUnitsInDateRangeQuery } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { colors } from "../../../helpers/styles/colors"
import { CustomLabeledSection } from "../../LabeledSection"
import { H5 } from "../../deprecated"
import { CustomDetailPanelToggleColDef } from "../DataGrid/CustomDetailPanelToggle"
import { DataGridEmptyState } from "../DataGridEmptyState"
import { TaskTitle } from "../Projects/Tasks/TaskTitle"
import { defaultColumnProps } from "./Sections/ProjectAndTaskSummaryTeamTable"
import { Skeleton, Typography } from "@mui/material"
import { TotalTimeWithEdits } from "../User/TimeEntries/TotalTimeWithEdits"

const ReportedUnitsInDateRangeDocument = graphql(`
  query ReportedUnitsInDateRange($rangeStart: DateTime, $rangeEnd: DateTime, $projectId: String!, $userId: String!) {
    user(id: $userId) {
      reportedUnitsInDateRangeByProjectOrTask(projectId: $projectId, rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
        id
        taskId
        task {
          name
          id
          isComplete
        }
        createdAt
        imageUrls
        note
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              id
              description
              unitOfMeasure
            }
          }
        }
      }
    }
  }
`)

const UserTimeEntriesSummary = graphql(`
  query UserTimeEntriesSummary($userId: String!, $rangeStartOn: String!, $rangeEndOn: String!) {
    timeEntryDays(userId: $userId, rangeEndOn: $rangeEndOn, rangeStartOn: $rangeStartOn) {
      rangeTotalPaidTimeInSeconds
      days {
        id
        totalPaidTimeInSeconds
        totalEditedTimeInSeconds
        timeEntryWrappers {
          id
          isBreak
          totalEditedTimeInSeconds
          actors {
            id
            firstName
            lastName
            imageUrl
          }
          timeEntry {
            id
            durationInSeconds
            startAt
            endAt
            project {
              id
              name
            }
            task {
              id
              name
              isComplete
            }
          }
        }
      }
    }
  }
`)

export interface TeamSummaryQueryProps {
  rangeStart: Date
  rangeEnd: Date
  projectId: string
  userId: string
}

interface DropDownTablesProps extends TeamSummaryQueryProps {
  initialState: {
    columns: {
      columnVisibilityModel: { createdAt: boolean }
    }
  }
}

type TeamMemberDetailTaskReportRow = NonNullable<
  NonNullable<ReportedUnitsInDateRangeQuery["user"]>["reportedUnitsInDateRangeByProjectOrTask"]
>[0]

const TimeEntryTable: FC<DropDownTablesProps> = ({ rangeStart, rangeEnd, projectId, userId, initialState }) => {
  const [{ data, fetching }] = useQuery({
    query: UserTimeEntriesSummary,
    variables: {
      rangeStartOn: format(rangeStart, "yyyy-MM-dd"),
      rangeEndOn: format(rangeEnd, "yyyy-MM-dd"),
      userId,
    },
  })

  const timeEntryWrappers = data?.timeEntryDays.days.flatMap((day) => day.timeEntryWrappers || []) || []

  type TimeEntryWrappersFromQuery = (typeof timeEntryWrappers)[0]

  const timeEntryTableColumns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      renderCell: ({ row }: { row: TimeEntryWrappersFromQuery }) => format(row.timeEntry.startAt, "MM.dd.yyyy"),
    },
    {
      field: "task",
      renderCell: ({ row }: { row: TimeEntryWrappersFromQuery }) => (
        <div className="flex gap-x-2 items-center">
          <BiSolidCheckCircle color={colors.green[600]} />
          <TaskTitle
            taskOrGroupId={row.timeEntry.task.id}
            projectId={projectId}
            isComplete={Boolean(row.timeEntry.task.isComplete)}
            size="small"
            name={row.timeEntry.task.name}
            secondaryTitle
          />
        </div>
      ),
      flex: 2,
      headerName: "Task",
    },
    {
      headerName: "Started",
      field: "startAt",
      minWidth: 90,
      renderCell: ({ row }: { row: TimeEntryWrappersFromQuery }) => format(new Date(row.timeEntry.startAt), "h:mm a"),
    },
    {
      headerName: "Ended",
      field: "endAt",
      minWidth: 90,
      renderCell: ({ row }: { row: TimeEntryWrappersFromQuery }) =>
        row.timeEntry.endAt ? (
          format(new Date(row.timeEntry.endAt), "h:mm a")
        ) : (
          <Typography color="primary">Clocked in</Typography>
        ),
    },
    {
      field: "durationInSeconds",
      headerName: "Total",
      minWidth: 120,
      renderCell: ({ row }: { row: TimeEntryWrappersFromQuery }) => {
        return (
          <TotalTimeWithEdits
            totalTimeInSeconds={row.timeEntry.durationInSeconds}
            totalEditedTimeInSeconds={row.totalEditedTimeInSeconds}
            showTimeDiff
            isClockedIn={isToday(row.timeEntry.startAt) && row.timeEntry.startAt && !row.timeEntry.endAt}
            className="flex-col"
          />
        )
      },
    },
  ]

  const isEmpty = !timeEntryWrappers || timeEntryWrappers.length === 0

  if (fetching && isEmpty)
    return (
      <div className="pt-2">
        <H5>Time Entries</H5>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )

  return (
    <div className="pt-2">
      <H5>Time Entries</H5>
      {timeEntryWrappers.length > 0 ? (
        <DataGridPro
          autoHeight
          className="pl-2"
          columns={timeEntryTableColumns.map((column) => ({
            ...defaultColumnProps,
            ...column,
          }))}
          disableRowSelectionOnClick
          hideFooter
          initialState={initialState}
          rows={timeEntryWrappers}
        />
      ) : (
        <DataGridEmptyState text="No time entries" />
      )}
    </div>
  )
}

const TaskReportTable: FC<DropDownTablesProps> = ({ rangeStart, rangeEnd, projectId, userId, initialState }) => {
  const taskReportTableColumns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 120,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["createdAt"]>) =>
        format(new Date(value), "MM.dd.yyyy"),
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 80,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => format(new Date(row.createdAt), "h:mm a"),
    },
    {
      field: "task",
      minWidth: 150,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => (
        <TaskTitle
          taskOrGroupId={row.task.id}
          projectId={projectId}
          isComplete={Boolean(row.task.isComplete)}
          size="small"
          name={row.task.name}
          secondaryTitle
        />
      ),
      flex: 2,
      headerName: "Task",
    },
    {
      field: "reported",
      headerName: "Reported",
      minWidth: 110,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => {
        const reported =
          row.unitGoalProgressReports.length > 1
            ? "Multiple"
            : row.unitGoalProgressReports.length === 1
              ? `${row.unitGoalProgressReports[0].progress} ${row.unitGoalProgressReports[0].unitGoal.deliverableUnit.unitOfMeasure}`
              : "None"

        return <div>{reported}</div>
      },
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 110,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => {
        const unitDescription =
          row.unitGoalProgressReports.length > 1
            ? "Multiple"
            : row.unitGoalProgressReports.length === 1
              ? row.unitGoalProgressReports[0].unitGoal.deliverableUnit.description
              : "None"

        return <div>{unitDescription}</div>
      },
    },
    {
      field: "imageUrls",
      headerName: "Images",
      minWidth: 80,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["imageUrls"]>) =>
        (value || []).length,
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 60,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["note"]>) =>
        value ? "Yes" : "No",
    },
    { ...CustomDetailPanelToggleColDef },
  ]
  const [{ data }] = useQuery({
    query: ReportedUnitsInDateRangeDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
  })
  return (
    <div className="pt-4 pb-10">
      <H5>Task Reports</H5>
      {data?.user?.reportedUnitsInDateRangeByProjectOrTask &&
      data?.user?.reportedUnitsInDateRangeByProjectOrTask.length > 0 ? (
        <DataGridPro
          autoHeight
          className="pl-2"
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={getDetailPanelContentForTaskReport}
          columns={taskReportTableColumns.map((column) => ({
            ...defaultColumnProps,
            ...column,
          }))}
          disableRowSelectionOnClick
          hideFooter
          initialState={initialState}
          rows={data?.user?.reportedUnitsInDateRangeByProjectOrTask ?? []}
        />
      ) : (
        <DataGridEmptyState text="No reports" />
      )}
    </div>
  )
}

export const TeamMemberDetailPanel: FC<TeamSummaryQueryProps> = ({ userId, projectId, rangeStart, rangeEnd }) => {
  const initialState = useMemo(() => {
    const isSameDay = differenceInDays(rangeEnd, rangeStart) <= 1
    return {
      columns: {
        columnVisibilityModel: { createdAt: isSameDay ? false : true },
      },
    }
  }, [rangeEnd, rangeStart])

  return (
    <div className="px-10 pt-2">
      <TimeEntryTable
        userId={userId}
        projectId={projectId}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        initialState={initialState}
      />
      <TaskReportTable
        userId={userId}
        projectId={projectId}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        initialState={initialState}
      />
    </div>
  )
}

const getDetailPanelContentForTaskReport = (params: GridRowParams<TeamMemberDetailTaskReportRow>) => {
  const { note, imageUrls, createdAt } = params.row

  if (!note && (!imageUrls || imageUrls.length === 0)) return null

  return (
    <div>
      {imageUrls && imageUrls.length > 0 && (
        <div className="p-4 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4">
          {imageUrls.map(
            (url) =>
              url && (
                <img
                  key={url}
                  alt="task update image"
                  src={url}
                  className="shadow-md rounded-md aspect-1 object-cover h-40"
                />
              )
          )}
        </div>
      )}
      <div className="p-4">
        {note && (
          <CustomLabeledSection
            label={format(createdAt, "MMM do")}
            className="p-4 mr-4 m-2 bg-yellow-100 shadow-md border-gray-200 rounded-md inline-block"
            note
          >
            {note}
          </CustomLabeledSection>
        )}
      </div>
    </div>
  )
}
