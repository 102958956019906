import { Box, LinearProgress, Typography } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { clsx } from "clsx"
import { FC, cloneElement } from "react"
import { BiCheckCircle, BiChevronsUp, BiListUl, BiSubdirectoryRight } from "react-icons/bi"
import { useQuery } from "urql"
import { ProjectSummaryTaskTableQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { getFriendlyFloat } from "../../../../helpers/numbers/getFriendlyFloat"
import { colors } from "../../../../helpers/styles/colors"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { HoursAndMinutes } from "../../../Time/HoursAndMinutes"
import {
  ProductionRateSpan,
  getProductionRateArrowClassName,
} from "../../../Widgets/SummaryWidgets/ProductionRateWidget"
import { TaskTitle } from "../../Projects/Tasks/TaskTitle"
import { SummarySectionProps } from "../types"

export const defaultColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  filterable: false,
  flex: 1,
  hideable: false,
  minWidth: 100,
  resizable: false,
  sortable: false,
}

const ProjectSummaryTaskTableDocument = graphql(`
  query ProjectSummaryTaskTable($projectId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    project(id: $projectId) {
      id
      tasks(includeCompleted: true, includeSubtasks: false) {
        id
        completedHours(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        estimatedHours
        isComplete
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
          rate
        }
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        usersWhoClockedOrReportedCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        primaryUnitGoals {
          id
          targetQuantity
          progressInDateRange(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
          deliverableUnit {
            id
            description
            unitOfMeasure
          }
        }
      }
      taskGroups {
        id
        completedHours(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        isComplete
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        userCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        taskCount
      }
    }
  }
`)

type TaskExpectation = NonNullable<ProjectSummaryTaskTableQuery["project"]>["tasks"][0]
type RowData = {
  id: TaskExpectation["id"]
  assetCount: number
  completedHours: TaskExpectation["completedHours"]
  name: TaskExpectation["name"]
  productionRate: number
  unitName: string | undefined
  unitProgress: string | undefined
  userCount: number

  // Convenience or helper properties
  href: string
  isComplete: boolean
  isTaskGroup: boolean
}

export const ProjectSummaryTaskTable: FC<SummarySectionProps> = ({ projectId, rangeEnd, rangeStart }) => {
  const [{ data }] = useQuery({
    query: ProjectSummaryTaskTableDocument,
    variables: { rangeEnd, rangeStart, projectId },
  })

  const rows: RowData[] = []
  data?.project?.tasks
    .filter(
      // We only want to render things that are interesting to the user.  If this feature isn't reconsidered during finalization of this flag, we can bring it into a queryable filter rather than being a "client only" filter.
      (task) => {
        return task.primaryUnitGoals.some((g) => g.progressInDateRange) || task.usersWhoClockedOrReportedCount > 0
      }
    )
    .forEach((task) => {
      const hasUnitGoals = task.primaryUnitGoals.length > 0
      const hasTooManyUnitGoals = task.primaryUnitGoals.length > 1
      const unitGoal = task.primaryUnitGoals[0]
      rows.push({
        ...task,
        productionRate: task.productionRate.rate,
        href: `/projects/${projectId}/${task.id}/summary`,
        isTaskGroup: false,
        isComplete: task.isComplete !== null,

        completedHours: task.completedHours,
        unitName: hasUnitGoals ? (hasTooManyUnitGoals ? "Multiple" : unitGoal.deliverableUnit.description) : undefined,
        userCount: task.usersWhoClockedOrReportedCount,
        unitProgress: hasUnitGoals
          ? hasTooManyUnitGoals
            ? "Multiple"
            : `${getFriendlyFloat(unitGoal.progressInDateRange)} ${unitGoal.deliverableUnit.unitOfMeasure}`
          : undefined,
      })
    })
  data?.project?.taskGroups
    .filter((taskGroup) => taskGroup.completedHours)
    .forEach((taskGroup) => {
      rows.push({
        ...taskGroup,
        name: `${taskGroup.name} (${taskGroup.taskCount})`,
        productionRate: taskGroup.productionRate,
        unitName: undefined,
        unitProgress: undefined,

        href: `/projects/${projectId}/groups/${taskGroup.id}/details`,
        isTaskGroup: true,
      })
    })
  const columns = [
    {
      field: "taskName",
      flex: 3,
      minWidth: 150,
      renderCell: ({ row }: { row: RowData }) => (
        <div className="flex gap-x-2 items-center">
          {cloneElement(getIconComponent(row), { color: colors.neutral[50] })}
          <TaskTitle
            taskOrGroupId={row.id}
            projectId={projectId}
            isComplete={Boolean(row.isComplete)}
            size="small"
            name={row.name}
            secondaryTitle
            tab="summary"
          />
        </div>
      ),
      headerName: "Name",
    },
    {
      field: "userCount",
      headerName: "Team",
    },
    {
      field: "assetCount",
      headerName: "Assets",
    },
    {
      field: "completedHours",
      headerName: "Hours",
      minWidth: 120,
      renderCell: ({ row }: GridRenderCellParams<RowData>) => (
        <HoursAndMinutes seconds={row.completedHours * 60 * 60} />
      ),
    },
    {
      field: "unitName",
      headerName: "Unit",
      flex: 2,
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.unitName ? row.unitName : <span className="text-gray-400">N/A</span>,
    },
    {
      field: "unitProgress",
      flex: 2,
      headerName: "Progress",
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.unitProgress ? row.unitProgress : <span className="text-gray-400">N/A</span>,
    },
    {
      field: "productionRate",
      headerName: "Rate",
      flex: 2,
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.productionRate ? (
          <div className="flex gap-x-2 items-center">
            <BiChevronsUp className={clsx("size-6", getProductionRateArrowClassName(row.productionRate))} />
            <ProductionRateSpan rate={row.productionRate} />
          </div>
        ) : (
          <span className="text-gray-400">N/A</span>
        ),
    },
  ]

  return (
    <Box
      paddingY={3}
      paddingX={2}
      style={{
        border: `1px solid ${colors.slate[200]}`,
        borderRadius: "8px",
      }}
    >
      <div>
        <Typography variant="h4">Task Progress</Typography>
        <DataGridPro
          autoHeight
          loading={!data}
          columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
          disableRowSelectionOnClick
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
          pageSizeOptions={[10]}
          pagination
          rows={rows}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => <EmptyStateBlock label="No tasks" />,
          }}
        />
      </div>
    </Box>
  )
}

const getIconComponent = (row: RowData) => {
  return row.isComplete ? (
    <BiCheckCircle className="p-1 mr-2 size-6 rounded-md bg-green-600" />
  ) : row.isTaskGroup ? (
    <BiSubdirectoryRight className="p-1 mr-2 size-6 rounded-md bg-gray-800" />
  ) : (
    <BiListUl className="p-1 mr-2 size-6 rounded-md bg-gray-800" />
  )
}
