import { clsx } from "clsx"
import { FC } from "react"

type Props = {
  className?: string
}

export const SkeletonH3: FC<Props> = ({ className }) => (
  <div className={clsx("h-7 w-36 mb-8 bg-gray-200 rounded-full", className)} />
)
