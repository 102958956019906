import { useContext } from "react"
import { ConfirmCompleteTaskModal } from "../components/Modals/components/ConfirmCompleteTaskModal"
import { CloseTaskDrawer } from "../components/Partials/Drawer/components/Task/CloseTaskDrawer"
import { TaskAction } from "../components/Partials/Tasks/CloseTaskWorkflow"
import {
  Task,
  TaskListItem,
  useDeleteTaskMutation,
  useMarkTaskAsCompleteMutation,
} from "../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../components/Partials/Drawer/providers/DrawerProvider"
import { ModalContext } from "../providers/ModalProvider"
import { PickPlus } from "../types/helpers"
import { ProjectExpectation } from "../types/ProjectExpectation"
import { useModalProps } from "../components/Modals/hooks/useModalProps"
type TaskWorkflowParams = {
  taskListItem: PickPlus<TaskListItem, "taskId" | "name" | "isComplete" | "userCount" | "assetCount"> & {
    project: ProjectExpectation
  }
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
  onCancelCallback?: () => void
}

const taskActionLabel: Record<TaskAction, string> = {
  completeTask: "Complete task",
  deleteTask: "Delete task",
}

export const useReassignAndCloseTaskWorkflow = (taskAction: TaskAction = "completeTask") => {
  const { push } = useContext(DrawerContext)
  const { showModal } = useContext(ModalContext)
  const modalProps = useModalProps(taskActionLabel[taskAction])
  const [, markTaskCompleteMutation] = useMarkTaskAsCompleteMutation()
  const [, deleteTaskMutation] = useDeleteTaskMutation()

  return ({ onSuccessCallback, onCancelCallback, onErrorCallback, taskListItem }: TaskWorkflowParams) => {
    const task: PickPlus<Task, "id" | "name"> = {
      id: taskListItem.taskId!,
      name: taskListItem.name,
    }
    const drawerProps = {
      onError: onErrorCallback,
      onSuccess: onSuccessCallback,
      onCancel: onCancelCallback,
      task,
      taskAction,
      project: taskListItem.project,
    }

    const modalData = {
      taskListItem,
      modalProps,
      onConfirm: async () => {
        const closeTaskMutation = taskAction === "completeTask" ? markTaskCompleteMutation : deleteTaskMutation

        const result = await closeTaskMutation({ id: task.id })
        if (result.error) {
          console.error(result.error)
          onErrorCallback?.()
        } else {
          onSuccessCallback?.()
          modalProps.handleCloseModal()
        }
      },
    }

    if ((drawerProps && taskListItem.userCount > 0) || taskListItem.assetCount > 0) {
      push(
        <CloseTaskDrawer
          task={drawerProps.task}
          project={drawerProps.project}
          taskAction={drawerProps.taskAction}
          onCancel={drawerProps.onCancel}
          onSuccess={drawerProps.onSuccess}
          onError={drawerProps.onError}
        />,
        "CloseTask"
      )
    } else if (taskAction === "completeTask") {
      showModal(ConfirmCompleteTaskModal, modalData)
    }
  }
}
