import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { ArrayHelpers, FieldArray, Formik } from "formik"
import { FC, useContext, useMemo } from "react"
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi"
import { MdDragIndicator } from "react-icons/md"
import { ReactSortable } from "react-sortablejs"
import { useMutation, useQuery } from "urql"
import { graphql } from "../../../../../graphql/generated/gql"
import { colors } from "../../../../../helpers/styles/colors"
import { errorSnack } from "../../../../Notistack/ThemedSnackbars"
import { H2 } from "../../../../deprecated"
import { SingleDrawerContext } from "../Elements/Drawer"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerFooter } from "../Elements/DrawerFooter"
import { DrawerHeader } from "../Elements/DrawerHeader"
import { DrawerLink } from "../Elements/DrawerLink"
import { TaskDrawer } from "./TaskDrawer"

export const testLabel_ReorderableTaskTable = "reorderable-task-table"
export const testLabel_TaskReorderDrawerUpButton = "task-reorder-drawer-up-button"
export const testLabel_TaskReorderDrawerDownButton = "task-reorder-drawer-down-button"

type TaskReorderDrawerProps = {
  taskGroupId?: string
  projectId: string
  onSuccess?: () => void
}

const TaskReorderDrawerGroupDocument = graphql(`
  query TaskReorderDrawerTaskGroup($groupId: String!) {
    tasksForGroupId(groupId: $groupId) {
      id
      name
      sortOrder
    }
  }
`)

const TaskReorderDrawerTaskDocument = graphql(`
  query TaskReorderDrawerTasks($projectId: String!) {
    taskList(projectId: $projectId, archived: false) {
      taskId

      groupId
      isComplete
      isDefault
      name
      sortOrder
      taskGroupId
    }
  }
`)

const BulkUpdateTaskSortOrderDocument = graphql(`
  mutation BulkUpdateTaskSortOrder($updates: [BulkUpdateTaskSortOrderInput!]!) {
    bulkUpdateTaskSortOrder(updates: $updates) {
      id
      sortOrder
    }
  }
`)

export const TaskReorderDrawer: FC<TaskReorderDrawerProps> = ({ taskGroupId, projectId, onSuccess = () => {} }) => {
  const { handleClose } = useContext(SingleDrawerContext)

  const [{ data: taskGroupTasks }, fetchTaskGroups] = useQuery({
    query: TaskReorderDrawerGroupDocument,
    variables: { groupId: taskGroupId! },
    pause: !taskGroupId,
  })

  const [{ data: projectTasks }, fetchTasks] = useQuery({
    query: TaskReorderDrawerTaskDocument,
    variables: { projectId },
    pause: !!taskGroupId,
  })

  const [_, bulkUpdateTaskSortOrderMutation] = useMutation(BulkUpdateTaskSortOrderDocument)

  const tasks = useMemo(() => {
    if (!taskGroupTasks && !projectTasks) return []

    if (projectTasks) {
      return (
        projectTasks.taskList
          // we don't want grouped, completed, or default tasks
          .filter((task) => !task.groupId && !task.isComplete && !task.isDefault)
          .map((task) => ({
            id: task.taskId || task.taskGroupId!,
            name: task.name,
            sortOrder: task.sortOrder,
            type: task.taskGroupId ? "taskGroup" : "task",
          }))
          .sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0))
      )
    }
    if (taskGroupTasks) {
      return taskGroupTasks.tasksForGroupId
        .map((task) => ({ id: task.id!, name: task.name, sortOrder: task.sortOrder, type: "task" }))
        .sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0))
    }

    return []
  }, [projectTasks, taskGroupTasks])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        tasks,
      }}
      onSubmit={(data) => {
        const updates = data.tasks.map(({ id, type }, index) => ({
          sortOrder: index,
          id: id!,
          type,
        }))

        bulkUpdateTaskSortOrderMutation({ updates })
          .then(() => {
            if (taskGroupId) fetchTaskGroups({ requestPolicy: "network-only" })
            fetchTasks({ requestPolicy: "network-only" })
          })
          .then(() => {
            handleClose()
            onSuccess && onSuccess()
          })
          .catch((error) => {
            errorSnack(`There was an error sorting the tasks.`)
            console.error(error)
          })
      }}
    >
      {({ setValues, submitForm, values }) => (
        <>
          <DrawerHeader href={`/projects/${projectId}/group/${taskGroupId}/details`} />

          <DrawerBody>
            <H2>Reorder Tasks</H2>
            <Table test-label={testLabel_ReorderableTaskTable}>
              <TableBody>
                <ReactSortable
                  list={values.tasks}
                  setList={(data) => {
                    setValues({
                      tasks: data,
                    })
                  }}
                >
                  <FieldArray name="tasks">
                    {(arrayHelpers: ArrayHelpers) => (
                      <>
                        {values.tasks.map((task, index) => (
                          <TableRow key={`${task.type}-${task.id}`}>
                            <TableCell className="cursor-grab">
                              <MdDragIndicator className="size-6 text-gray-400" />
                            </TableCell>
                            <TableCell className="w-full">
                              <DrawerLink
                                component={<TaskDrawer taskId={task.id!} />}
                                drawerName="Task"
                                href={`/projects/${projectId}/task/${task.id}/details`}
                              >
                                <Typography
                                  fontSize="20px"
                                  fontWeight={700}
                                  color={colors.blue[600]}
                                  sx={{ textDecoration: "underline" }}
                                >
                                  {task.name}
                                </Typography>
                              </DrawerLink>
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={index === 0}
                                variant="contained"
                                color="secondary"
                                sx={{ fontSize: "14px", height: "32px" }}
                                test-label={testLabel_TaskReorderDrawerUpButton}
                                onClick={() => {
                                  arrayHelpers.swap(index, index - 1)
                                }}
                              >
                                <BiUpArrowAlt className="size-6" />
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                disabled={index + 1 === tasks.length}
                                variant="contained"
                                color="secondary"
                                sx={{ fontSize: "14px", height: "32px" }}
                                test-label={testLabel_TaskReorderDrawerDownButton}
                                onClick={() => {
                                  arrayHelpers.swap(index, index + 1)
                                }}
                              >
                                <BiDownArrowAlt className="size-6" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </ReactSortable>
              </TableBody>
            </Table>
          </DrawerBody>
          <DrawerFooter>
            <Box className="px-8 flex gap-2 items-center">
              <Button variant="contained" type="submit" onClick={submitForm}>
                Update
              </Button>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </DrawerFooter>
        </>
      )}
    </Formik>
  )
}
