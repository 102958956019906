import { Button, MenuItem, Select, colors } from "@mui/material"
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro"
import { FC, useContext, useEffect, useReducer, useState } from "react"
import { useQuery } from "urql"
import { useReassignUserMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { TaskReassignUsersQuery } from "../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { UserBadge } from "../../UserBadge"
import DeprecatedModal from "../../deprecated/StandardModal"
import { TaskReassignmentWorkflowSkeleton } from "../Projects/Tasks/TaskReassignmentWorkflow.skeleton"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { ReassignUserTaskForm, UserExpectation } from "../User/ReassignUserTaskForm/ReassignUserTaskForm"

export const testLabel_CompleteTaskReassignUsersProjectSelect = "complete-task-reassign-users-project-select"
export const testLabel_CompleteTaskReassignUserTaskSelect = "complete-task-reassign-user-task-select"
export const testLabel_CompleteTaskReassignUsersAssignButton = "complete-task-reassign-users-assign-button"

type RowAssignmentMap = {
  [userId: string]: RowAssignmentState
}

type RowAssignmentAction =
  | { userId: string; type: "assignTask"; taskId: string }
  | { userId: string; type: "assignProject"; projectId: string }

type RowAssignmentState = {
  status: "pending" | "needsTask" | "ready"
  selectedProjectId?: string
  selectedTaskId?: string
}

const TaskReassignUsersDocument = graphql(`
  query TaskReassignUsers($taskId: String, $projectId: String) {
    usersList(status: "active", taskId: $taskId, projectId: $projectId) {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      projectId
      taskId
    }
    projectsByStatus(status: active) {
      id
      name
      imageUrl
      tasks {
        id
        name
      }
    }
  }
`)

const rowAssignmentReducer = (state: RowAssignmentMap, action: RowAssignmentAction): RowAssignmentMap => {
  let userState = state[action.userId]
  switch (action.type) {
    case "assignProject":
      userState = { status: "needsTask", selectedProjectId: action.projectId }
      break
    case "assignTask":
      if (userState.status !== "needsTask") {
        // Return to sender... restart
        userState = { status: "pending" }
      } else {
        userState = {
          ...userState,
          status: "ready",
          selectedTaskId: action.taskId,
        }
      }
      break
  }

  return { ...state, [action.userId]: userState }
}

const assignmentColumnSettings = {
  filterable: false,
  hideable: false,
  sortable: false,
}

type RowData = TaskReassignUsersQuery["usersList"][0]

export const UserReassignment: FC<{
  onSuccess?: () => void
  projectId?: string
  taskId?: string
  excludedProjectId?: string
}> = ({ onSuccess, projectId, taskId, excludedProjectId }) => {
  const [assignments, dispatch] = useReducer(rowAssignmentReducer, {})
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data, error, fetching }] = useQuery({
    query: TaskReassignUsersDocument,
    variables: { taskId, projectId },
  })
  const apiRef = useGridApiRef()
  const [selectedRowCount, setSelectedRowCount] = useState<number | null>(null)
  const [selectedUsers, setSelectedUsers] = useState<UserExpectation[]>([])
  const [_, reassignUserMutation] = useReassignUserMutation()
  const reassignUserTaskFormModalProps = useModalProps("Reassign Users")
  useHandleError(error, "There was an error loading needed data.")

  const users: RowData[] | undefined = data?.usersList
  const projects = data?.projectsByStatus.filter((project) => project.id !== excludedProjectId) || []

  useEffect(() => {
    if (!fetching && users && users.length === 0) {
      onSuccess?.()
    }
  }, [fetching, onSuccess, users])

  const handleReassignUsers = () => {
    const userIdsToReassign = apiRef.current?.getSelectedRows?.() || new Map()

    if (userIdsToReassign.size > 0) {
      reassignUserTaskFormModalProps.handleOpenModal()
    } else {
      errorSnack("Please select users to reassign")
    }
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Team Member",
      flex: 3,
      renderCell: ({ row }) => <UserBadge user={row} />,
    },
    {
      ...assignmentColumnSettings,
      field: "projectSelect",
      flex: 2,
      headerName: "Project",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ userId: row.id, type: "assignProject", projectId: e.target.value })}
            renderValue={assignment?.selectedProjectId ? undefined : () => "Select a project"}
            defaultValue=""
            value={assignment?.selectedProjectId ?? ""}
            test-label={testLabel_CompleteTaskReassignUsersProjectSelect}
            name="selectProject"
            placeholder="Select a project"
            margin="dense"
            displayEmpty
            variant="outlined"
          >
            <MenuItem key="selectProject" value="" disabled>
              Select a project
            </MenuItem>
            {projects.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      ...assignmentColumnSettings,
      field: "taskSelect",
      flex: 2,
      headerName: "Task",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ userId: row.id, type: "assignTask", taskId: e.target.value })}
            disabled={!["ready", "needsTask"].includes(assignment?.status)}
            renderValue={assignment?.selectedTaskId ? undefined : () => "Select a task"}
            defaultValue=""
            value={assignment?.selectedTaskId ?? ""}
            test-label={testLabel_CompleteTaskReassignUserTaskSelect}
            name="selectTaskId"
            placeholder="Select a task"
            margin="dense"
            displayEmpty
            variant="outlined"
          >
            <MenuItem key="selectTaskId" value="" disabled>
              Select a task
            </MenuItem>
            {(
              projects.find((p: { id: string; name: string }) => p.id === assignment?.selectedProjectId)?.tasks || []
            ).flatMap((p: { id: string; name: string }) => (
              <MenuItem disabled={p.id === taskId} key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      type: "actions",
      field: "actions",
      renderHeader: () => {
        if (!selectedRowCount) return null

        return (
          <Button variant="contained" color="primary" onClick={handleReassignUsers}>
            Reassign
          </Button>
        )
      },
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={assignment?.status !== "ready"}
            test-label={testLabel_CompleteTaskReassignUsersAssignButton}
            onClick={() => {
              if (assignment.status === "ready") {
                reassignUserMutation({
                  userId: row.id,
                  taskId: assignment.selectedTaskId!,
                  projectId: assignment.selectedProjectId!,
                }).then((result) => {
                  if (result.error) {
                    errorSnack(`Whoops! Error reassigning user: ${row.firstName} ${row.lastName}`)
                  } else {
                  }
                })
              } else {
                errorSnack("Something went wrong; please try again")
              }
            }}
          >
            Reassign
          </Button>
        )
      },
      disableColumnMenu: true,
    },
  ]

  return (
    <>
      {fetching && !users ? (
        <TaskReassignmentWorkflowSkeleton />
      ) : (
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection={hasPermissionTo(["user:update"])}
          disableRowSelectionOnClick
          columns={columns}
          pageSizeOptions={[10]}
          rows={users || []}
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids)
            setSelectedRowCount(selectedIDs.size)
            const _selectedUsers = users?.filter((u) => selectedIDs.has(u.id))

            setSelectedUsers(_selectedUsers || [])
          }}
          sx={{
            border: `1px solid ${colors.grey[300]}`,
          }}
        />
      )}
      {reassignUserTaskFormModalProps.isOpen && (
        <DeprecatedModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
          <ReassignUserTaskForm
            projectId={excludedProjectId === projectId ? projectId : undefined}
            newProjectId={excludedProjectId !== projectId ? projectId : undefined}
            excludedProjectId={excludedProjectId}
            previousTaskId={taskId}
            onCancel={() => {
              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            onSuccess={() => {
              successSnack("Selected users have been successfully reassigned.")

              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            preselectedUsers={selectedUsers}
          />
        </DeprecatedModal>
      )}
    </>
  )
}
