import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { BiLayer, BiSortAlt2 } from "react-icons/bi"
import { Asset, AssetAssignableType } from "../../../../graphql/generated/client-types-and-hooks"
import { Project } from "../../../../graphql/generated/gql/graphql"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../../types/helpers"
import { IconLabel } from "../../../IconLabel"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import {
  AssetGroupReassignmentFilterDefinition,
  ReassignAssetDrawer,
} from "../../Drawer/components/Asset/ReassignAssetDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"

export const ProjectCell: FC<{ asset: Asset }> = ({ asset }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const router = useRouter()

  const isAssetGroup = asset.isAssetGroup && asset.groupQuantity && asset.groupQuantity > 1

  let project: PickPlus<Project, "id" | "name"> | undefined = asset.assignedUser?.currentProject

  if (asset.assignableType === AssetAssignableType.Asset) {
    project = asset?.assignedAsset?.assignedUser?.currentProject
  }

  if (asset.assignableType === AssetAssignableType.Task) {
    project = asset?.assignedTask?.project
  }

  if (asset.inferredOwner) {
    project = asset.inferredOwner?.currentProject
  }

  const projectQuickMenuItems: MenuItem[][] = [
    [
      {
        value: "View project",
        Icon: BiLayer,
        onClick: () => {
          router.push(`/projects/${project?.id}`)
        },
      },
    ],
  ]

  if (hasPermissionTo("asset:transfer") && !asset.isAssetGroup && !asset.deletedAt) {
    projectQuickMenuItems.push([
      {
        value: "Transfer asset",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(
            <ReassignAssetDrawer
              assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
              assetGroupToReassign={
                asset.assetGroupId
                  ? ({
                      assetGroupId: asset.assetGroupId!,
                      assignableId: asset.assignableId,
                      assignableType: asset.assignableType,
                      status: asset.status!,
                      count: asset.assetChildCount,
                      groupParent: asset?.childAssetGroups?.[0]?.groupParent,
                      compositeKey: asset.compositeKey,
                    } as AssetGroupReassignmentFilterDefinition)
                  : null
              }
            />,
            "ReassignAsset"
          )
        },
      },
    ])
  }

  const isAsset = asset.assignableType === AssetAssignableType.Asset
  const hasAssignedAsset = Boolean(asset?.assignedAsset)
  const isSameAssetGroup = asset.assignableId === asset.assetGroupId
  const isInventoryAsset = isAsset && hasAssignedAsset && isSameAssetGroup

  let label

  if (!project?.name || isAssetGroup || isInventoryAsset) {
    label = "N/A"
  } else {
    label = project.name
  }

  const isUserArchived = asset.assignableType === AssetAssignableType.User && asset.assignedUser?.archived
  const isDisabled = !!isAssetGroup || !project?.name || isUserArchived || isInventoryAsset

  return (
    <QuickMenu
      items={projectQuickMenuItems}
      className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
      disabled={isDisabled}
    >
      <IconLabel icon={BiLayer} label={label} />
    </QuickMenu>
  )
}
