import { FC, Fragment } from "react"
import {
  PayType,
  User,
  UserBillingClassification,
  WorkersCompCode,
} from "../../../graphql/generated/client-types-and-hooks"
import { formatMoney } from "../../../helpers/formatters/formatMoney"
import { formatPhoneNumber } from "../../../helpers/formatters/formatPhoneNumber"
import { PickPlus } from "../../../types/helpers"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { H5 } from "../../deprecated"
import { LabeledSection } from "../../LabeledSection"
import { RenderIf } from "../../RenderIf"
import { ValueOrNoneLabel } from "../Assets/BasicInfo"
import { FaEllipsisH } from "react-icons/fa"
import { BiSortAlt2 } from "react-icons/bi"
import { QuickMenuMui } from "../../QuickMenuMui"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { useUserDivisionAssignments } from "../../../hooks/useUserDivisionAssignments"
import { SwitchDivisionAssignmentModalWrapper } from "../../Modals/components/SwitchDivisionAssignmentModal"
import { AdminRoleName, ManagerRoleName } from "../../../types/Role"

type Props = {
  user: PickPlus<
    User,
    | "id"
    | "organizationId"
    | "archived"
    | "companyUserId"
    | "email"
    | "firstName"
    | "jobTitle"
    | "lastName"
    | "phoneNumber"
    | "phoneNumberExt"
    | "projectId"
    | "payRate"
    | "payType"
    | "currentProjectId"
    | "currentTaskId"
  > & {
    roles: PickPlus<User["roles"][number], "id" | "description" | "name">[]
    billingClassification?: PickPlus<UserBillingClassification, "name"> | null
    workersCompCode?: PickPlus<WorkersCompCode, "name" | "ncciCode"> | null
    assignedDivision?: PickPlus<User["assignedDivision"], "id" | "name"> | null
    divisionAccess?: PickPlus<User["divisionAccess"][number], "id" | "name">[]
  }
}

export const BasicInfo: FC<Props> = ({ user }) => {
  const switchDivisionAccessModalProps = useModalProps()
  const { organizationId, userDivisionAssignment } = useUserDivisionAssignments(
    user?.assignedDivision,
    user?.divisionAccess
  )

  if (!user) return null

  const isAdminOrManager = user.roles.some((role) => role.name === AdminRoleName || role.name === ManagerRoleName)
  return (
    <div>
      <H5 className="my-0">Basic Info</H5>
      <hr className="mt-2 mb-4 md:mb-6" />

      <div className="grid gap-y-4 md:grid-cols-2">
        <LabeledSection label="Name">{user.firstName + " " + user.lastName}</LabeledSection>
        <LabeledSection label="Job Title">{user.jobTitle ? user.jobTitle : "Not Listed"}</LabeledSection>
        <LabeledSection className="break-all" label="Email">
          {user.email ? user.email : "Not Listed"}
        </LabeledSection>
        <LabeledSection label="Role">
          {user.roles.map((role) => (
            <Fragment key={role.id}>
              <p>{role.name}</p>
            </Fragment>
          ))}
        </LabeledSection>
        <LabeledSection label="Phone">
          {user.phoneNumber ? formatPhoneNumber(user.phoneNumber) : "Not Listed"}
          <p className="ml-3 text-gray-500">{user.phoneNumberExt && "Ext. " + user.phoneNumberExt}</p>
        </LabeledSection>
        <DevelopmentFeatureFlag name="Billing Classifications">
          <LabeledSection label="Billing Classification">
            {user?.billingClassification ? (
              <div>{user?.billingClassification?.name}</div>
            ) : (
              <div className="text-gray-500">None</div>
            )}
          </LabeledSection>
        </DevelopmentFeatureFlag>
        <DevelopmentFeatureFlag name="Workers Comp Code">
          <LabeledSection label="Workers Comp Code">
            {user?.workersCompCode ? (
              <div>
                {user?.workersCompCode?.name} ({user?.workersCompCode?.ncciCode})
              </div>
            ) : (
              <div className="text-gray-500">None</div>
            )}
          </LabeledSection>
        </DevelopmentFeatureFlag>
        <LabeledSection label="Company User ID" className="md:col-span-1">
          <ValueOrNoneLabel value={user.companyUserId} />
        </LabeledSection>
        <DevelopmentFeatureFlag name="Divisions">
          <LabeledSection label="Division" className="md:col-span-1">
            <ValueOrNoneLabel value={user.assignedDivision?.name} />
            <RenderIf permissionsInclude={isAdminOrManager ? "divisions:update" : "divisions:read"}>
              {userDivisionAssignment && userDivisionAssignment.length > 0 && (
                <QuickMenuMui
                  className="size-1 px-1 py-3"
                  items={[
                    [
                      {
                        value: "Switch Division",
                        Icon: BiSortAlt2,
                        onClick: () => switchDivisionAccessModalProps.handleOpenModal(),
                      },
                    ],
                  ]}
                >
                  <FaEllipsisH className="text-gray-500" />
                </QuickMenuMui>
              )}
            </RenderIf>
          </LabeledSection>
        </DevelopmentFeatureFlag>
        <DevelopmentFeatureFlag name="Pay Rate">
          <RenderIf permissionsInclude="payRate:read">
            <LabeledSection label="Pay" className="md:col-span-1">
              <ValueOrNoneLabel
                value={
                  user.payRate &&
                  `${formatMoney(Number(user.payRate))} per ${user.payType === PayType.Hourly ? "hour" : "year"}`
                }
              />
            </LabeledSection>
          </RenderIf>
        </DevelopmentFeatureFlag>
      </div>
      {switchDivisionAccessModalProps.isOpen && (
        <SwitchDivisionAssignmentModalWrapper
          modalProps={switchDivisionAccessModalProps}
          user={user}
          organizationId={organizationId}
          userDivisionAssignment={userDivisionAssignment}
        />
      )}
    </div>
  )
}
