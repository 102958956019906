import { Box, Chip, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { clsx } from "clsx"
import { useField } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { BiCamera } from "react-icons/bi"
import { useImageUpload } from "../../../hooks/useImageUpload"
import { AssetInspectionWizardContext } from "../../../providers/AssetInspectionWizardProvider"
import { EagerUploadImage } from "../../ImageUploadPreviewList"
import { PHOTO_BOOTH_IMAGE_TYPE, PhotoBooth } from "../../PhotoBooth"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { QuickMenuMui } from "../../QuickMenuMui"
import { listItemTypes } from "./CustomInspectionTemplateForm"
import { InspectionFieldInput } from "./InspectionFieldInput"

type InspectionItem = {
  id: string
  label: string
  photoRequired: boolean
  photoLabel?: string | null | undefined
  rule?: string | null | undefined
  failedStatus?: string | null | undefined
  required: boolean
  type: string
}

export const InspectionReportField: FC<{ assetId: string; inspectionItem: InspectionItem }> = ({
  assetId,
  inspectionItem,
}) => {
  const { activeStep } = useContext(AssetInspectionWizardContext)
  const [{ value }, { error }, { setValue }] = useField(`${inspectionItem.id}-photos`)
  const [previousStep, setPreviousStep] = useState(0)

  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const { setFiles, removeFileById, setUploadedById, fileObjects, reset } = useImageUpload(
    `asset/${assetId}/image`,
    (photos) => setValue(photos)
  )

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    if (activeStep !== previousStep) {
      setPreviousStep(activeStep)
      reset()
    }
  }, [activeStep, setPreviousStep, previousStep, reset, setValue, value])

  return (
    <div className="flex justify-between border-t items-center">
      <div className="flex items-start md:items-center basis-1/2">
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <Typography variant="h6" fontWeight={500} sx={{ pr: { xs: 0, md: 2 }, pl: 0 }}>
            {inspectionItem.label}
          </Typography>
          <div className="flex gap-x-2">
            <Chip
              size={isSmall ? "small" : "medium"}
              label={listItemTypes.find((t) => t.value === inspectionItem.type)?.label}
              sx={{
                "& .MuiChip-label": { fontSize: { xs: 11, md: 14 } },
              }}
            />
            {inspectionItem.photoLabel && (
              <Chip
                size={isSmall ? "small" : "medium"}
                label={inspectionItem.photoLabel}
                color="primary"
                sx={{
                  "& .MuiChip-label": { fontSize: { xs: 11, md: 14 } },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <Box paddingRight={1}>
          <InspectionFieldInput field={inspectionItem} activeStep={activeStep} />
        </Box>
        {Array.isArray(fileObjects) && fileObjects.length > 0 ? (
          <div className="size-10">
            {fileObjects.map((fileObject) => (
              <EagerUploadImage
                key={fileObject.fileId}
                fileObject={fileObject}
                removeFile={removeFileById}
                setUploaded={setUploadedById}
              />
            ))}
          </div>
        ) : inspectionItem.photoRequired ? (
          <div className={clsx(!!error ? "border-2 border-red-600 rounded" : "")}>
            <IconButton onClick={() => setPhotoBoothIsOpen(true)}>
              <BiCamera color={value?.length ? "green" : "gray"} />
            </IconButton>
          </div>
        ) : (
          <QuickMenuMui
            items={[
              [
                {
                  value: "Take photo (optional)",
                  onClick: () => setPhotoBoothIsOpen(true),
                  Icon: BiCamera,
                },
              ],
            ]}
            buttonShape="round"
          >
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        )}
        <PhotoBooth
          title={
            <div>
              Asset Report{" "}
              <span className="text-gray-400">
                / {inspectionItem.label}
                {inspectionItem.photoLabel && ` / ${inspectionItem.photoLabel}`}
              </span>
            </div>
          }
          isOpen={photoBoothIsOpen}
          setIsOpen={setPhotoBoothIsOpen}
          onAcceptPhoto={(photo) => {
            setFiles([
              new File([photo], new Date().toISOString(), {
                type: PHOTO_BOOTH_IMAGE_TYPE,
              }),
            ])
          }}
        />
      </div>
    </div>
  )
}
