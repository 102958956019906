import { Box, Button } from "@mui/material"
import { clsx } from "clsx"
import { FC, useCallback, useContext, useState } from "react"
import {
  UserNotificationModelType,
  useMarkNotificationsReadByIdMutation,
} from "../../graphql/generated/client-types-and-hooks"
import { colors } from "../../helpers/styles/colors"
import { NotificationsContext } from "../../providers/NotificationsProvider"
import { UserNotificationRow } from "../Partials/Drawer/components/NotificationsDrawer"
import { H4 } from "../deprecated"

type Props = {
  assetId?: string
  modelType: UserNotificationModelType
  userId?: string
}

export const NotificationsTable: FC<Props> = ({ assetId, modelType, userId }) => {
  const [isNotificationTableScrollGradientVisible, setIsNotificationTableScrollGradientVisible] = useState(false)
  const { notifications } = useContext(NotificationsContext)

  const [_, markAllReadById] = useMarkNotificationsReadByIdMutation()

  const applicableNotifications = notifications?.filter((notification) => {
    if (notification.markedReadAt) return false

    if (modelType === UserNotificationModelType.Asset && notification.asset?.id !== assetId) return false
    if (modelType === UserNotificationModelType.User && notification.user?.id !== userId) return false

    return true
  })

  const tableRowContainerRef = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return

      const refCurrent = node

      const scrollHandler = () => {
        // if the container has no scroll, hide the gradient
        if (refCurrent?.scrollHeight === refCurrent?.clientHeight) {
          setIsNotificationTableScrollGradientVisible(false)
          return
        }

        // hide container gradient if the user has scrolled all the way down
        if ((refCurrent?.scrollHeight || 0) - (refCurrent?.scrollTop || 0) > (refCurrent?.clientHeight || 0)) {
          setIsNotificationTableScrollGradientVisible(true)
        } else {
          setIsNotificationTableScrollGradientVisible(false)
        }

        return
      }

      scrollHandler()

      refCurrent?.addEventListener("scroll", scrollHandler)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicableNotifications.length]
  )

  if (!applicableNotifications || applicableNotifications?.length === 0) return null

  return (
    <Box
      position="relative"
      padding="14px 24px 24px"
      border={`thin solid ${colors.gray[300]}`}
      marginBottom="24px"
      className="rounded-lg"
    >
      <div className="flex items-baseline justify-between gap-x-2 mb-6">
        <H4 className="mb-2">
          Notifications{" "}
          <span className="relative bottom-0.5 text-base font-normal">({applicableNotifications.length})</span>
        </H4>
        <Button
          className="focus:outline-none"
          onClick={() =>
            markAllReadById({
              ids: applicableNotifications?.map((notification) => notification.id),
            })
          }
          variant="text"
        >
          Mark all as read
        </Button>
      </div>

      <div className="border-t mt-2 max-h-[180px] overflow-auto" ref={tableRowContainerRef}>
        {applicableNotifications.map((notification) => (
          <UserNotificationRow navigateOnClick={false} key={notification.id} notification={notification} />
        ))}
      </div>
      <div
        className={clsx(
          "absolute bottom-[24px] inset-x-0 h-12 bg-gradient-to-t from-white to-transparent transition-opacity pointer-events-none",
          !isNotificationTableScrollGradientVisible && "opacity-0"
        )}
      ></div>
    </Box>
  )
}
