import { add, formatDistance, isPast, isToday } from "date-fns"
import { FC } from "react"
import { InventoryIntervalLookup } from "./CreateOrEditAssetForm/InventoryIntervalOptions"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"

export const AssetInventoryDueIn: FC<{ asset: PickPlus<Asset, "inventoryRequirements" | "lastInventoriedAt"> }> = ({
  asset,
}) => {
  if (!asset.inventoryRequirements?.intervalInSeconds)
    return <div className="text-gray-400 text-sm">Not Required</div>

  let color = "text-orange-400"
  let dueAt
  if (asset.lastInventoriedAt) {
    dueAt = add(asset.lastInventoriedAt, { seconds: asset.inventoryRequirements.intervalInSeconds })
    if (isPast(dueAt)) color = "text-red-400"
    if (isToday(dueAt)) color = "text-orange-400"
    // Ok, we are good... so lets go ahead and set it to the normal
    color = "text-gray-400"
  }

  return (
    <div className="text-sm text-gray-400">
      {asset.inventoryRequirements.intervalInSeconds ? (
        <>
          <span>{InventoryIntervalLookup[asset.inventoryRequirements.intervalInSeconds]}</span>
          <span> -- </span>
          <span className={color}>{dueAt ? <>Due in {formatDistance(new Date(), dueAt)}</> : <>Due now</>}</span>
        </>
      ) : (
        "Not required"
      )}
    </div>
  )
}
