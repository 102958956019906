import { Checkbox, ListItemText, MenuItem, MenuList } from "@mui/material"
import { useStore } from "@tanstack/react-store"
import { useState } from "react"
import { BiCalculator, BiChevronDown } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../../../graphql/generated/gql"
import { assetListStore, assetListStoreActions } from "../../../../../stores/assetList"
import { CustomMenu } from "../../../../CustomMenu"

const AssetFilterBillingClassificationDocument = graphql(`
  query AssetFilterBillingClassification {
    assetBillingClassifications {
      id
      name
    }
  }
`)

export const AssetFilterBillingClassification = () => {
  const [{ data }] = useQuery({
    query: AssetFilterBillingClassificationDocument,
  })

  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const { billingClassificationIds } = useStore(assetListStore, (s) => s.filters)
  const selectedBillingClassificationIds = billingClassificationIds || []

  const assetBillingClassifications = data?.assetBillingClassifications
    ? [
        {
          id: "all",
          name: "All",
          value: null,
          isChecked: !selectedBillingClassificationIds?.length,
          onSelect: () =>
            assetListStoreActions.setListFilterItems({
              billingClassificationIds: [],
            }),
        },
        ...data?.assetBillingClassifications.map((classification) => {
          return {
            ...classification,
            isChecked: !!selectedBillingClassificationIds?.includes(classification.id),
            onSelect: () => {
              assetListStoreActions.setListFilterItems({
                billingClassificationIds: selectedBillingClassificationIds.includes(classification.id)
                  ? selectedBillingClassificationIds.filter((id) => id !== classification.id)
                  : [...selectedBillingClassificationIds, classification.id],
              })
            },
          }
        }),
      ]
    : []

  const selectedBillingClassificationIdCount = selectedBillingClassificationIds?.length ?? 0
  const labelName =
    selectedBillingClassificationIdCount > 1
      ? `${selectedBillingClassificationIdCount} selected`
      : assetBillingClassifications.find((c) => c.id === selectedBillingClassificationIds?.[0])?.name || "All"

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        anchor={
          <div className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800">
            <BiCalculator />
            <span>Classification</span>
            <span className="text-gray-500">{labelName}</span>
            <BiChevronDown className="inline" />
          </div>
        }
      >
        <MenuList sx={{ minWidth: 250 }}>
          {assetBillingClassifications.map((assetBillingClassification) => {
            return (
              <MenuItem key={assetBillingClassification.name} onClick={assetBillingClassification.onSelect}>
                <Checkbox checked={assetBillingClassification.isChecked} />
                <ListItemText>{assetBillingClassification.name}</ListItemText>
              </MenuItem>
            )
          })}
        </MenuList>
      </CustomMenu>
    </>
  )
}
