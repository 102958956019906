import { Button, IconButton, LinearProgress, Typography } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { addDays, format, isToday, parseISO } from "date-fns"
import { FC, useContext, useMemo, useState } from "react"
import { BiEditAlt, BiRightArrowAlt, BiTimeFive } from "react-icons/bi"
import { useQuery } from "urql"
import { UserTimeCardsQuery } from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import {
  secondsToFormattedString,
  timezoneEndOfDay,
  timezoneStartOfDay,
} from "../../../../../helpers/dateAndTime/time-utility"
import { useHandleError } from "../../../../../hooks/useHandleError"
import { NormalizedDateRange, useDateRange } from "../../../../../hooks/useMuiDateRange"
import { DateSelect } from "../../../../DateSelect"
import { useModalProps } from "../../../../Modals/hooks/useModalProps"
import { QuickMenuDotsHorizontal } from "../../../../QuickMenu/QuickMenuDotsHorizontal"
import DeprecatedModal from "../../../../deprecated/StandardModal"
import {
  EditDayActivityForm,
  TimeEntrySplit,
  TimeEntryWithProjectAndTaskName,
} from "../../EditDayActivityForm/EditDayActivityForm"
import { TimeEntryActivityBar } from "../../TimeEntryActivityBar"
import { ClockInOutCell } from "./ClockInOutCell"
import { EmptyStateBlock } from "../../../../Table/EmptyStateBlock"
import { DrawerContext } from "../../../Drawer/providers/DrawerProvider"
import { TimeEntryDayDrawer } from "../TimeEntryDayDrawer/TimeEntryDayDrawer"
import { TotalTimeWithEdits } from "../TotalTimeWithEdits"
import { QuickMenuMui } from "../../../../QuickMenuMui"
import { DevelopmentFeatureFlag } from "../../../../DevelopmentFeatureFlag"

const UserTimeCardsDocument = graphql(`
  query UserTimeCards($userId: String!, $rangeStartOn: String!, $rangeEndOn: String!) {
    timeCardWeek(userId: $userId, rangeEndOn: $rangeEndOn, rangeStartOn: $rangeStartOn) {
      totalTimeInSeconds
      totalPaidTimeInSeconds
      totalEditedTimeInSeconds
      regularTime
      overtime
      timezone
      days {
        id
        totalPaidTimeInSeconds
        totalEditedTimeInSeconds
        timeEntryWrappers {
          id
          isBreak
          splitStartAt
          splitEndAt
          totalEditedTimeInSeconds
          timeEntry {
            id
            durationInSeconds
            endAt
            signInPhotoUrl
            signOutPhotoUrl
            projectId
            taskId
            startAt
            isBreak
            isUnpaid
            project {
              name
            }
            task {
              name
            }
          }
        }
      }
    }
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      taskId
      task {
        id
        name
      }
    }
  }
`)

type TimeEntryDay = UserTimeCardsQuery["timeCardWeek"]["days"][number]

type TimeEntryWrapper = TimeEntryDay["timeEntryWrappers"][number]

export type TimeEntryDayRow = TimeEntryDay & {
  firstTimeEntryWrapper: TimeEntryWrapper
  lastTimeEntryWrapper: TimeEntryWrapper
  user: UserTimeCardsQuery["user"]
  timezone: string
}

export const defaultTimeEntryColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  editable: false,
  filterable: false,
  sortable: false,
  flex: 1,
}

const Empty: FC = () => <Typography className="text-gray-400">None</Typography>

export const TimeEntryDaysDataGrid: FC<{
  userId: string
}> = ({ userId }) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const [dateRange, setDateRange] = useDateRange({ defaultRangeType: "weekly" })
  const { rangeStart, rangeEnd } = dateRange

  const [{ data, fetching, error }, refetch] = useQuery({
    query: UserTimeCardsDocument,
    variables: {
      rangeStartOn: format(rangeStart, "yyyy-MM-dd"),
      rangeEndOn: format(rangeEnd, "yyyy-MM-dd"),
      userId,
    },
  })

  const openTimeEntryDayDrawer = (day: TimeEntryDayRow, user: UserTimeCardsQuery["user"]) => {
    pushDrawer(<TimeEntryDayDrawer user={user} day={day} />, "TimeEntryDay")
  }

  const [selectedTimeEntryData, setSelectedTimeEntryData] = useState<
    | (TimeEntrySplit & {
        timeEntries: TimeEntryWithProjectAndTaskName[]
      })
    | null
  >(null)
  const editDayActivityFormModalProps = useModalProps("Edit day activity")

  useHandleError(error, "There was a problem getting time card history")

  const columns: GridColDef[] = [
    {
      ...defaultTimeEntryColumnProps,
      field: "id",
      headerName: "Day",
      minWidth: 50,
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => format(parseISO(row.id), "EE"),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "day",
      headerName: "Date",
      minWidth: 180,
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => format(parseISO(row.id), "MM/dd/YYY"),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "activity",
      headerName: "Activity",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => (
        <TimeEntryActivityBar
          timeEntries={row.timeEntryWrappers?.map((timeEntryWrapper) => timeEntryWrapper.timeEntry) || []}
          minDate={timezoneStartOfDay(row.id, row.timezone) || new Date()}
          maxDate={timezoneEndOfDay(row.id, row.timezone) || new Date()}
        />
      ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "clockIn",
      headerName: "Clock in",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => {
        if (!row.firstTimeEntryWrapper) return <Empty />
        if (row.firstTimeEntryWrapper.splitStartAt)
          return (
            <Button onClick={() => openTimeEntryDayDrawer(row, row.user)}>
              <span>Start of day</span>
            </Button>
          )
        return (
          <ClockInOutCell
            clockTime={row.firstTimeEntryWrapper.timeEntry.startAt}
            photoUrl={row.firstTimeEntryWrapper.timeEntry.signInPhotoUrl}
          />
        )
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "clockOut",
      headerName: "Clock out",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => {
        // if the clock in is the current day then show clocked in.

        const isClockedIn =
          row.firstTimeEntryWrapper?.splitStartAt &&
          isToday(row.firstTimeEntryWrapper?.splitStartAt) &&
          !Boolean(row.lastTimeEntryWrapper.timeEntry.endAt)
        if (isClockedIn) {
          return (
            <Button onClick={() => openTimeEntryDayDrawer(row, row.user)}>
              <span>Clocked in</span>
            </Button>
          )
        }
        if ((!row.lastTimeEntryWrapper || !row.lastTimeEntryWrapper.timeEntry.endAt) && !row.firstTimeEntryWrapper)
          return <Empty />
        if (row.lastTimeEntryWrapper.splitEndAt)
          return (
            <Button onClick={() => openTimeEntryDayDrawer(row, row.user)}>
              <span>End of day</span>
            </Button>
          )
        return (
          <ClockInOutCell
            clockTime={row.lastTimeEntryWrapper.timeEntry.endAt}
            photoUrl={row.lastTimeEntryWrapper.timeEntry.signOutPhotoUrl}
          />
        )
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "total",
      headerName: "Paid Total",
      minWidth: 50,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => {
        if (!row.totalPaidTimeInSeconds) return <Empty />
        return (
          <TotalTimeWithEdits
            totalTimeInSeconds={row.totalPaidTimeInSeconds}
            totalEditedTimeInSeconds={row.totalEditedTimeInSeconds}
            className="flex-col"
            isClockedIn={
              Boolean(row.firstTimeEntryWrapper.timeEntry.startAt) && !Boolean(row.lastTimeEntryWrapper.timeEntry.endAt)
            }
            isToday={
              ((row.firstTimeEntryWrapper?.splitStartAt && isToday(row.firstTimeEntryWrapper.splitStartAt)) ||
                isToday(row.firstTimeEntryWrapper.timeEntry.startAt)) ??
              false
            }
            showTimeDiff
          />
        )
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "quickActionsMenu",
      headerName: "",
      flex: 0,
      width: 100,
      resizable: false,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDayRow>) => {
        if (!row.firstTimeEntryWrapper) return null
        return (
          <>
            <QuickMenuMui
              buttonShape="round"
              items={[
                [
                  {
                    requiredPermission: "timeEntry:update",
                    value: "Edit day activity",
                    onClick: () => {
                      setSelectedTimeEntryData({
                        timeEntries: row.timeEntryWrappers?.map((wrapper) => wrapper.timeEntry) || [],
                        splitByStartAt: !!row.firstTimeEntryWrapper.splitStartAt,
                        splitByEndAt: !!row.lastTimeEntryWrapper.splitEndAt,
                        preSplitStart: row.firstTimeEntryWrapper.timeEntry.startAt,
                        preSplitEnd: row.firstTimeEntryWrapper.timeEntry.endAt,
                      })

                      editDayActivityFormModalProps.handleOpenModal()
                    },
                    Icon: BiEditAlt,
                  },
                ],
              ]}
            >
              <QuickMenuDotsHorizontal />
            </QuickMenuMui>
            <IconButton onClick={() => openTimeEntryDayDrawer(row, row.user)}>
              <BiRightArrowAlt className="text-gray-600" />
            </IconButton>
          </>
        )
      },
    },
  ].map((col) => ({ flex: 1, resizable: false, disableReorder: true, ...col }))

  const rows: TimeEntryDayRow[] = useMemo(() => {
    if (!data?.timeCardWeek) return []

    return data.timeCardWeek.days.map((day) => {
      const sorted =
        day.timeEntryWrappers?.sort((a, b) => a.timeEntry.startAt.valueOf() - b.timeEntry.startAt.valueOf()) || []
      const firstTimeEntryWrapper = sorted[0]
      const lastTimeEntryWrapper = sorted[sorted.length - 1]

      const row = {
        ...day,
        lastTimeEntryWrapper,
        firstTimeEntryWrapper,
        user: data.user,
        timezone: data.timeCardWeek.timezone,
      }
      return row
    })
  }, [data?.timeCardWeek, data?.user])

  return (
    <div>
      <DateSelect
        dateRangeType="weekly"
        initialDate={dateRange.rangeStart}
        onChange={(newDateRange: NormalizedDateRange) => {
          // Force 1 week date selections
          setDateRange({
            rangeStart: newDateRange.rangeStart,
            rangeEnd: addDays(newDateRange.rangeStart, 6),
          })
        }}
        disableShortcuts
        disabled
      />
      <DataGridPro
        columns={columns}
        disableRowSelectionOnClick
        density="standard"
        loading={fetching}
        keepNonExistentRowsSelected
        rows={rows}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: () => <EmptyStateBlock label="No time card history" />,
        }}
        sx={{ paddingX: 2 }}
        hideFooter
        autoHeight
      />

      <div className="bg-blue-50 rounded-md text-blue-600 h-10 flex items-center gap-2 px-3 mt-4">
        <BiTimeFive className="size-6" />
        <div className="flex gap-4">
          <Typography>
            Paid Total: {secondsToFormattedString(data?.timeCardWeek.totalPaidTimeInSeconds || 0)}
          </Typography>
          <DevelopmentFeatureFlag name="Payroll Export">
            <Typography className="text-gray-400">
              Regular hours: {secondsToFormattedString(data?.timeCardWeek.regularTime || 0)}
            </Typography>
            <Typography className="text-gray-400">
              Overtime hours: {secondsToFormattedString(data?.timeCardWeek.overtime || 0)}
            </Typography>
          </DevelopmentFeatureFlag>
        </div>
      </div>

      {data?.user && selectedTimeEntryData && editDayActivityFormModalProps.isOpen && (
        <DeprecatedModal {...editDayActivityFormModalProps}>
          <EditDayActivityForm
            onCancel={editDayActivityFormModalProps.handleCloseModal}
            onSuccess={() => {
              refetch()
              setSelectedTimeEntryData(null)
              editDayActivityFormModalProps.handleCloseModal()
            }}
            timeEntries={selectedTimeEntryData.timeEntries}
            timeEntrySplit={{
              splitByStartAt: selectedTimeEntryData.splitByStartAt,
              splitByEndAt: selectedTimeEntryData.splitByEndAt,
              preSplitStart: selectedTimeEntryData.preSplitStart,
              preSplitEnd: selectedTimeEntryData.preSplitEnd,
            }}
            user={data?.user}
          />
        </DeprecatedModal>
      )}
    </div>
  )
}
