import { useCallback, useState } from "react"
import { ZodSchema } from "zod"
import { Logger } from "../lib/logger"

export const useHashState = <T extends Record<string, unknown>>(initialState: T, schema: ZodSchema) => {
  const [state, setState] = useState<T>(() => {
    const hash = window.location.hash.slice(1)
    if (hash) {
      try {
        const hashObject = JSON.parse(decodeURIComponent(hash))
        return {
          // push in the existing hash state
          ...hashObject,
          // parse the hash state with the schema
          ...schema.parse({
            ...initialState,
            // the existing hash data should override the initial state
            ...hashObject,
          }),
        }
      } catch (e) {
        Logger.error("Error parsing hash state", e)
      }
    }
    return initialState
  })

  const setHashState = useCallback(
    (newState: Partial<T>) => {
      const hashData = JSON.parse(decodeURIComponent(window.location.hash.slice(1)) || "{}")

      window.location.hash = encodeURIComponent(JSON.stringify({ ...hashData, ...schema.parse(newState) }))
      setState((prevState) => ({ ...prevState, ...newState }))
    },
    [schema]
  )

  return [state, setHashState] as const
}
