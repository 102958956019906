import { LoadingButton } from "@mui/lab"
import { Button } from "@mui/material"
import TextField from "@mui/material/TextField"
import { Form, Formik, FormikHelpers } from "formik"
import { FC } from "react"
import { BiLoaderAlt, BiSortAlt2, BiTrash } from "react-icons/bi"
import { useMutation } from "urql"
import * as Yup from "yup"
import { DELETE_TASK, DELETE_TASK_GROUP } from "../../../../graphql/queries/task.queries"
import { ModalProps } from "../../../Modals/hooks/useModalProps"
import { useReassignAndCloseTaskWorkflow } from "../../../../hooks/useReassignAndCloseTaskWorkflow"
import { ModalBody } from "../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../Modals/components/Elements/ModalFooter"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import DeprecatedModal from "../../../deprecated/StandardModal"

const TaskGroupDescription: FC<{ taskName: string | undefined }> = ({ taskName }) => (
  <>
    <p className="pb-5">
      Are you sure you want to delete the summary task <span className="font-semibold">{taskName}</span>? After deletion
      the sub-tasks will be converted into primary tasks.
    </p>
    <p className="pb-5">
      Please type the summary task name to confirm the deletion. <br /> This cannot be undone.
    </p>
  </>
)

const TaskDescription: FC<{ taskName: string | undefined }> = ({ taskName }) => (
  <>
    <p className="pb-5">
      Are you certain you want to delete the task <span className="font-semibold">{taskName}</span>?
    </p>
    <p className="pb-5">
      Please type the task name to confirm the deletion.
      <br />
      This cannot be undone.
    </p>
  </>
)

const TaskCannotDeleteDescription: FC<{ taskName: string | undefined; assetCount: number; userCount: number }> = ({
  taskName,
  assetCount,
  userCount,
}) => {
  const hasUsersAndAssets = assetCount && userCount
  return (
    <>
      <p className="pb-5">
        You cannot delete the task <span className="font-semibold">{taskName}</span> because it has users or assets
        assigned to it.
      </p>
      <p className="pb-5">
        This task has <span className="font-semibold">{userCount ? `${userCount} users ` : null}</span>
        {hasUsersAndAssets ? " and " : null}
        <span className="font-semibold">{assetCount ? ` ${assetCount} assets ` : null}</span>
        assigned to it.
      </p>
      <p className="pb-5">Please reassign them before deleting the task.</p>
    </>
  )
}

interface FormValues {
  id: string
  taskName: string
}

type Props = {
  modalProps: ModalProps
  task: { projectId: string; id: string; name: string; assetCount?: number; userCount?: number } | undefined
  isTaskGroup?: boolean
  onSuccess: () => void
  closeModal: () => void
}

export const DeleteTaskModal: FC<Props> = ({ modalProps, closeModal, task, isTaskGroup = false, onSuccess }) => {
  const deleteTaskWorkflow = useReassignAndCloseTaskWorkflow("deleteTask")
  const [_, deleteTaskGroup] = useMutation(DELETE_TASK_GROUP)
  const [__, archiveTask] = useMutation(DELETE_TASK)

  const assetCount = task?.assetCount || 0
  const userCount = task?.userCount || 0
  const hasUsersOrAssets = task && assetCount + userCount > 0

  const Schema = Yup.object().shape({
    id: Yup.string().required(),
    taskName: Yup.string()
      .required("The task name is required")
      .oneOf([task?.name ?? ""], "Must match task name"),
  })

  const handleDeleteTask = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const mutation = isTaskGroup ? deleteTaskGroup : archiveTask
    const actionName = isTaskGroup ? "Summary Task" : "Task"

    try {
      const result = await mutation({
        id: values?.id,
      })

      if (result.error) {
        throw new Error(result.error.message)
      }

      successSnack(`${actionName} deleted`)
      onSuccess()
      closeModal()
    } catch (error) {
      errorSnack(`There was a problem deleting the ${actionName.toLowerCase()}`)
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleReassignments = () => {
    const taskListItem = {
      assetCount: task?.assetCount || 0,
      userCount: task?.userCount || 0,
      taskId: task?.id || "",
      name: task?.name || "",
      isComplete: false,
    }
    const project = {
      id: task?.projectId || "",
      name: "",
      assetsCount: 0,
      userCount: 0,
    }
    closeModal()
    deleteTaskWorkflow({
      taskListItem: { ...taskListItem, project },
      onErrorCallback: () => {
        errorSnack(`${task?.name} deletion error; please try again`)
      },
    })
  }

  return (
    <DeprecatedModal {...modalProps}>
      <Formik
        enableReinitialize
        validationSchema={Schema}
        initialValues={{ id: task?.id || "", taskName: "" }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleDeleteTask}
      >
        {({ values, setFieldValue, isValidating, isSubmitting }) => (
          <Form>
            <ModalBody>
              {!isTaskGroup && hasUsersOrAssets ? (
                <TaskCannotDeleteDescription taskName={task?.name} assetCount={assetCount} userCount={userCount} />
              ) : (
                <>
                  {isTaskGroup ? (
                    <TaskGroupDescription taskName={task?.name} />
                  ) : (
                    <TaskDescription taskName={task?.name} />
                  )}
                  <TextField
                    name="taskName"
                    label=""
                    placeholder={task?.name}
                    error={isValidating && !isSubmitting}
                    helperText={isValidating && !isSubmitting ? "This must match the task name" : ""}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("taskName", e.target.value)}
                    value={values.taskName}
                    disabled={isValidating || isSubmitting}
                    autoFocus
                    sx={{ width: "100%" }}
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button variant="text" color="black" type="button" onClick={closeModal}>
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                color={!isTaskGroup && hasUsersOrAssets ? "black" : "error"}
                loading={isValidating || isSubmitting}
                disabled={isValidating || isSubmitting}
                loadingIndicator={<BiLoaderAlt className="animate-spin" size="20px" color="white" />}
                startIcon={
                  !isTaskGroup && hasUsersOrAssets ? (
                    <BiSortAlt2 className="rotate-90 size-5" />
                  ) : (
                    <BiTrash size="20px" color="white" />
                  )
                }
                loadingPosition="start"
                onClick={(e) => {
                  if (!isTaskGroup && hasUsersOrAssets) {
                    e.preventDefault()
                    handleReassignments()
                  }
                }}
              >
                <span>{!isTaskGroup && hasUsersOrAssets ? "Reassign" : "Delete"}</span>
              </LoadingButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </DeprecatedModal>
  )
}
