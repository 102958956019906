import { DataGridPro, GridColDef, GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid-pro"
import { formatRelative } from "date-fns"
import formatDistance from "date-fns/formatDistance"
import { NextPage } from "next"
import Error from "next/error"
import { FC, MutableRefObject } from "react"
import { useQuery } from "urql"
import { H4 } from "../../../components/deprecated"
import { LoadingIndicator } from "../../../components/Loading/LoadingIndicator"
import { PageTitle } from "../../../components/PageTitle"
import { RenderIf } from "../../../components/RenderIf"
import { OfflineEventType, UserOfflineEventsQuery } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { getFullName } from "../../../helpers/strings/getFullName"
import UserPageLayout from "./_layout"
import { useRouter } from "next/router"
import { LinearProgress, Typography } from "@mui/material"
import { BiError } from "react-icons/bi"
import { sentenceCase } from "../../../helpers/strings/sentenceCase"
import { TaskDrawerLink } from "../../../components/Partials/Drawer/components/Task/TaskDrawerLink"
import { UserDrawerLink } from "../../../components/Partials/Drawer/components/User/UserDrawerLink"
import { EmptyStateBlock } from "../../../components/Table/EmptyStateBlock"

const UserOfflineEventsDocument = graphql(`
  query UserOfflineEvents($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      offlineEvents(limitToWeeks: 4) {
        id
        reconciledAt
        receivedAt
        clientCreatedAt
        clientCanceledAt
        # location {
        #   latitude
        #   longitude
        #   accuracy
        # }
        issues
        errors

        markedAsResolvedAt
        markedAsResolvedBy {
          id
          fullName
        }

        clockInData {
          task {
            id
            projectId
            name
          }
          user {
            id
            fullName
          }
        }
        clockOutData {
          task {
            id
            name
            projectId
          }
          user {
            id
            fullName
          }
        }
        taskProgressData {
          task {
            id
            projectId
            name
          }
        }
        reassignData {
          user {
            id
            fullName
          }
          oldTask {
            id
            projectId
            name
          }
          newTask {
            id
            projectId
            name
          }
        }
        # assetReportData

        type
      }
    }
  }
`)

type OfflineEventRow = UserOfflineEventsQuery["user"]["offlineEvents"][0]

const columns: GridColDef[] = [
  {
    field: "clientCreatedAt",
    minWidth: 160,
    headerName: "Date",
    type: "dateTime",
    renderCell: ({ row }: GridRenderCellParams<OfflineEventRow>) => {
      return (
        <div className="grid">
          <Typography>{sentenceCase(formatRelative(row.clientCreatedAt, Date.now()))}</Typography>
          <Typography variant="subtitle2">
            {row.reconciledAt ? `Applied ${formatDistance(row.reconciledAt, row.clientCreatedAt)} later` : "--"}
          </Typography>
        </div>
      )
    },
  },
  {
    field: "type",
    headerName: "Type",
    flex: 0.75,
    valueFormatter(params: GridValueFormatterParams<OfflineEventRow["type"]>) {
      switch (params.value) {
        case OfflineEventType.AssetReportEvent:
          return "Asset Report"
        case OfflineEventType.ClockInEvent:
          return "Clock In"
        case OfflineEventType.ClockOutEvent:
          return "Clock Out"
        case OfflineEventType.ReassignEvent:
          return "Reassign"
        case OfflineEventType.TaskProgressEvent:
          return "Task Report"
        default:
          return params.value
      }
    },
  },
  {
    field: "issues",
    flex: 2,
    headerName: "Issues",
    renderCell: ({ row }: GridRenderCellParams<OfflineEventRow>) => {
      if (!row.issues || !row.errors) return <div className="text-gray-400">-</div>

      if (row.errors && row.markedAsResolvedAt) {
        return (
          <div>{`Resolved by ${row.markedAsResolvedBy?.fullName} ${formatDistance(row.markedAsResolvedAt, row.clientCreatedAt)} later`}</div>
        )
      }
      return (
        <div className="grid">
          <div>{row.issues}</div>
          <div className="flex align-baseline overflow-auto">
            <BiError className="text-red-500 size-6" />
            {row.errors}
          </div>
        </div>
      )
    },
  },
  {
    field: "task",
    flex: 2,
    headerName: "Task",
    renderCell: ({ row }: GridRenderCellParams<OfflineEventRow>) => {
      const data = row.clockInData || row.clockOutData || row.taskProgressData || row.reassignData
      if (!data) return "--"
      if (row.clockInData)
        return (
          <TaskDrawerLink
            taskId={row.clockInData.task.id}
            projectId={row.clockInData.task.projectId}
            name={row.clockInData.task.name}
          />
        )
      if (row.clockOutData)
        return (
          <TaskDrawerLink
            taskId={row.clockOutData.task.id}
            projectId={row.clockOutData.task.projectId}
            name={row.clockOutData.task.name}
          />
        )
      if (row.reassignData)
        return (
          <div className="flex flex-col">
            <div>
              From:{" "}
              <TaskDrawerLink
                taskId={row.reassignData.oldTask.id}
                projectId={row.reassignData.oldTask.projectId}
                name={row.reassignData.oldTask.name}
              />
            </div>
            <div>
              To:{" "}
              <TaskDrawerLink
                taskId={row.reassignData.newTask.id}
                projectId={row.reassignData.newTask.projectId}
                name={row.reassignData.newTask.name}
              />
            </div>
          </div>
        )

      if (row.taskProgressData)
        return (
          <TaskDrawerLink
            taskId={row.taskProgressData.task.id}
            projectId={row.taskProgressData.task.projectId}
            name={row.taskProgressData.task.name}
          />
        )
    },
  },
  {
    field: "user",
    headerName: "User",
    renderCell: ({ row }: GridRenderCellParams<OfflineEventRow>) => {
      const data = row.clockInData || row.clockOutData || row.reassignData
      if (!data) return "--"
      if (row.clockOutData)
        return <UserDrawerLink userId={row.clockOutData.user.id} name={row.clockOutData.user.fullName} />
      if (row.clockInData)
        return <UserDrawerLink userId={row.clockInData.user.id} name={row.clockInData.user.fullName} />
      if (row.reassignData)
        return <UserDrawerLink userId={row.reassignData.user.id} name={row.reassignData.user.fullName} />
    },
  },
]

export const UserPageOfflineEvents: FC<{ userId: string; drawerRef?: MutableRefObject<null> }> = ({ userId }) => {
  const [{ data }, _refetch] = useQuery({ query: UserOfflineEventsDocument, variables: { userId } })

  const user = data?.user

  return (
    <>
      <PageTitle
        title={
          !user?.firstName && !user?.lastName
            ? "User offline event history"
            : `${getFullName(user)} offline event history`
        }
      />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-4">
        <H4 className="mb-2 md:col-span-2 col-span-1">Offline Event History</H4>
      </div>
      <div className="w-full">
        {!user ? (
          <LoadingIndicator />
        ) : (
          <DataGridPro
            columns={columns.map((col) => ({ flex: 1, hideable: true, ...col }))}
            getRowHeight={() => "auto"}
            initialState={{ columns: { columnVisibilityModel: { data: true } } }}
            rowSelection={false}
            rows={user.offlineEvents}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => <EmptyStateBlock label="No offline events" />,
            }}
          />
        )}
      </div>
    </>
  )
}

const UserPage: NextPage = () => {
  const { query } = useRouter()
  const userId = query._id as string

  return (
    <RenderIf permissionsInclude="user:read" fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageOfflineEvents userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

export default UserPage
