import { Button, Typography } from "@mui/material"
import { FC, useState } from "react"
import { BiPencil, BiRuler, BiTrash } from "react-icons/bi"
import { DeliverableUnit, Task, UnitGoal, UnitGoalProgress } from "../../../graphql/generated/client-types-and-hooks"
import { calculateProductionRate } from "../../../helpers/numbers/calculateProductionRate"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { PickPlus } from "../../../types/helpers"
import { CustomLabeledSection } from "../../LabeledSection"
import { QuickMenu } from "../../QuickMenu"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { UnitReportModal } from "../../Modals/components/UnitReportModal"
import DeprecatedModal from "../../deprecated/StandardModal"
import { AddOrEditGoalModal } from "../Tasks/AddOrEditGoalModal"
import { DeleteGoalModal } from "../Tasks/DeleteGoalModal"

type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity" | "deliverableUnitId"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
} & {
  unitGoalProgress: PickPlus<UnitGoalProgress, "id">[]
}

type TaskExpectation = PickPlus<Task, "id" | "isComplete" | "isDefault"> & {
  unitGoals?: UnitGoalExpectation[]
}

type DeliverableUnitSectionProps = {
  task: TaskExpectation
  title: string
  isPrimary?: boolean
  onSuccess?: () => void
  taskEstimatedHours: number
}

export const DeliverableUnitSection: FC<DeliverableUnitSectionProps> = ({
  task,
  title,
  isPrimary = true,
  onSuccess,
  taskEstimatedHours,
}) => {
  const addUnitGoalModalProps = useModalProps("Add Unit Goal")
  const editUnitGoalModalProps = useModalProps("Edit Unit Goal")
  const deleteUnitGoalModalProps = useModalProps("Delete Task Goal")

  const [reportModalOpen, setReportModalOpen] = useState(false)

  const allUnitGoals = task?.unitGoals || []
  const unitGoals = allUnitGoals.filter((unitGoal) => unitGoal.isPrimary === isPrimary)
  const areAllUnitOfMeasureSame = unitGoals.every(
    (unitGoal, i, arr) => i === 0 || unitGoal.deliverableUnit.unitOfMeasure === arr[i - 1].deliverableUnit.unitOfMeasure
  )

  const [selectedUnitGoal, setSelectedUnitGoal] = useState<UnitGoalExpectation>()

  const rowLevelMenuItems = (unitGoal: UnitGoalExpectation) => {
    const hasBeenReportedOn = !!unitGoal.unitGoalProgress.length
    const items = [
      [
        {
          requiredPermission: "task:create",
          value: "Edit goal",
          onClick: () => {
            setSelectedUnitGoal(unitGoal)
            editUnitGoalModalProps.handleOpenModal()
          },
          Icon: BiPencil,
          isDisabled: !!task?.isComplete,
        },
        {
          requiredPermission: "task:update",
          value: "Delete goal",
          isDisabled: hasBeenReportedOn || !!task?.isComplete,
          onClick: () => {
            setSelectedUnitGoal(unitGoal)
            deleteUnitGoalModalProps.handleOpenModal()
          },
          Icon: BiTrash,
          color: "red",
        },
      ],
    ] as MenuItem[][]

    return items
  }

  return (
    <section>
      <div className="flex justify-between items-center">
        <Typography fontSize={20} fontWeight={700}>
          {title}
        </Typography>
        <RenderIf permissionsInclude="task:create">
          <Button
            variant="text"
            disabled={!!task?.isComplete}
            onClick={() => {
              setSelectedUnitGoal(undefined)
              addUnitGoalModalProps.handleOpenModal()
            }}
          >
            Add Unit
          </Button>
        </RenderIf>
      </div>
      <hr className="mb-4 mt-2" />
      {unitGoals.length > 0 ? (
        <div className="flex flex-col gap-4">
          {unitGoals.map((unitGoal) => {
            const myMenuItems = rowLevelMenuItems(unitGoal)
            return (
              <div key={unitGoal.deliverableUnitId} className="flex">
                <CustomLabeledSection label={unitGoal.deliverableUnit.description} icon={BiRuler}>
                  {unitGoal.targetQuantity} {unitGoal.deliverableUnit.unitOfMeasure}
                  {unitGoal.isPrimary &&
                    (areAllUnitOfMeasureSame
                      ? ` / ${calculateProductionRate(unitGoals, taskEstimatedHours)} per man-hour`
                      : " / Can't produce production rate with disparate units")}
                </CustomLabeledSection>
                <RenderIf permissionsInclude={["task:create", "task:update"]}>
                  <QuickMenu className="hidden md:block col-span-1" items={myMenuItems} buttonShape="round">
                    <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
                  </QuickMenu>
                </RenderIf>
              </div>
            )
          })}
        </div>
      ) : isPrimary ? (
        <Typography color="secondary.light" className="py-4 text-center m-4 ">
          No Reporting Units
        </Typography>
      ) : (
        <Typography className="py-4 text-center text-gray-500 m-4 ">No Additional Units</Typography>
      )}
      <UnitReportModal isOpen={reportModalOpen} closeModal={() => setReportModalOpen(false)} taskId={task.id} />
      <DeprecatedModal {...addUnitGoalModalProps}>
        <AddOrEditGoalModal
          closeModal={addUnitGoalModalProps.handleCloseModal}
          unitGoal={selectedUnitGoal}
          isPrimary={isPrimary}
          taskId={task.id}
          taskEstimatedHours={taskEstimatedHours}
          onSuccess={onSuccess}
        />
      </DeprecatedModal>
      <DeprecatedModal {...editUnitGoalModalProps}>
        <AddOrEditGoalModal
          closeModal={editUnitGoalModalProps.handleCloseModal}
          unitGoal={selectedUnitGoal}
          isPrimary={isPrimary}
          taskId={task.id}
          taskEstimatedHours={taskEstimatedHours}
          onSuccess={onSuccess}
        />
      </DeprecatedModal>
      <DeprecatedModal {...deleteUnitGoalModalProps}>
        <DeleteGoalModal
          closeModal={deleteUnitGoalModalProps.handleCloseModal}
          goal={selectedUnitGoal!}
          taskId={task.id}
          onSuccess={onSuccess}
        />
      </DeprecatedModal>
    </section>
  )
}
