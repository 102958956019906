import { Transition } from "@headlessui/react"
import { Masonry } from "@mui/lab"
import { Button, Typography } from "@mui/material"
import { clsx } from "clsx"
import { differenceInCalendarDays } from "date-fns"
import { FormikValues } from "formik"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { BiChart, BiEdit, BiGitMerge, BiNote, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { PieProgressIndicator } from "../../../features/GanttChart/components/PieProgressIndicator"
import { MetadataNoteInput, useUpdateTaskGroupMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useGetNewTaskGroupProgress } from "../../../hooks/queries/useGetNewTaskGroupProgress"
import { useHandleError } from "../../../hooks/useHandleError"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { MetadataNote } from "../../../types/MetadataNote"
import { HandleDeleteFunc, HandleReportFunc } from "../../../types/Task"
import { RichContentDisplay } from "../../Content/RichContentDisplay"
import { LabeledSection } from "../../LabeledSection"
import { AddNoteModal } from "../../Modals/components/AddNoteModal"
import { UnitReportModal } from "../../Modals/components/UnitReportModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { NoteCard } from "../../NoteCard"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { SectionHeader } from "../../PageSectionHeader"
import { PageTitle } from "../../PageTitle"
import Pill from "../../Pill"
import { ProjectUnitReportForm } from "../../ProjectUnitReportForm"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"
import { QuickMenuSectionTitle } from "../../QuickMenuSectionTitle"
import { RenderIf } from "../../RenderIf"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { ValueOrNoneLabel } from "../Assets/BasicInfo"
import { SkeletonRow } from "../Documents/DocumentList.skeleton"
import { DrawerBody } from "../Drawer/components/Elements/DrawerBody"
import { DrawerHeader } from "../Drawer/components/Elements/DrawerHeader"
import { DrawerLink } from "../Drawer/components/Elements/DrawerLink"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { TaskGroupProgressBar } from "./TaskList/TaskGroupProgressBar"
import { TaskList } from "./TaskList/TaskList"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"

const GetDetailsForTaskGroup = graphql(`
  query GetDetailsForTaskGroup($groupId: String!, $projectId: String!) {
    taskGroup(id: $groupId) {
      id
      name
      notes {
        label
        content
      }
      description
      completedTaskCount
      assetCount
      organizationId
      unitProgress
      unitTargetGoal
      isComplete
      taskCount
      updatedAt
      userCount
      projectId
      startDate
      endDate
    }

    tasksForGroupId(groupId: $groupId) {
      id
      groupId
      name
      projectId
      assetCount
      hasReportableUnit
      completedHours
      isComplete
      isDefault
      userCount
      estimatedHours
    }

    project(id: $projectId) {
      id
      name
      isComplete
      isArchived
      assetsCount
      userCount
    }
  }
`)

type Props = {
  taskGroupId: string
  projectId: string
  currentTab?: string
  withTitle?: boolean
  setCurrentTab?: Dispatch<SetStateAction<string>>
  onSuccess?: () => void
}

type Tab = {
  value: string
  label: string
}

const tabs: Tab[] = [
  {
    value: "details",
    label: "Details",
  },
  {
    value: "sub-tasks",
    label: "Sub-tasks",
  },
]

export const TaskGroupDetails: FC<Props> = ({
  taskGroupId,
  projectId,
  currentTab = "details",
  withTitle = false,
  setCurrentTab,
}) => {
  const [{ data, fetching, error }] = useQuery({
    query: GetDetailsForTaskGroup,
    variables: { groupId: taskGroupId, projectId },
    pause: !taskGroupId || !projectId,
  })

  const [reportUnitTaskId, setReportUnitTaskId] = useState<string | undefined>(undefined)
  const [taskIdToDelete, setTaskIdToDelete] = useState<string | undefined>(undefined)
  const [deleteTaskGroup, setDeleteTaskGroup] = useState<boolean>(false)

  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const currentUser = useCurrentUser()
  const deleteTaskModalProps = useModalProps("Delete Task")
  const { handleCloseModal, ...otherModalProps } = useModalProps("Add Note")

  const [, updateTaskGroup] = useUpdateTaskGroupMutation()
  const modalProps = {
    ...otherModalProps,
    handleCloseModal: () => {
      setNoteToEdit(undefined)
      handleCloseModal()
    },
  }

  const [noteToEdit, setNoteToEdit] = useState<MetadataNote | undefined>(undefined)
  const handleSubmit = async (values: FormikValues) => {
    const newNotes: MetadataNote[] = [{ label: values.label, content: values.content }]

    await updateTaskGroup({
      id: taskGroupId,
      name: taskGroup?.name,
      projectId: projectId,
      notesToAdd: newNotes,
      notesToRemove: noteToEdit ? [noteToEdit] : [],
    }).then((result) => {
      result.error ? errorSnack("There was an error adding the note") : successSnack("Note added successfully")
    })
  }
  useHandleError(error, "There was an error retrieving the summary task details. Please try again")
  const onEditNote = (note: MetadataNote | MetadataNoteInput) => {
    setNoteToEdit(note)
    modalProps.handleOpenModal()
  }

  const onDeleteNote = (note: MetadataNote) => {
    updateTaskGroup({
      id: taskGroupId,
      name: taskGroup?.name,
      projectId: projectId,
      notesToRemove: [note],
    }).then((result) => {
      result.error ? errorSnack("There was an error deleting the note") : successSnack("Note deleted successfully")
    })
  }
  const handleReport: HandleReportFunc = (taskId: string | undefined) => setReportUnitTaskId(taskId)

  const handleDelete: HandleDeleteFunc = (taskId: string | undefined, isTaskGroup: boolean) => {
    setTaskIdToDelete(taskId)
    setDeleteTaskGroup(isTaskGroup)

    deleteTaskModalProps.handleOpenModal()
  }

  const handleCloseDeleteTaskModal = () => {
    setTaskIdToDelete(undefined)
    deleteTaskModalProps.handleCloseModal()
  }

  const menuItems: MenuItem[][] = [
    [
      {
        value: "Edit",
        onClick: () =>
          pushDrawer(
            <RenderIf permissionsInclude="task:create">
              <DrawerHeader href={`projects/${projectId}/tasks/create`} />
              <DrawerBody>
                <CreateOrEditTaskForm
                  task={{ id: taskGroupId, projectId }}
                  taskGroupId={taskGroupId}
                  taskType="summary-task"
                />
              </DrawerBody>
            </RenderIf>,
            "TaskEdit"
          ),
        Icon: BiEdit,
      },
      {
        value: "Add note",
        onClick: () => {
          modalProps.handleOpenModal()
        },
        Icon: BiNote,
      },
    ],
    [
      {
        value: "Delete",
        onClick: () => handleDelete(taskGroupId, true),
        Icon: BiTrash,
        color: "red",
      },
    ],
  ]

  const { setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions } = useContext(BreadcrumbsContext)

  useEffect(() => {
    setIsLoading(fetching)

    if (!data?.taskGroup || !data?.project) return

    setLabelSubstitutions([
      {
        original: data.project.id,
        replacement: data.project.name,
      },
      {
        original: data.taskGroup.id,
        replacement: data.taskGroup.name,
      },
    ])

    return () => {
      setAdditionalBreadcrumbs([])
    }
  }, [data?.taskGroup, data?.project, fetching, setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions])

  const taskGroup = data?.taskGroup
  const groupTasks = data?.tasksForGroupId
  const project = data?.project

  const taskGroupProgress = Math.round(
    useGetNewTaskGroupProgress(projectId, taskGroupId, Boolean(data?.project?.isArchived)).data.completionPercentage
  )

  const displayDuration = (): string => {
    if (!taskGroup?.endDate || !taskGroup?.startDate) {
      return "None"
    }

    const duration = differenceInCalendarDays(new Date(taskGroup.endDate), new Date(taskGroup.startDate)) + 1
    return `${duration} days`
  }

  const totalManHours = useMemo(() => {
    const getTotalManHours = (groupTasks || [])
      .filter((task) => task.estimatedHours !== undefined)
      .map((task) => task.estimatedHours)
      .reduce((a, b) => (a || 0) + (b || 0), 0)
    return getTotalManHours
  }, [groupTasks])
  const totalCompletedHours = useMemo(() => {
    const getTotalCompletedHours =
      (groupTasks || [])
        .filter((task) => task.completedHours !== undefined)
        .map((task) => task.completedHours)
        .reduce((a, b) => a + b, 0) || 0
    return getTotalCompletedHours
  }, [groupTasks])

  const taskToDelete = useMemo(() => {
    if (!taskIdToDelete || !groupTasks || groupTasks.length === 0) {
      return undefined
    }
    const task = groupTasks.find((t) => t.id === taskIdToDelete)

    if (!task) return undefined
    return {
      projectId: projectId,
      id: taskIdToDelete!,
      name: task.name,
      assetCount: task.assetCount,
      userCount: task.userCount,
    }
  }, [projectId, groupTasks, taskIdToDelete])

  if (fetching) {
    return (
      <SkeletonContainer>
        <SkeletonRow />
        <SkeletonRow />
      </SkeletonContainer>
    )
  }

  return (
    <>
      <PageTitle title={`${taskGroup?.name || "Summary Task"}`} />
      {withTitle && (
        <div className="flex gap-x-2 mb-2 items-center">
          <div className="flex items-center mr-2">
            <PieProgressIndicator progress={taskGroupProgress} color="green" sizeLarge={true} />
          </div>
          <div className="lg:flex lg:flex-wrap lg:justify-between">
            <Typography fontSize="36px" lineHeight="40px" fontWeight={700}>
              {taskGroup?.name}
            </Typography>
          </div>
          <Pill color="gray" className="text-sm flex flex-row gap-2 min-w-fit items-center">
            <BiChart />
            <span className="text-gray-500">{`${taskGroupProgress}%`}</span>
          </Pill>
          <Pill color="gray" className="text-sm flex flex-row gap-2 min-w-fit items-center">
            <BiGitMerge />
            <span className="text-gray-500">{`${taskGroup?.completedTaskCount}/${taskGroup?.taskCount} `}</span>
          </Pill>
          <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
            <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
          </QuickMenuMui>
        </div>
      )}
      <div className="flex gap-6">
        {tabs.map((tab) => {
          const isSelected = tab.value === currentTab

          return (
            <RenderIf
              key={tab.value}
              permissionsInclude={["project:read"]}
              context={{ projectId: currentUser.projectId! }}
            >
              <button
                onClick={() => setCurrentTab?.(tab.value)}
                className={clsx(
                  "relative my-2 font-bold uppercase whitespace-nowrap md:my-8",
                  !isSelected && "text-gray-400"
                )}
              >
                {tab.label}
                <Transition
                  appear={true}
                  show={true}
                  enter="transition ease-in-out duration-150 transform"
                  enterFrom="opacity-0 translate-y-full"
                  enterTo="opacity-100 translate-y-0"
                >
                  <div
                    className={clsx(
                      "w-full relative top-1 h-1 transition-all rounded-sm",
                      isSelected ? "bg-blue-600" : "bg-transparent"
                    )}
                  />
                </Transition>
              </button>
            </RenderIf>
          )
        })}
      </div>
      {currentTab === "details" && (
        <>
          <section>
            <SectionHeader
              title="Quick Actions"
              actionText="Edit Summary Task"
              permissions="task:update"
              disabled={data?.tasksForGroupId.some((task) => task.isDefault)}
              onClick={() =>
                pushDrawer(
                  <RenderIf permissionsInclude="task:create">
                    <DrawerHeader href={`projects/${projectId}/tasks/create`} />
                    <DrawerBody>
                      <CreateOrEditTaskForm
                        task={{ id: taskGroupId, projectId }}
                        taskGroupId={taskGroupId}
                        taskType="summary-task"
                      />
                    </DrawerBody>
                  </RenderIf>,
                  "TaskEdit"
                )
              }
            />
            <div className="flex justify-between mb-4">
              <LabeledSection label="Unit Reporting">
                {taskGroup?.taskCount && `${taskGroup.taskCount} sub-tasks`}
              </LabeledSection>
              <DrawerLink
                component={<ProjectUnitReportForm projectId={projectId} taskId={taskGroupId} />}
                drawerName="ProjectUnitReport"
              >
                <RenderIf permissionsInclude="task:report" context={{ projectId }}>
                  <Button variant="contained" color="black" component="span" size="large" sx={{ marginRight: 1 }}>
                    Report
                  </Button>
                </RenderIf>
              </DrawerLink>
            </div>
          </section>
          <section className="mb-10">
            <SectionHeader title="Summary Task Progress" className="mt-20 mb-5" />
            {project && (
              <TaskGroupProgressBar
                taskGroupId={taskGroupId}
                project={project}
                totalCompletedHours={totalCompletedHours}
                totalManHours={totalManHours}
              />
            )}
          </section>
          <section className="mb-10">
            <QuickMenuSectionTitle
              isEditing={false}
              title="Summary Task Notes"
              permission="task:update"
              modalProps={modalProps}
            />
            {(taskGroup?.notes || [])?.length > 0 && (
              <Masonry columns={{ xs: 2, sm: 3, md: 3, lg: 4 }} spacing={2} defaultHeight={450} defaultColumns={4}>
                {(taskGroup?.notes || []).map((note: MetadataNote, i) => (
                  <NoteCard key={`${note.label}-${i}`} note={note} onEdit={onEditNote} onDelete={onDeleteNote} />
                ))}
              </Masonry>
            )}
            {(taskGroup?.notes || []).length === 0 && <EmptyStateBlock label="No notes" />}
          </section>
          <section>
            <SectionHeader title="Basic Info" className="mt-14" />
            <LabeledSection label="Summary Task Name" className="mb-4">
              <ValueOrNoneLabel value={taskGroup?.name} />
            </LabeledSection>
            <LabeledSection label="Description" className="mb-4">
              <RichContentDisplay content={taskGroup?.description ?? ""} />
            </LabeledSection>
          </section>
          <section>
            <SectionHeader title="Summary Task Schedule" className="mt-14" />
            <LabeledSection label="Start & End Date" className="mb-4">
              {taskGroup?.startDate && taskGroup?.endDate
                ? `${new Date(taskGroup.startDate).toLocaleDateString("en-US")} - ${new Date(
                    taskGroup.endDate
                  ).toLocaleDateString("en-US")}`
                : "None"}
            </LabeledSection>
            <LabeledSection label="Summary Task Duration" className="mb-4">
              {displayDuration()}
            </LabeledSection>
            <LabeledSection label="Man-Hours" className="mb-4">
              {`${totalManHours} hours`}
            </LabeledSection>
          </section>
        </>
      )}
      {currentTab === "sub-tasks" && (
        <>
          <PageTitle title={`${taskGroup?.name || "Summary Task"} sub-tasks`} />
          {project && taskGroup && (
            <TaskList
              project={project}
              tasks={groupTasks ?? []}
              taskGroup={taskGroup}
              handleReport={handleReport}
              handleDelete={handleDelete}
            />
          )}
        </>
      )}
      {/* This is important. There are bugs in the modal where the initialValues aren't released because the
      component isn't reinitialized or something...  Don't remove this as it will reintroduce "report goes to
      the wrong task" bug */}
      {reportUnitTaskId && (
        <UnitReportModal
          isOpen={!!reportUnitTaskId}
          closeModal={() => setReportUnitTaskId(undefined)}
          taskId={reportUnitTaskId!}
        />
      )}
      {taskToDelete && (
        <DeleteTaskModal
          modalProps={deleteTaskModalProps}
          closeModal={handleCloseDeleteTaskModal}
          task={taskToDelete}
          isTaskGroup={deleteTaskGroup}
          onSuccess={closeDrawer}
        />
      )}
      {modalProps.isOpen && (
        <AddNoteModal
          modalProps={modalProps}
          parentName={taskGroup?.name || "Task Group"}
          note={noteToEdit}
          onSave={async (values: FormikValues) => {
            await handleSubmit(values)
          }}
        />
      )}
    </>
  )
}
