import { DateTimePicker, DateTimePickerProps, TimePicker, TimePickerProps } from "@mui/x-date-pickers-pro"
import { useField } from "formik"

type DateTimeOrMaybeJustTimePickerProps<TDate = Date> = {
  name: string
  required?: boolean
  timeOnly?: boolean
  placeholder?: string
  minDateTime?: TDate
  maxDateTime?: TDate
  disabled?: boolean | null
  maxDate?: TDate
} & (Omit<DateTimePickerProps<TDate>, "onChange" | "value"> | Omit<TimePickerProps<TDate>, "onChange" | "value">)

export const DateTimeOrMaybeJustTimePicker = (props: DateTimeOrMaybeJustTimePickerProps) => {
  const { format, name, placeholder, required, timeOnly, minDateTime, maxDateTime, maxDate: _maxDate, ...rest } = props
  const [field, _, { setValue }] = useField(name)
  let val = field.value
  if (val && typeof val === "string") {
    val = new Date(val)
  }

  if (timeOnly) {
    // Props are now `TimePickerProps` because of `timeOnly`
    return (
      <TimePicker
        {...(rest as TimePickerProps<Date>)}
        format={format || "h:mm aa"}
        desktopModeMediaQuery="@media screen"
        onChange={(value) => setValue(value)}
        minTime={minDateTime || undefined}
        maxTime={maxDateTime || undefined}
        slotProps={{
          textField: { size: "small", required: required || undefined, placeholder: placeholder || undefined },
          ...(rest.slotProps as TimePickerProps<Date>["slotProps"]),
        }}
        value={field.value ?? null}
      />
    )
  }
  return (
    <DateTimePicker
      {...(rest as DateTimePickerProps<Date>)}
      format={format || "MM/dd/yyyy h:mm aa"}
      desktopModeMediaQuery="@media screen"
      minDateTime={minDateTime || undefined}
      maxDateTime={maxDateTime || undefined}
      onChange={(value) => setValue(value)}
      slotProps={{
        textField: { size: "small", required: required || undefined, placeholder: placeholder || undefined },
        ...(rest.slotProps as DateTimePickerProps<Date>["slotProps"]),
      }}
      value={field.value ?? null}
    />
  )
}
