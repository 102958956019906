import { Box, Skeleton } from "@mui/material"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { FC, useEffect } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  ReportAssetRepairMutationVariables,
  useReportAssetRepairMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { repairReportImagePrefix } from "../../../helpers/files/s3UploadFilePrefixHelpers"
import { TextField } from "../../Formik/TextField"
import { ImageUploadPreviewList } from "../../ImageUploadPreviewList"
import { MuiModal } from "./Elements/MuiModal"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { BiError } from "react-icons/bi"
import { format } from "date-fns"
import { AssetStatusMultiSelect } from "../../Formik/MultiSelect/implementations/AssetStatusMultiSelect"
import { assetStatusOptions, isOperational } from "../../../helpers/assets/assetStatus"

type FileObject = {
  fileId: string
  objectKey?: string
  uploaded: boolean
  file: File
  id: string
  preview: string
  signedUrl: string
}

const AssetRepairQueryDocument = graphql(`
  query GetAssetRepairForRepair($assetRepairId: String!) {
    assetRepairRequest(id: $assetRepairId) {
      id
      label
      createdAt
      assetId
      asset {
        repairRequestCount
      }
    }
  }
`)

type AssetRepairModalValueType = {
  status?: string
  assetRepairId: string
  note: string
  files: FileObject[]
}

export const ReportAssetRepairModal: FC<{
  assetRepairId: string
  isOpen: boolean
  closeModal: (isRepaired?: boolean) => void
  onSuccess?: (status?: string) => void
}> = ({ assetRepairId, isOpen, closeModal, onSuccess = () => null }) => {
  const [, reportAssetRepairMutation] = useReportAssetRepairMutation()

  const [{ data: assetRepairData, error, fetching }] = useQuery({
    query: AssetRepairQueryDocument,
    pause: !assetRepairId,
    variables: { assetRepairId },
  })

  useEffect(() => {
    if (error) {
      closeModal()
    }
  }, [error, closeModal])

  const assetId = assetRepairData?.assetRepairRequest?.assetId

  const handleSubmit = async (
    values: FormikValues,
    { resetForm, setSubmitting }: FormikHelpers<AssetRepairModalValueType>
  ) => {
    if (values.assetRepairId !== assetRepairId) {
      errorSnack("Something went wrong; please refresh the page")
    }

    if (assetRepairId && assetId) {
      const status = values.status ?? undefined
      const data: ReportAssetRepairMutationVariables = {
        report: {
          assetRepairId: values.assetRepairId,
          assetId,
          statusChange: status
            ? {
                status,
                active: isOperational(status),
              }
            : undefined,
          notes: values.note,
          fileIds: values.files.map(({ fileId }: FileObject) => fileId),
          photoUrls: values.files.map(({ objectKey }: FileObject) => objectKey),
        },
      }

      try {
        const result = await reportAssetRepairMutation(data)

        if (result.error) {
          errorSnack("Something went wrong with submitting the form")
        } else {
          onSuccess?.(status)
          closeModal(true)
          successSnack("Report saved successfully")
          resetForm()
        }
      } catch (e) {
        errorSnack("Something went wrong with submitting the form")
      } finally {
        setSubmitting(false)
      }
    }
  }

  const isLoading = (!assetRepairData && fetching) || assetRepairData?.assetRepairRequest.id !== assetRepairId
  const repairRequest = assetRepairData?.assetRepairRequest
  const repairRequestCount = repairRequest?.asset?.repairRequestCount ?? null
  const isFinalRepairRequest = repairRequestCount === 1

  return (
    <Formik<AssetRepairModalValueType>
      enableReinitialize
      initialValues={{
        status: isFinalRepairRequest ? "" : undefined,
        assetRepairId,
        note: "",
        files: [],
      }}
      validationSchema={Yup.object().shape({
        status: isFinalRepairRequest
          ? Yup.string()
              .oneOf(assetStatusOptions.map((status) => status.value))
              .required("Required")
          : Yup.string().test(
              "is-undefined",
              "status must not be provided",
              (value) => value === undefined || value === null
            ),
        assetRepairId: Yup.string(),
        note: Yup.string(),
        files: Yup.array(
          Yup.object().shape({
            uploaded: Yup.bool().required().oneOf([true], "Files must finish uploading"),
            fileId: Yup.string(),
          })
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({ submitForm, resetForm, isSubmitting }) => (
        <MuiModal
          contentLabel="Repair Asset"
          submitButtonText="Mark as repaired"
          isOpen={isOpen}
          handleCloseModal={() => {
            resetForm()
            closeModal()
          }}
          disabled={isLoading || isSubmitting}
          submitForm={submitForm}
        >
          <Form className="h-full flex flex-col">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col md:flex-row justify-between gap-y-4 lg:gap-y-8 pb-2">
                <div className="flex gap-4 items-center">
                  <div className="size-10 flex justify-center items-center shrink-0 rounded-lg bg-orange-50">
                    <BiError className="text-orange-600 p-1 rounded size-7" />
                  </div>
                  {repairRequest && !isLoading ? (
                    <div className="md:flex flex-wrap gap-x-4 relative">
                      <div className="text-gray-800 font-medium">{repairRequest.label}</div>
                      <div className="flex gap-2 text-gray-500">{format(repairRequest.createdAt, "MM/dd/yyyy")}</div>
                    </div>
                  ) : (
                    <Skeleton variant="rectangular" height={25} width={250} />
                  )}
                </div>
              </div>
              {isLoading ? (
                <Skeleton variant="rectangular" height={43} width={220} className="rounded-md" />
              ) : (
                isFinalRepairRequest && (
                  <Box className="mb-3">
                    <AssetStatusMultiSelect name="status" withErrorHandling useNewDesign />
                  </Box>
                )
              )}

              <TextField multiline fullWidth label="Notes" name="note" required={false} rows={2} />

              <ImageUploadPreviewList
                name="files"
                disabled={!repairRequest?.id}
                prefix={repairRequest?.id ? repairReportImagePrefix(repairRequest.assetId) : ""}
              />
            </div>
          </Form>
        </MuiModal>
      )}
    </Formik>
  )
}
