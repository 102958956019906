import { clsx } from "clsx"
import { FC, useState } from "react"
import { BiPlus, BiPoll, BiSearchAlt2, BiX } from "react-icons/bi"
import { MdAddChart } from "react-icons/md"
import { Task, TaskGroup } from "../../../../graphql/generated/gql/graphql"
import { ProjectExpectation } from "../../../../types/ProjectExpectation"
import { HandleDeleteFunc, HandleReportFunc } from "../../../../types/Task"
import { PickPlus } from "../../../../types/helpers"
import { AddRow } from "../../../AddRow"
import { IconButton } from "../../../IconButton"
import { useModalProps } from "../../../Modals/hooks/useModalProps"
import { RenderIf } from "../../../RenderIf"
import { Table } from "../../../Table/Table"
import { TableContextMenuContainer } from "../../../TableContextMenuContainer"
import { H4 } from "../../../deprecated"
import { SearchInput } from "../../../deprecated/SearchInput"
import { DrawerBody } from "../../Drawer/components/Elements/DrawerBody"
import { DrawerHeader } from "../../Drawer/components/Elements/DrawerHeader"
import { DrawerLink } from "../../Drawer/components/Elements/DrawerLink"
import { CreateOrEditTaskForm } from "../../Tasks/CreateOrEditTaskForm"
import { TaskRow } from "./TaskRow"

type TaskExpectation = PickPlus<
  Task,
  | "id"
  | "assetCount"
  | "hasReportableUnit"
  | "isComplete"
  | "isDefault"
  | "name"
  | "projectId"
  | "userCount"
  | "completedHours"
>

type Props = {
  project: ProjectExpectation
  tasks: TaskExpectation[]
  taskGroup?: PickPlus<TaskGroup, "id">
  handleReport: HandleReportFunc
  handleDelete: HandleDeleteFunc
}

export const TaskList: FC<Props> = ({ project, tasks, taskGroup, handleReport, handleDelete, ...props }) => {
  const reportUnitProps = useModalProps("Report Unit")

  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true)
  const assignToProjectFormModalProps = useModalProps("Assign to project")
  const convertTaskToSubTaskModalProps = useModalProps("Convert to sub-task")

  const showUnitReportButton = tasks.some((task) => task.hasReportableUnit)

  return (
    <div>
      <div id="task-list-header" className="lg:flex justify-between items-center">
        <H4>{taskGroup ? "Sub-Task List" : "Task List"}</H4>
        <div className="relative w-full lg:w-auto flex justify-start items-start lg:justify-end min-h-[20px]">
          <TableContextMenuContainer isOpen={!searchIsOpen}>
            <IconButton Icon={BiSearchAlt2} onClick={() => setSearchIsOpen(true)} />
            {showUnitReportButton && (
              <RenderIf permissionsInclude="task:update">
                <IconButton Icon={MdAddChart} onClick={() => reportUnitProps.handleOpenModal()} />
              </RenderIf>
            )}
            <RenderIf permissionsInclude="task:create">
              {!project.isComplete && (
                <DrawerLink
                  href={`/projects/${project.id}/tasks/create${taskGroup ? `?groupId=${taskGroup.id}` : ""}`}
                  component={
                    <div>
                      <DrawerHeader
                        href={`/projects/${project.id}/tasks/create${taskGroup ? `?groupId=${taskGroup.id}` : ""}`}
                      />
                      <DrawerBody>
                        <CreateOrEditTaskForm
                          task={{ projectId: project.id }}
                          taskGroupId={taskGroup?.id}
                          taskType="sub-task"
                        />
                      </DrawerBody>
                    </div>
                  }
                  drawerName="TaskCreate"
                >
                  <IconButton Icon={BiPlus} />
                </DrawerLink>
              )}
            </RenderIf>
            <IconButton onClick={() => setIsProgressBarVisible((isVisible) => !isVisible)} Icon={BiPoll}>
              <span
                className={clsx("relative w-7 h-4 rounded-full", isProgressBarVisible ? "bg-blue-600" : "bg-gray-400")}
              >
                <span
                  className={clsx(
                    "absolute top-1 size-2 bg-gray-100 rounded-full left-1 transition-transform",
                    isProgressBarVisible && "translate-x-3"
                  )}
                ></span>
              </span>
            </IconButton>
          </TableContextMenuContainer>
          <TableContextMenuContainer isOpen={searchIsOpen}>
            <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} onClose={() => setSearchIsOpen(false)} />
            <IconButton
              onClick={() => {
                setSearchIsOpen(false)
                setSearchTerm("")
              }}
              Icon={BiX}
            />
          </TableContextMenuContainer>
        </div>
      </div>
      {tasks && (
        <Table>
          {tasks
            .filter((task) => task.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((task) => {
              return (
                <TaskRow
                  key={task.id}
                  {...props}
                  task={task}
                  handleReport={handleReport}
                  handleDelete={handleDelete}
                  isProgressBarVisible={isProgressBarVisible}
                  openConvertToSubTaskModal={convertTaskToSubTaskModalProps.handleOpenModal}
                  openReassignModal={assignToProjectFormModalProps.handleOpenModal}
                  project={project}
                />
              )
            })}
        </Table>
      )}
      {!project.isComplete && (
        <DrawerLink
          href={`/projects/${project.id}/tasks/create${taskGroup ? `?groupId=${taskGroup.id}` : ""}`}
          component={
            <div>
              <DrawerHeader
                href={`/projects/${project.id}/tasks/create${taskGroup ? `?groupId=${taskGroup.id}` : ""}`}
              />
              <DrawerBody>
                <CreateOrEditTaskForm
                  task={{ projectId: project.id }}
                  taskGroupId={taskGroup?.id}
                  taskType="sub-task"
                />
              </DrawerBody>
            </div>
          }
          drawerName="TaskCreate"
        >
          <AddRow label={taskGroup ? "Add sub-task" : "Add task"} component="span" />
        </DrawerLink>
      )}
    </div>
  )
}
