import { Button } from "@mui/material"
import { FC, useContext, useEffect, useState } from "react"
import { BiEditAlt, BiLogOut, BiNavigation, BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import { getDirectionsMapUrl } from "../../../features/Maps/utils/helpers"
import { useBulkClockInMutation, useBulkClockOutMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { getFriendlyFloat } from "../../../helpers/numbers/getFriendlyFloat"
import { pluralize } from "../../../helpers/strings/pluralize"
import { useHandleError } from "../../../hooks/useHandleError"
import { OrganizationSettingsContext } from "../../../providers/OrganizationSettingsProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { UnitReportModal } from "../../Modals/components/UnitReportModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, infoSnack } from "../../Notistack/ThemedSnackbars"
import { QuickActionContainer } from "../../QuickActionContainer"
import { QuickActionRow } from "../../QuickActionRow"
import { RenderIf } from "../../RenderIf"
import DeprecatedModal from "../../deprecated/StandardModal"
import { TaskExpectation } from "../Projects/TaskDetails"
import { BulkClockInModalContents } from "../Team/BulkTeamActions/BulkClockInModalContents"
import { BulkClockOutModal } from "../Team/BulkTeamActions/BulkClockOutModal"

type TaskQuickActionsProps = {
  task: TaskExpectation
}

const TaskQuickActionUserCount = graphql(`
  query TaskQuickActionUserCount($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      clockedInUserCount
      clockedOutUserCount
      project {
        id
        address {
          formattedAddress
        }
        location {
          lat
          lng
        }
      }
      userCount
      users {
        id
        isClockedIn
        projectId
      }
    }
  }
`)

export const TaskQuickActions: FC<TaskQuickActionsProps> = ({ task }) => {
  const { requireTimeEntryEvidence } = useContext(OrganizationSettingsContext)
  const [mapUrl, setMapUrl] = useState("")

  const [{ data, error }, refetch] = useQuery({
    query: TaskQuickActionUserCount,
    pause: !task.id,
    variables: {
      taskId: task.id,
    },
  })

  useHandleError(error, "There was a problem loading some user data")

  useEffect(() => {
    if (data?.task.project.location) {
      const url = getDirectionsMapUrl(data.task.project.location)
      setMapUrl(url)
    }
  }, [data?.task?.project?.location, setMapUrl])

  const [reportModalOpen, setReportModalOpen] = useState(false)

  const bulkClockInModalProps = useModalProps("Bulk Clock In")
  const bulkClockOutModalProps = useModalProps("Bulk Clock Out")
  const [, bulkClockInMutation] = useBulkClockInMutation()
  const [, bulkClockOutMutation] = useBulkClockOutMutation()

  const handleBulkClockIn = () => {
    if (requireTimeEntryEvidence) {
      bulkClockInModalProps.handleOpenModal()

      return
    }

    const candidates =
      data?.task?.users?.map((user) => ({
        projectId: user.projectId,
        taskId: task.id,
        userId: user.id,
      })) || []

    bulkClockInMutation({ candidates }).then((result) => {
      if (result.error) {
        errorSnack(`Error with Bulk Clock in`)
        console.error(result.error)
      } else {
        const clockedInUserCount = result.data?.bulkClockIn.success || 0
        refetch()
        infoSnack(`Clocked in ${clockedInUserCount} ${pluralize("user", clockedInUserCount)}`)
      }
    })
  }

  const handleBulkClockOut = () => {
    if (requireTimeEntryEvidence) {
      bulkClockOutModalProps.handleOpenModal()

      return
    }

    bulkClockOutMutation({
      candidates: data?.task?.users.map((user) => ({ userId: user.id })) || [],
    }).then((result) => {
      if (result.error) {
        errorSnack("Error with Bulk Clock Out")
        console.error(result.error)
      } else {
        const clockedOutUserCount = result.data?.bulkClockOut.success || 0
        refetch()
        infoSnack(`Clocked out ${clockedOutUserCount} ${pluralize("user", clockedOutUserCount)}`)
      }
    })
  }

  const sumProgress = task.unitGoals?.reduce((sum, goal) => {
    const sumUnitGoalProgress = goal?.unitGoalProgress?.reduce((unitSum, event) => {
      return unitSum + event?.progress
    }, 0)

    return sum + sumUnitGoalProgress
  }, 0)

  const sumUnitGoalsTargetQuantity = task.unitGoals?.reduce((acc, goal) => {
    return acc + (goal?.targetQuantity || 0)
  }, 0)

  const { hasPermissionTo } = useContext(PermissionsContext)
  const noReportPermissions = !hasPermissionTo("task:report", { projectId: task.projectId })

  return (
    <QuickActionContainer>
      {!task.isDefault && (
        <QuickActionRow
          icon={<BiEditAlt className="size-5" />}
          title="Unit Reporting"
          subTitle={`${getFriendlyFloat(sumProgress || 0)} / ${getFriendlyFloat(sumUnitGoalsTargetQuantity || 0)}`}
          action={
            <Button
              variant="contained"
              disabled={noReportPermissions || !!data?.task.isComplete}
              color="black"
              onClick={() => {
                setReportModalOpen(true)
              }}
              type="button"
              endIcon={<BiEditAlt size={18} />}
            >
              Report
            </Button>
          }
        />
      )}

      <RenderIf permissionsInclude="timeEntry:create">
        <>
          <QuickActionRow
            icon={<BiStopwatch className="size-5" />}
            title="Clock team in"
            subTitle={`${data?.task.clockedOutUserCount} of ${data?.task.userCount}`}
            action={
              <Button
                color="black"
                disabled={!data?.task.clockedOutUserCount || !!data?.task.isComplete}
                onClick={handleBulkClockIn}
                type="button"
                variant="contained"
              >
                Clock in
              </Button>
            }
          />

          <QuickActionRow
            icon={<BiLogOut className="size-5" />}
            title="Clock team out"
            subTitle={`${data?.task.clockedInUserCount} of ${data?.task.userCount}`}
            action={
              <Button
                color="black"
                disabled={!data?.task.clockedInUserCount || !!data?.task.isComplete}
                onClick={handleBulkClockOut}
                type="button"
                variant="contained"
              >
                Clock out
              </Button>
            }
          />

          {data?.task?.project?.location && (
            <QuickActionRow
              icon={<BiNavigation className="size-5" />}
              title="Get Directions"
              subTitle={data?.task?.project?.address?.formattedAddress || "None"}
              action={
                <Button variant="contained" color="black" disabled={!mapUrl} href={mapUrl} target="_blank">
                  Directions
                </Button>
              }
            />
          )}

          <DeprecatedModal {...bulkClockInModalProps}>
            <BulkClockInModalContents
              onCancel={bulkClockInModalProps.handleCloseModal}
              onSuccess={() => {
                refetch()
                bulkClockInModalProps.handleCloseModal()
              }}
              taskId={task.id}
            />
          </DeprecatedModal>

          <BulkClockOutModal
            {...bulkClockOutModalProps}
            handleCloseModal={() => {
              refetch()
              bulkClockOutModalProps.handleCloseModal()
            }}
            taskId={task.id}
          />
        </>
      </RenderIf>

      <UnitReportModal isOpen={reportModalOpen} closeModal={() => setReportModalOpen(false)} taskId={task.id} />
    </QuickActionContainer>
  )
}
