import { Stack, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { BiGitMerge } from "react-icons/bi"
import { colors } from "../../../../helpers/styles/colors"
import { usePermissions } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext, TaskListStateContext } from "../../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../../providers/TaskListProvider/taskListReducer"
import { DrawerLink } from "../../Drawer/components/Elements/DrawerLink"
import { TabValue, TaskDrawer } from "../../Drawer/components/Task/TaskDrawer"
import { TaskGroupDrawer } from "../../Drawer/components/Task/TaskGroupDrawer"

type Props = {
  taskOrGroupId: string
  projectId: string
  isComplete?: boolean
  className?: string
  size: "small" | "large"
  isTaskGroup?: boolean
  name?: string
  secondaryTitle?: boolean
  tab?: TabValue
}

export const TaskTitle: FC<Props> = ({
  name,
  taskOrGroupId,
  projectId,
  className,
  isComplete,
  isTaskGroup = false,
  tab = "details",
}) => {
  const { hasPermissionTo } = usePermissions()

  const dispatch = useContext(TaskListDispatchContext)
  const { taskList } = useContext(TaskListStateContext)
  const onSuccess = () => dispatch({ type: TASK_LIST_ACTION_TYPES.refreshTaskList })

  const task = taskList?.find(
    (t) => taskOrGroupId != null && (t.taskId === taskOrGroupId || t.taskGroupId === taskOrGroupId)
  )
  const taskName = name || task?.name
  const isGroup = task?.taskGroupId != null

  const isDisabled = !hasPermissionTo("task:read", { taskId: taskOrGroupId, projectId })

  return (
    <DrawerLink
      disabled={isDisabled}
      component={
        isGroup ? (
          <TaskGroupDrawer taskGroupId={taskOrGroupId} projectId={projectId!} onSuccess={onSuccess} />
        ) : (
          <TaskDrawer taskId={taskOrGroupId!} tab={tab} />
        )
      }
      drawerName={isGroup ? "TaskGroup" : "Task"}
      href={
        isTaskGroup
          ? `/projects/${projectId}/group/${taskOrGroupId}/details`
          : `/projects/${projectId}/${taskOrGroupId}/summary`
      }
      className={className}
    >
      <div className="flex flex-wrap items-center">
        <Typography
          variant="h6"
          fontSize={16}
          sx={{
            textDecoration: isComplete ? "line-through" : "none",
            cursor: isDisabled ? "not-allowed" : "pointer",
            color: colors.gray[isComplete ? 500 : 800],
            fontWeight: 400,
            ":hover": {
              textDecoration: isDisabled ? "auto" : "underline",
              color: colors.blue[600],
            },
          }}
        >
          {taskName}
        </Typography>
        {isGroup && (
          <Stack className="ml-2 text-gray-400 text-sm" direction="row" spacing="4px">
            <BiGitMerge size={18} />
            <div>
              {task.completedTaskCount}/{task.taskCount}
            </div>
          </Stack>
        )}
      </div>
    </DrawerLink>
  )
}
