import { format, parse } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { TaskUnitsGraphProgressQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { ProjectUnitSummaryGraphHoursData, TaskUnitSummaryGraphHoursData } from "../../../../models/TimeEntryModel"

type GraphData = {
  description: string
  color: string
  progress: number
  highlightBar?: boolean
  unitOfMeasure: string
}

export type UnitsData = Omit<GraphData, "progress">

type RechartData = {
  name: string
  [description: string]: number | string | boolean | { [taskId: string]: number }
}

export function prepareDataForRecharts(
  task: TaskUnitsGraphProgressQuery["task"],
  rangeStart: Date,
  rangeEnd: Date,
  entityType: "project" | "task"
): { data: RechartData[]; units: UnitsData[] } {
  const groupedItems: { [key: string]: GraphData[] } = {}
  const uniqueUnits: { [description: string]: UnitsData } = {}
  const { taskProgressEventsWithUnitReports: taskProgressEvents } = task || { taskProgressEvents: [] }
  // Filter out additional units reported
  taskProgressEvents.forEach((tpe) => {
    tpe.unitGoalProgressReports = tpe.unitGoalProgressReports.filter((ugp) => ugp.unitGoal.isPrimary)
  })

  for (const event of taskProgressEvents) {
    const groupingKey = format(new Date(event.createdAt), "yyyy-MM-dd")
    const isHighlighted = event.createdAt >= rangeStart && event.createdAt <= rangeEnd
    const formattedName = format(parse(groupingKey, "yyyy-MM-dd", new Date()), "MMM dd yyyy")

    if (!groupedItems[formattedName]) {
      groupedItems[formattedName] = []
    }

    for (const report of event.unitGoalProgressReports || []) {
      const existingUnit = groupedItems[formattedName].find(
        (item) => item.description === report.unitGoal.deliverableUnit.description
      )

      if (existingUnit) {
        existingUnit.progress += report.progress ?? 0
      } else {
        const newUnit = {
          description: report.unitGoal.deliverableUnit.description,
          color: report.unitGoal.deliverableUnit.color,
          progress: report.progress,
          highlightBar: isHighlighted,
          unitOfMeasure: report.unitGoal.deliverableUnit.unitOfMeasure,
        }
        groupedItems[formattedName].push(newUnit)
      }

      uniqueUnits[report.unitGoal.deliverableUnit.description] = {
        description: report.unitGoal.deliverableUnit.description,
        color: report.unitGoal.deliverableUnit.color,
        unitOfMeasure: report.unitGoal.deliverableUnit.unitOfMeasure,
      }
    }
  }

  const taskHoursLookup: { [formattedDate: string]: number } = {}
  const taskRechartData: RechartData[] = []
  if (entityType === "task") {
    task.hoursDataByDateRangeType?.forEach((hourData: TaskUnitSummaryGraphHoursData) => {
      const formattedDate = formatDate(hourData.date)
      taskHoursLookup[formattedDate] = hourData.duration_in_hours
    })
    Object.entries(groupedItems).map(([name, units]) => {
      taskRechartData.push(createDayData(name, taskHoursLookup, units, entityType))
    })
  }
  const projectHoursLookup: { [formattedDate: string]: { [taskId: string]: number } } = {}
  const projectRechartData: RechartData[] = []
  if (entityType === "project") {
    task.hoursDataByDateRangeType?.forEach((hourData: ProjectUnitSummaryGraphHoursData) => {
      const taskId = hourData.taskId
      const formattedDate = formatDate(hourData.date)
      if (!projectHoursLookup[formattedDate]) {
        projectHoursLookup[formattedDate] = { [taskId]: hourData.task_duration_in_hours }
      } else {
        projectHoursLookup[formattedDate][taskId] = hourData.task_duration_in_hours
      }
    })
    Object.entries(groupedItems).map(([name, units]) => {
      projectRechartData.push(createDayData(name, projectHoursLookup, units, entityType))
    })
  }
  return entityType === "project"
    ? { data: projectRechartData, units: Object.values(uniqueUnits) }
    : { data: taskRechartData, units: Object.values(uniqueUnits) }
}

function formatDate(dateInput: string): string {
  const utcDate = utcToZonedTime(dateInput, "UTC")
  const date = format(utcDate, "yyyy-MM-dd")
  return format(parse(date, "yyyy-MM-dd", new Date()), "MMM dd yyyy")
}

function createDayData(
  name: string,
  hoursLookup: { [formattedDate: string]: number | { [taskId: string]: number } },
  units: GraphData[],
  entityType: "project" | "task"
): RechartData {
  const hours =
    entityType === "project" ? hoursLookup[name] || {} : Math.round((hoursLookup[name] as number) * 100) / 100 || 0
  const dataItem: RechartData = { name, hours }
  units.forEach((unit) => {
    dataItem[unit.description] = unit.progress
    dataItem.highlightBar = unit.highlightBar ?? false
  })
  return dataItem
}
