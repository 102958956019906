import { useStore } from "@tanstack/react-store"
import { FC, useEffect } from "react"
import { useQuery } from "urql"
import { AssetListDataGridQuery, AssetSort } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { assetListStore, assetListStoreActions } from "../../../../stores/assetList"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { AssetDataGrid } from "./AssetDataGrid"
import { AssetListHeader } from "./AssetListHeader"

const AssetListDataGridDocument = graphql(`
  query AssetListDataGrid(
    $filter: AssetFilter!
    $first: Int!
    $sort: AssetSort!
    $after: String
    $visibilityFilter: ListVisibilityFilter
  ) {
    assets_2(filter: $filter, first: $first, sort: $sort, after: $after, visibilityFilter: $visibilityFilter) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          category
          assetChildCount
          assetGroupId
          assignableId
          assignableType
          assignmentsCount
          rentalAgreement {
            rate {
              daily
              weekly
              monthly
            }
            endOn
            startOn
          }
          purchaseDetails {
            price
            date
          }
          manufacturer {
            id
            make
            name
            year
            model
          }
          vendorContact {
            name
            email
            phone
          }

          assignedAsset {
            id
            name
            compositeKey
          }

          assignedTask {
            id
            name
            projectId
            project {
              id
              name
            }
          }

          assignedTaskId

          assignedUser {
            id
            firstName
            lastName
            currentProject {
              id
              name
            }
            currentTask {
              id
              name
            }
          }

          assignedUserId

          companyAssetNumber
          compositeKey
          deletedAt
          groupQuantity
          imageUrl
          inferredOwner {
            id
            currentProject {
              id
              name
            }
            currentTask {
              id
              name
            }
          }

          inferredProjectId
          isAssetGroup
          status
          ownershipType
        }
      }
    }
  }
`)

export type AssetNode = NonNullable<AssetListDataGridQuery["assets_2"]["edges"][0]>["node"]

type Props = {
  handleAssignAsset?: () => void
  isComplete?: boolean
  projectId?: string
  taskId?: string
  userId?: string
}

export const AssetListDataGrid: FC<Props> = ({ handleAssignAsset, isComplete, projectId, taskId, userId }) => {
  const filters = useStore(assetListStore, (state) => state.filters)
  const sort = useStore(assetListStore, (state) => state.sort)
  const cursor = useStore(assetListStore, (state) => state.cursor)

  const [{ data, fetching: loadingAssets, error: assetsError }] = useQuery({
    query: AssetListDataGridDocument,
    variables: {
      first: 50,
      after: cursor,
      filter: {
        isArchived: filters.isArchived,
        billingClassificationIds: filters.billingClassificationIds,
        includeGroupedAssets: true,
        searchText: filters.searchText,
        projectId,
        taskId,
        statuses: filters.statuses,
        categories: filters.categories,
        userId,
      },
      sort: { by: sort?.field, direction: sort?.sort } as AssetSort,
      visibilityFilter: filters.visibility,
    },
  })

  useEffect(() => {
    assetListStoreActions.initializeAssetListStore({
      filters: {
        billingClassificationIds: [],
        categories: [],
        isArchived: false,
        searchText: null,
        statuses: [],
        visibility: null,
        projectId,
        taskId,
        userId,
      },
    })
  }, [projectId, taskId, userId])

  useHandleError(assetsError, "There was a problem loading assets")

  useEffect(() => {
    assetListStoreActions.addPageData(data?.assets_2 || { edges: [], totalCount: 0, pageInfo: { endCursor: null } })
  }, [data?.assets_2])

  return (
    <div className="flex flex-col w-full">
      <AssetListHeader
        projectId={projectId}
        taskId={taskId}
        handleAssignAsset={handleAssignAsset}
        isComplete={isComplete}
        userId={userId}
      />

      <div className="w-full">
        <AssetDataGrid
          loadingAssets={loadingAssets}
          projectId={projectId}
          taskId={taskId}
          totalCount={data?.assets_2?.totalCount}
          NoRowsOverlay={<EmptyStateBlock label="No assets" />}
        />
      </div>
    </div>
  )
}
