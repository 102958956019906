import { Box, Button, colors, Skeleton, Typography } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { FC, useState } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/strings/pluralize"
import { formatDuration, getDuration } from "../../../helpers/dateAndTime/time-utility"
import { useHandleError } from "../../../hooks/useHandleError"
import { BiError, BiWrench, BiX } from "react-icons/bi"
import { ReportAssetRepairModal } from "../../Modals/components/ReportAssetRepairModal"
import { DismissRepairRequestModal } from "../../Modals/components/DismissRepairRequestModal"

const AssetRepairRequestsDocument = graphql(`
  query AssetRepairRequestsQuery($assetId: String!) {
    assetRepairRequests(assetId: $assetId) {
      id
      assetId
      inspectionFieldId
      label
      createdAt
    }
  }
`)

export const AssetRepairRequests: FC<{ assetId: string }> = ({ assetId }) => {
  const [{ fetching, data, error }, refetch] = useQuery({
    query: AssetRepairRequestsDocument,
    variables: { assetId },
    pause: !assetId,
  })
  const [selectedRepairId, setSelectedRepairId] = useState<string | null>(null)
  const [repairModalOpen, setRepairModalOpen] = useState(false)
  const [dismissRepairModalOpen, setDismissRepairModalOpen] = useState(false)

  useHandleError(error, "There was a problem retrieving failed inspection items. Please try again.")

  const openRepairModal = (id: string) => {
    setRepairModalOpen(true)
    setSelectedRepairId(id)
  }

  const openDismissRepairModal = (id: string) => {
    setDismissRepairModalOpen(true)
    setSelectedRepairId(id)
  }

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <BiError className="bg-orange-600 text-white p-1 rounded size-6 mr-3" />
          <Typography>{params.row.label}</Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => format(params.row.createdAt, "MM/dd/yyyy"),
    },
    {
      field: "duration",
      headerName: "Timer",
      flex: 1,
      renderCell: (params) => {
        const duration = getDuration(params.row.createdAt, new Date())
        let time = ""

        if (duration.hours > 24) {
          const durationDays = Math.floor(duration.hours / 24)
          time = `${durationDays} ${pluralize("day", durationDays)}`
        } else {
          const formatted = formatDuration(duration)
          time = `${formatted.hours} ${formatted.minutes}`
        }

        return <Typography className="text-orange-600">{time}</Typography>
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<BiWrench />}
              onClick={() => openRepairModal(params.row.id)}
            >
              Repair
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<BiX />}
              onClick={() => openDismissRepairModal(params.row.id)}
            >
              Dismiss
            </Button>
          </Box>
        )
      },
    },
  ]
  const assetRepairRequests = data?.assetRepairRequests ?? null

  if (fetching && !assetRepairRequests) {
    return <Skeleton variant="rectangular" width="100%" height="250px" className="mt-[40px] border-2 rounded-md" />
  }

  return assetRepairRequests?.length ? (
    <>
      <DataGridPro
        rowSelection={false}
        autoHeight
        columns={columns}
        rows={assetRepairRequests}
        sx={{ border: `1px solid ${colors.grey[300]}` }}
      />
      {selectedRepairId && (
        <>
          <ReportAssetRepairModal
            isOpen={repairModalOpen}
            closeModal={() => {
              setSelectedRepairId(null)
              setRepairModalOpen(false)
            }}
            assetRepairId={selectedRepairId}
            onSuccess={() => refetch()}
          />
          <DismissRepairRequestModal
            isOpen={dismissRepairModalOpen}
            closeModal={() => {
              setSelectedRepairId(null)
              setDismissRepairModalOpen(false)
            }}
            repairRequestId={selectedRepairId}
            onSuccess={refetch}
          />
        </>
      )}
    </>
  ) : null
}
