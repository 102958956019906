import { Checkbox, ListItemText, MenuItem, MenuList } from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { BiCalculator, BiChevronDown } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { CustomMenu } from "../../../CustomMenu"
import { testLabel_ListFilterButton, testLabel_ListFilterMenu } from "./labels"

type BillingClassificationFilterOption = {
  isChecked: boolean
  value: string | null
  label: string
  onSelect: () => void
}

type Props = {
  billingClassificationIds: string[] | null
  setBillingClassificationIds: Dispatch<SetStateAction<string[] | null>>
}

const TeamListBillingClassificationFilterQueryDocument = graphql(`
  query TeamListBillingClassificationFilterQuery {
    userBillingClassifications {
      id
      name
    }
  }
`)

export const TeamListBillingClassificationFilter: FC<Props> = ({
  billingClassificationIds,
  setBillingClassificationIds,
}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const [{ data }] = useQuery({
    query: TeamListBillingClassificationFilterQueryDocument,
  })
  const userBillingClassifications = data?.userBillingClassifications || []

  const filterOptions: BillingClassificationFilterOption[] = [
    {
      isChecked: !billingClassificationIds?.length,
      value: null,
      label: "All",
      onSelect: () => setBillingClassificationIds(null),
    },
    ...userBillingClassifications.map((classification) => {
      return {
        isChecked: !!billingClassificationIds?.includes(classification.id),
        value: classification.id,
        label: classification.name,
        onSelect: () => {
          return setBillingClassificationIds(
            billingClassificationIds?.includes(classification.id)
              ? billingClassificationIds.filter((id) => id !== classification.id)
              : [...(billingClassificationIds || []), classification.id]
          )
        },
      }
    }),
  ]

  const getFilterLabel = () => {
    if (!billingClassificationIds) return "All"
    if (billingClassificationIds?.length > 1) return `${billingClassificationIds.length} selected`

    return userBillingClassifications.find((c) => c.id === billingClassificationIds[0])?.name || "All"
  }
  if (!data) return null

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        test-label={testLabel_ListFilterMenu}
        anchor={
          <div
            className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800"
            test-label={testLabel_ListFilterButton}
          >
            <BiCalculator />
            <span className="text-gray-800">Classification</span>
            <span className="text-gray-500">{getFilterLabel()}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {filterOptions.map((filterOption) => (
            <MenuItem key={filterOption.label} onClick={filterOption.onSelect}>
              <Checkbox checked={filterOption.isChecked} />
              <ListItemText>{filterOption.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
