import { useEffect, useState } from "react"
import { User } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

export function useClockedOutUsers<U extends PickPlus<User, "id" | "isClockedIn">>(users: U[]) {
  const [clockedOutUsers, setClockedOutUsers] = useState<U[]>([])

  useEffect(() => {
    setClockedOutUsers(users.filter((user) => !user.isClockedIn))
  }, [users])

  return clockedOutUsers
}
