import { IconButton } from "@mui/material"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { clsx } from "clsx"
import { FC, MutableRefObject, useContext, useEffect, useState } from "react"
import { BiCar, BiChevronDown, BiChevronUp, BiEdit, BiSortAlt2, BiTrash } from "react-icons/bi"
import { Asset, useRestoreAssetMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { ConfirmArchiveAssetModal } from "../../../Modals/components/ConfirmArchiveAssetModal"
import { useModalProps } from "../../../Modals/hooks/useModalProps"
import { QuickMenuDotsHorizontal } from "../../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../../QuickMenuMui"
import { AssetDrawer } from "../../Drawer/components/Asset/AssetDrawer"
import { AssetEditDrawer } from "../../Drawer/components/Asset/AssetEditDrawer"
import {
  AssetGroupReassignmentFilterDefinition,
  ReassignAssetDrawer,
} from "../../Drawer/components/Asset/ReassignAssetDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"

export const AssetQuickActionsCell: FC<{
  apiRef: MutableRefObject<GridApiPro>
  asset: Asset
}> = ({ apiRef, asset }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const expandedDetailPanels = apiRef.current.getExpandedDetailPanels()

  const [_restoreAssetData, assetRestoreMutation] = useRestoreAssetMutation()
  const [isExpanded, setIsExpanded] = useState(expandedDetailPanels.includes(asset.compositeKey))
  const archiveAssetModalProps = useModalProps("Archive Asset")

  const hasChildRows = asset.isAssetGroup || asset.assetChildCount > 0

  const quickMenuItems: MenuItem[][] = [
    [
      {
        value: "View",
        Icon: BiCar,
        onClick: () => pushDrawer(<AssetDrawer assetId={asset.id} />, "Asset"),
      },
    ],
  ]

  if (hasPermissionTo("asset:transfer") && !asset?.isAssetGroup && !asset?.deletedAt) {
    quickMenuItems[0].push({
      value: "Transfer",
      onClick: () =>
        pushDrawer(
          <ReassignAssetDrawer
            assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
            assetGroupToReassign={
              asset.assetGroupId
                ? ({
                    assetGroupId: asset.assetGroupId!,
                    assignableId: asset.assignableId,
                    assignableType: asset.assignableType,
                    status: asset.status!,
                    count: asset.assetChildCount,
                    groupParent: asset?.childAssetGroups?.[0]?.groupParent,
                    compositeKey: asset.compositeKey,
                  } as AssetGroupReassignmentFilterDefinition)
                : null
            }
          />,
          "ReassignAsset"
        ),
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
    })
  }

  if (hasPermissionTo("asset:update") && asset) {
    quickMenuItems[0].push({
      value: "Edit",
      onClick: () => pushDrawer(<AssetEditDrawer assetId={asset.id} />, "AssetEdit"),
      Icon: BiEdit,
    })
  }

  if (hasPermissionTo("asset:delete") && asset.deletedAt === null) {
    quickMenuItems.push([
      {
        value: "Archive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => archiveAssetModalProps.handleOpenModal(),
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && asset.deletedAt !== null) {
    quickMenuItems.push([
      {
        value: "Unarchive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => {
          assetRestoreMutation({
            id: asset.id,
          })
        },
      },
    ])
  }

  const handleToggle = () => {
    apiRef.current.toggleDetailPanel(asset.compositeKey)
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    // toggle closed if the asset no longer has child rows
    if (!asset.assetChildCount && !hasChildRows && isExpanded) {
      apiRef.current.toggleDetailPanel(asset.compositeKey)
      setIsExpanded(false)
    }
  }, [apiRef, asset.assetChildCount, asset.compositeKey, hasChildRows, isExpanded])

  return (
    <div className="flex mt-0 col-span-1 justify-self-end">
      <QuickMenuMui
        className={clsx(
          "size-10 -mr-4 flex items-center justify-center flex-none rounded-full transition-colors p-2",
          "hover:bg-gray-50"
        )}
        items={quickMenuItems}
        buttonShape="round"
      >
        <QuickMenuDotsHorizontal />
      </QuickMenuMui>
      <IconButton color="inherit" onClick={handleToggle} disabled={!hasChildRows}>
        {isExpanded ? (
          <BiChevronUp className={hasChildRows ? "text-gray-800" : ""} />
        ) : (
          <BiChevronDown className={hasChildRows ? "text-gray-800" : ""} />
        )}
      </IconButton>
      <ConfirmArchiveAssetModal asset={asset} modalProps={archiveAssetModalProps} />
    </div>
  )
}
