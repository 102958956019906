import { useEffect, useState } from "react"
import { User } from "../graphql/generated/gql/graphql"
import { PickPlus } from "../types/helpers"

export function useClockedInUsers<U extends PickPlus<User, "id" | "isClockedIn">>(users: U[]) {
  const [clockedInUsers, setClockedInUsers] = useState<U[]>([])

  useEffect(() => {
    setClockedInUsers(users.filter((user) => user.isClockedIn))
  }, [users])

  return clockedInUsers
}
