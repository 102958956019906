import MobileStepper from "@mui/material/MobileStepper"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { OrganizationSettingsContext } from "../../../providers/OrganizationSettingsProvider"
import { useContext, useEffect, useState } from "react"
import { StartEvidenceInput, User, useClockInMutation } from "../../../graphql/generated/client-types-and-hooks"
import { VideoWithFrameGrab } from "./VideoFrame"
import { PickPlus } from "../../../types/helpers"
import { errorSnack } from "../../Notistack/ThemedSnackbars"
import { Confirmation } from "./Confirmation"
import { Divider } from "@mui/material"
import { GeolocationProviderContext } from "../../../providers/GeolocationProvider"
import { uploadTimeEntryEvidence } from "../../../helpers/timeEntries/uploadTimeEntryEvidence"
import { toastClockOutError } from "../../../helpers/clockInOut/toastClockOutError"

type Steps = "timeEntryEvidence" | "injuryReportOnClockout" | "confirmation"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "isClockedIn" | "currentTaskId"> & {
    currentProject: PickPlus<User["currentProject"], "id" | "name">
    currentTask: PickPlus<User["currentTask"], "id" | "name">
  }
  onSuccess?: () => void
}

export default function ClockInWorkflow({ user, onSuccess }: Props) {
  const settings = useContext(OrganizationSettingsContext)
  const { location, locationError } = useContext(GeolocationProviderContext)
  const [steps, setSteps] = useState<Steps[]>([])
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isLoading, setIsLoading] = useState(false)
  const [_, clockInMutation] = useClockInMutation()

  useEffect(() => {
    const newSteps: Steps[] = []
    if (settings.requireTimeEntryEvidence) newSteps.push("timeEntryEvidence")
    newSteps.push("confirmation")
    setSteps(newSteps)
  }, [settings])

  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const activeStep = steps[activeStepIndex]

  const handleNext = () => {
    if (activeStep == "timeEntryEvidence" && !imageBlob) return errorSnack("Please take a photo to continue")

    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClockIn = async () => {
    try {
      const startEvidence: StartEvidenceInput = {
        location,
        locationError: locationError?.message || undefined,
      }
      if (settings.requireTimeEntryEvidence) {
        if (!imageBlob) throw new Error("Photo is required")
        const imagePath = await uploadTimeEntryEvidence(imageBlob, user.id)
        startEvidence.imagePath = imagePath
      }

      setIsLoading(true)

      const response = await clockInMutation({
        taskId: user.currentTaskId!,
        userId: user.id,
        startEvidence,
      })

      if (response.error) throw new Error(response.error.message)
      onSuccess?.()
    } catch (error) {
      toastClockOutError(error as Error, user)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className="py-2">
        {activeStep === "timeEntryEvidence" && (
          <VideoWithFrameGrab
            imageBlob={imageBlob}
            setImageBlob={setImageBlob}
            onFail={() => {}}
            withFaceFrame={true}
          />
        )}

        {activeStep === "confirmation" && <Confirmation photo={imageBlob} user={user} />}
      </div>

      <Divider className="mx-[-24px] my-2" />
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStepIndex}
        nextButton={
          activeStepIndex === steps.length - 1 ? (
            <Button variant="contained" onClick={handleClockIn} disabled={isLoading}>
              Clock in
            </Button>
          ) : (
            <Button size="small" onClick={handleNext} endIcon={<KeyboardArrowRight className="size-5" />}>
              Next
            </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStepIndex === 0}
            startIcon={<KeyboardArrowLeft className="size-5" />}
          >
            Back
          </Button>
        }
      />
    </div>
  )
}
