import { Box } from "@mui/material"
import { FC } from "react"
import { getHoursAndMinutesFromSeconds } from "../../helpers/dateAndTime/getHoursAndMinutesFromSeconds"

type HoursAndMinutesProps = {
  seconds: number
}

export const HoursAndMinutes: FC<HoursAndMinutesProps> = ({ seconds }) => {
  const { hours, minutes } = getHoursAndMinutesFromSeconds(seconds)

  return (
    <Box
      style={{
        letterSpacing: +hours > 10000 ? "-0.25px" : "0",
      }}
      component="span"
    >
      {Intl.NumberFormat("en-us").format(+hours)}h {minutes}m
    </Box>
  )
}
