import { NextPage } from "next"
import { FC, ReactNode, useContext, useEffect } from "react"
import { BiEdit, BiHash, BiLink, BiPurchaseTag, BiTrash, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { Tooltip, Typography } from "@mui/material"
import { ConfirmArchiveAssetModal } from "../../../components/Modals/components/ConfirmArchiveAssetModal"
import { AssetImage } from "../../../components/AssetImage"
import Layout from "../../../components/Layout/Layout"
import { AssetSkeleton } from "../../../components/Partials/Assets/Asset.skeleton"
import { AssetEditDrawer } from "../../../components/Partials/Drawer/components/Asset/AssetEditDrawer"
import { QuickMenuDotsHorizontal } from "../../../components/QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../../components/QuickMenuMui"
import { useRestoreAssetMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { DrawerContext } from "../../../components/Partials/Drawer/providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { Tab, TabGroup } from "../../../components/Tabs/MuiTabs"

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/assets/[_id]/details",
  },
  {
    label: "History",
    href: "/assets/[_id]/history",
  },
  {
    label: "Documents",
    href: "/assets/[_id]/documents",
  },
]

type Props = {
  children: ReactNode
  assetId: string
  withTabs?: boolean
}

const AssetDetailsPageDocument = graphql(`
  query AssetDetailsPage($id: String!) {
    asset(id: $id) {
      id
      status
      assetChildCount
      assetGroupId
      assignableType
      assignableId
      companyAssetNumber
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      division {
        id
        name
      }
      category
      ownershipType
      active
      activeChangedAt
      assetChildCount
      assetGroupMemberCount

      billingClassification {
        id
        name
      }
      purchaseDetails {
        price
        date
      }
      rentalAgreement {
        rate {
          daily
          weekly
          monthly
        }
        endOn
        startOn
      }
      manufacturer {
        id
        make
        name
        year
        model
      }
      vendorContact {
        name
        email
        phone
      }
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      inspectionTemplatesAssignments {
        id
        assetId
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
  }
`)

export const AssetPageContent: FC<Props> = ({ children, assetId, withTabs = true }) => {
  const [{ data, fetching, error }] = useQuery({
    query: AssetDetailsPageDocument,
    variables: { id: assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const { setLabelSubstitutions, setIsLoading } = useContext(BreadcrumbsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const archiveAssetModalProps = useModalProps("Archive Asset")
  const [_restoreAssetData, assetRestoreMutation] = useRestoreAssetMutation()

  useEffect(() => {
    setIsLoading(fetching)
    setLabelSubstitutions([
      {
        original: data?.asset?.id || "",
        replacement: `${data?.asset?.name}`,
      },
    ])
  }, [fetching, setIsLoading, setLabelSubstitutions, data?.asset?.id, data?.asset?.name])

  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("asset:update") && data?.asset) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () => pushDrawer(<AssetEditDrawer assetId={data.asset?.id} />, "AssetEdit"),
        Icon: BiEdit,
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && data?.asset.deletedAt === null) {
    menuItems.push([
      {
        value: "Archive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => archiveAssetModalProps.handleOpenModal(),
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && data?.asset.deletedAt !== null && data?.asset.id) {
    menuItems.push([
      {
        value: "Unarchive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => {
          assetRestoreMutation({
            id: data?.asset.id,
          })
        },
      },
    ])
  }

  return (
    <div>
      {fetching && <AssetSkeleton />}
      {!fetching && data?.asset && (
        <>
          <div className="flex mb-4 gap-x-4">
            <AssetImage width="w-20" height="h-20" padding="p-6" asset={data?.asset} />
            <div className="md:ml-4 grid gap-2 w-full">
              <div className="flex gap-x-2">
                <Tooltip title={data?.asset.name}>
                  <Typography variant="h2" className="text-3xl lg:text-4xl text-gray-800 my-0 mt-1 text-ellipsis line-clamp-2">
                    {data?.asset.name}
                  </Typography>
                </Tooltip>
                <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
                  <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
                </QuickMenuMui>
              </div>
              <div className="flex gap-3 items-center text-gray-600 truncate">
                {data?.asset.ownershipType === "RENT" && (
                  <div className="flex items-center gap-0.5 min-w-4 text-orange-600">
                    <BiPurchaseTag className="shrink-0" />
                    <div>Rental</div>
                  </div>
                )}
                {data?.asset.assetChildCount > 0 && !data?.asset.isAssetGroup && !data?.asset.assetGroupId && (
                  <div className="flex items-center gap-0.5 min-w-4">
                    <BiLink className="shrink-0" />
                    <div>{data?.asset.assetChildCount}</div>
                  </div>
                )}
                {!!data?.asset.groupQuantity && data?.asset.groupQuantity > 1 && (
                  <div className="flex items-center gap-0.5 min-w-4">
                    <BiX className="shrink-0" />
                    <div>{data?.asset.groupQuantity}</div>
                  </div>
                )}
                {data?.asset.companyAssetNumber && (
                  <div className="flex items-center gap-0.5 min-w-4 max-w-[50%]">
                    <BiHash className="shrink-0" />
                    <div className="truncate">{data?.asset.companyAssetNumber}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {withTabs && <TabGroup tabs={tabs} />}
          {children}
          <ConfirmArchiveAssetModal asset={data.asset} modalProps={archiveAssetModalProps} />
        </>
      )}
    </div>
  )
}

const AssetsPageLayout: NextPage<Props> = ({ children, assetId }) => {
  return (
    <Layout>
      <AssetPageContent assetId={assetId}>{children}</AssetPageContent>
    </Layout>
  )
}

export default AssetsPageLayout
