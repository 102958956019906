import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from "react"

type ContextValue = {
  activeStep: number
  completed: { [k: number]: boolean }
  steps: string[]

  setSteps: Dispatch<SetStateAction<string[]>>

  handleComplete: () => void
  handleNext: () => void
  handleStep: (step: number) => void
  handleReset: () => void
  isStepSkipped: (step: number) => boolean
}

export const AssetInspectionWizardContext = createContext<ContextValue>({} as ContextValue)

export const AssetInspectionWizardProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [steps, setSteps] = useState<string[]>([])
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({})

  const isStepSkipped = (step: number) => skipped.has(step)

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleNext = () => {
    let newSkipped = skipped

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(getNextIncompleteStep())
    setSkipped(newSkipped)
  }

  const handleStep = (step: number) => {
    setActiveStep(step)
  }

  const handleReset = () => setActiveStep(0)

  const getNextIncompleteStep = () => {
    const completedSteps = Object.keys(completed)
    const nextStepName = steps.find((_step, index) => index > activeStep && !completedSteps.includes(index.toString()))
    const nextStep = steps.indexOf(nextStepName || "") || activeStep + 1

    return nextStep
  }

  return (
    <AssetInspectionWizardContext.Provider
      value={{
        activeStep,
        completed,
        isStepSkipped,
        steps,
        handleComplete,
        handleNext,
        handleStep,
        handleReset,
        setSteps,
      }}
    >
      {children}
    </AssetInspectionWizardContext.Provider>
  )
}
