import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC } from "react"
import { PageTitle } from "../../../components/PageTitle"
import { DocumentsTable } from "../../../components/Partials/Documents/DocumentsTable"
import { RenderIf } from "../../../components/RenderIf"
import { DocumentModelType } from "../../../helpers/files/fileUpload"
import AssetsPageLayout from "./_layout"

type AssetDocumentsPageProps = {
  assetId: string
}

export const AssetPageDocuments: FC<AssetDocumentsPageProps> = ({ assetId }) => {
  return (
    <>
      <PageTitle title="Asset Documents" />
      <div className="flex flex-col gap-9">
        <DocumentsTable
          modelId={assetId}
          requiredPermissions={["asset:read"]}
          title="Asset Documents"
          type={DocumentModelType.Asset}
        />
      </div>
    </>
  )
}

const AssetPage: NextPage = () => {
  const { query } = useRouter()
  const assetId = query._id as string
  return (
    <RenderIf permissionsInclude="asset:read" fallbackComponent={<Error statusCode={404} />}>
      <AssetsPageLayout assetId={assetId}>
        <AssetPageDocuments assetId={assetId} />
      </AssetsPageLayout>
    </RenderIf>
  )
}

export default AssetPage
