import React, { FC } from "react"
import { Typography } from "@mui/material"
import { secondsToFormattedString } from "../../../../helpers/dateAndTime/time-utility"
import clsx from "clsx"
import { BiSolidCircle } from "react-icons/bi"
import { useUpdatingUserClockedInHoursAndMinutes } from "../../../../hooks/useUpdatingUserClockedInHoursAndMinutes"

export const TotalTimeWithEdits: FC<{
  totalTimeInSeconds: number
  totalEditedTimeInSeconds: number | undefined | null
  showTimeDiff?: boolean
  showSeconds?: boolean
  isClockedIn?: boolean
  className?: string
  isToday?: boolean
}> = ({
  totalTimeInSeconds,
  totalEditedTimeInSeconds,
  isClockedIn = false,
  showTimeDiff = false,
  showSeconds = true,
  className = "flex gap-1",
  isToday = true,
}) => {
  const { hoursClockedIn, minutesClockedIn, secondsClockedIn } = useUpdatingUserClockedInHoursAndMinutes(
    {
      isClockedIn,
      secondsClockedSinceOrgMidnight: totalTimeInSeconds,
    },
    true,
    isToday
  )

  const symbol = (totalEditedTimeInSeconds || 0) > 0 ? "+" : "-"
  return (
    <div className={className}>
      <Typography
        className={clsx(isClockedIn ? "text-blue-600" : "text-gray-800", "flex justify-center items-center gap-1")}
      >
        <BiSolidCircle className={clsx("size-2", isClockedIn ? "text-blue-600" : "text-gray-500")} />
        {`${hoursClockedIn}:${minutesClockedIn}`}
        {showSeconds && `:${secondsClockedIn}`}
      </Typography>
      {!!totalEditedTimeInSeconds && (
        <Typography className="text-gray-500">
          <span className="line-through text-xs">
            {secondsToFormattedString(totalEditedTimeInSeconds - totalTimeInSeconds)}
          </span>{" "}
          <span className="text-xs">
            {showTimeDiff && <>{`(${symbol}${secondsToFormattedString(totalEditedTimeInSeconds)})`}</>}
          </span>
        </Typography>
      )}
    </div>
  )
}
