import { Skeleton } from "@mui/material"

import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { FC, useContext, useMemo } from "react"
import * as Yup from "yup"
import {
  AssetReportType,
  NestedAssetInspectionTemplatesQuery,
  useInsertManyAssetReportsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { isOperational } from "../../../helpers/assets/assetStatus"
import { AssetInspectionWizardContext } from "../../../providers/AssetInspectionWizardProvider"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { SingleDrawerContext } from "../Drawer/components/Elements/Drawer"
import { InspectionReportContainer } from "./InspectionReportContainer"

type Template =
  NestedAssetInspectionTemplatesQuery["nestedAssets"][0]["inspectionTemplatesAssignments"][0]["inspectionTemplate"]

type PhotoFile = {
  fileId: string
  objectKey: string
  uploaded: boolean
}

type InspectionReportFormValues = {
  photos?: PhotoFile[]
  status: string[] | null
  notes: string
}

export const InspectionReportForm: FC<{
  assetId: string
  template: Template
  repairRequests?: NestedAssetInspectionTemplatesQuery["assetRepairRequests"]
}> = ({ assetId, template, repairRequests }) => {
  const { activeStep, handleComplete, steps } = useContext(AssetInspectionWizardContext)
  const assetName = steps[activeStep]

  const { handleClose } = useContext(SingleDrawerContext)
  const [, createAssetReportsMutation] = useInsertManyAssetReportsMutation()
  const isOnLastStep = activeStep === steps.length - 1

  const initialValues = useMemo(() => {
    const templateFields = template.fields.reduce((fields, field) => {
      if (field.type === "y/n") {
        const requiresRepair = repairRequests?.some((repair) => repair.inspectionFieldId === field.id) ?? false
        return {
          ...fields,
          [field.id]: requiresRepair ? false : undefined,
          [`${field.id}-photos`]: [],
        }
      }

      return {
        ...fields,
        [field.id]: undefined,
        [`${field.id}-photos`]: undefined,
      }
    }, {})

    return { ...templateFields, status: null, notes: "" }
  }, [template, repairRequests])

  const onSubmit = async (values: FormikValues, helpers: FormikHelpers<InspectionReportFormValues>) => {
    const inspectionReport =
      template?.fields
        ?.map((field) => {
          const input = values[field.id]?.toString()
          const fileIds = values[field.id + "-photos"]?.map((photo: PhotoFile) => photo.fileId)
          const photos = values[field.id + "-photos"]?.map((photo: PhotoFile) => photo.objectKey)

          return {
            ...field,
            input,
            fileIds,
            photos,
          }
        })
        .filter((field) => {
          return (typeof field.input === "string" && field.input) || field.fileIds !== undefined
        }) || []

    const [status] = values.status || []
    const report = {
      assetId,
      inspectionReport,
      assetReportTemplateId: template?.id,
      statusChange: {
        status,
        active: isOperational(status),
      },
      type: AssetReportType.Inspection,
      notes: values.notes,
    }

    const result = await createAssetReportsMutation({ reports: [report] })
    if (result.error) {
      errorSnack("Error reporting")
    } else {
      if (isOnLastStep) {
        handleClose()
      } else {
        helpers.resetForm({ values: initialValues })
        handleComplete()
      }

      successSnack(`Successfully submitted inspection for ${assetName}`)
    }
  }

  const validate = (values: FormikValues) => {
    const errors: { [key: string]: string } = {}

    template?.fields.map((item) => {
      if (item.photoRequired && !values[item.id + "-photos"]?.length) {
        errors[`${item.id}-photos`] = "Required"
      }
    })

    return errors
  }

  if (!template) {
    return <Skeleton />
  }

  const fieldsSchema = template.fields.reduce(
    (schema, field) => ({
      ...schema,
      [field.id]: field.type === "y/n" ? Yup.boolean() : Yup.string(),
      [`${field.id}-photos`]: field.photoRequired
        ? Yup.array()
            .of(
              Yup.object().shape({
                fileId: Yup.string(),
                objectKey: Yup.string(),
                uploaded: Yup.boolean(),
              })
            )
            .required("Required")
        : Yup.array().of(
            Yup.object().shape({
              fileId: Yup.string(),
              objectKey: Yup.string(),
              uploaded: Yup.boolean(),
            })
          ),
    }),
    {}
  )

  const schema = Yup.object().shape({
    ...fieldsSchema,
    status: Yup.array(Yup.string()).required("Required field"),
    notes: Yup.string(),
  })

  return (
    <Formik<InspectionReportFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form>
        <InspectionReportContainer template={template} assetId={assetId} repairRequests={repairRequests} />
      </Form>
    </Formik>
  )
}
