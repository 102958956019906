import Error from "next/error"
import { FC } from "react"
import { useAssetDetailsPageQuery } from "../../../../../graphql/generated/client-types-and-hooks"
import { AssetSkeleton } from "../../../Assets/Asset.skeleton"
import { CreateOrEditAssetForm } from "../../../Assets/CreateOrEditAssetForm"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

// If given an asset, just use that.
// Else if given an assetId, fetch the asset.
type Props = {
  assetId?: string
}

export const AssetEditDrawer: FC<Props> = ({ assetId }) => {
  const [{ data, fetching }] = useAssetDetailsPageQuery({
    variables: { id: assetId! },
    pause: !assetId,
  })

  const asset = data?.asset

  if (!fetching && !asset) return <Error statusCode={404} />

  return (
    <>
      <DrawerHeader href={`/assets/${assetId}/details`} />
      <DrawerBody>
        {!asset && <AssetSkeleton />}
        {asset && <CreateOrEditAssetForm asset={asset} />}
      </DrawerBody>
    </>
  )
}
