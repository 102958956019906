import { Button } from "@mui/material"
import { useField, useFormikContext } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import {
  AssetReportTemplateField,
  NestedAssetInspectionTemplatesQuery,
} from "../../../graphql/generated/client-types-and-hooks"
import { getStatusBasedOnRules } from "../../../helpers/assets/getStatusChangeFromTemplateRules"
import { AssetInspectionWizardContext } from "../../../providers/AssetInspectionWizardProvider"
import { AssetStatusMultiSelect } from "../../Formik/MultiSelect/implementations/AssetStatusMultiSelect"
import { StandardTextarea } from "../../Formik/StandardTextarea"
import { SingleDrawerContext } from "../Drawer/components/Elements/Drawer"
import { DrawerFooter } from "../Drawer/components/Elements/DrawerFooter"
import { InspectionReportField } from "./InspectionReportField"
import { ReportAssetRepairModal } from "../../Modals/components/ReportAssetRepairModal"

type Template =
  NestedAssetInspectionTemplatesQuery["nestedAssets"][0]["inspectionTemplatesAssignments"][0]["inspectionTemplate"]

export const InspectionReportContainer: FC<{
  assetId: string
  template: Template
  repairRequests?: NestedAssetInspectionTemplatesQuery["assetRepairRequests"]
}> = ({ assetId, template, repairRequests }) => {
  const { activeStep, steps } = useContext(AssetInspectionWizardContext)
  const { handleClose } = useContext(SingleDrawerContext)
  const { values, setFieldValue } = useFormikContext<{ [key: string]: number | string | boolean }>()
  const [statusField, statusMeta, statusHelpers] = useField("status")
  const [_notesField, _notesMeta, notesHelpers] = useField("notes")
  const [disableStatus, setDisableStatus] = useState(false)
  const [previousStep, setPreviousStep] = useState(0)
  const isOnLastStep = activeStep === steps.length - 1
  const [unresolvedRepairRequests, setUnresolvedRepairRequests] = useState(repairRequests)
  const [repairModalOpen, setRepairModalOpen] = useState(false)
  const [selectedRepair, setSelectedRepair] = useState<{ id: string; inspectionFieldId: string } | null>(null)

  useEffect(() => {
    if (activeStep !== previousStep) {
      setPreviousStep(activeStep)
      statusHelpers.setValue(null)
      notesHelpers.setValue("")
    }
  }, [activeStep, setPreviousStep, previousStep, statusHelpers, notesHelpers])

  useEffect(() => {
    if (statusMeta.touched && statusMeta.value && statusMeta.error) {
      statusHelpers.setError(undefined)
    }
  })

  useEffect(() => {
    const keys = Object.keys(values)

    if (!keys.length) {
      return
    }

    const failedFieldsWithRules: AssetReportTemplateField[] = template.fields.filter(
      (f) =>
        keys.includes(f.id) && f.type === "y/n" && Boolean(f.rule) && Boolean(f.failedStatus) && values[f.id] === false
    )

    if (failedFieldsWithRules?.length) {
      const newStatus = getStatusBasedOnRules(failedFieldsWithRules)

      if (newStatus && statusField.value?.[0] !== newStatus) {
        statusHelpers.setValue([newStatus])
        setDisableStatus(true)
      }
    } else {
      setDisableStatus(false)
    }
  }, [values, template.fields, statusHelpers, statusField])

  useEffect(() => {
    if (unresolvedRepairRequests?.length) {
      unresolvedRepairRequests.forEach((r) => {
        if (!repairModalOpen && r.inspectionFieldId && values[r.inspectionFieldId] === true) {
          setSelectedRepair({
            id: r.id,
            inspectionFieldId: r.inspectionFieldId ?? "",
          })
          setRepairModalOpen(true)
        }
      })
    }
  }, [values, unresolvedRepairRequests, repairModalOpen, setRepairModalOpen])

  return (
    <div className="pt-12 px-4">
      <div className="flex-col">
        {template?.fields?.map((item) => (
          <InspectionReportField key={item.id} assetId={assetId} inspectionItem={item} />
        ))}
        <div className="flex py-6 border-t">
          <div className="basis-1/2">
            <AssetStatusMultiSelect name="status" disabled={disableStatus} withErrorHandling />
          </div>
        </div>
        <StandardTextarea name="notes" placeholder="Inspection notes" className="w-full rounded" rows={3} />
      </div>
      <DrawerFooter>
        <Button variant="contained" color="primary" type="submit">
          {isOnLastStep ? "Finish" : "Next"}
        </Button>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DrawerFooter>
      {selectedRepair && (
        <ReportAssetRepairModal
          isOpen={repairModalOpen}
          closeModal={(isRepaired: boolean = false) => {
            setRepairModalOpen(false)
            setSelectedRepair(null)
            setFieldValue(selectedRepair.inspectionFieldId, isRepaired)
          }}
          onSuccess={(status?: string) => {
            if (status) {
              statusHelpers.setValue([status])
            }
            setUnresolvedRepairRequests((prev) => prev?.filter((r) => r.id !== selectedRepair.id) || [])
          }}
          assetRepairId={selectedRepair.id}
        />
      )}
    </div>
  )
}
