import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { CustomMenu } from "../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "../../Assets/DataGrid/AssetFilters/AssetFilterState"
import { TeamListStatus } from "../TeamList"
import { testLabel_ListFilterButton, testLabel_ListFilterMenu } from "./labels"

type StatusFilterOption = {
  value: TeamListStatus
  label: string
  onSelect: () => void
}
// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  selectedStatus: TeamListStatus
  setSelectedStatus: (value: TeamListStatus) => void
  resetOtherFilters: () => void
}

export const TeamListStatusFilter = ({ selectedStatus, setSelectedStatus, resetOtherFilters }: Props): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const statusFilterOptions: StatusFilterOption[] = [
    {
      value: TeamListStatus.Active,
      label: "Active",
      onSelect: () => {
        setSelectedStatus(TeamListStatus.Active)
        resetOtherFilters()
      },
    },
    {
      value: TeamListStatus.Archived,
      label: "Archived",
      onSelect: () => {
        setSelectedStatus(TeamListStatus.Archived)
        resetOtherFilters()
      },
    },
  ]

  const getFilterLabel = (status: TeamListStatus) =>
    statusFilterOptions.find((option) => option.value === status)?.label || statusFilterOptions[0].label

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        test-label={testLabel_ListFilterMenu}
        anchor={
          <div
            className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800"
            test-label={testLabel_ListFilterButton}
          >
            <span className="text-gray-800">{getFilterLabel(selectedStatus)}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {statusFilterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                option.onSelect()
                setSelectedStatus(option.value)
              }}
            >
              <SingleOrMultiSelectionMenuItem selected={selectedStatus === option.value} />
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
