import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { getProjectProgressSummary } from "../../helpers/progress/summarizeProgressFromTaskList"

// Queries for the progress of a specified task and its subtasks.
const TaskProgressQueryDocument = graphql(`
  query TaskProgressSummaryHook($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      estimatedHours
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
  }
`)

/**
Custom hook to fetch and summarize the progress of a task.
@param taskId - The ID of the task.
@returns An object containing the summarized progress data, along with fetching status, potential errors, and a refetch function.
 */
export const useGetTaskProgressSummary = (taskId: string) => {
  const [{ data, error, fetching }, refetch] = useQuery({
    query: TaskProgressQueryDocument,
    variables: { taskId },
    pause: !taskId,
  })

  const tasks = []

  if (data?.task) tasks.push(data?.task)
  return {
    data: getProjectProgressSummary(tasks),
    fetching,
    error,
    refetch,
  }
}
