import { Masonry } from "@mui/lab"
import { Button, Typography } from "@mui/material"
import { format } from "date-fns"
import { FormikValues } from "formik"
import { FC, useContext, useState } from "react"
import { useQuery } from "urql"
import useScheduleCalculations from "../../../features/Scheduling/hooks/useScheduleCalculations"
import { defaultWorkDays } from "../../../features/Scheduling/utils/helpers"
import {
  DeliverableUnit,
  MetadataNoteInput,
  Task,
  UnitGoal,
  UnitGoalProgress,
  WorkersCompCode,
  useTaskEditMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useGetTaskProgressSummary } from "../../../hooks/queries/useGetTaskProgressSummary"
import { useReassignAndCloseTaskWorkflow } from "../../../hooks/useReassignAndCloseTaskWorkflow"
import { useFlags } from "../../../providers/DevelopmentFeatureFlagProvider"
import { TaskListDispatchContext } from "../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../providers/TaskListProvider/taskListReducer"
import { MetadataNote } from "../../../types/MetadataNote"
import { ProjectExpectation } from "../../../types/ProjectExpectation"
import { PickPlus } from "../../../types/helpers"
import { RichContentDisplay } from "../../Content/RichContentDisplay"
import { LabeledSection } from "../../LabeledSection"
import { AddNoteModal } from "../../Modals/components/AddNoteModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { NoteCard } from "../../NoteCard"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { SectionHeader } from "../../PageSectionHeader"
import { PageTitle } from "../../PageTitle"
import { QuickMenuSectionTitle } from "../../QuickMenuSectionTitle"
import { RenderIf } from "../../RenderIf"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { ValueOrNoneLabel } from "../Assets/BasicInfo"
import { DrawerBody } from "../Drawer/components/Elements/DrawerBody"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { ProjectScheduleLabels, ScheduleDetails } from "../Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { TaskDependencyDetails } from "../Organizations/TabPanels/ScheduleSubPanels/TaskDependencyDetails"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { TaskQuickActions } from "../Tasks/TaskQuickActions"
import { DeliverableUnitSection } from "./DeliverableUnitSection"
import { TaskExtraAssignmentTable } from "./TaskExtraAssignmentTable"
import { TaskProgressBar } from "./TaskList/TaskProgressBar"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"

type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity" | "deliverableUnitId"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
} & {
  unitGoalProgress: PickPlus<UnitGoalProgress, "id" | "progress">[]
}
export type TaskExpectation = PickPlus<
  Task,
  | "id"
  | "description"
  | "endDate"
  | "estimatedHours"
  | "isComplete"
  | "isDefault"
  | "metadata"
  | "name"
  | "projectId"
  | "startDate"
  | "completedHours"
  | "assetCount"
  | "userCount"
> & {
  unitGoals?: UnitGoalExpectation[]
  workersCompCode?: PickPlus<WorkersCompCode, "id" | "name" | "ncciCode"> | null
  group?: { id: string; name: string } | null
  project: ProjectExpectation
}

type Props = {
  task: TaskExpectation & {
    parentTask?: TaskExpectation
  }
  onSuccess?: () => void
}

const TaskScheduleDetails = graphql(`
  query TaskScheduleDetailsQuery($id: String!) {
    task(id: $id) {
      id
      projectId
      group {
        id
        name
      }

      orgScheduleId
      projectScheduleId

      schedule {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }

      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
          isUnpaid
          projectId
        }
      }
      startDate
      endDate
      estimatedHours
    }
  }
`)

export const TaskDetails: FC<Props> = ({ task, onSuccess }) => {
  const { push: pushDrawer, pop: handleClose } = useContext(DrawerContext)
  const { flagIsEnabled } = useFlags()
  const deleteTaskModalProps = useModalProps("Delete Task")
  const noteModalProps = useModalProps("Add Note")
  const [, updateOneTask] = useTaskEditMutation()
  const markTaskCompleteWorkflow = useReassignAndCloseTaskWorkflow()

  const dispatch = useContext(TaskListDispatchContext)
  const { data: progressData } = useGetTaskProgressSummary(task.id)

  const [{ data }] = useQuery({
    query: TaskScheduleDetails,
    variables: { id: task.id },
    pause: !task?.id,
    requestPolicy: "cache-and-network",
  })

  const schedule = data?.task?.schedule
  const scheduledBreaks = data?.task?.scheduledBreaks

  const { duration, workDaysCount } = useScheduleCalculations(
    task.startDate,
    task.endDate,
    schedule?.workDays ?? defaultWorkDays,
    schedule?.nonWorkDays
  )

  const [noteToEdit, setNoteToEdit] = useState<MetadataNote | undefined>(undefined)

  const handleSubmit = async (values: FormikValues) => {
    const newMetadata: MetadataNote[] = [{ label: values.label, content: values.content }]

    await updateOneTask({
      id: task?.id,
      name: task?.name,
      projectId: task?.projectId,
      metadataToRemove: noteToEdit ? [noteToEdit] : [],
      metadataToAdd: newMetadata,
    })

    successSnack("Note added successfully")
  }

  const onEditNote = (note: MetadataNote | MetadataNoteInput) => {
    setNoteToEdit(note)
    noteModalProps.handleOpenModal()
  }

  const onDeleteNote = async (note: MetadataNote) => {
    await updateOneTask({
      id: task?.id,
      name: task?.name,
      projectId: task?.projectId,
      metadataToRemove: [note],
    })
    successSnack("Note deleted successfully")
  }

  const openTaskEditDrawer = () =>
    pushDrawer(
      <div>
        <DrawerBody>
          <CreateOrEditTaskForm task={task} taskType={task.group?.id ? "sub-task" : "task"} />
        </DrawerBody>
      </div>,
      "TaskEdit"
    )

  const taskListItem = {
    ...task,
    isComplete: Boolean(task.isComplete),
    taskId: task.id,
  }

  const orgScheduleId = data?.task?.orgScheduleId
  const projectScheduleId = data?.task?.projectScheduleId
  const isInheritedSchedule =
    schedule?.id === orgScheduleId ||
    schedule?.id === projectScheduleId ||
    (!schedule?.id && !orgScheduleId && !projectScheduleId)

  const scheduleChipLabel = isInheritedSchedule ? "Inherits Project Schedule" : "Custom Schedule"
  const chipColor = isInheritedSchedule ? "warning" : "primary"
  const scheduleActionText = isInheritedSchedule ? "Customize Task Schedule" : "Edit Task Schedule"

  return (
    <>
      <PageTitle title={`${task?.name || "Task"} details`} />
      <div className="flex flex-col gap-12">
        {task?.id && (
          <>
            <section>
              <SectionHeader
                title="Quick Actions"
                actionText="Edit Task"
                permissions="task:update"
                disabled={task.isDefault}
                onClick={openTaskEditDrawer}
              />
              <TaskQuickActions task={task} />
            </section>

            <section>
              <SectionHeader title="Task Progress" />
              <TaskProgressBar drawer task={task} />
            </section>
          </>
        )}
        <section>
          <QuickMenuSectionTitle
            isEditing={false}
            title="Task Notes"
            permission="task:update"
            modalProps={{
              ...noteModalProps,
              handleCloseModal: () => {
                setNoteToEdit(undefined)
                noteModalProps.handleCloseModal()
              },
            }}
          />
          {(task?.metadata || [])?.length > 0 && (
            <Masonry columns={{ xs: 2, sm: 3, md: 3, lg: 4 }} spacing={2} defaultHeight={450} defaultColumns={4}>
              {(task.metadata || []).map((note: MetadataNote, i) => (
                <NoteCard key={`${note.label}-${i}`} note={note} onEdit={onEditNote} onDelete={onDeleteNote} />
              ))}
            </Masonry>
          )}

          {(task.metadata || []).length === 0 && <EmptyStateBlock label="No notes" />}
        </section>

        <section>
          <SectionHeader title="Basic Info" />

          <div className="flex flex-col gap-4">
            <LabeledSection label="Task Name">{task.name}</LabeledSection>
            <LabeledSection label="Start & End Date">
              {task.startDate && task.endDate ? (
                `${format(new Date(task.startDate), "P")} - ${format(new Date(task.endDate), "P")}`
              ) : (
                <span className="text-gray-500">None</span>
              )}
            </LabeledSection>

            {task.group?.id && (
              <LabeledSection label="Parent Task">
                <ValueOrNoneLabel value={task.group?.name} />
              </LabeledSection>
            )}
            <LabeledSection label="Description" className="col-span-2 whitespace-pre-line">
              <RichContentDisplay content={task.description ?? ""} />
            </LabeledSection>
          </div>
        </section>

        <>
          {flagIsEnabled("Task Dependencies") && (
            <section>
              <SectionHeader title="Dependencies" permissions="task:update" disabled={task.isDefault} />
              <TaskDependencyDetails taskId={task?.id} />
            </section>
          )}
          <section>
            <SectionHeader title="Task Schedule" permissions="task:update" />
            <ProjectScheduleLabels
              startDate={task.startDate}
              endDate={task.endDate}
              estimatedHours={task.estimatedHours}
              duration={duration}
              workDays={workDaysCount}
            />
          </section>
          <section>
            <SectionHeader
              title="Work Schedule"
              chipColor={chipColor}
              chipLabel={scheduleChipLabel}
              permissions="task:update"
              disabled={task.isDefault}
              actionText={scheduleActionText}
              onClick={() =>
                pushDrawer(
                  <div>
                    <DrawerBody>
                      <CreateOrEditTaskForm task={task} />
                    </DrawerBody>
                  </div>,
                  "TaskEdit"
                )
              }
            />
            <ScheduleDetails
              schedule={schedule ?? undefined}
              scheduledBreaks={scheduledBreaks || []}
              startDate={task.startDate}
              endDate={task.endDate}
              estimatedHours={task.estimatedHours}
            />
          </section>
        </>

        {!task.isDefault && (
          <>
            <DeliverableUnitSection
              isPrimary={true}
              onSuccess={onSuccess}
              task={task}
              taskEstimatedHours={task.estimatedHours || 0}
              title="Reporting Units"
            />

            <DeliverableUnitSection
              isPrimary={false}
              onSuccess={onSuccess}
              task={task}
              taskEstimatedHours={task.estimatedHours || 0}
              title="Additional Units"
            />
          </>
        )}
        <TaskExtraAssignmentTable taskId={task.id} projectId={task.projectId} />
        {noteModalProps.isOpen && (
          <AddNoteModal
            modalProps={noteModalProps}
            parentName={task?.name || "Project"}
            note={noteToEdit}
            onSave={async (values: FormikValues) => {
              await handleSubmit(values)
            }}
          />
        )}
        {!task.isDefault && (
          <section className="mb-24">
            <SectionHeader title="Task Options" />
            <RenderIf permissionsInclude="task:complete">
              <div className="flex justify-between my-4">
                <LabeledSection label="Complete Task">
                  {progressData?.targetUnits > 0 &&
                    `${Math.round((progressData.completedUnits / progressData.targetUnits) * 100)}% unit progress`}
                </LabeledSection>
                <Button
                  variant="contained"
                  color="black"
                  onClick={() => {
                    markTaskCompleteWorkflow({
                      taskListItem: { ...taskListItem, project: task.project },
                      onSuccessCallback: () => {
                        if (typeof dispatch === "function") {
                          dispatch({
                            type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                            payload: { taskId: task.id, isComplete: true },
                          })
                        }
                        successSnack("Success!")
                      },
                      onErrorCallback: () => {
                        errorSnack(`${task.name} completion error; please try again`)
                      },
                    })
                  }}
                >
                  Complete
                </Button>
              </div>
            </RenderIf>
            <div className="flex justify-between my-4">
              <LabeledSection label="Delete Task">
                <Typography>This will permanently delete the task</Typography>
              </LabeledSection>
              <Button variant="contained" color="error" onClick={() => deleteTaskModalProps.handleOpenModal()}>
                Delete
              </Button>
            </div>
            <DeleteTaskModal
              modalProps={deleteTaskModalProps}
              closeModal={deleteTaskModalProps.handleCloseModal}
              task={task}
              isTaskGroup={false}
              onSuccess={handleClose}
            />
          </section>
        )}
      </div>
    </>
  )
}
