import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { TailwindIcon } from "../../../types/tailwind"
import { ConditionalLink } from "../../ConditionalLink"
import { Tooltip } from "@mui/material"

type Props = {
  Icon: TailwindIcon
  description?: string
  extras?: ReactNode
  extrasClassName?: string
  href?: string
  iconClassNames?: string
  value: string
  valueElement?: ReactNode
}

export const SummaryWidget: FC<Props> = ({
  Icon,
  description,
  extras,
  extrasClassName,
  href,
  iconClassNames,
  value,
  valueElement,
}) => {
  return (
    <div className="border border-gray-200 rounded-lg p-6 flex flex-col justify-between gap-2 w-56 shrink-0 lg:w-auto">
      <div className="flex flex-row justify-between items-center">
        <ConditionalLink className="flex grow-0 items-center gap-x-2 font-semibold text-xl w-2/3" href={href}>
          {!valueElement ? (
            <Tooltip title={value}>
              <div className="text-blue-600 text-ellipsis overflow-hidden">{value}</div>
            </Tooltip>
          ) : (
            valueElement
          )}
          <div className="text-gray-400">{description}</div>
        </ConditionalLink>
        <Icon className={clsx("size-8 rounded-lg p-[0.4rem] text-gray-800 bg-gray-100", iconClassNames)} />
      </div>
      {extras && <div className={clsx("text-sm text-gray-400", extrasClassName)}>{extras}</div>}
    </div>
  )
}
