import { TypedDocumentNode } from "urql"
import {
  Exact,
  ProjectUnitsTableProgressQuery,
  TaskUnitsTableProgressQuery,
} from "../../../../graphql/generated/client-types-and-hooks"

export function getTaskOrProjectQueryDocument(
  entityType: "project" | "task",
  taskDocument: TypedDocumentNode<
    TaskUnitsTableProgressQuery,
    Exact<{
      rangeStart: Date
      rangeEnd: Date
      entityId: string
    }>
  >,
  projectDocument: TypedDocumentNode<
    ProjectUnitsTableProgressQuery,
    Exact<{
      rangeStart: Date
      rangeEnd: Date
      entityId: string
    }>
  >
) {
  return entityType === "project" ? projectDocument : taskDocument
}
