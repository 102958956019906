import { format } from "date-fns"
import { ActionTypeEnum, User } from "../../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../../types/helpers"
import { Changeset } from "./TimeEntryTable"

export function timeEntryChangesetToString(changeset: Changeset, user: PickPlus<User, "id">): string {
  const firstName = changeset.actor?.firstName ?? ""
  const lastName = changeset.actor?.lastName ?? ""
  const name = `${firstName} ${lastName}`.trim() || "Unknown actor"
  const actionTaken = action(changeset, user)
  const phrase = `${name} ${actionTaken}`
  return phrase
}

function action(changeset: Changeset, user: PickPlus<User, "id">): string {
  const isSelfAction = user.id === changeset.actor?.id
  let verbPhrase: string

  switch (changeset.action) {
    case ActionTypeEnum.ClockIn:
      verbPhrase = isSelfAction ? "clocked themselves in" : "clocked this user in"
      break
    case ActionTypeEnum.ClockOut:
      verbPhrase = isSelfAction ? "clocked themselves out" : "clocked this user out"
      break
    case ActionTypeEnum.ManualCreate:
      verbPhrase = isSelfAction ? "created their own time" : "created time"
      break
    case ActionTypeEnum.ManualEdit:
      verbPhrase = editActionDescription(changeset, isSelfAction)
      break
    case ActionTypeEnum.ManualDelete:
      verbPhrase = isSelfAction ? "deleted their own time" : "deleted time"
      break
    default:
      verbPhrase = "performed an action"
      break
  }

  return verbPhrase
}

function editActionDescription(changeset: Changeset, isSelfAction: boolean): string {
  const changeDescriptions: string[] = []
  const dateFormat = "h:mm a (dd/MM)"

  if (changeset.changes?.startAt) {
    changeDescriptions.push(
      `start time from ${format(changeset.changes.startAt, dateFormat)} to ${format(changeset.value!.startAt, dateFormat)}`
    )
  }

  if (changeset.changes?.endAt) {
    changeDescriptions.push(
      `end time from ${format(changeset.changes.endAt, dateFormat)} to ${format(changeset.value!.endAt!, dateFormat)}`
    )
  }

  const changesDescription = changeDescriptions.join(" and ")

  return isSelfAction ? `edited their own time (${changesDescription})` : `edited time (${changesDescription})`
}
