import { clsx } from "clsx"
import { ChangeEvent, FC } from "react"

type Props = {
  checked: boolean
  className?: string
  disabled?: boolean
  testLabel?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<Props> = ({ checked, onChange, className, disabled = false, testLabel }) => {
  return (
    <input
      checked={checked}
      className={clsx(
        "rounded-sm size-4 border-2 border-gray-400 cursor-pointer",
        "focus:outline-0 focus:outline-none focus:ring-offset-0 focus:ring-0 focus:border-gray-700",
        disabled && "opacity-50",
        className
      )}
      disabled={disabled}
      test-label={testLabel}
      onChange={onChange}
      type="checkbox"
    />
  )
}
export default Checkbox
