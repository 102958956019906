import { LinearProgress } from "@mui/material"
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { useStore } from "@tanstack/react-store"
import { FC, MutableRefObject, useCallback, useContext, useMemo } from "react"
import { AssetAssignableType, AssetGroup } from "../../../../graphql/generated/client-types-and-hooks"
import { Asset } from "../../../../graphql/generated/gql/graphql"
import { useWindowSize } from "../../../../hooks/useWindowSize"
import { assetListStore, assetListStoreActions } from "../../../../stores/assetList"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetStatusPill } from "../AssetStatusPill"
import { AssetBadgeCell } from "./AssetBadgeCell"
import { AssetDetailsPanel } from "./AssetDetailsPanel"
import { AssetQuickActionsCell } from "./AssetQuickActionsCell"
import { AssigneeCell } from "./AssigneeCell"
import { ProjectCell } from "./ProjectCell"
import { QuantityCell } from "./QuantityCell"
import { QuantizedAssetDataGrid } from "./Quantized/QuantizedAssetDataGrid"
import { TaskCell } from "./TaskCell"

const defaultColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  editable: false,
  filterable: false,
  sortable: false,
}

export const showAssetGroups = (asset: Asset | AssetGroup, useAssetGroupFeatures = false) =>
  useAssetGroupFeatures && asset.hasOwnProperty("groupParent")

export const getAssetListColumns = (
  apiRef: MutableRefObject<GridApiPro>,
  indentLevel = 0,
  isMobile: boolean = false
) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: indentLevel > 0 ? 1.2 : 1, // adjust cell alignment for nested assets
      minWidth: 150,
      renderCell: ({ row: asset }: GridCellParams) => (
        <AssetBadgeCell
          asset={asset as Asset}
          depth={indentLevel}
          addSubIcon={asset.assignableType === AssetAssignableType.Asset}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Qty.",
      flex: 0.5,
      minWidth: 75,
      renderCell: ({ row: asset }: GridCellParams) => <QuantityCell asset={asset as Asset} />,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams) => <AssigneeCell asset={asset} />,
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams) => <ProjectCell asset={asset} />,
    },
    {
      field: "task",
      headerName: "Task",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams<Asset>) => <TaskCell asset={asset} />,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.55,
      renderCell: ({ row: asset }: GridCellParams) => <AssetStatusPill asset={asset} />,
    },
    {
      field: "menu",
      headerName: "",
      type: "actions",
      flex: 0.5,
      align: "right",
      renderCell: ({ row: asset }: GridCellParams<Asset>) => <AssetQuickActionsCell asset={asset} apiRef={apiRef} />,
    },
  ].map((column) => ({ ...defaultColumnProps, ...column }) as GridColDef)

  return isMobile ? columns.filter((column) => column.field === "name" || column.field === "menu") : columns
}

export const AssetDataGrid: FC<{
  assignedAsset?: boolean
  loadingAssets: boolean
  projectId?: string
  taskId?: string
  totalCount?: number
  NoRowsOverlay?: React.ReactNode
}> = ({ loadingAssets, projectId, taskId, totalCount = 0, NoRowsOverlay }) => {
  const apiRef = useGridApiRef()
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])

  const getDetailPanelContent = useCallback(
    ({ row: asset }: GridRowParams) =>
      asset.isAssetGroup ? (
        <QuantizedAssetDataGrid assetId={asset.id} embeddedList />
      ) : (
        <AssetDetailsPanel assetId={asset.id} showTaskColumn={!!projectId} indentLevel={1} />
      ),
    [projectId]
  )
  const getDetailPanelHeight = useCallback(() => "auto", [])
  const columns: GridColDef[] = useMemo(() => getAssetListColumns(apiRef, 0, isMobile), [apiRef, isMobile])
  const isViewingInTab = !!projectId || !!taskId

  const assets = useStore(assetListStore, (state) => state.assets)
  const selectedRows = useStore(assetListStore, (state) => state.selectedRows)

  return (
    <>
      <div
        className="w-full h-[80vh]"
        style={{
          // 120px is about the height of the necessary elements above the grid
          height: isViewingInTab ? `80vh` : `calc(100vh - 120px")`,
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection={hasPermissionTo(["asset:transfer", "asset:update"])}
          columns={columns}
          disableRowSelectionOnClick
          density="comfortable"
          filterMode="server"
          initialState={{
            columns: {
              columnVisibilityModel: {
                [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
                project: !projectId,
                task: !!projectId,
              },
            },
          }}
          isRowSelectable={(params) =>
            params.row.assignableType !== AssetAssignableType.Asset && !params.row.isAssetGroup
          }
          loading={loadingAssets}
          sortingMode="server"
          paginationMode="server"
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          keepNonExistentRowsSelected
          pageSizeOptions={[50]}
          onSortModelChange={([model]) => assetListStoreActions.setSort(model)}
          rows={assets || []}
          getRowId={(row) => row.compositeKey}
          rowCount={totalCount}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={assetListStoreActions.setSelectedRows}
          onRowsScrollEnd={() => assetListStoreActions.fetchNextPage()}
          scrollEndThreshold={200}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => NoRowsOverlay,
          }}
        />
      </div>
    </>
  )
}
