import { clsx } from "clsx"
import { FC, ReactNode } from "react"
import { TableSortingState } from "../../hooks/useTableSorting"
import { SortChevron } from "../SortChevron"

type Props = {
  children: ReactNode
  className: string
  sortKey?: string
  tableSortingState?: TableSortingState<Record<string, unknown>>
  isSortable?: boolean
  sortByKey?: (sortKey: string) => void
}

export const ColumnTitle: FC<Props> = ({
  className,
  sortKey = "",
  tableSortingState,
  isSortable = true,
  children,
  sortByKey,
}) => {
  return (
    <div
      className={clsx(
        "flex font-medium select-none",
        !isSortable && "hidden md:block",
        isSortable && "cursor-pointer",
        className
      )}
      onClick={() => {
        if (isSortable && sortByKey) {
          sortByKey(sortKey)
        }
      }}
    >
      {children}
      {isSortable && (
        <SortChevron
          isAscending={tableSortingState?.sortKeys[sortKey] || false}
          isActive={tableSortingState?.currentSortKey === sortKey}
        />
      )}
    </div>
  )
}
