import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material"
import { Form, Formik, FormikHelpers, FormikValues, useField } from "formik"
import { FC, useContext, useEffect, useMemo } from "react"
import { useQuery } from "urql"
import { Asset, Task, useTransferAssetsMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import { SelectableAsset } from "../../../../types/Asset"
import { SelectableUser } from "../../../../types/User"
import { PickPlus } from "../../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../../deprecated"
import { AssetsMultiSelect } from "../../../Formik/MultiSelect/implementations/AssetsMultiSelect"
import { ProjectAndTasksMultiSelects } from "../../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { TeamMembersMultiSelect } from "../../../Formik/MultiSelect/implementations/TeamMembersMultiSelect"
import { UserSelect } from "../../../Formik/UserSelect"
import { ModalBody } from "../Elements/ModalBody"
import { ModalFooter } from "../Elements/ModalFooter"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { ModalLoadingContext } from "../../../deprecated/StandardModal"
import { validationSchema } from "./transferAssetValidation"
import { ASSET_CIRCULAR_REFERENCE } from "../../../../graphql/errors"

const GetProjectTasksDocument = graphql(`
  query TransferAssetModalGetProjectTasks($projectId: String!) {
    project(id: $projectId) {
      id
      tasks {
        id
        name
      }
    }

    assets(projectId: $projectId, includeGroupedAssets: false) {
      id
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUserId
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }

      active
      assetChildCount
      companyAssetNumber
      compositeKey
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }

    assetGroups(projectId: $projectId) {
      assetGroupId
      assignableId
      assignableType
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      compositeKey
      count
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
      status
    }
  }
`)

interface FormValues {
  assignment: {
    assignmentType: string
    selectedAssetId: string
    selectedProjectId: string[] | string
    selectedTaskId: string[] | string
    selectedUserIds: string[] | string
  }
  assets: string[]
}

const getDefaultAssignmentType = (
  selectedUserIds?: string[],
  selectedAssetIds?: string[],
  task?: PickPlus<Task, "id" | "name"> | null,
  excludedTaskIds?: string[],
  excludedProjectId?: string,
  excludedUserId?: string
) => {
  if (excludedUserId || (selectedUserIds && selectedUserIds.length)) return "User"
  if (selectedAssetIds && selectedAssetIds.length) return "Asset"
  if (excludedProjectId || (excludedTaskIds && excludedTaskIds.length > 0) || (task && task.id)) return "Task"

  return "Task"
}

export const TransferAssetModal: FC<{
  assets: SelectableAsset[]
  formModalProps: ModalProps
  selectedUserIds?: string[]
  selectedAssetIds?: string[]
  assetsSelectedToTransfer?: string[]
  projectId?: string
  task?: PickPlus<Task, "id" | "name"> | null
  excludedProjectId?: string
  excludedTaskIds?: string[]
  excludedUserId?: string
  excludedAssetId?: string
  onSuccess?: () => void
  teamMembers: SelectableUser[]
  includeAllProjects?: boolean
  selectedUser?: SelectableUser | undefined
}> = ({
  assets,
  formModalProps,
  assetsSelectedToTransfer = [],
  selectedUserIds,
  selectedAssetIds,
  excludedTaskIds = [],
  excludedProjectId,
  excludedUserId,
  excludedAssetId,
  onSuccess = () => {},
  teamMembers,
  projectId,
  task,
  includeAllProjects = false,
  selectedUser,
}) => {
  const [, transferAssetMutation] = useTransferAssetsMutation()
  const { isLoading, setIsLoading } = useContext(ModalLoadingContext)

  const handleSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      setIsLoading(true)

      let selectedAssignable
      let selectedProjectId

      const { assignment } = values
      const { assignmentType } = assignment
      switch (assignmentType) {
        case "User":
          if (Array.isArray(assignment.selectedUserIds)) {
            ;[selectedAssignable] = assignment.selectedUserIds
          } else {
            selectedAssignable = assignment.selectedUserIds
          }
          break
        case "Asset":
          selectedAssignable = assignment.selectedAssetId
          break
        case "Task":
          if (Array.isArray(assignment.selectedTaskId)) {
            ;[selectedAssignable] = assignment.selectedTaskId
            ;[selectedProjectId] = assignment.selectedProjectId
          } else {
            selectedAssignable = assignment.selectedTaskId
            selectedProjectId = Array.isArray(assignment.selectedProjectId)
              ? assignment.selectedProjectId[0]
              : assignment.selectedProjectId || projectId
          }
          break
        default:
          throw new Error("Invalid assignment type", assignmentType)
      }

      const result = await transferAssetMutation({
        assetIds: values.assets,
        assignableId: selectedAssignable,
        assignableType: assignmentType,
        projectIdIfTask: selectedProjectId,
      })

      if (result.error) {
        console.error(result.error)
        
        const graphqlErrors = result.error.graphQLErrors
        if ((graphqlErrors.filter((e) => e.message.startsWith(ASSET_CIRCULAR_REFERENCE))).length > 0) {
          errorSnack("Invalid asset assignment. An asset cannot be a parent or child of itself.")
        } else {
          errorSnack("An error was encountered while reassigning assets. Please try again.")
        }

      } else {
        successSnack("Assets Successfully Reassigned")
        if (onSuccess) onSuccess()
        formModalProps.handleCloseModal()
      }
    } catch (error) {
      console.error(error)
      errorSnack("An error was encountered while reassigning assets. Please try again.")
    } finally {
      setIsLoading(false)
      setSubmitting(false)
    }
  }

  const defaultAssignmentType = getDefaultAssignmentType(
    selectedUserIds,
    selectedAssetIds,
    task,
    excludedTaskIds,
    excludedProjectId,
    excludedUserId
  )

  const defaultProjectId = projectId ?? ""
  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        assignment: {
          assignmentType: defaultAssignmentType,
          selectedAssetId: selectedAssetIds && selectedAssetIds.length > 0 ? selectedAssetIds[0] : "",
          selectedProjectId: excludedProjectId !== projectId ? defaultProjectId : "",
          selectedTaskId: task?.id ?? "",
          selectedUserIds: selectedUserIds ?? [],
        },
        assets: assetsSelectedToTransfer,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <FormContents
          assets={assets}
          handleClose={formModalProps.handleCloseModal}
          isLoading={isLoading || isSubmitting}
          teamMembers={teamMembers}
          projectId={projectId}
          task={task}
          defaultAssignmentType={defaultAssignmentType}
          excludedTaskIds={excludedTaskIds}
          excludedProjectId={excludedProjectId}
          excludedUserId={excludedUserId}
          excludedAssetId={excludedAssetId}
          includeAllProjects={includeAllProjects}
          selectedUser={selectedUser}
        />
      )}
    </Formik>
  )
}

type FormContentsProps = {
  assets: Pick<Asset, "id" | "assignableId" | "companyAssetNumber" | "imageUrl" | "name">[]
  projectId?: string
  task?: PickPlus<Task, "id" | "name"> | null
  defaultAssignmentType: string
  excludedTaskIds: string[]
  excludedProjectId?: string
  excludedUserId?: string
  handleClose: () => void
  isLoading: boolean
  teamMembers: SelectableUser[]
  includeAllProjects?: boolean
  excludedAssetId?: string
  selectedUser?: SelectableUser | undefined
}

const TaskTypeRadioControls: FC<{
  defaultAssignmentType: string
  teamMemberCount: number
  includeAllProjects: boolean
  availableAssetCount: number
}> = ({ defaultAssignmentType, teamMemberCount, includeAllProjects, availableAssetCount }) => {
  const [assignmentType, , typeHelper] = useField<string>("assignment.assignmentType")

  const fields = [
    {
      label: "User",
      value: "User",
      weight: defaultAssignmentType === "User" ? 1 : 2,
      showOption: teamMemberCount > 0 || includeAllProjects,
    },
    {
      label: "Task",
      value: "Task",
      weight: defaultAssignmentType === "Task" ? 1 : 2,
    },
    {
      label: "Asset",
      value: "Asset",
      weight: defaultAssignmentType === "Asset" ? 1 : 3,
      showOption: availableAssetCount > 0 || includeAllProjects,
    },
  ].sort((a, b) => {
    if (a.weight === b.weight) {
      return a.label.localeCompare(b.label)
    }
    return a.weight - b.weight
  })

  return (
    <RadioGroup
      name="assignment.assignmentType"
      row
      className="py-5"
      value={assignmentType.value}
      onChange={(e) => typeHelper.setValue(e?.target?.value)}
    >
      {fields.map((field) => (
        <FormControlLabel key={field.value} value={field.value} control={<Radio />} label={field.label} />
      ))}
    </RadioGroup>
  )
}

const FormContents: FC<FormContentsProps> = ({
  assets,
  isLoading,
  handleClose,
  teamMembers,
  defaultAssignmentType,
  projectId = "",
  task,
  excludedTaskIds,
  excludedUserId,
  excludedProjectId,
  excludedAssetId,
  includeAllProjects = false,
  selectedUser,
}) => {
  const [assetsField] = useField<string[]>("assets")
  const [tasksField, , taskHelpers] = useField<string>("assignment.selectedTaskId")
  const [assetAssignmentField, assetAssignmentMeta, assignmentHelpers] = useField<string>("assignment.selectedAssetId")

  const [{ data: componentQuery }] = useQuery({
    query: GetProjectTasksDocument,
    variables: { projectId },
    pause: !projectId,
  })

  useEffect(() => {
    if (!projectId) {
      taskHelpers.setValue(task?.id || "")
    }
  }, [projectId, task, taskHelpers])

  const availableAssets = useMemo(() => {
    const _assets = assets ?? componentQuery?.assets ?? []
    const selectedAssets = assetsField.value

    // Is a parent asset already selected? And is that parent asset in the list of selected children?
    if (assetAssignmentField?.value.length > 0 && selectedAssets.includes(assetAssignmentField.value)){
        // Clear the selected parent. We don't want to assign an asset to itself
        assignmentHelpers.setValue('')
    }

    return selectedAssets && selectedAssets.length > 0
      ? _assets.filter((asset) => !selectedAssets.includes(asset.id))
      : _assets

  }, [assets, componentQuery?.assets, assetsField.value, assetAssignmentField.value, assignmentHelpers])
  const tasks = componentQuery?.project?.tasks ?? []
  const [assignmentType] = useField<string>("assignment.assignmentType")

  return (
    <Form className="h-full flex flex-col">
      <ModalBody className="min-h-[250px]">
        <AssetsMultiSelect assets={assets} name="assets" />

        <TaskTypeRadioControls
          defaultAssignmentType={defaultAssignmentType}
          teamMemberCount={teamMembers.length}
          includeAllProjects={includeAllProjects}
          availableAssetCount={availableAssets.length}
        />

        {assignmentType.value === "Task" && (
          <div className="col-span-12">
            {includeAllProjects ? (
              <ProjectAndTasksMultiSelects
                formGroupId="assignment"
                excludeTaskIds={excludedTaskIds}
                excludedProjectId={excludedProjectId}
                includeCurrentAssignment
                withErrorHandling
              />
            ) : (
              <FormControl fullWidth>
                <TextField
                  select
                  label="Tasks"
                  name="assignment.selectedTaskId"
                  fullWidth
                  size="small"
                  value={tasksField.value || ""}
                  onChange={(e) => {
                    taskHelpers.setValue(e.target.value)
                  }}
                >
                  {getTaskOptions(projectId ? tasks : task ? [task] : [])}
                </TextField>
              </FormControl>
            )}
          </div>
        )}
        {assignmentType.value === "User" &&
          (includeAllProjects ? (
            <UserSelect
              userExclusionFilter={(user) => {
                if (excludedUserId === user.id) return false
                if (excludedTaskIds[0] === user.currentTaskId) return false
                if (excludedProjectId === user.currentProjectId) return false

                return true
              }}
              name="assignment.selectedUserIds"
              label="Team Member"
              preselected={selectedUser ? [selectedUser] : []}
            />
          ) : (
            <TeamMembersMultiSelect
              excludedUserId={excludedUserId}
              teamMembers={teamMembers}
              name="assignment.selectedUserIds"
              isSingleSelect={true}
            />
          ))}
        {assignmentType.value === "Asset" && (
          <FormControl fullWidth>
            <TextField
              select
              label="Asset"
              name="assignment.selectedAssetId"
              helperText={
                assetAssignmentMeta.touched && assetAssignmentMeta.error ? assetAssignmentMeta.error : <>&nbsp;</>
              }
              error={assetAssignmentMeta.touched && !!assetAssignmentMeta.error}
              fullWidth
              size="small"
              value={assetAssignmentField.value || ""}
              onChange={(e) => {
                assignmentHelpers.setValue(e.target.value)
              }}
            >
              {availableAssets?.length ? (
                availableAssets.map((asset) => (
                  <MenuItem key={asset.id} value={asset?.id} disabled={excludedAssetId === asset?.id}>
                    {asset?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="" key="0">
                  <em>No assets available to select</em>
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type="button" onClick={handleClose}>
          Cancel
        </ButtonHollow>
        <ButtonFilled
          type="submit"
          disabled={isLoading || !assetsField.value.length}
          onClick={(e) => e.stopPropagation()}
        >
          Transfer
        </ButtonFilled>
      </ModalFooter>
    </Form>
  )
}

function getTaskOptions<T extends PickPlus<Task, "id" | "name">>(tasks: T[]) {
  if (!tasks || !tasks.length) {
    return (
      <MenuItem disabled value="">
        <em>No tasks available to select</em>
      </MenuItem>
    )
  }

  return tasks.map((task: T) => (
    <MenuItem key={task?.id} value={task?.id} selected={tasks.length === 1}>
      {task.name}
    </MenuItem>
  ))
}
