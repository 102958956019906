import { FC, useCallback, useContext, useState } from "react"
import { User, useClockInMutation, useClockOutUserMutation } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { OrganizationSettingsContext } from "../../providers/OrganizationSettingsProvider"
import { Button } from "@mui/material"
import { infoSnack } from "../Notistack/ThemedSnackbars"
import { toastClockInError } from "../../helpers/clockInOut/toastClockInError"
import { BasicModal } from "../Modals/components/Elements/BasicModal"
import { useModalProps } from "../Modals/hooks/useModalProps"
import ClockOutWorkflow from "./workflows/ClockOutWorkflow"
import { GeolocationProvider } from "../../providers/GeolocationProvider"
import ClockInWorkflow from "./workflows/ClockInWorkflow"

type Props = {
  user: PickPlus<
    User,
    "id" | "firstName" | "lastName" | "taskId" | "projectId" | "currentTaskId" | "archived" | "isClockedIn"
  > & {
    currentProject: PickPlus<User["currentProject"], "id" | "name">
    currentTask: PickPlus<User["currentTask"], "id" | "name">
  }
  onSuccess?: () => void
  disabled?: boolean
}

export const ClockInOut: FC<Props> = ({ user, onSuccess, disabled = false }) => {
  const { requireTimeEntryEvidence, requireInjuryReportOnClockout } = useContext(OrganizationSettingsContext)
  const [isLoading, setIsLoading] = useState(false)
  const [_, clockInMutation] = useClockInMutation()
  const [__, clockOutMutation] = useClockOutUserMutation()

  const clockInModalProps = useModalProps("Clock In")
  const clockOutModalProps = useModalProps("Clock Out")

  const handleClockIn = useCallback(async () => {
    try {
      setIsLoading(true)
      // if extra steps are required use modal
      if (requireTimeEntryEvidence) return clockInModalProps.handleOpenModal()
      // Otherwise just clock them in
      const result = await clockInMutation({ taskId: user.currentTaskId, userId: user.id })
      if (result.error) return toastClockInError(result.error, user)

      if (result.data?.clockIn?.user.isClockedIn) onSuccess?.()
    } catch (error) {
      toastClockInError(error as Error, user)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, requireTimeEntryEvidence])

  const handleClockOut = useCallback(async () => {
    try {
      setIsLoading(true)
      // if extra steps are required use modal
      if (requireTimeEntryEvidence || requireInjuryReportOnClockout) return clockOutModalProps.handleOpenModal()
      // Otherwise just clock them out
      const result = await clockOutMutation({ userId: user.id })
      if (result.error) return toastClockInError(result.error, user)

      if (!result.data?.clockOutUser?.user.isClockedIn) onSuccess?.()
    } catch (error) {
      toastClockInError(error as Error, user)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, requireTimeEntryEvidence, requireInjuryReportOnClockout])

  // NEW CLOCK IN / OUT

  return (
    <GeolocationProvider>
      <Button
        className="px-6 whitespace-nowrap"
        variant="contained"
        color={user.isClockedIn ? "lightPrimary" : "primary"}
        disabled={user.archived || isLoading || disabled}
        onClick={(e) => {
          e.stopPropagation()
          if (user.archived) return infoSnack("Cannot perform this action on an archived user.")
          if (user.isClockedIn) return handleClockOut()
          else return handleClockIn()
        }}
      >
        <span>Clock {user.isClockedIn ? "out" : "in"}</span>
      </Button>

      <BasicModal modalProps={clockInModalProps}>
        <ClockInWorkflow user={user} onSuccess={clockInModalProps.handleCloseModal} />
      </BasicModal>
      <BasicModal modalProps={clockOutModalProps}>
        <ClockOutWorkflow user={user} onSuccess={clockOutModalProps.handleCloseModal} />
      </BasicModal>
    </GeolocationProvider>
  )
}
