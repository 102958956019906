import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { NotesSkeleton, TransferSkeleton } from "./AssetHistoryDropdownSkeleton"
import { ImagesGrid } from "./History/ImagesGrid"
import { InspectionDetails } from "./History/InspectionDetails"
import { Notes } from "./History/Notes"
import { RepairNotes } from "./History/RepairNotes"
import { TransferDetails } from "./History/TransferDetails"
import { RepairDetails } from "./History/RepairDetails"

const AssetReportDocument = graphql(`
  query AssetHistoryReport($reportId: String!) {
    assetReport(reportId: $reportId) {
      id
      createdAt
      type
      inspectionReport {
        id
        fileIds
        input
        label
        photoUrls
        photoRequired
        photoLabel
        type
      }
      inventoryReport {
        fileIds
        note
        photoUrls
      }
      notes
      statusChange {
        status
      }
      transferReport {
        from {
          assignableId
          assignableType
          task {
            id
            name
            project {
              id
              name
            }
          }
          user {
            id
            firstName
            imageUrl
            jobTitle
            lastName
            isClockedIn
          }
          asset {
            id
          }
        }
        to {
          assignableId
          assignableType
          task {
            id
            name
            project {
              id
              name
            }
          }
          user {
            id
            firstName
            imageUrl
            jobTitle
            lastName
            isClockedIn
          }
          asset {
            id
          }
        }
      }
      updatedAt
    }
  }
`)

export const AssetHistoryDropDown: FC<{
  assetReportId: string
}> = ({ assetReportId }) => {
  const [{ data, fetching }] = useQuery({
    query: AssetReportDocument,
    variables: { reportId: assetReportId },
  })

  const report = data?.assetReport

  if (fetching || !report) {
    return (
      <SkeletonContainer>
        <TransferSkeleton />
        <NotesSkeleton />
      </SkeletonContainer>
    )
  }

  return (
    <div className="m-4">
      <TransferDetails transferReport={report.transferReport} createdAt={report.createdAt} />
      {report?.type === "Repair" ? (
        <>
          <RepairNotes notes={report?.notes} />
          <RepairDetails inspection={report.inspectionReport} />
        </>
      ) : (
        <>
          <InspectionDetails inspection={report.inspectionReport} />
          <Notes notes={report?.notes} />
        </>
      )}
      <ImagesGrid inventoryReport={report?.inventoryReport} />
    </div>
  )
}
