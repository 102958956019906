import { useEffect, useState } from "react"

type UnitType = {
  description: string
}

export const useInitialVisibility = (
  units: UnitType[]
): [Record<string, boolean>, (unitDescription: string) => void] => {
  const [visibility, setVisibility] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (units.length) {
      const defaultVisibility = units.reduce(
        (acc, unit) => {
          if (acc[unit.description] === undefined) {
            acc[unit.description] = true
          }
          return acc
        },
        { ...visibility }
      )
      
      if (!compareVisibility(visibility, defaultVisibility)) {
        setVisibility(defaultVisibility)
      }
    }
  }, [units, visibility])

  const toggleVisibility = (unitDescription: string) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [unitDescription]: !prevVisibility[unitDescription],
    }))
  }

  return [visibility, toggleVisibility]
}

const compareVisibility = (visibility: Record<string, boolean>, defaultVisibility: Record<string, boolean>) => {
  const visibilityKeys = Object.keys(visibility)
  const defaultVisibilityKeys = Object.keys(defaultVisibility)
  if (visibilityKeys.length !== defaultVisibilityKeys.length) {
    return false
  }
  for (const key of visibilityKeys) {
    if (visibility[key] !== defaultVisibility[key]) {
      return false
    }
  }
  return true
}