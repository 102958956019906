import { Box, Button, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { colors } from "../../../helpers/styles/colors"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { PickPlus } from "../../../types/helpers"
import { AssetBadge } from "../../AssetBadge"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { TransferAssetsToAny } from "../../Modals/components/TransferAssetModal/TransferAssetsToAny"

type NestedAssetsProps = {
  childAssets: (PickPlus<
    Asset,
    | "id"
    | "active"
    | "assetChildCount"
    | "assetGroupId"
    | "assignableId"
    | "assignableType"
    | "companyAssetNumber"
    | "groupQuantity"
    | "imageUrl"
    | "isAssetGroup"
    | "name"
    | "ownershipType"
    | "status"
  > & { activeCount?: number; missingCount?: number; depth?: number })[]
  asset: PickPlus<Asset, "id" | "name">
}

export const NestedAssetsGrid: FC<NestedAssetsProps> = ({ asset, childAssets }) => {
  
  const assetModalProps = useModalProps("Transfer Assets")

  return (
    <div>
      <div className="flex justify-between">
        <Typography variant="h5">Nested Assets</Typography>
        <Button onClick={assetModalProps.handleOpenModal}>Nest Assets</Button>
      </div>
      <div className="relative">
        {childAssets.length === 0 && (
          <EmptyStateBlock label="No nested assets" />
        )}
        {childAssets.length > 0 && (
          <Grid container spacing={1}>
            {childAssets.map((childAsset) => (
              <Grid key={childAsset.id} item md={3} xs={12} alignItems="center">
                <Box borderRadius="8px" border="1px solid" borderColor={colors.gray[200]} padding="10px">
                  <AssetBadge asset={{ ...childAsset, assetChildCount: childAsset.assetChildCount }} />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      <TransferAssetsToAny formModalProps={assetModalProps} selectedAssetIds={[asset.id]} />
    </div>
  )
}
