import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { TransferAssetToUser } from "../../../components/Modals/components/TransferAssetModal/TransferAssetToUser"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { PageTitle } from "../../../components/PageTitle"
import { AssetListDataGrid } from "../../../components/Partials/Assets/DataGrid/AssetListDataGrid"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { RenderIf } from "../../../components/RenderIf"
import { getFullName } from "../../../helpers/strings/getFullName"
import UserPageLayout, { UserLayoutContext } from "./_layout"

type UserAssetsPageProps = {
  userId: string
}

export const UserAssetsPage: FC<UserAssetsPageProps> = ({ userId }) => {
  const { user } = useContext(UserLayoutContext)
  const assetModalProps = useModalProps("Assign Asset")

  return (
    <>
      <PageTitle title={!user?.firstName ? "User assets" : `${getFullName(user)} assets`} />
      {!userId && <UserSkeleton />}
      {userId && (
        <>
          <div className="flex flex-col gap-9">
            <AssetListDataGrid userId={user?.id} handleAssignAsset={assetModalProps.handleOpenModal} />
          </div>

          {assetModalProps.isOpen && (
            <TransferAssetToUser formModalProps={assetModalProps} selectedUserIds={[userId]} />
          )}
        </>
      )}
    </>
  )
}

const UserPage: NextPage = () => {
  const userId = useRouter().query._id as string

  return (
    <RenderIf permissionsInclude="user:read" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserAssetsPage userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

export default UserPage
