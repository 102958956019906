import { Autocomplete, Checkbox, TextField } from "@mui/material"
import { clsx } from "clsx"
import { useField } from "formik"
import { FC, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"
import { Project } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ProjectStatus } from "../../../../graphql/generated/gql/graphql"
import { sortByFieldAscending } from "../../../../helpers/sorts/sortByFieldAscending"
import { SelectableUser } from "../../../../types/User"
import UserWithTaskRow from "../Rows/UserWithTaskRow"

type Props = {
  disabled?: boolean
  isSingleSelect?: boolean
  name: string
  onChange?: (selectedValues: string[]) => void
  teamMembers: SelectableUser[]
  excludedUserId?: string
  withErrorHandling?: boolean
  label?: string
}

type ProjectWithUsers = {
  users: SelectableUser[]
  name: string
}

type ProjectsWithUsers = {
  [projectId: string]: ProjectWithUsers
}

const ProjectsQuery = graphql(`
  query TeamMembersMultiSelectListOrgProjects($status: ProjectStatus!) {
    projectsByStatus(status: $status) {
      id
      name
    }
  }
`)

export const TeamMembersMultiSelect: FC<Props> = ({
  disabled = false,
  name,
  isSingleSelect,
  onChange,
  teamMembers,
  withErrorHandling = true,
  label,
  excludedUserId,
}) => {
  const [{ data: projectsData }] = useQuery({
    query: ProjectsQuery,
    variables: { status: ProjectStatus.Active },
  })
  const [{ value }, { error, touched }, { setValue }] = useField(name)

  const [muiFieldValue, setMuiFieldState] = useState<SelectableUser[]>([])

  const projectsWithUsers = teamMembers
    .sort(sortByFieldAscending((user: SelectableUser) => `${user.firstName} ${user.lastName}`))
    ?.filter((user) => user.id !== excludedUserId)
    .reduce((acc, user) => {
      const teamMemberProjectId = user.projectId!

      if (excludedUserId && user.id === excludedUserId) return acc
      if (acc[teamMemberProjectId]) {
        acc[teamMemberProjectId].users.push(user)
      } else {
        const project = projectsData?.projectsByStatus.find(
          (innerProject: Pick<Project, "id" | "name">) => innerProject.id === teamMemberProjectId
        )
        acc[teamMemberProjectId] = {
          users: [user],
          name: project?.name || "",
        }
      }

      return acc
    }, {} as ProjectsWithUsers)

  const options = useMemo(() => {
    const users = []

    if (!isSingleSelect) {
      users.push({
        id: "all",
        firstName: "All",
        lastName: "Team Members",
        projectId: "",
        currentProjectId: "",
        currentTaskId: "",
        jobTitle: "",
      })
    }

    return [
      ...users,
      ...teamMembers.sort((a, b) => {
        const aProjectName = projectsWithUsers[a.projectId!].name
        const bProjectName = projectsWithUsers[b.projectId!].name

        const aSortString = `${aProjectName === "Overhead" ? "" : aProjectName} ${a.firstName} ${a.lastName}`
        const bSortString = `${bProjectName === "Overhead" ? "" : bProjectName} ${b.firstName} ${b.lastName}`

        return -bSortString.localeCompare(aSortString)
      }),
    ]
  }, [isSingleSelect, projectsWithUsers, teamMembers])

  const allSelected = muiFieldValue.length === teamMembers.length

  // It takes a bit for the query (on the parent component) to return the data that is the 'teamMembers' var.
  // When that data is updated, update the MUI field state to reflect the new data and preselect the values.
  useEffect(() => {
    const preselectedValue =
      (value || [])
        ?.map((val: string) => {
          const user = teamMembers.find((innerUser) => innerUser.id === val)
          return user
        })
        ?.filter(Boolean) || []

    setMuiFieldState(preselectedValue || [])
  }, [teamMembers, value])

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        disableCloseOnSelect={!isSingleSelect}
        getOptionLabel={(option) => {
          if (Array.isArray(option)) {
            const singleOption = option[0]
            const user = teamMembers.find((innerUser) => innerUser.id === singleOption?.id)
            if (user) return `${user.firstName} ${user.lastName}`
          }

          const user = teamMembers.find((innerUser) => innerUser.id === option?.id)
          if (user) return `${user.firstName} ${user.lastName}`

          return ""
        }}
        groupBy={(option) => {
          const key = option?.projectId

          if (!key) return ""

          return projectsWithUsers[key].name
        }}
        isOptionEqualToValue={(option, val) => {
          if (Array.isArray(val) && val.length === 0) {
            return true
          }

          // we always set "value" to an array, which causes a type error here. If "multiple" is false, the component expects "value" to be an object.
          // @ts-expect-error val in any type
          return option.id === val?.[0]?.id
        }}
        limitTags={3}
        multiple={!isSingleSelect}
        onChange={(_event, newValue) => {
          if (!newValue) {
            setMuiFieldState([])
            setValue([])
            return
          }

          // we always want to work with an array
          const updateValue = Array.isArray(newValue) ? newValue : [newValue]

          // if "select all" is unselected, unselect all team members
          if (allSelected && updateValue.length - 1 === teamMembers.length) {
            setMuiFieldState([])
            setValue([])
            return
          }

          // if "select all" is selected, select all team members
          if (updateValue.find((user: SelectableUser) => user.id === "all")) {
            onChange && onChange(teamMembers.map((user: SelectableUser) => user.id))
            setMuiFieldState(teamMembers)
            setValue(teamMembers.map((user: SelectableUser) => user.id))
            return
          }

          onChange && onChange(updateValue.map((user: SelectableUser) => user.id))
          setMuiFieldState(updateValue)
          setValue(updateValue.map((user: SelectableUser) => user.id))
        }}
        openOnFocus
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!(withErrorHandling && touched && error)}
            helperText={withErrorHandling && touched && error}
            size="small"
            label={label || "Team Member"}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const isAllOption = option.id === "all"
          return (
            <li {...props} className={clsx("py-2 px-4 flex items-center cursor-pointer", isAllOption && "h-8")}>
              {!isSingleSelect && (
                <Checkbox style={{ marginRight: 8 }} checked={isAllOption ? allSelected : selected} />
              )}
              {option.id === "all" ? (
                <div className="w-full">Select All</div>
              ) : (
                <div className="w-full">
                  <UserWithTaskRow user={option} />
                </div>
              )}
            </li>
          )
        }}
        sx={{
          "& .MuiInputBase-root.Mui-focused": {
            height: "100%",
            maxHeight: "6rem",
          },
        }}
        value={muiFieldValue}
      />
    </div>
  )
}
