import { useContext } from "react"
import { Asset } from "../graphql/generated/client-types-and-hooks"
import { ModalContext } from "../providers/ModalProvider"
import { DrawerContext } from "../components/Partials/Drawer/providers/DrawerProvider"
import { useModalProps } from "../components/Modals/hooks/useModalProps"
import { PickPlus } from "../types/helpers"
import { ConfirmArchiveAssetModal } from "../components/Modals/components/ConfirmArchiveAssetModal"
import { ArchiveAssetDrawer } from "../components/Partials/Drawer/ArchiveAssetDrawer"

type AssetWorkflowParams = {
  asset: PickPlus<Asset, "id" | "name" | "assetChildCount" | "status">
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
  onCancelCallback?: () => void
}

export const useTransferAndArchiveAssetWorkflow = () => {
  const { push, pop: closeDrawer } = useContext(DrawerContext)
  const { showModal } = useContext(ModalContext)
  const modalProps = useModalProps("Archive Asset")

  return ({ onSuccessCallback, onCancelCallback, onErrorCallback, asset }: AssetWorkflowParams) => {
    const drawerAsset: PickPlus<Asset, "id" | "name" | "assetChildCount" | "status"> = {
      id: asset.id,
      name: asset.name,
      assetChildCount: asset?.assetChildCount ?? 0,
      status: asset?.status,
    }
    const drawerProps = {
      onError: onErrorCallback,
      onSuccess: onSuccessCallback,
      onCancel: () => {
        onCancelCallback?.()
        modalProps.handleCloseModal()
        closeDrawer()
      },
      asset: drawerAsset,
    }

    const modalData = {
      asset: asset,
      modalProps,
      onConfirm: async () => {
        onSuccessCallback?.()
        modalProps.handleCloseModal()
        closeDrawer()
      },
    }

    const assetCount = asset?.assetChildCount ?? 0

    if (assetCount > 0) {
      push(
        <ArchiveAssetDrawer
          onError={drawerProps.onError}
          onSuccess={drawerProps.onSuccess}
          onCancel={drawerProps.onCancel}
          asset={drawerProps.asset}
        />,
        "AssetArchive"
      )
    } else {
      showModal(ConfirmArchiveAssetModal, modalData)
    }
  }
}
