import { FC, ReactNode } from "react"
import { BiCalendarStar, BiRuler, BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import { Task } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { PickPlus } from "../../../../types/helpers"
import Pill from "../../../Pill"

const TaskUnitProgressDocument = graphql(`
  query TaskUnitProgress($taskId: String!) {
    task(id: $taskId) {
      id
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        deliverableUnit {
          id
          description
        }
      }
    }
  }
`)

type Props = {
  task: PickPlus<Task, "id" | "endDate" | "completedHours" | "estimatedHours" | "startDate"> &
    ({ isComplete?: boolean } | PickPlus<Task, "isComplete">)
  subtask?: boolean
  mobile?: boolean
}

type PillProps = {
  icon: ReactNode
  title: string
  children: string
}

export const TaskPillCluster: FC<Props> = ({ task: { id, endDate, completedHours, estimatedHours } }) => {
  const [{ data }] = useQuery({
    query: TaskUnitProgressDocument,
    variables: { taskId: id },
    pause: !id,
  })

  return (
    <div className="flex flex-wrap gap-2 overflow-auto w-full mt-3">
      {endDate && (
        <IconStatusPill icon={<BiCalendarStar />} title="Schedule">
          {`${calculateDaysLeft(endDate)}`}
        </IconStatusPill>
      )}
      {estimatedHours || completedHours ? (
        <IconStatusPill icon={<BiStopwatch />} title="Hours">
          {estimatedHours
            ? `${completedHours.toFixed(2)} of ${estimatedHours} hours`
            : `${completedHours.toFixed(2)} hours`}
        </IconStatusPill>
      ) : null}

      {data?.task.unitGoals?.map((unit) => (
        <IconStatusPill
          key={unit.id}
          icon={<BiRuler />}
          title={unit.isPrimary ? unit.deliverableUnit.description : `${unit.deliverableUnit.description} (Add.)`}
        >
          {`${unit.totalProgress} of ${unit.targetQuantity} `}
        </IconStatusPill>
      ))}
    </div>
  )
}

export const IconStatusPill: FC<PillProps> = ({ icon, title, children }) => {
  return (
    <Pill color="gray" className="text-sm flex flex-row gap-2 min-w-fit items-center">
      {icon}
      <span className="font-medium">{title}</span>
      <span className="text-gray-500">{children}</span>
    </Pill>
  )
}

export const calculateDaysLeft = (endDate: Date) => {
  const currentDate = new Date()
  const timeDifference = endDate.getTime() - currentDate.getTime()
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  return daysLeft > 1
    ? `${daysLeft} days left`
    : daysLeft === 1
    ? `${daysLeft} day left`
    : daysLeft === 0
    ? "Due today"
    : `Due ${Math.abs(daysLeft)} days ago`
}
