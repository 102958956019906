import { FC, useContext } from "react"
import { ArchiveUserWorkflow, Props } from "../User/ArchiveUserWorkflow"
import { SingleDrawerContext } from "./components/Elements/Drawer"
import { DrawerBody } from "./components/Elements/DrawerBody"
import { DrawerHeader } from "./components/Elements/DrawerHeader"

export const ArchiveUserDrawer: FC<Props> = ({ onError, onSuccess, user }) => {
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <>
      <DrawerHeader href={`/team/${user?.id}/archive`} />
      <DrawerBody>
        <ArchiveUserWorkflow
          onError={() => {
            onError?.()
          }}
          onSuccess={() => {
            onSuccess?.()
            handleClose()
          }}
          onCancel={() => {
            handleClose()
          }}
          user={user}
          isDrawer
        />
      </DrawerBody>
    </>
  )
}
