import { User } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"

export function getFullName<U extends PickPlus<User, "firstName" | "lastName">>(user?: U | null): string {
  if (!user?.firstName && !user?.lastName) {
    return ""
  }

  return `${`${user?.firstName} ` || ""}${user?.lastName || ""}`.trim()
}
