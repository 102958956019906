import { TaskListItem } from "../../graphql/generated/gql/graphql"

type TaskListActionPayload = {
  filters?: TaskListProviderFilters
  taskId?: string
  taskGroupId?: string
  isComplete?: boolean
  groupId?: string
  taskList?: TaskList
}

export type TaskListAction = {
  type: string
  payload?: TaskListActionPayload
}

type TaskListProviderFilterType = "reports" | "team" | "assets" | "subtasks"

export type TaskListProviderFilters = Array<TaskListProviderFilterType>

type TaskList = TaskListItem[]

export type TaskListState = {
  filters: TaskListProviderFilters
  reportVisibility: Map<string, boolean>
  teamVisibility: Map<string, boolean>
  assetVisibility: Map<string, boolean>
  tasksExpanded: Map<string, boolean>
  taskList: TaskList
  refreshTasksAndGroups: boolean
  refreshGroupTasks: boolean
}

export const initialTaskListState: TaskListState = {
  filters: ["subtasks"],
  reportVisibility: new Map(),
  teamVisibility: new Map(),
  assetVisibility: new Map(),
  tasksExpanded: new Map(),
  taskList: [],
  refreshTasksAndGroups: true,
  refreshGroupTasks: false,
}

export enum TASK_LIST_ACTION_TYPES {
  addTasks = "ADD_TASKS",
  refreshTaskList = "REFRESH_TASK_LIST",
  refreshTaskListSuccess = "REFRESH_TASKS_AND_GROUPS_SUCCESS",
  toggleAllTaskData = "TOGGLE_ALL_TASK_DATA",
  toggleTaskAssets = "TOGGLE_TASK_ASSETS",
  toggleTaskCompletion = "TOGGLE_TASK_COMPLETION",
  toggleTaskGroupAccordion = "TOGGLE_TASK_GROUP_ACCORDION",
  toggleTaskReport = "TOGGLE_TASK_REPORT",
  toggleTaskTeam = "TOGGLE_TASK_TEAM",
  updateFilters = "UPDATE_FILTERS",
}

const newFilterDisplayState = (
  previousState: TaskListState,
  newFilters: TaskListProviderFilters,
  type: TaskListProviderFilterType,
  ids: string[]
): Map<string, boolean> => {
  const clearAll = previousState.filters.includes(type) && !newFilters.includes(type)
  if (clearAll) {
    return new Map<string, boolean>()
  }

  const showAll = newFilters.includes(type)
  if (showAll) {
    const fullVisibility = new Map<string, boolean>(ids.map((id) => [id, true]))
    return fullVisibility
  }

  if (type === "team") {
    return new Map(previousState.teamVisibility)
  }
  if (type === "assets") {
    return new Map(previousState.assetVisibility)
  }

  return new Map(previousState.reportVisibility)
}

type VisibilityType = "reportVisibility" | "teamVisibility" | "assetVisibility" | "tasksExpanded"
const visibilityKeys: Record<TaskListProviderFilterType, string> = {
  reports: "reportVisibility",
  team: "teamVisibility",
  assets: "assetVisibility",
  subtasks: "tasksExpanded",
}

const newDisplayStateForTask = (
  previousState: TaskListState,
  allGroupIds: string[],
  allParentIds: string[],
  taskId: string,
  type: TaskListProviderFilterType
): TaskListState => {
  let newFilters = [...previousState.filters]
  const key = visibilityKeys[type] as VisibilityType
  const previousVisibility = previousState[key] as Map<string, boolean> | null
  const visibility = previousVisibility ? new Map(previousVisibility) : new Map<string, boolean>()
  const tasksExpanded = new Map(previousState.tasksExpanded)

  const isOpenDisplayState = visibility.get(taskId) || false
  const toggledDisplayState = !isOpenDisplayState

  visibility.set(taskId, toggledDisplayState)
  tasksExpanded.set(taskId, toggledDisplayState)

  const reportVisibility = new Map(previousState.reportVisibility)
  const teamVisibility = new Map(previousState.teamVisibility)
  const assetVisibility = new Map(previousState.assetVisibility)
  if (allGroupIds.some((groupId) => groupId === taskId)) {
    previousState.taskList
      .filter((task) => task.groupId === taskId)
      .forEach((t) => {
        visibility.set(t.taskId!, toggledDisplayState)
        if (isOpenDisplayState) {
          reportVisibility.set(t.taskId!, toggledDisplayState)
          teamVisibility.set(t.taskId!, toggledDisplayState)
          assetVisibility.set(t.taskId!, toggledDisplayState)
        }
      })

    if (isOpenDisplayState) {
      newFilters = []
    }
  } else {
    if (isOpenDisplayState) {
      newFilters = previousState.filters?.filter((f) => f !== type)
    }
  }

  if (!isOpenDisplayState) {
    if (allParentIds.every((id) => visibility.get(id))) {
      if (!newFilters.includes(type)) {
        newFilters.push(type)
      }
      if (!newFilters.includes("subtasks")) {
        newFilters.push("subtasks")
      }
    }
  }

  return {
    ...previousState,
    reportVisibility,
    teamVisibility,
    assetVisibility,
    filters: newFilters,
    [key]: visibility,
    tasksExpanded,
  }
}

export const taskListReducer = (state: TaskListState, action: TaskListAction): TaskListState => {
  const { type, payload } = action
  const id: string = payload?.taskId || ""

  const taskList = state.taskList || []

  const allIds = taskList.map((tl) => tl?.taskId || tl.taskGroupId!)
  const allParentIds = taskList.filter((task) => !task?.groupId).map((tl) => tl?.taskId || tl.taskGroupId!)
  const allGroupIds = taskList?.filter((task) => task.taskGroupId)?.map((tl) => tl.taskGroupId!)

  let newFilters: TaskListProviderFilters = [...(payload?.filters || [])]

  let tasksExpanded = new Map(state.tasksExpanded)
  let reportVisibility = new Map(state.reportVisibility)
  let teamVisibility = new Map(state.teamVisibility)
  let assetVisibility = new Map(state.assetVisibility)

  switch (type) {
    case TASK_LIST_ACTION_TYPES.updateFilters:
      const newFilterIncludesSubtasks = newFilters.includes("subtasks")
      const currentIncludesSubtasks = state.filters.includes("subtasks")

      if (!newFilterIncludesSubtasks && currentIncludesSubtasks) {
        newFilters = []
      } else if (!newFilterIncludesSubtasks && newFilters.length > 0) {
        newFilters.push("subtasks")
      }

      reportVisibility = newFilterDisplayState(state, newFilters, "reports", allIds)
      teamVisibility = newFilterDisplayState(state, newFilters, "team", allIds)
      assetVisibility = newFilterDisplayState(state, newFilters, "assets", allIds)

      tasksExpanded =
        state.filters.length && !newFilters.length
          ? new Map()
          : new Map<string, boolean>(allGroupIds.map((groupId) => [groupId, true]))

      return {
        ...state,
        filters: newFilters,
        reportVisibility,
        teamVisibility,
        assetVisibility,
        tasksExpanded,
      }

    case TASK_LIST_ACTION_TYPES.toggleAllTaskData:
      newFilters = [...state.filters]

      const showingReports = reportVisibility.get(id)
      const showingTeam = teamVisibility.get(id)
      const showingAssets = assetVisibility.get(id)

      const isOpenDisplayState = showingReports && showingTeam && showingAssets
      const toggledDisplayState = !isOpenDisplayState

      reportVisibility.set(id, toggledDisplayState)
      teamVisibility.set(id, toggledDisplayState)
      assetVisibility.set(id, toggledDisplayState)
      tasksExpanded.set(id, toggledDisplayState)

      if (allGroupIds.some((groupId) => groupId === id)) {
        state.taskList
          .filter((task) => task.groupId === id)
          .forEach((t) => {
            reportVisibility.set(t.taskId!, toggledDisplayState)
            teamVisibility.set(t.taskId!, toggledDisplayState)
            assetVisibility.set(t.taskId!, toggledDisplayState)
          })

        if (isOpenDisplayState) {
          newFilters = []
        }
      }

      if (isOpenDisplayState) {
        if (allIds.some((taskId) => !reportVisibility.get(taskId))) {
          newFilters = newFilters?.filter((f) => f !== "reports")
        }
        if (allIds.some((taskId) => !teamVisibility.get(taskId))) {
          newFilters = newFilters?.filter((f) => f !== "team")
        }
        if (allIds.some((taskId) => assetVisibility.get(taskId))) {
          newFilters = newFilters?.filter((f) => f !== "assets")
        }
      } else {
        if (allIds.every((taskId) => reportVisibility.get(taskId))) {
          newFilters.push("reports")
        }
        // what about tasks that don't have any team members?
        if (allIds.every((taskId) => teamVisibility.get(taskId))) {
          newFilters.push("team")
        }
        // what about tasks that don't have any assets?
        if (allIds.every((taskId) => assetVisibility.get(taskId))) {
          newFilters.push("assets")
        }
      }

      return {
        ...state,
        filters: newFilters,
        reportVisibility,
        teamVisibility,
        assetVisibility,
        tasksExpanded,
      }

    case TASK_LIST_ACTION_TYPES.toggleTaskReport:
      return newDisplayStateForTask(state, allGroupIds, allParentIds, id, "reports")

    case TASK_LIST_ACTION_TYPES.toggleTaskTeam:
      return newDisplayStateForTask(state, allGroupIds, allParentIds, id, "team")

    case TASK_LIST_ACTION_TYPES.toggleTaskAssets:
      return newDisplayStateForTask(state, allGroupIds, allParentIds, id, "assets")

    case TASK_LIST_ACTION_TYPES.toggleTaskGroupAccordion:
      const taskGroupId = payload?.taskGroupId || ""
      const isExpanded = tasksExpanded.get(taskGroupId) || false
      tasksExpanded.set(taskGroupId, !isExpanded)

      if (isExpanded) {
        reportVisibility.set(taskGroupId, false)
        teamVisibility.set(taskGroupId, false)
        assetVisibility.set(taskGroupId, false)

        if (allGroupIds.some((groupId) => groupId === taskGroupId)) {
          state.taskList
            .filter((task) => task.groupId === taskGroupId)
            .forEach((t) => {
              reportVisibility.set(t.taskId!, false)
              teamVisibility.set(t.taskId!, false)
              assetVisibility.set(t.taskId!, false)
            })
        }
      }

      newFilters = [...state.filters]

      const allSubTasksExpanded = taskList
        .filter((task) => Boolean(task.groupId))
        .every((task) => tasksExpanded.get(task.groupId!))

      const subtasksInFilters = state.filters.includes("subtasks")

      // if all subtasks are expanded and "subtasks" is not in filters, add it
      if (allSubTasksExpanded && !subtasksInFilters) {
        newFilters.push("subtasks")
      }

      // if not all subtasks are expanded and "subtasks" is in filters, remove it
      if (!allSubTasksExpanded && subtasksInFilters) {
        newFilters = newFilters.filter((f) => f !== "subtasks")
      }

      return { ...state, tasksExpanded, reportVisibility, teamVisibility, assetVisibility, filters: newFilters }

    case TASK_LIST_ACTION_TYPES.toggleTaskCompletion:
      const newTaskList = [...state.taskList]
      // update completed count of the task
      const taskIndex = newTaskList.findIndex(({ taskId }) => taskId === id)
      newTaskList[taskIndex].completedTaskCount = payload?.isComplete ? 1 : 0
      // update completed count of tasks in the group task
      if (payload?.groupId) {
        const groupTaskIndex = newTaskList.findIndex(({ taskGroupId: groupId }) => groupId === payload?.groupId)
        newTaskList[groupTaskIndex].completedTaskCount = newTaskList
          .filter(({ groupId }) => groupId && groupId === payload?.groupId)
          .reduce((total, { completedTaskCount }) => total + completedTaskCount, 0)
      }
      return { ...state, taskList: newTaskList }

    case TASK_LIST_ACTION_TYPES.addTasks:
      const updatedTaskList: TaskList = payload?.taskList || []

      if (state.filters.length > 0) {
        const showSubtasks = state.filters.includes("subtasks")
        const showAssets = state.filters.includes("assets")
        const showTeam = state.filters.includes("team")
        const showReports = state.filters.includes("reports")

        updatedTaskList?.forEach((task) => {
          if (task.taskGroupId && showSubtasks) {
            tasksExpanded.set(task.taskGroupId, true)
          }
          if (task.taskId!) {
            if (showAssets) {
              assetVisibility.set(task.taskId!, true)
            }
            if (showTeam) {
              teamVisibility.set(task.taskId!, true)
            }
            if (showReports) {
              reportVisibility.set(task.taskId!, true)
            }
          }
        })
      }

      return {
        ...state,
        taskList: updatedTaskList,
        tasksExpanded,
        assetVisibility,
        teamVisibility,
      }

    case TASK_LIST_ACTION_TYPES.refreshTaskList:
      return { ...state, refreshTasksAndGroups: true, refreshGroupTasks: true }
    case TASK_LIST_ACTION_TYPES.refreshTaskListSuccess:
      return { ...state, refreshTasksAndGroups: false }

    default:
      return state
  }
}
