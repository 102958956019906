import { FC } from "react"
import { BiRuler, BiTimeFive } from "react-icons/bi"
import { Task } from "../../../../graphql/generated/client-types-and-hooks"
import { useGetTaskProgressSummary } from "../../../../hooks/queries/useGetTaskProgressSummary"
import { PickPlus } from "../../../../types/helpers"
import { DateProgressBar } from "../../../ProgressBars/DateProgressBar"
import { DateProgressGroup } from "../../../ProgressBars/DateProgressGroup"
import { ProgressBar } from "../../../ProgressBars/ProgressBar"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"
import { TaskType } from "../Tasks/TaskRow"
import { TaskPillCluster } from "./TaskPillCluster"

type TaskProgressBarProps = {
  task: PickPlus<Task, "id" | "endDate" | "completedHours" | "estimatedHours" | "startDate"> &
    ({ isComplete?: boolean } | PickPlus<Task, "isComplete">)
  type?: TaskType
  drawer?: boolean
  mobile?: boolean
  hideCluster?: boolean
  showGroupTaskProgress?: boolean
}

/** Renders a task's progress bar cluster. */
export const TaskProgressBar: FC<TaskProgressBarProps> = ({
  task,
  type,
  drawer = false,
  mobile = false,
  hideCluster = false,
  showGroupTaskProgress = false,
}) => {
  const { data, fetching } = useGetTaskProgressSummary(task.id)
  const showProgress = type === "primaryTask" || showGroupTaskProgress || type === "groupTask" || drawer

  if (fetching && !data) {
    return (
      <SkeletonContainer>
        <div className="grid gap-1">
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
        </div>
      </SkeletonContainer>
    )
  }

  return (
    <>
      {showProgress && (
        <>
          <DateProgressGroup start={task.startDate} end={task.endDate} isComplete={!!task.isComplete}>
            <DateProgressBar start={task.startDate} end={task.endDate} isComplete={!!task.isComplete} />
            <ProgressBar
              Icon={BiTimeFive}
              isComplete={!!task.isComplete}
              itemLabel="hours"
              max={task?.estimatedHours || 0}
              textColor={task?.completedHours ? "text-blue-800" : "text-gray-400"}
              
              value={task?.completedHours}
            />
            <ProgressBar
              bgColor="bg-sky-500"
              Icon={BiRuler}
              isComplete={!!task.isComplete}
              itemLabel="units"
              max={data?.targetUnits}
              textColor={data?.completedUnits ? "text-sky-800" : "text-gray-400"}
              value={data?.completedUnits}
            />
          </DateProgressGroup>
          {hideCluster ? null : <TaskPillCluster mobile={mobile} subtask={type === "groupTask"} task={task} />}
        </>
      )}
    </>
  )
}
