import { DrawerLink } from "../Elements/DrawerLink"
import { UserDrawer } from "./UserDrawer"

export const UserDrawerLink = ({ name, userId }: { name: string; userId: string }) => (
  <DrawerLink
    className="underline text-blue-600 hover:text-blue-700"
    component={<UserDrawer userId={userId} />}
    drawerName="User"
    href={`/team/${userId}`}
  >
    {name}
  </DrawerLink>
)
