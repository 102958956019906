/**
  TODO: This needs to be cleaned up. The logic works, but the way that state is overwriting state repeatedly will make
        this really hard to maintain. The comments definitely help, but code rots and comments eventually lie.
        The eventual irony of me writing this in a code is far from lost on me.
 */

import { isAfter, isBefore } from "date-fns"
import { FormikValues } from "formik"
import { TimeEntry } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { getDoubleBookings } from "./getDoubleBookings"

type Errors = { startAt?: string; endAt?: string }

// TODO: Make this return all relevant errors instead of just the first one
export function validateTimeEntry<T extends Pick<TimeEntry, "id" | "endAt" | "startAt">>(
  { timeEntries, startAt, endAt }: { timeEntries: T[]; startAt: Date; endAt?: Date },
  { requireEndAt: requireEndAt, requireStartAt: requireStartAt }: { requireEndAt: boolean; requireStartAt: boolean } = {
    requireStartAt: true,
    requireEndAt: false,
  }
) {
  const now = new Date()
  const errors: Errors = {}

  if (isAfter(startAt, now)) {
    errors.startAt = "Start date cannot be in the future"
  }

  if (endAt && isAfter(endAt, now)) {
    errors.endAt = "End date cannot be in the future"
  }

  if (errors.endAt || errors.startAt) return errors

  // Dates are in wrong order
  if (endAt && isBefore(endAt, startAt)) {
    errors.endAt = "End date cannot be before start date"
    return errors
  }

  if (requireStartAt && !startAt) errors.startAt = "Missing required Start date"

  if (errors.endAt || errors.startAt) return errors

  const doubleBookings = getDoubleBookings(timeEntries, startAt, endAt)
  // if there are no double bookings, skip that logic
  if (doubleBookings.length > 0) {
    const doubleBookingReasons = doubleBookings.map((doubleBooking) => doubleBooking.reason)

    // start date
    if (doubleBookingReasons.includes("invalid proposed startAt")) {
      errors.startAt = "This entry conflicts with an existing time entry"
    }

    // end date
    if (doubleBookingReasons.includes("invalid proposed endAt")) {
      errors.endAt = "This entry conflicts with an existing time entry"
    }
  }

  // Finally, run the check on endAt as it is the simplest edge on reordered tasks
  if (requireEndAt && !endAt) errors.endAt = "Missing required End date"

  return errors
}

export function validateTimeEntries<T extends PickPlus<TimeEntry, "id" | "endAt" | "startAt">>(
  values: FormikValues,
  existingTimeEntries: T[]
) {
  // TODO: Pull timeEntries that *could* conflict from the backend... probably means we need an endpoint that accepts date ranges
  // TODO: Make sure that submission makes modifications in order to ensure we don't get errors because we didn't update the right time entry in the right order.

  const errors: { [id: string]: Errors } = {}

  // Check new values as modifications of the existing ones and raise errors against those
  // const candidateTimeEntries: { [key: string]: TimeEntry | { startAt: Date; endAt?: Date } } = {}
  const candidateTimeEntries: { [id: string]: TimeEntry } = existingTimeEntries.reduce(
    (acc, timeEntry) => ({
      ...acc,
      [timeEntry.id]: timeEntry,
    }),
    {}
  )
  Object.entries(values).forEach(([id, value]) => {
    candidateTimeEntries[id] = value
  })

  const sortedCandidateTimeEntries = Object.values(candidateTimeEntries).sort(
    (a, b) => b.startAt.getTime() - a.startAt.getTime()
  )

  const latestCandidateTimeEntry = sortedCandidateTimeEntries[0]

  Object.entries(values).forEach(([id, timeEntry]) => {
    const isLatestTimeEntry = timeEntry.id === latestCandidateTimeEntry.id
    const error = validateTimeEntry(
      { timeEntries: sortedCandidateTimeEntries, startAt: timeEntry.startAt, endAt: timeEntry.endAt },
      { requireStartAt: true, requireEndAt: !isLatestTimeEntry }
    )
    error.endAt || error.startAt ? (errors[id] = error) : null
  })

  return errors
}
