import { Box, Skeleton, Typography } from "@mui/material"
import { FC, useEffect } from "react"
import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  DismissAssetRepairMutationVariables,
  useDismissAssetRepairMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { MuiModal } from "./Elements/MuiModal"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { assetStatusOptions, isOperational } from "../../../helpers/assets/assetStatus"
import { AssetStatusMultiSelect } from "../../Formik/MultiSelect/implementations/AssetStatusMultiSelect"

const DismissRepairRequestQueryDocument = graphql(`
  query GetAssetRepairToDismissRepair($repairRequestId: String!) {
    assetRepairRequest(id: $repairRequestId) {
      id
      label
      createdAt
      assetId
      asset {
        repairRequestCount
      }
    }
  }
`)

type DismissRepairRequestModalValueType = {
  status?: string
  assetRepairId: string
  assetId: string
}

export const DismissRepairRequestModal: FC<{
  repairRequestId: string
  isOpen: boolean
  closeModal: () => void
  onSuccess?: () => void
}> = ({ repairRequestId, isOpen, closeModal, onSuccess = () => null }) => {
  const [, dismissAssetRepairMutation] = useDismissAssetRepairMutation()
  const [{ data: assetRepairData, error, fetching }] = useQuery({
    query: DismissRepairRequestQueryDocument,
    pause: !repairRequestId,
    variables: { repairRequestId },
  })

  useEffect(() => {
    if (error) {
      closeModal()
    }
  }, [error, closeModal])

  const handleSubmit = async (
    values: FormikValues,
    { resetForm, setSubmitting }: FormikHelpers<DismissRepairRequestModalValueType>
  ) => {
    if (values.assetRepairId !== repairRequestId) {
      errorSnack("Something went wrong; please refresh the page")
    }

    const assetId = values.assetId
    const assetRepairId = values.assetRepairId

    if (assetRepairId && assetId) {
      const status = values.status ?? undefined
      const data: DismissAssetRepairMutationVariables = {
        report: {
          assetRepairId,
          assetId,
          statusChange: status
            ? {
                status,
                active: isOperational(status),
              }
            : undefined,
        },
      }

      try {
        const result = await dismissAssetRepairMutation(data)

        if (result.error) {
          errorSnack("Something went wrong when dismissing the repair request")
        } else {
          onSuccess()
          closeModal()
          successSnack("Repair successfully dismissed")
          resetForm()
        }
      } catch (e) {
        errorSnack("Something went wrong with dismissing the repair Requests")
      } finally {
        setSubmitting(false)
      }
    }
  }

  const isLoading = (!assetRepairData && fetching) || assetRepairData?.assetRepairRequest.id !== repairRequestId
  const repairRequest = assetRepairData?.assetRepairRequest
  const repairRequestCount = repairRequest?.asset?.repairRequestCount ?? null
  const isFinalRepairRequest = repairRequestCount === 1

  return (
    <Formik<DismissRepairRequestModalValueType>
      enableReinitialize
      initialValues={{
        status: isFinalRepairRequest ? "" : undefined,
        assetRepairId: repairRequestId,
        assetId: repairRequest?.assetId ?? "",
      }}
      validationSchema={Yup.object().shape({
        status: isFinalRepairRequest
          ? Yup.string()
              .oneOf(assetStatusOptions.map((status) => status.value))
              .required("Required")
          : Yup.string().test(
              "is-undefined",
              "status must not be provided",
              (value) => value === undefined || value === null
            ),
        assetRepairId: Yup.string().required(),
        assetId: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, resetForm }) => (
        <MuiModal
          contentLabel="Dismiss Repair Event"
          submitButtonText="Dismiss"
          isOpen={isOpen}
          handleCloseModal={() => {
            resetForm()
            closeModal()
          }}
          disabled={isLoading || isSubmitting}
          submitForm={submitForm}
          variant="small"
        >
          <Form className="h-full flex flex-col">
            <Typography className="mb-4">
              Are you certain you wish to dismiss this repair event? This action is irreversible, and the repair item
              will revert to a neutral state in future inspections.
            </Typography>
            {isLoading ? (
              <Skeleton variant="rectangular" height={43} width={220} className="rounded-md" />
            ) : (
              isFinalRepairRequest && (
                <>
                  <Typography className="mb-4">Please select a new status.</Typography>
                  <Box className="mb-3">
                    <AssetStatusMultiSelect name="status" withErrorHandling useNewDesign />
                  </Box>
                </>
              )
            )}
          </Form>
        </MuiModal>
      )}
    </Formik>
  )
}
