import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { PageTitle } from "../../../components/PageTitle"
import { DocumentsTable } from "../../../components/Partials/Documents/DocumentsTable"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { RenderIf } from "../../../components/RenderIf"
import { getFullName } from "../../../helpers/strings/getFullName"
import UserPageLayout, { UserLayoutContext } from "./_layout"
import { DocumentModelType } from "../../../helpers/files/fileUpload"

export const UserPageDocuments: FC = () => {
  const { user } = useContext(UserLayoutContext)

  return (
    <>
      <PageTitle title={!user?.firstName ? "User documents" : `${getFullName(user)} documents`} />
      {!user?.id && <UserSkeleton />}
      {user?.id && (
        <DocumentsTable
          modelId={user.id}
          requiredPermissions={["user:read"]}
          title="User Documents"
          type={DocumentModelType.User}
        />
      )}
    </>
  )
}

const UserPage: NextPage = () => {
  const { query } = useRouter()
  const userId = query._id as string
  return (
    <RenderIf permissionsInclude="user:read" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageDocuments />
      </UserPageLayout>
    </RenderIf>
  )
}

export default UserPage
