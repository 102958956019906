import { Box, Button, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useState } from "react"
import { useQuery } from "urql"
import { BulkClockOutModalUsersQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { canBeClockedOut } from "../../../../helpers/clockInOut/canBeClockedOut"
import ClockOutWorkflow from "../../../ClockInOut/workflows/ClockOutWorkflow"
import { DateTimeOrMaybeJustTimePicker } from "../../../Formik/DateTimeOrMaybeJustTimePicker"
import { BasicModal } from "../../../Modals/components/Elements/BasicModal"
import { MuiModal } from "../../../Modals/components/Elements/MuiModal"
import { ModalProps, useModalProps } from "../../../Modals/hooks/useModalProps"
import { Monogram } from "../../../Monogram"
import { ConflictTeamMemberRow } from "./ConflictTeamMemberRow"

const BulkClockOutModalUsersDocument = graphql(`
  query BulkClockOutModalUsers($first: Int, $filter: UserFilter!) {
    users(first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName

          currentProject {
            id
            name
          }

          currentTask {
            id
            name
          }

          latestTimeEntry {
            id
            taskId
            endAt
            startAt
            evidence
          }
        }
      }
    }
  }
`)

type BulkClockOutModalProps = {
  taskId?: string
  userIds?: string[]
} & ModalProps

export const BulkClockOutModal: FC<BulkClockOutModalProps> = ({
  contentLabel,
  handleCloseModal,
  isOpen,
  taskId,
  userIds,
}) => {
  const [{ data }] = useQuery({
    query: BulkClockOutModalUsersDocument,
    pause: !isOpen || (!taskId && !userIds?.length),
    variables: { first: userIds?.length, filter: { taskId, userIds } },
  })

  const [clockOutUser, setClockOutUser] = useState<
    NonNullable<BulkClockOutModalUsersQuery["users"]["edges"][number]>["node"] | null
  >(null)

  const clockOutModalProps = useModalProps("Clock Out")

  if (!isOpen) return null

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        endAt: new Date(),
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <Form>
          <MuiModal
            cancelButtonColor="primary"
            isLoading={!data}
            variant="small"
            isOpen={isOpen}
            contentLabel={contentLabel}
            handleCloseModal={handleCloseModal}
            disableSaveButton
            cancelButtonText="Close"
          >
            <>
              <DateTimeOrMaybeJustTimePicker className="w-full" name="endAt" timeOnly />
              <Stack marginBlockStart={4} gap={1}>
                {data?.users.edges.map((edge) => {
                  if (!edge) return null
                  const user = edge.node

                  return canBeClockedOut(user.latestTimeEntry, values.endAt) || !user.isClockedIn ? (
                    <Box key={user.id}>
                      <Box display="flex" gap={2}>
                        <Monogram user={user} />
                        <div className="grow">
                          <p>
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="text-sm text-gray-400">{user.jobTitle}</p>
                        </div>
                        <Button
                          disabled={!user.isClockedIn}
                          onClick={() => {
                            setClockOutUser(user)
                            clockOutModalProps.handleOpenModal()
                          }}
                          variant="contained"
                        >
                          Clock out
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <ConflictTeamMemberRow key={user.id} user={user} type="out" />
                  )
                })}
              </Stack>
            </>
          </MuiModal>

          <BasicModal modalProps={clockOutModalProps}>
            {clockOutUser && (
              <ClockOutWorkflow
                user={clockOutUser}
                onSuccess={() => {
                  clockOutModalProps.handleCloseModal()
                  setClockOutUser(null)
                }}
                overrideDate={values.endAt}
              />
            )}
          </BasicModal>
        </Form>
      )}
    </Formik>
  )
}
