import { Box } from "@mui/material"
import { clsx } from "clsx"
import { useContext } from "react"
import { overrideTailwindClasses } from "tailwind-override"
import { NotificationsContext, UserNotificationExpectation } from "../../providers/NotificationsProvider"

type NotificationBubbleProps = {
  className?: string
  shouldRender: (notifications: UserNotificationExpectation[]) => boolean
}

export const NotificationBubble = ({ className = "", shouldRender }: NotificationBubbleProps) => {
  const { unreadNotifications } = useContext(NotificationsContext)

  if (!shouldRender(unreadNotifications)) return null

  return (
    <Box
      display="inline-block"
      width="8px"
      height="8px"
      marginBottom="2px"
      marginRight="5px"
      className={overrideTailwindClasses(clsx(className, "bg-orange-600 rounded-full"))}
    ></Box>
  )
}
