import { createContext, FC, ReactNode, useEffect, useState } from "react"
import { getCurrentLocation } from "../helpers/getCurrentLocation"

type ContextValue = {
  location: GeolocationPosition | undefined
  locationError: GeolocationPositionError | undefined
  refreshLocation: () => void
}

type GeolocationProviderProps = {
  children: ReactNode
}

export const GeolocationProviderContext = createContext<ContextValue>({} as ContextValue)

export const GeolocationProvider: FC<GeolocationProviderProps> = ({ children }) => {
  const [location, setLocation] = useState<GeolocationPosition | undefined>()
  const [locationError, setLocationError] = useState<GeolocationPositionError | undefined>()

  const getLocation = () =>
    getCurrentLocation()
      .then((loc) => {
        setLocation(loc)
      })
      .catch((e) => {
        console.error(e)
        setLocationError(e as GeolocationPositionError)
      })

  useEffect(() => {
    getLocation()
  }, [])

  return (
    <GeolocationProviderContext.Provider
      value={{
        location,
        locationError,
        refreshLocation: () => getLocation(),
      }}
    >
      {children}
    </GeolocationProviderContext.Provider>
  )
}
