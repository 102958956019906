import { FC } from "react"

export const taskProductionPercentageRate = ({
  clockedSeconds,
  unitsDelivered,
  targetQuantity,
  estimatedHours,
}: {
  clockedSeconds: number
  unitsDelivered: number
  targetQuantity: number
  estimatedHours: number | undefined | null
}) => {
  const actual = clockedSeconds && clockedSeconds > 0 ? (unitsDelivered || 0) / (clockedSeconds / 3600) : 0
  const expected = targetQuantity > 0 && estimatedHours && estimatedHours > 0 ? targetQuantity / estimatedHours : 0
  const rate = actual && expected && isFinite(actual) && isFinite(expected) ? Math.round((actual / expected) * 100) : 0

  return { actual, expected, rate }
}

type ProjectOrTaskGroup = {
  productionRateData: {
    completed_seconds: number
    estimated_hours: number | null
    target_quantity: number
    units_delivered: number
  }[][]
  divider: number
}

export const sumTaskProductionPercentageRate: FC<ProjectOrTaskGroup> = ({ productionRateData, divider }) => {
  const rate =
    productionRateData.reduce((sum: number, row) => {
      return (
        sum +
        taskProductionPercentageRate({
          clockedSeconds: row[0].completed_seconds,
          unitsDelivered: row[0].units_delivered,
          targetQuantity: row[0].target_quantity,
          estimatedHours: row[0].estimated_hours,
        }).rate
      )
    }, 0) / divider || 0
  return rate
}
