import { FC, useContext, useEffect, useMemo, useReducer, useState } from "react"

import { useQuery } from "urql"

import { Button, colors, IconButton, MenuItem, Select } from "@mui/material"
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro"
import { BiBox } from "react-icons/bi"

import {
  AssetAssignableType,
  AssetStatus,
  CompleteTaskReassignAssetsQuery,
  useTransferAssetsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetBadge } from "../../AssetBadge"
import { ConfirmArchiveAssetModal } from "../../Modals/components/ConfirmArchiveAssetModal"
import { TransferAssetsToAny } from "../../Modals/components/TransferAssetModal/TransferAssetsToAny"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { TaskReassignmentWorkflowSkeleton } from "../Projects/Tasks/TaskReassignmentWorkflow.skeleton"

export const testLabel_CompleteTaskReassignAssetsAssignmentType = "complete-task-reassign-assets-assignment-type"
export const testLabel_CompleteTaskReassignAssetsAssignmentOption = "complete-task-reassign-assets-assignment-option"
export const testLabel_CompleteTaskReassignAssetsAssignmentButton = "complete-task-reassign-assets-assignment-button"

type RowAssignmentAction =
  | { assetId: string; type: "selectType"; assignableType: string }
  | { assetId: string; type: "assign"; assignableId: string; prevAssignableId: string; prevAssignableType: string }
type RowAssignmentMap = { [assetId: string]: RowAssignmentState }
type RowAssignmentState = {
  status: "pending" | "needsType" | "ready"
  selectedAssignableType?: string
  selectedAssignableId?: string
  currentAssignableId?: string
  currentAssignableType?: string
}
type RowData = CompleteTaskReassignAssetsQuery["taskOrProjectAssets"][0]

type ValueObject = { value: string; label: string }

const CompleteTaskReassignAssetsAssignmentTargetDocument = graphql(`
  query TaskCompletionAssetReassignmentTargets {
    usersList(status: "active") {
      id
      firstName
      lastName
    }
    projectsByStatus(status: active) {
      id
      name
      tasks {
        id
        name
      }
    }
    allAssets: assets {
      id
      name
      assignableType
      assignableId
    }
  }
`)

type AssetReassignmentType = {
  id: string
  name: string
  status: AssetStatus
  assetChildCount: number
  assignableId: string
  assignableType: string
  imageUrl?: string | null
  companyAssetNumber?: string | null
}

const CompleteTaskReassignAssetsDocument = graphql(`
  query CompleteTaskReassignAssets($taskId: String, $projectId: String, $userId: String, $assetId: String) {
    taskOrProjectAssets: assets(taskId: $taskId, projectId: $projectId) {
      id
      name
      status
      assetChildCount
      assignableId
      assignableType
      imageUrl
      companyAssetNumber
    }
    userAssets: assets(assignableType: User, assignableId: $userId) {
      id
      name
      status
      assetChildCount
      assignableId
      assignableType
      imageUrl
      companyAssetNumber
    }
    nestedAssets(assignableId: $assetId) {
      id
      name
      status
      assetChildCount
      assignableId
      assignableType
      imageUrl
      companyAssetNumber
    }
  }
`)

const rowAssignmentReducer = (state: RowAssignmentMap, action: RowAssignmentAction): RowAssignmentMap => {
  let assetState = state[action.assetId]
  switch (action.type) {
    case "selectType":
      assetState = { status: "needsType", selectedAssignableType: action.assignableType }
      break
    case "assign":
      if (assetState.status !== "needsType") {
        // Return to sender... restart
        assetState = { status: "pending" }
      } else {
        assetState = {
          ...assetState,
          selectedAssignableId: action.assignableId,
          currentAssignableId: action.prevAssignableId,
          currentAssignableType: action.prevAssignableType,
          status: "ready",
        }
      }
      break
  }

  return { ...state, [action.assetId]: assetState }
}

type AssetChild = CompleteTaskReassignAssetsQuery["nestedAssets"][0]

function sortAssets(parentId: string, assets: AssetChild[], depth: number = 0): AssetReassignmentType[] {
  const { children, remaining } = assets.reduce(
    (acc, asset) => {
      if (asset.assignableId === parentId) {
        // Filter assets that are children of the current parent
        return { ...acc, children: [...acc.children, { ...asset, depth }] }
      }

      return { ...acc, remaining: [...acc.remaining, asset] }
    },
    { children: [], remaining: [] } as {
      children: AssetReassignmentType[]
      remaining: AssetChild[]
    }
  )

  // Sort these children alphabetically by name
  children.sort((a, b) => a.name.localeCompare(b.name))

  // Array to hold sorted assets including children's children
  let sorted: AssetReassignmentType[] = []
  children.forEach((child) => {
    sorted.push(child)
    // Recursively sort the children of the current child
    sorted = sorted.concat(sortAssets(child.id, remaining, depth + 1))
  })
  return sorted
}

export const AssetReassignment: FC<{
  onSuccess?: () => void
  onLoad?: (assetCount: number) => void
  assetId?: string
  userId?: string
  projectId?: string
  taskId?: string
  excludedProjectId?: string
  isArchiveEnabled?: boolean
}> = ({ onSuccess, onLoad, assetId, userId, projectId, taskId, excludedProjectId, isArchiveEnabled }) => {
  const [assignments, dispatch] = useReducer(rowAssignmentReducer, {})
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data: targetData }] = useQuery({
    query: CompleteTaskReassignAssetsAssignmentTargetDocument,
  })
  const apiRef = useGridApiRef()
  const assetModalProps = useModalProps("Transfer Assets")
  const archiveModalProps = useModalProps("Archive Asset")

  const [{ data, error, fetching }, refetchTarget] = useQuery({
    query: CompleteTaskReassignAssetsDocument,
    variables: { taskId, projectId, userId, assetId },
  })
  const [selectedRowCount, setSelectedRowCount] = useState<number | null>(null)
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([])
  const [linkedAssetsToArchive, setLinkedAssetsToArchive] = useState<AssetReassignmentType[] | null>(null)
  const [_, transferAssetMutation] = useTransferAssetsMutation()

  useEffect(() => {
    if (!fetching && data) {
      const taskAssetCount = (taskId && data?.taskOrProjectAssets.length) ?? 0
      const userAssetCount = (userId && data?.userAssets.length) ?? 0
      const assetAssetCount = (assetId && data?.nestedAssets.length) ?? 0

      const assetCount = taskAssetCount || userAssetCount || assetAssetCount || 0
      onLoad?.(assetCount)
    }
  }, [fetching, data, taskId, data?.taskOrProjectAssets, userId, data?.userAssets, assetId, data?.nestedAssets, onLoad])

  useHandleError(error, "There was an error loading needed data.")

  const assets = useMemo((): AssetReassignmentType[] => {
    if (userId) {
      return data?.userAssets ?? []
    }
    if (assetId) {
      return sortAssets(assetId, data?.nestedAssets ?? [])
    }

    return data?.taskOrProjectAssets ?? []
  }, [userId, data?.userAssets, assetId, data?.nestedAssets, data?.taskOrProjectAssets])

  useEffect(() => {
    if (!fetching && assets && assets.length === 0) {
      onSuccess?.()
    }
  }, [fetching, assets, onSuccess])

  const userOptions: ValueObject[] = useMemo(() => {
    return (targetData?.usersList || [])
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.id }))
  }, [targetData?.usersList])

  const projectAndTaskOptions = useMemo(() => {
    const projectAndTaskList: ValueObject[] = []
    ;(targetData?.projectsByStatus || []).forEach((project) => {
      if (project.id === excludedProjectId) return

      project.tasks.forEach((task) => {
        projectAndTaskList.push({ label: `${project.name} / ${task.name}`, value: task.id })
      })
    })
    return projectAndTaskList
  }, [targetData?.projectsByStatus, excludedProjectId])

  const assetOptions: ValueObject[] = useMemo(() => {
    return (targetData?.allAssets || []).map((asset) => ({ value: asset.id, label: asset.name }))
  }, [targetData?.allAssets])

  const handleReassignAssets = () => {
    const assetIdsToReassign = apiRef.current?.getSelectedRows?.() || new Map()
    if (assetIdsToReassign.size > 0) {
      assetModalProps.handleOpenModal()
    }
  }

  const assignmentColumnSettings = {
    filterable: false,
    hideable: false,
    sortable: false,
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Asset",
      flex: 3,
      renderCell: ({ row }) => <AssetBadge asset={row} showDepth />,
    },
    {
      ...assignmentColumnSettings,
      field: "selectType",
      flex: 2,
      headerName: "Type",
      renderCell: ({ row }: { row: RowData }) => {
        const rowAssignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) =>
              dispatch({
                assetId: row.id,
                type: "selectType",
                assignableType: e.target.value,
              })
            }
            name="selectType"
            renderValue={rowAssignment?.selectedAssignableType ? undefined : () => "Select a type"}
            defaultValue=""
            value={rowAssignment?.selectedAssignableType ?? ""}
            placeholder="Select a type"
            test-label={testLabel_CompleteTaskReassignAssetsAssignmentType}
            margin="dense"
            displayEmpty
            variant="outlined"
          >
            <MenuItem key="selectType" value="" disabled>
              Select a type
            </MenuItem>
            <MenuItem key="task" value="Task">
              Task
            </MenuItem>
            <MenuItem key="user" value="User">
              User
            </MenuItem>
            <MenuItem key="asset" value="Asset">
              Asset
            </MenuItem>
          </Select>
        )
      },
    },
    {
      ...assignmentColumnSettings,
      field: "assignableId",
      flex: 2,
      headerName: "Assignment",
      renderCell: ({ row }: { row: RowData }) => {
        const assignmentValue = assignments[row.id]
        let menuItems: ValueObject[] = []

        const isTaskSelected = assignmentValue?.selectedAssignableType === "Task"
        const isUserSelected = assignmentValue?.selectedAssignableType === "User"
        const isAssetSelected = assignmentValue?.selectedAssignableType === "Asset"

        switch (assignmentValue?.selectedAssignableType) {
          case "Task":
            menuItems = projectAndTaskOptions
            break
          case "User":
            menuItems = userOptions
            break
          case "Asset":
            menuItems = assetOptions.filter((a) => a.value !== row.id)
            break
        }

        return (
          <Select
            fullWidth
            onChange={(e) =>
              dispatch({
                assetId: row.id,
                type: "assign",
                assignableId: e.target.value,
                prevAssignableId: row.assignableId,
                prevAssignableType: row.assignableType,
              })
            }
            name="selectAssignment"
            disabled={!["ready", "needsType"].includes(assignmentValue?.status)}
            renderValue={assignmentValue?.selectedAssignableId ? undefined : () => "Select assignment"}
            defaultValue=""
            value={assignmentValue?.selectedAssignableId ?? ""}
            test-label={testLabel_CompleteTaskReassignAssetsAssignmentOption}
            placeholder="Select assignment"
            margin="dense"
            displayEmpty
            variant="outlined"
          >
            <MenuItem key="selectAssignment" value="" disabled>
              Select assignment
            </MenuItem>
            {menuItems && menuItems.length ? (
              menuItems.map((item) => (
                <MenuItem
                  disabled={
                    (isTaskSelected && item.value === taskId) ||
                    (isUserSelected && item.value === userId) ||
                    (isAssetSelected && item.value === assetId)
                  }
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options available</MenuItem>
            )}
          </Select>
        )
      },
    },
    {
      type: "actions",
      field: "actions",
      minWidth: isArchiveEnabled ? 136 : 72,
      align: "right",
      renderHeader: () => {
        if (!selectedRowCount) return null

        return (
          <>
            <Button variant="contained" color="primary" onClick={handleReassignAssets}>
              Transfer
            </Button>
            {isArchiveEnabled && (
              <IconButton
                color="error"
                onClick={() => {
                  setLinkedAssetsToArchive(assets.filter((asset) => selectedAssetsIds.includes(asset.id)))
                  archiveModalProps.handleOpenModal()
                }}
                className="bg-red-600 text-white rounded-md m-1"
              >
                <BiBox className="size-5" />
              </IconButton>
            )}
          </>
        )
      },
      renderCell: ({ row }: { row: RowData }) => {
        const assignmentValue = assignments[row.id]
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={assignmentValue?.status !== "ready"}
              test-label={testLabel_CompleteTaskReassignAssetsAssignmentButton}
              onClick={() => {
                const cellAssignment = assignments[row.id]
                if (cellAssignment.status === "ready") {
                  transferAssetMutation({
                    assetIds: [row.id],
                    assignableId: cellAssignment?.selectedAssignableId ?? "",
                    assignableType: cellAssignment?.selectedAssignableType as AssetAssignableType,
                  }).then((result) => {
                    if (result.error) {
                      errorSnack(`Whoops! Error transferring asset: ${row.name}`)
                    } else {
                      successSnack(`Success: ${row.name} has been transferred`)
                      refetchTarget()
                    }
                  })
                } else {
                  errorSnack("Something went wrong; please try again")
                }
              }}
            >
              Transfer
            </Button>
            {isArchiveEnabled && (
              <IconButton
                color="error"
                onClick={() => {
                  setLinkedAssetsToArchive([row])
                  archiveModalProps.handleOpenModal()
                }}
                className="bg-red-600 text-white rounded-md m-1"
              >
                <BiBox className="size-5" />
              </IconButton>
            )}
          </>
        )
      },
      disableColumnMenu: true,
    },
  ]

  return (
    <>
      {fetching && assets ? (
        <TaskReassignmentWorkflowSkeleton />
      ) : (
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection={hasPermissionTo(["asset:transfer", "asset:update"])}
          disableRowSelectionOnClick
          columns={columns}
          pageSizeOptions={[10]}
          rows={assets || []}
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids)
            setSelectedRowCount(selectedIDs.size)
            const _selectedAssetIds = Array.from(selectedIDs).map((id) => id.toString())

            setSelectedAssetsIds(_selectedAssetIds || [])
          }}
          sx={{
            border: `1px solid ${colors.grey[300]}`,
          }}
        />
      )}
      {assetModalProps.isOpen && (
        <TransferAssetsToAny
          excludedTaskIds={taskId ? [taskId] : []}
          excludedProjectId={excludedProjectId}
          excludedUserId={userId}
          excludedAssetId={assetId}
          assetsSelectedToTransfer={selectedAssetsIds}
          formModalProps={assetModalProps}
        />
      )}
      {isArchiveEnabled && archiveModalProps.isOpen && linkedAssetsToArchive && (
        <ConfirmArchiveAssetModal assets={linkedAssetsToArchive} modalProps={archiveModalProps} archiveChildAssets />
      )}
    </>
  )
}
