import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { BiSearchAlt } from "react-icons/bi"
import { Asset, AssetReportTemplate } from "../graphql/generated/client-types-and-hooks"
import { AssetInspectionTemplateAssignments } from "../graphql/generated/gql/graphql"
import { AssetInspectionWizardProvider } from "../providers/AssetInspectionWizardProvider"
import { DrawerContext } from "./Partials/Drawer/providers/DrawerProvider"
import { PickPlus } from "../types/helpers"
import { InspectionReportDrawer } from "./Partials/Drawer/components/InspectionReportDrawer"

type ReportTemplateRowProps = {
  assetId: string
  includeIcon?: boolean
  className?: string
  style?: "black" | "secondary"
  inspectionTemplateAssignmentId: string
  label?: string
}

type InspectionTemplatesAssignment = PickPlus<
  AssetInspectionTemplateAssignments,
  "id" | "intervalInSeconds" | "lastReportedAt" | "startDate"
> & { inspectionTemplate: PickPlus<AssetReportTemplate, "id" | "name"> }

type InspectButtonProps = {
  asset: PickPlus<Asset, "id"> & {
    inspectionTemplatesAssignment?: InspectionTemplatesAssignment
  }
  className?: string
  includeIcon?: boolean
  label?: string
  style?: "black" | "secondary"
}

export const InspectButton: FC<InspectButtonProps> = ({
  asset,
  includeIcon = false,
  className,
  label,
  style = "black",
}) => {
  return (
    <>
      {!asset?.inspectionTemplatesAssignment && (
        <Button
          className={className}
          variant="contained"
          color={style}
          disabled
          type="button"
          endIcon={includeIcon ? <BiSearchAlt className="size-5" /> : null}
        >
          Inspect
        </Button>
      )}
      {!!asset?.inspectionTemplatesAssignment && (
        <ReportButton
          assetId={asset.id}
          className={className}
          includeIcon={includeIcon}
          inspectionTemplateAssignmentId={asset?.inspectionTemplatesAssignment?.id}
          label={label}
          style={style}
        />
      )}
    </>
  )
}

const ReportButton: FC<ReportTemplateRowProps> = ({
  assetId,
  includeIcon = false,
  className,
  style = "black",
  inspectionTemplateAssignmentId,
  label,
}) => {
  const { push: pushDrawer } = useContext(DrawerContext)

  const onReport = () => {
    pushDrawer(
      <AssetInspectionWizardProvider>
        <InspectionReportDrawer assetId={assetId} inspectionTemplateAssignmentId={inspectionTemplateAssignmentId} />
      </AssetInspectionWizardProvider>,
      "InspectionReport"
    )
  }

  return (
    <>
      <Button
        className={className}
        color={style}
        onClick={onReport}
        type="button"
        variant="contained"
        endIcon={includeIcon ? <BiSearchAlt className="size-5" /> : null}
      >
        {label ?? "Inspect"}
      </Button>
    </>
  )
}
