import { NextPage } from "next"
import { createContext, FC, ReactNode, useContext, useEffect } from "react"
import { BiEdit, BiHealth, BiLabel, BiMailSend, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import Layout from "../../../components/Layout/Layout"
import { MonogramRing } from "../../../components/Monogram"
import { errorSnack, successSnack } from "../../../components/Notistack/ThemedSnackbars"
import { UserEditDrawer } from "../../../components/Partials/Drawer/components/User/UserEditDrawer"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { QuickMenuDotsHorizontal } from "../../../components/QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../../components/QuickMenuMui"
import { RenderIf } from "../../../components/RenderIf"
import { TeamMemberPageQuery, useUserRestoreMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { DrawerContext } from "../../../components/Partials/Drawer/providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { Tab, TabGroup } from "../../../components/Tabs/MuiTabs"
import { H2 } from "../../../components/deprecated/H2"
import { ConfirmArchiveUserModal } from "../../../components/Modals/components/ConfirmArchiveUserModal"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { InjuryReportModal } from "../../../components/Modals/components/InjuryReportModal"

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/team/[_id]/details",
  },
  {
    label: "Assets",
    href: "/team/[_id]/assets",
  },
  {
    label: "Time Cards",
    href: "/team/[_id]/time-cards",
  },
  {
    label: "Documents",
    href: "/team/[_id]/documents",
  },
  {
    label: "Offline Events",
    href: "/team/[_id]/offline-events",
    requiredFeatureFlag: "Show Offline Events for User",
  },
]

type Props = {
  children: ReactNode
  userId: string
  withTabs?: boolean
}

type UserLayoutContextType = {
  user: TeamMemberPageQuery["user"]
}

export const UserLayoutContext = createContext<UserLayoutContextType>({
  user: {} as TeamMemberPageQuery["user"],
})

const TeamMemberPageQueryDocument = graphql(`
  query TeamMemberPage($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      imageUrl
      jobTitle
      assetCount
      archived
      isClockedIn
      invitationPending
      currentTaskId
      currentProjectId
    }
  }
`)

export const TeamMemberPageContent: FC<Props> = ({ children, userId, withTabs = true }) => {
  const { setLabelSubstitutions, setIsLoading } = useContext(BreadcrumbsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ fetching, data }] = useQuery({ query: TeamMemberPageQueryDocument, variables: { id: userId } })
  const [, restoreUserMutation] = useUserRestoreMutation()
  const archiveUserModalProps = useModalProps("Archive User")
  const injuryReportModalProps = useModalProps("Injury Report")

  const user = data?.user
  useEffect(() => {
    setIsLoading(fetching)
    setLabelSubstitutions([
      {
        original: userId,
        replacement: `${user?.firstName} ${user?.lastName}`,
      },
    ])
  }, [fetching, setIsLoading, setLabelSubstitutions, user?.firstName, user?.lastName, userId])

  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("user:update")) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () => pushDrawer(<UserEditDrawer userId={userId} />, "UserEdit"),
        Icon: BiEdit,
      },
    ])
  }

  if (hasPermissionTo("user:update") && !user?.archived) {
    menuItems.push([
      {
        value: "Report Injury",
        onClick: () => injuryReportModalProps.handleOpenModal(),
        color: "orange",
        Icon: BiHealth,
      },
    ])
  }

  if (hasPermissionTo("user:archive") && !user?.archived) {
    menuItems.push([
      {
        value: "Archive",
        onClick: () => {
          archiveUserModalProps.handleOpenModal()
        },
        color: "red",
        Icon: BiTrash,
      },
    ])
  }

  if (hasPermissionTo("user:archive") && user?.archived) {
    menuItems.push([
      {
        value: "Unarchive",
        onClick: () => {
          restoreUserMutation({ id: user.id }).then((result) =>
            result.error ? errorSnack("Error unarchiving user") : successSnack("User unarchived successfully")
          )
        },
        color: "red",
        Icon: BiTrash,
      },
    ])
  }

  if (!user) return <UserSkeleton />

  return (
    <UserLayoutContext.Provider value={{ user }}>
      <div>
        {fetching && <UserSkeleton />}
        {!fetching && user && (
          <>
            <div className="flex mb-4 gap-x-4">
              <MonogramRing
                user={user}
                textSize="text-2xl"
                ringWidth="w-20"
                ringHeight="h-20"
                ringBorder="border-4"
                width="w-16"
                height="h-16"
              />
              <div className="md:ml-4 flex flex-col justify-center gap-2">
                <div className="flex gap-x-2">
                  <H2 className="text-gray-800 my-0">{user.firstName + " " + user.lastName}</H2>
                  <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
                    <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
                  </QuickMenuMui>
                </div>
                <div className="flex gap-0.5 text-gray-600 items-center">
                  <BiLabel className="size-4" />
                  <span>{user.jobTitle}</span>
                  <RenderIf permissionsInclude="user:create">
                    {user.invitationPending && (
                      <span className="ml-[10px] text-orange-600 flex items-center justify-center gap-0.5">
                        <BiMailSend className="size-4" />
                        <span>Pending</span>
                      </span>
                    )}
                  </RenderIf>
                </div>
              </div>
            </div>
            {withTabs && <TabGroup tabs={tabs} />}
            {children}
          </>
        )}
      </div>
      {archiveUserModalProps.isOpen && <ConfirmArchiveUserModal user={user} modalProps={archiveUserModalProps} />}
      {injuryReportModalProps.isOpen && <InjuryReportModal modalProps={injuryReportModalProps} user={user} />}
    </UserLayoutContext.Provider>
  )
}

const UserPageLayout: NextPage<Props> = ({ children, userId }) => {
  return (
    <Layout>
      <TeamMemberPageContent userId={userId}>{children}</TeamMemberPageContent>
    </Layout>
  )
}

export default UserPageLayout
