import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { BiLayer, BiListUl, BiUserCircle } from "react-icons/bi"
import { AssetAssignableType, AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { AssetImage } from "../../../../AssetImage"
import { Monogram } from "../../../../Monogram"
import { errorSnack } from "../../../../Notistack/ThemedSnackbars"
import { QuickMenu } from "../../../../QuickMenu"
import { MenuItem } from "../../../../QuickMenuMui"
import { TaskDrawer } from "../../../Drawer/components/Task/TaskDrawer"
import { DrawerContext } from "../../../Drawer/providers/DrawerProvider"

export const QuantizedAssigneeCell: FC<{ assetGroup: AssetGroup }> = ({ assetGroup }) => {
  const router = useRouter()
  const { push: pushDrawer } = useContext(DrawerContext)

  const assigneeQuickMenuItems: MenuItem[][] =
    assetGroup.assignableType === "User"
      ? [
          [
            {
              value: "View user",
              Icon: BiUserCircle,
              onClick: () => {
                router.push(`/team/${assetGroup.assignedUserId}`)
              },
            },
          ],
        ]
      : [
          [
            {
              value: "View project",
              Icon: BiLayer,
              onClick: () => {
                router.push(`/projects/${assetGroup.assignedTask?.projectId}`)
              },
            },
            {
              value: "View task",
              Icon: BiListUl,
              onClick: () => {
                if (assetGroup.assignedTask?.id) {
                  pushDrawer(<TaskDrawer taskId={assetGroup.assignedTask?.id} />, "Task")
                } else {
                  errorSnack("Assigned task not found")
                }
              },
            },
          ],
        ]

  const isInventoryAsset =
    assetGroup.assignableType === AssetAssignableType.Asset &&
    assetGroup?.assignedAsset &&
    assetGroup.assignableId === assetGroup.groupParent.id

  return (
    <>
      {isInventoryAsset ? (
        <div className="w-full p-2 bg-gray-100 rounded hover:rounded transition-all col-span-12 mt-2 md:mt-0 md:col-span-2">
          <div className="w-full truncate leading-tight flex gap-2 items-center">
            <AssetImage
              width="w-6"
              height="h-6"
              padding="1"
              placeholderImageTextColor="white"
              placeholderImageBgColor="gray-800"
              asset={assetGroup.assignedAsset!}
              isGroupedAsset
              defaultImage="package"
            />
            Inventory
          </div>
        </div>
      ) : (
        <QuickMenu
          items={assigneeQuickMenuItems}
          className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
          menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
          disabled={assetGroup.assignableType === AssetAssignableType.User && assetGroup.assignedUser?.archived}
        >
          <div className="w-full truncate leading-tight flex gap-2 items-center">
            {assetGroup.assignableType === AssetAssignableType.User && assetGroup.assignedUser && (
              <>
                <Monogram height="h-6" width="w-6" user={assetGroup.assignedUser} />
                {assetGroup.assignedUser?.firstName} {assetGroup.assignedUser?.lastName}
              </>
            )}
            {assetGroup.assignableType === AssetAssignableType.Asset && assetGroup?.assignedAsset && (
              <>
                <AssetImage
                  width="w-6"
                  height="h-6"
                  padding="1"
                  placeholderImageTextColor="white"
                  placeholderImageBgColor="gray-800"
                  asset={assetGroup.assignedAsset}
                  isGroupedAsset
                />
                {assetGroup.assignedAsset?.name}
              </>
            )}
            {assetGroup.assignableType === AssetAssignableType.Task && assetGroup.assignedTask && (
              <>
                <BiListUl className="size-6 text-white bg-gray-800 rounded p-1" />
                {assetGroup.assignedTask?.name}
              </>
            )}
          </div>
        </QuickMenu>
      )}
    </>
  )
}
