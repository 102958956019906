import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { AssetHistoryReportQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { InspectionItemResult } from "./InspectionItemResult"

type InspectionReport = AssetHistoryReportQuery["assetReport"]["inspectionReport"]

export const InspectionDetails: FC<{ inspection?: InspectionReport }> = ({ inspection }) => {
  if (!inspection?.length || !inspection[0]) {
    return null
  }

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "photoLabel",
      headerName: "Label",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Result",
      flex: 1,
      renderCell: (params) => <InspectionItemResult item={params.row} />,
    },
    {
      field: "photoUrls",
      headerName: "Photo",
      flex: 1,
      renderCell: (params) =>
        params.row?.photoUrls?.map((url: string, i: number) => (
          <img
            className="rounded object-cover w-8 aspect-1"
            alt="inspection item evidence"
            key={params.row?.fileIds[i]}
            src={url}
          />
        )),
    },
  ]

  return <DataGridPro columns={columns} rows={inspection} hideFooter sx={{ border: "none" }} />
}
