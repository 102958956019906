import { FC, useState } from "react"
import { TaskListItem } from "../../../graphql/generated/gql/graphql"
import { ModalProps } from "../hooks/useModalProps"
import { PickPlus } from "../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../deprecated"
import DeprecatedModal from "../../deprecated/StandardModal"
import { ModalBody } from "./Elements/ModalBody"
import { ModalFooter } from "./Elements/ModalFooter"

type Props = {
  onConfirm: () => void
  taskListItem: PickPlus<TaskListItem, "taskId" | "name">
  modalProps: ModalProps
}

export const ConfirmCompleteTaskModal: FC<Props> = ({ modalProps, onConfirm, taskListItem }) => {
  const [wasClicked, setWasClicked] = useState(false)
  return (
    <DeprecatedModal {...modalProps}>
      {modalProps.isOpen && (
        <>
          <ModalBody className="lg:mb-12 grid gap-8">
            <p>Are you sure you want to complete task &quot;{taskListItem.name}&quot;?</p>
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={modalProps.handleCloseModal}>
              Cancel
            </ButtonHollow>
            <ButtonFilled
              type="button"
              disabled={wasClicked}
              onClick={() => {
                setWasClicked(true)
                onConfirm?.()
                modalProps.handleCloseModal()
              }}
            >
              Complete
            </ButtonFilled>
          </ModalFooter>
        </>
      )}
    </DeprecatedModal>
  )
}
