import { useRouter } from "next/router"
import { FC } from "react"
import { BiLayer } from "react-icons/bi"
import { AssetAssignableType, AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { Project } from "../../../../../graphql/generated/gql/graphql"
import { PickPlus } from "../../../../../types/helpers"
import { IconLabel } from "../../../../IconLabel"
import { QuickMenu } from "../../../../QuickMenu"
import { MenuItem } from "../../../../QuickMenuMui"

export const QuantizedProjectCell: FC<{ assetGroup: AssetGroup }> = ({ assetGroup }) => {
  const router = useRouter()

  let project: PickPlus<Project, "id" | "name"> | undefined = assetGroup.assignedUser?.currentProject

  if (assetGroup.assignableType === AssetAssignableType.Task) {
    project = assetGroup?.assignedTask?.project
  }

  if (assetGroup.groupParent.inferredOwner) {
    project = assetGroup.groupParent.inferredOwner?.currentProject
  }

  const projectQuickMenuItems: MenuItem[][] = [
    [
      {
        value: "View project",
        Icon: BiLayer,
        onClick: () => {
          router.push(`/projects/${project?.id}`)
        },
      },
    ],
  ]

  return (
    <QuickMenu
      items={projectQuickMenuItems}
      className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
      disabled={
        !project?.name || (assetGroup.assignableType === AssetAssignableType.User && assetGroup.assignedUser?.archived)
      }
    >
      <IconLabel icon={BiLayer} label={project?.name || "N/A"} />
    </QuickMenu>
  )
}
