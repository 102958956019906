import { format } from "date-fns"
import { FC } from "react"
import { BiInfoCircle } from "react-icons/bi"
import { Task, TaskGroup } from "../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../types/helpers"

type Props = {
  task?: PickPlus<Task, "isComplete">
  taskGroup?: Pick<TaskGroup, "completedTaskCount" | "taskCount"> | null
}

export const CompletedTaskBanner: FC<Props> = ({ task, taskGroup: _taskGroup }) => {
  // @todo: we need to update this component to account for task groups
  // currently there's not a way to know *when* a task group was completed
  // this will need to be the last completed task in the group if all tasks are complete

  if (!task?.isComplete) return null

  const completedAt = new Date(task.isComplete)

  return (
    <div className="flex mb-10 p-4 rounded-lg w-full bg-blue-50 text-blue-600">
      <BiInfoCircle className="size-6 mr-2" />
      <div>
        This task was completed on&nbsp;
        <span className="font-semibold">{format(completedAt, "P 'at' p")}</span>
      </div>
    </div>
  )
}
