import { Typography } from "@mui/material"
import { format, parseISO } from "date-fns"
import { FC } from "react"
import { useQuery } from "urql"
import { User, UserTimeCardsDayQuery } from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { useHandleError } from "../../../../../hooks/useHandleError"
import { PickPlus } from "../../../../../types/helpers"
import { DrawerBody } from "../../../Drawer/components/Elements/DrawerBody"
import { DrawerHeader } from "../../../Drawer/components/Elements/DrawerHeader"
import { TimeEntryDayRow } from "../DataGrid/TimeEntryDaysDataGrid"
import { PhotosAndSignatures } from "./PhotosAndSignatures"
import { Summary } from "./Summary"
import { TimeEntryTable } from "./TimeEntryTable"

const UserTimeCardDayDocument = graphql(`
  query UserTimeCardsDay($userId: String!, $day: String!) {
    timeEntryDay(userId: $userId, day: $day) {
      id
      totalPaidTimeInSeconds
      totalEditedTimeInSeconds
      timeEntryWrappers {
        id
        isBreak
        splitStartAt
        splitEndAt
        totalEditedTimeInSeconds
        actors {
          id
          firstName
          lastName
          imageUrl
        }
        timeEntry {
          id
          durationInSeconds
          endAt
          signInPhotoUrl
          signOutPhotoUrl
          projectId
          taskId
          startAt
          isUnpaid
          isBreak
          project {
            name
          }
          task {
            name
          }
          evidenceType {
            start {
              imageUrl
            }
            end {
              imageUrl
              signatureUrl
            }
          }
        }
        history {
          id
          timestamp
          action
          totalEditedTimeInSeconds
          changes {
            startAt
            endAt
          }
          value {
            startAt
            endAt
          }
          actor {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`)

type Props = {
  day: TimeEntryDayRow
  user: PickPlus<User, "id" | "taskId" | "currentTaskId">
}

export type Day = UserTimeCardsDayQuery["timeEntryDay"]

export const TimeEntryDayDrawer: FC<Props> = (props) => {
  const [{ data, error, fetching }] = useQuery({
    query: UserTimeCardDayDocument,
    variables: {
      day: format(parseISO(props.day.id), "yyyy-MM-dd"),
      userId: props.user.id,
    },
  })

  useHandleError(error, "There was a problem getting time card history")

  const loading = fetching && !data?.timeEntryDay

  const day = data?.timeEntryDay

  return (
    <>
      <DrawerHeader />
      <DrawerBody>
        <Typography variant="h2">{format(parseISO(props.day.id), "EEE, MMM do yyyy")}</Typography>
        <Summary day={day} loading={loading} />
        <TimeEntryTable day={day} loading={loading} user={props.user} />
        <PhotosAndSignatures day={day} loading={loading} />
      </DrawerBody>
    </>
  )
}
