import { DrawerLink } from "../Elements/DrawerLink"
import { TaskDrawer } from "./TaskDrawer"

export const TaskDrawerLink = ({ name, projectId, taskId }: { name: string; projectId: string; taskId: string }) => (
  <DrawerLink
    className="underline text-blue-600 hover:text-blue-700"
    component={<TaskDrawer taskId={taskId} />}
    drawerName="Task"
    href={`/projects/${projectId}/${taskId}`}
  >
    {name}
  </DrawerLink>
)
