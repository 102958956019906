import { Box, IconButton, colors } from "@mui/material"
import { FormikValues } from "formik"
import { FC, useContext } from "react"
import {
  BiCheckSquare,
  BiDownArrowAlt,
  BiEdit,
  BiEditAlt,
  BiListOl,
  BiListUl,
  BiMenuAltRight,
  BiNote,
  BiPlus,
  BiSquareRounded,
  BiTrash,
  BiUpArrowAlt,
} from "react-icons/bi"
import { useMutation } from "urql"
import {
  TaskListItem,
  useMarkTaskAsIncompleteMutation,
  useTaskEditMutation,
  useUpdateTaskGroupMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { getTaskListItemSortOrder } from "../../../../helpers/sorts/getTaskListItemSortOrder"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext, TaskListStateContext } from "../../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../../providers/TaskListProvider/taskListReducer"
import { HandleDeleteFunc, HandleReportFunc } from "../../../../types/Task"
import { AddNoteModal } from "../../../Modals/components/AddNoteModal"
import { useModalProps } from "../../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { ProjectUnitReportForm } from "../../../ProjectUnitReportForm"
import { QuickMenuDotsHorizontal } from "../../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../../QuickMenuMui"
import { RenderIf } from "../../../RenderIf"
import { DrawerBody } from "../../Drawer/components/Elements/DrawerBody"
import { DrawerHeader } from "../../Drawer/components/Elements/DrawerHeader"
import { TaskDrawer } from "../../Drawer/components/Task/TaskDrawer"
import { TaskGroupDrawer } from "../../Drawer/components/Task/TaskGroupDrawer"
import { TaskReorderDrawer } from "../../Drawer/components/Task/TaskReorderDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"
import { CreateOrEditTaskForm } from "../../Tasks/CreateOrEditTaskForm"
import { ProjectExpectation } from "../../../../types/ProjectExpectation"
import { useReassignAndCloseTaskWorkflow } from "../../../../hooks/useReassignAndCloseTaskWorkflow"

type Props = {
  taskId: string | undefined
  taskName: string | undefined
  assetCount?: number
  userCount?: number
  projectId: string
  isTaskComplete: boolean
  isTaskGroup: boolean
  isDefault: boolean
  sortOrder: number | undefined
  handleReport: HandleReportFunc
  handleDelete: HandleDeleteFunc
  onSuccess?: () => void
  groupId?: string
  taskListItem?: TaskListItem
  projectExpectation?: ProjectExpectation
}

const TaskItemUpdateTaskSortOrderMutation = graphql(`
  mutation UpdateTaskSortOrder($type: String!, $taskId: String!, $sortOrder: Int!) {
    updateTaskSortOrder(type: $type, taskId: $taskId, sortOrder: $sortOrder) {
      id
      sortOrder
    }
  }
`)

export const TaskRowActions: FC<Props> = ({
  handleDelete,
  handleReport,
  isDefault,
  isTaskComplete,
  isTaskGroup = false,
  onSuccess = () => {},
  projectId,
  sortOrder,
  groupId,
  taskId,
  taskName,
  taskListItem = null,
  projectExpectation = null,
}) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const dispatch = useContext(TaskListDispatchContext)
  const { taskList } = useContext(TaskListStateContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const sameLevelTasks = (
    groupId ? taskList.filter((task) => task.groupId === groupId) : taskList.filter((task) => !task.groupId)
  )
    .map((task) => ({
      ...task,
      sortOrder: getTaskListItemSortOrder(task),
    }))
    .sort((taskA, taskB) => {
      return (taskA.sortOrder || 0) - (taskB.sortOrder || 0)
    })
  const subTasks = taskList.filter((task) => task.groupId === taskId)
  const hasSubTasks = subTasks.length > 0
  const task = taskList.find((t) => t.taskId === taskId)

  const incompleteSameLevelTasks = sameLevelTasks.filter((t) => !t?.isComplete)

  const taskIndex = incompleteSameLevelTasks.findIndex((t) => t.taskId === taskId || t.taskGroupId === taskId)
  const nextSortOrder = incompleteSameLevelTasks.at(taskIndex + 1)?.sortOrder || 0
  const previousSortOrder = incompleteSameLevelTasks.at(taskIndex - 1)?.sortOrder || 0
  const highestSortOrder = incompleteSameLevelTasks.length > 0 ? incompleteSameLevelTasks.at(-1)?.sortOrder || 0 : 0
  const isComplete = task?.isComplete || false

  const { handleCloseModal, ...otherModalProps } = useModalProps("Add Note")

  const [, updateOneTask] = useTaskEditMutation()
  const [, updateTaskGroup] = useUpdateTaskGroupMutation()
  const modalProps = {
    ...otherModalProps,
    handleCloseModal,
  }
  const [_, updateTaskSortOrderMutation] = useMutation(TaskItemUpdateTaskSortOrderMutation)

  const [, markTaskIncompleteMutation] = useMarkTaskAsIncompleteMutation()
  const markTaskCompleteWorkflow = useReassignAndCloseTaskWorkflow()

  const href = isTaskGroup
    ? `/projects/${projectId}/group/${taskId}/details`
    : `/projects/${projectId}/${taskId}/details`

  const handleSubmit = async (values: FormikValues) => {
    if (isTaskGroup) {
      await updateTaskGroup({
        id: taskId!,
        notesToAdd: [
          {
            label: values.label,
            content: values.content,
          },
        ],
      }).then((result) => {
        result.error ? errorSnack("Error adding note") : successSnack("Note added successfully")
      })
    } else {
      await updateOneTask({
        id: taskId!,
        name: taskName!,
        projectId: projectId,
        metadataToAdd: [
          {
            label: values.label,
            content: values.content,
          },
        ],
      }).then((result) => {
        result.error ? errorSnack("Error adding note") : successSnack("Note added successfully")
      })
    }
  }

  const handleChangeSortOrder = async (type: "task" | "taskGroup", taskIdToReorder: string, newSortOrder: number) => {
    updateTaskSortOrderMutation({ type, taskId: taskIdToReorder, sortOrder: newSortOrder }).then(() => {
      dispatch({ type: TASK_LIST_ACTION_TYPES.refreshTaskList })
    })
  }

  const DrawerComponent = () => {
    if (isTaskGroup) {
      return (
        <div className="p-8">
          <TaskGroupDrawer taskGroupId={taskId ?? ""} projectId={projectId} onSuccess={onSuccess} />
        </div>
      )
    }

    return <TaskDrawer taskId={taskId!} />
  }

  const CreateNewSubtask = () => (
    <RenderIf permissionsInclude="task:create">
      <div>
        <DrawerHeader href={`projects/${projectId}/tasks/create`} />
        <DrawerBody>
          <CreateOrEditTaskForm task={{ projectId }} taskGroupId={taskId} taskType="sub-task" />
        </DrawerBody>
      </div>
    </RenderIf>
  )

  const menuItems: MenuItem[][] = [
    [
      {
        value: "View task",
        Icon: BiListUl,
        drawer: {
          href,
          component: <DrawerComponent />,
          name: isTaskGroup ? "TaskGroup" : "Task",
        },
      },
    ],
  ]

  const toggleTaskCompletion = (taskIdUpdated: string, groupIdUpdated: string, newCompletionState: boolean) => {
    // ToggleCompletionButton has two potential parent components:
    // 1. tasks.tsx, which has TaskListDispatchContext as a provider. That provider contains 'dispatch'.
    // 2. _layout.tsx, which does not have the same provider, and as a result, does not have 'dispatch'.
    // This is a temporary solution until we can do some refactoring to make this more consistent.
    dispatch?.({
      type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
      payload: { taskId: taskIdUpdated, groupId: groupIdUpdated, isComplete: newCompletionState },
    })
  }

  if (!isDefault && taskListItem && projectExpectation) {
    if (isComplete) {
      menuItems.unshift([
        {
          value: "Reopen task",
          onClick: () => {
            markTaskIncompleteMutation({ id: taskId! }).then((result) => {
              if (result.error) {
                console.error(result.error)
                errorSnack(`${name} could not be reopened. Please try again.`)
              } else {
                toggleTaskCompletion(taskId!, groupId!, false)
              }
            })
          },
          Icon: BiSquareRounded,
        },
      ])
    } else {
      menuItems.unshift([
        {
          value: "Complete task",
          onClick: () => {
            markTaskCompleteWorkflow({
              taskListItem: { ...taskListItem, project: projectExpectation },
              onSuccessCallback: () => {
                toggleTaskCompletion(taskId!, groupId!, true)
                successSnack("Success!")
              },
              onErrorCallback: () => {
                errorSnack(`${name} completion error; please try again`)
              },
            })
          },
          Icon: BiCheckSquare,
        },
      ])
    }
  }

  if (!isDefault && hasPermissionTo("task:update") && taskId) {
    const taskType = task?.groupId ? "sub-task" : isTaskGroup ? "summary-task" : "task"
    const editTaskDrawer = (
      <RenderIf permissionsInclude="task:create">
        <DrawerHeader href={`projects/${projectId}/tasks/create`} />
        <DrawerBody>
          <CreateOrEditTaskForm
            task={{ id: taskId, projectId }}
            taskGroupId={isTaskGroup ? taskId : undefined}
            taskType={taskType}
          />
        </DrawerBody>
      </RenderIf>
    )

    menuItems[0].push(
      {
        value: "Edit",
        onClick: () => pushDrawer(editTaskDrawer, "TaskEdit"),
        Icon: BiEdit,
      },
      {
        value: "Add note",
        onClick: () => {
          modalProps.handleOpenModal()
        },
        Icon: BiNote,
      }
    )
  }

  if (!groupId && isTaskGroup) {
    const item: MenuItem = {
      value: "Add sub-task",
      Icon: BiPlus,
      drawer: {
        href,
        component: <CreateNewSubtask />,
        name: "TaskCreate",
      },
    }

    menuItems[0].push(item)
  }

  // This array is used to store the items that should be shown in the quick menu
  // We store them in a temp array so that we can add the reorder options to the end, creating a divider between the groups
  const tempItems: MenuItem[] = []

  if (!isComplete) {
    // don't show up/down if no sort order is set
    if (!isDefault && typeof sortOrder !== "undefined") {
      // if this is the first item in the list then don't show the up arrow
      if (sortOrder > 0) {
        tempItems.push({
          value: "Move up",
          Icon: BiUpArrowAlt,
          onClick: () => taskId && handleChangeSortOrder(isTaskGroup ? "taskGroup" : "task", taskId, previousSortOrder),
        })
      }

      // if this is the last item in the list then don't show the down arrow
      if (highestSortOrder > sortOrder) {
        tempItems.push({
          value: "Move down",
          Icon: BiDownArrowAlt,
          onClick: () => taskId && handleChangeSortOrder(isTaskGroup ? "taskGroup" : "task", taskId, nextSortOrder),
        })
      }
    }

    // add reorder option if this is a top level task
    if (!groupId) {
      tempItems.push({
        value: "Reorder",
        Icon: BiListOl,
        drawer: {
          href,
          component: <TaskReorderDrawer projectId={projectId} />,
          name: "TaskReorder",
        },
      })
    }

    // add reorder sub-tasks option if this is a grouped task or has sub-tasks
    if (!hasSubTasks || groupId) {
      tempItems.push({
        value: "Reorder sub-tasks",
        Icon: BiMenuAltRight,
        drawer: {
          href,
          component: <TaskReorderDrawer projectId={projectId} taskGroupId={groupId} />,
          name: "TaskReorder",
        },
      })
    }

    if (tempItems.length > 0) {
      menuItems.push(tempItems)
    }
  }

  // can't delete the default task
  if (!isDefault) {
    menuItems.push([
      {
        value: "Delete",
        onClick: () => handleDelete(taskId!, isTaskGroup),
        Icon: BiTrash,
        color: "red",
        isDisabled: isTaskComplete,
      },
    ])
  }

  const isDisabled = !hasPermissionTo("task:report", { projectId: projectId }) || isTaskComplete || isDefault

  return (
    <div>
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <IconButton
          className="size-[32px] bg-gray-100 hover:bg-gray-200"
          color="secondary"
          disabled={isDisabled}
          sx={{
            fontSize: "14px",
            borderRadius: "4px",
            backgroundColor: isDisabled ? `${colors.grey[200]} !important` : "",
          }}
          onClick={() => {
            if (isTaskGroup) {
              pushDrawer(<ProjectUnitReportForm projectId={projectId} taskId={taskId} />, "ProjectUnitReport")
            } else {
              handleReport(taskId)
            }
          }}
          aria-label={`Report for ${taskName}`}
        >
          <BiEditAlt size="18px" className="text-gray-800" />
        </IconButton>

        <Box className="ml-2">
          <QuickMenuMui items={menuItems} disabled={!hasPermissionTo("task:update")} buttonShape="round">
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        </Box>
      </Box>

      {modalProps.isOpen && (
        <AddNoteModal
          modalProps={modalProps}
          parentName={taskName || "Task"}
          onSave={async (values: FormikValues) => {
            await handleSubmit(values)
          }}
        />
      )}
    </div>
  )
}
