import { InputAdornment, OutlinedInput } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"

type NumberInputWithAdornmentProps = {
  name: string
  adornmentText: string
}

export const NumberInputWithAdornment: FC<NumberInputWithAdornmentProps> = ({ name, adornmentText }) => {
  const [{ value }, _meta, { setValue }] = useField(name)

  return (
    <OutlinedInput
      className="w-2/5"
      type="number"
      value={value ?? 0}
      inputProps={{
        step: 1,
      }}
      endAdornment={<InputAdornment position="end">{adornmentText}</InputAdornment>}
      onChange={(event) => setValue(Number(event.target.value))}
    />
  )
}
