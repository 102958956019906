import { NextPage } from "next"
import Error from "next/error"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import { PageTitle } from "../../../components/PageTitle"
import { BasicInfo } from "../../../components/Partials/User/BasicInfo"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { UserQuickActions } from "../../../components/Partials/User/UserQuickActions"
import { RenderIf } from "../../../components/RenderIf"
import { UserAssignmentsTable } from "../../../components/UserAssignmentsTable"
import { graphql } from "../../../graphql/generated/gql"
import { getFullName } from "../../../helpers/strings/getFullName"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import UserPageLayout from "./_layout"
import { useRouter } from "next/router"
import { UserDivisionsTable } from "../../../components/UserDivisionsTable"
import { DevelopmentFeatureFlag } from "../../../components/DevelopmentFeatureFlag"

type UserPageDetailsProps = {
  userId: string
}

const GetUserDetails = graphql(`
  query TeamDetailsPageGetUser($id: String!, $fetchPay: Boolean!) {
    user(id: $id) {
      id
      organizationId
      assetCount
      currentProjectId
      currentTaskId
      organizationId
      projectId
      taskId
      companyUserId
      assignedDivision {
        id
        name
      }
      divisionAccess {
        id
        name
      }

      archived
      email
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      secondsClockedSinceOrgMidnight

      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)

      currentProject {
        id
        name
      }

      currentTask {
        id
        name
      }

      assignments {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          code
          imageUrl
          name
        }
        task {
          id
          name
        }
        taskId
      }

      billingClassification {
        id
        name
      }

      workersCompCode {
        id
        name
        ncciCode
      }

      roles {
        id
        description
        name
      }
    }
  }
`)

export const UserPageDetails: FC<UserPageDetailsProps> = ({ userId }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data, fetching, error }, refetch] = useQuery({
    query: GetUserDetails,
    variables: { id: userId, fetchPay: hasPermissionTo("payRate:read") },
    pause: !userId,
  })

  const user = data?.user

  useHandleError(error, "Unable to retrieve user details")

  if (!user) {
    return <UserSkeleton />
  }

  const isAdmin = user.roles.some((role) => role.name === "Admin" || role.name === "Manager")
  return (
    <>
      <PageTitle title={!user?.firstName ? "User details" : `${getFullName(user)} details`} />
      <div className="grid gap-12">
        <div>{fetching ? <div>Loading...</div> : <UserQuickActions user={user} refetchUser={refetch} />}</div>

        <div>
          <div className="flex flex-col gap-9">
            <BasicInfo user={user} />
            <DevelopmentFeatureFlag name="Divisions">
              <RenderIf permissionsInclude="divisions:update">
                {isAdmin && (
                  <UserDivisionsTable
                    assignedDivision={user?.assignedDivision}
                    divisionAccess={user?.divisionAccess}
                    title="User Division Access"
                    user={user}
                  />
                )}
              </RenderIf>
            </DevelopmentFeatureFlag>
            <UserAssignmentsTable
              assignments={user?.assignments || []}
              preassignments={{ userId }}
              refetch={refetch}
              title="User Project & Task Access"
              groupBy="project"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const UserPage: NextPage = () => {
  const { query } = useRouter()
  const userId = query._id as string

  return (
    <RenderIf permissionsInclude="user:list" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageDetails userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

export default UserPage
