import { GridRenderCellParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { clsx } from "clsx"
import { FC } from "react"
import { BiChevronsUp, BiListUl } from "react-icons/bi"
import { taskProductionPercentageRate } from "../../../helpers/progress/taskProductionRate"
import { titleCase } from "../../../helpers/strings/titleCase"
import { NAButton } from "../../NAbutton"
import { StackedHorizontalBarChart } from "../../StackedHorizontalBarChart"
import { ProductionRateSpan, getProductionRateArrowClassName } from "../../Widgets/SummaryWidgets/ProductionRateWidget"
import {
  UNITS_ACTIVE_COLORS,
  UNITS_INACTIVE_COLORS,
  createSource,
} from "../../Widgets/SummaryWidgets/WidgetSourceColors"
import { TaskDetails } from "./Sections/ProjectAndTaskSummaryUnitsGraphAndTable"
import { defaultColumnProps } from "./Sections/ProjectSummaryTaskTable"
import { DateRangeFilterString } from "./types"

type UnitDetailPanelProps = {
  tasks: TaskDetails[]
  dateRangeType: DateRangeFilterString
}

export const UnitTableDetailPanel: FC<UnitDetailPanelProps> = ({ tasks, dateRangeType }) => {
  const columns = [
    {
      field: "taskName",
      headerName: "Task",
      flex: 1,
      renderCell: TaskNameCell,
    },
    { field: "unitTotal", headerName: "Total", flex: 1 },
    {
      field: "unitProgress",
      headerName: "Progress",
      flex: 2,
      renderCell: ProgressCell,
    },
    {
      field: "unitRate",
      headerName: "Unit Rate",
      renderCell: UnitRateCell,
    },
  ]

  if (dateRangeType && dateRangeType !== "all-time") {
    columns.splice(1, 0, {
      field: "dateRangeType",
      flex: 0,
      headerName: titleCase(dateRangeType),
    })
  }

  type RowData = {
    id: string
    unitType: string
    unitTotal: string
    totalProgress: number
    recentProgress: number
    previousProgress: number
    currentProgress: number
  }

  const rows: RowData[] = tasks.map((task) => {
    const combinedProgress = task.previousProgress + task.recentProgress

    const {
      timeEntriesSumDurationInSeconds: clockedSeconds,
      recentProgress: unitsDelivered,
      targetQuantity,
      estimatedHours,
    } = task

    const unitRate = taskProductionPercentageRate({
      clockedSeconds,
      unitsDelivered,
      targetQuantity,
      estimatedHours,
    }).rate
    return {
      id: task.id,
      taskName: task.name,
      unitType: task.unitType,
      dateRangeType: `${task.recentProgress} ${task.unitType}`,
      unitTotal: `${combinedProgress} / ${task.targetQuantity} ${task.unitType}`,
      totalProgress: task.totalProgress,
      recentProgress: task.recentProgress,
      targetQuantity: task.targetQuantity,
      previousProgress: task.previousProgress,
      currentProgress: task.currentProgress,
      unitRate: unitRate,
    }
  })

  return (
    <>
      <DataGridPro
        autoHeight
        columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
        disableRowSelectionOnClick
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
        pageSizeOptions={[10]}
        hideFooter
        pagination
        rows={rows}
        style={{ margin: "1rem" }}
      />
    </>
  )
}

const TaskNameCell: React.FC<GridRenderCellParams> = ({ value }) => (
  <div className="flex">
    <BiListUl className="p-1 mr-2 size-6 rounded-md text-neutral-200 bg-gray-800" />
    <p>{value}</p>
  </div>
)

const ProgressCell: React.FC<GridRenderCellParams> = ({ row }) => {
  if (!row) return null
  const { previousProgress, recentProgress, currentProgress, targetQuantity } = row
  const sources =
    currentProgress === 0
      ? [createSource(previousProgress + recentProgress, UNITS_INACTIVE_COLORS)]
      : [
          createSource(previousProgress, UNITS_INACTIVE_COLORS),
          createSource(currentProgress, UNITS_ACTIVE_COLORS),
          createSource(recentProgress, UNITS_INACTIVE_COLORS),
        ]

  return (
    <StackedHorizontalBarChart
      sources={sources}
      expectedMaximum={targetQuantity}
      expectedMaximumClassName="w-60"
      onlyBar
    />
  )
}

const UnitRateCell: React.FC<GridRenderCellParams> = ({ row }) =>
  row.unitRate ? (
    <div className="flex gap-x-2 items-center">
      <BiChevronsUp className={clsx("size-6", getProductionRateArrowClassName(row.unitRate))} />
      <ProductionRateSpan rate={row.unitRate} />
    </div>
  ) : (
    <NAButton />
  )
