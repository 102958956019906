import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import { format } from "date-fns"
import { FC, useMemo, useState } from "react"
import { useQuery } from "urql"
import {
  File as CrewViewFile,
  TaskOrProjectSummaryImageSectionQuery,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { colors } from "../../../../helpers/styles/colors"
import { useModalProps } from "../../../Modals/hooks/useModalProps"
import { PreviewDocumentModal } from "../../Documents/DocumentModals"
import { formatDisplayDate } from "../helpers/formatDisplayDate"
import { GroupedObject, groupImageAndNotesByDate } from "../helpers/groupByDate"
import { DateRangeFilterString, SummarySectionProps } from "../types"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { PickPlus } from "../../../../types/helpers"

type PreviewableFile = PickPlus<CrewViewFile, "id" | "documentUrl" | "fileName" | "createdAt" | "contentType">

const SummaryImageSectionDocument = graphql(`
  query TaskOrProjectSummaryImageSection(
    $projectId: String!
    $taskId: String
    $rangeEnd: String!
    $rangeStart: String!
    $filter: TaskProgressEventFilter
  ) {
    taskProgressEvents(
      projectId: $projectId
      taskId: $taskId
      rangeEnd: $rangeEnd
      rangeStart: $rangeStart
      filter: $filter
    ) {
      id
      imageUrls
      createdAt
      reporter {
        id
        fullName
      }
      task {
        id
        name
      }
    }
  }
`)

export const SummaryImageSection: FC<SummarySectionProps> = ({
  taskId,
  projectId,
  rangeEnd,
  rangeStart,
  dateRangeType = "daily",
  filter,
}) => {
  const imageQueryVariables = {
    projectId,
    taskId,
    filter: filter === "images" ? "IMAGES" : undefined,
    rangeStart: format(rangeStart, "yyyy-MM-dd"),
    rangeEnd: format(rangeEnd, "yyyy-MM-dd"),
  }

  const [{ data }] = useQuery<TaskOrProjectSummaryImageSectionQuery>({
    query: SummaryImageSectionDocument,
    variables: imageQueryVariables,
    pause: !projectId,
  })

  const taskProgressEvents = data?.taskProgressEvents

  const groupedImages = useMemo(() => {
    return taskProgressEvents ? groupImageAndNotesByDate(taskProgressEvents, dateRangeType) : {}
  }, [taskProgressEvents, dateRangeType])

  return (
    <Box
      paddingY={3}
      paddingX={2}
      style={{
        border: `1px solid ${colors.slate[200]}`,
        borderRadius: "8px",
      }}
    >
      {Object.keys(groupedImages).length !== 0 ? (
        <div>
          <Typography variant="h4">Images</Typography>
          {Object.entries(groupedImages).map(([key, imagesForDate], i) => (
            <div className="mb-6" key={i}>
              <Typography variant="h5" fontSize={16}>
                {formatDisplayDate(key, dateRangeType)}
              </Typography>
              <ImageGrid images={imagesForDate} dateRangeType={dateRangeType} />
            </div>
          ))}
        </div>
      ) : (
        <>
          <Typography variant="h4">Images</Typography>
          <EmptyStateBlock label="No images" />
        </>
      )}
    </Box>
  )
}

const ImageGrid: FC<{ images: GroupedObject[]; dateRangeType: DateRangeFilterString }> = ({
  images,
  dateRangeType,
}) => {
  const showDate = dateRangeType === "monthly" || dateRangeType === "all-time"
  const previewDocumentModalProps = useModalProps("Document Preview")
  const [previewDocument, setPreviewDocument] = useState<PreviewableFile>()
  const [initialSlideIndex, setInitialSlideIndex] = useState(0)

  const onPreview = (imgObj: GroupedObject, i: number) => {
    const document: PreviewableFile = {
      id: imgObj.id,
      documentUrl: imgObj.documentUrl,
      fileName: imgObj.reporterName,
      createdAt: imgObj.uploaded,
      contentType: imgObj.type || "image",
    }

    setPreviewDocument(document)
    setInitialSlideIndex(i)
    previewDocumentModalProps.handleOpenModal()
  }

  return (
    <>
      <div className="flex overflow-x-scroll space-x-4">
        {images.map((imgObj, i) => (
          <div className="flex-none max-w-[144px]" key={i}>
            <img
              src={imgObj.documentUrl}
              alt={`Image ${i} uploaded by ${imgObj.reporterName} at ${imgObj.uploaded}`}
              loading="lazy"
              className="cursor-pointer size-36 object-cover object-center rounded-lg mb-2.5"
              onClick={() => onPreview(imgObj, i)}
            />
            <EllipsisText color={colors.gray[500]} variant="body2" component="p">
              {imgObj.reporterName}
            </EllipsisText>
            <EllipsisText color={colors.gray[500]} variant="body2" component="p">
              {showDate && `${format(imgObj.uploaded, "MMM do")} at `}
              {format(imgObj.uploaded, "h:mm a").toLowerCase()}
            </EllipsisText>
            {imgObj.taskName && (
              <EllipsisText color={colors.gray[500]} variant="body2" component="p">
                {imgObj.taskName}
              </EllipsisText>
            )}
          </div>
        ))}
      </div>
      <PreviewDocumentModal
        {...previewDocumentModalProps}
        document={previewDocument}
        images={images}
        initialSlideIndex={initialSlideIndex}
      />
    </>
  )
}

const EllipsisText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
` as typeof Typography // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
