import { Box, Container, IconButton, Slider } from "@mui/material"
import { useField } from "formik"
import { FC } from "react"
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi"

type SliderWithControlsProps = {
  name: string
}

export const SliderWithControls: FC<SliderWithControlsProps> = ({ name }) => {
  const [{ value: sliderValue }, { error, touched }, { setValue }] = useField(name)

  const handleChange = (newValue: string | string[] | number | number[]) => {
    // The slider component could potentially return an array of numbers
    // If it does, we want to use the first value in the array and if it's a string, convert it to a number
    const singleNumberValue = Array.isArray(newValue) ? Number(newValue[0]) : Number(newValue)
    setValue(singleNumberValue)
  }

  return (
    <Container className="bg-gray-100 rounded-3xl">
      <Box className="flex items-center">
        <IconButton className="text-gray-500 -ml-4" onClick={() => handleChange(sliderValue - 1)}>
          <BiMinusCircle className="size-5" />
        </IconButton>
        <Slider
          value={sliderValue ?? 0}
          valueLabelDisplay="auto"
          step={1}
          min={-14}
          max={14}
          onChange={(_event, value) => handleChange(value)}
        />
        <IconButton className="text-gray-500 -mr-4" onClick={() => handleChange(sliderValue + 1)}>
          <BiPlusCircle className="size-5" />
        </IconButton>
      </Box>
      {touched && error && <div className="text-red-600 text-sm">{error}</div>}
    </Container>
  )
}
