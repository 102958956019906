import { Box, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  UserAssignment,
  UserAssignmentCreateInput,
  useBulkUpdateUserAssignmentsMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { PickPlus } from "../types/helpers"
import { MuiMultiSelect } from "./Formik/MultiSelect/MuiMultiSelect"
import { Switch } from "./Formik/Switch"
import { UserSelect } from "./Formik/UserSelect"
import { MuiModal } from "./Modals/components/Elements/MuiModal"
import { ModalProps } from "./Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"

const placeholder = {
  id: "placeholder",
  value: "",
  disabled: true,
  searchableTextString: "",
}

export type UserAssignmentUpdateCandidate = PickPlus<
  UserAssignment,
  "id" | "projectId" | "taskId" | "userId" | "isCurrentAssignment"
>

const projectPlaceholder = { ...placeholder, label: "Loading project options..." }
const taskPlaceholder = { ...placeholder, label: "Please select a project first" }
const userPlaceholder = { ...placeholder, label: "Loading..." }

const CreateOrUpdateUserAssignmentFormQueryDocument = graphql(`
  query CreateOrUpdateUserAssignmentForm($after: String, $first: Int) {
    activeProjects {
      id
      name
      tasks {
        id
        name
      }
    }
    users(after: $after, first: $first) {
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          currentProjectId
          currentTaskId
          projectId
          taskId
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

type UserAssignmentFormValues = {
  id?: string
  allowFullProjectAccess: boolean
  projectId: [string]
  taskId?: string[] | null
  userIds: [string]
}

export const CreateOrUpdateUserAssignmentForm: FC<{
  contextUserAssignments?: UserAssignmentUpdateCandidate[] | null
  preassignments: {
    projectId?: string
    taskId?: string
    userId?: string | null
  }
  userAssignments?: UserAssignmentUpdateCandidate[] | null
  modalProps: ModalProps
  refetch?: () => void
}> = ({ contextUserAssignments, preassignments, userAssignments, modalProps, refetch }) => {
  const [{ data }] = useQuery({
    query: CreateOrUpdateUserAssignmentFormQueryDocument,
  })

  const [{ fetching: bulkUpdateUserAssignmentFetching }, bulkUpdateUserAssignmentMutation] =
    useBulkUpdateUserAssignmentsMutation()

  const handleSubmit = async (values: UserAssignmentFormValues) => {
    const { allowFullProjectAccess, userIds, projectId, taskId: selectedTaskIds } = values
    const userAssignmentValues = userAssignments ?? contextUserAssignments ?? []
    const [selectedProjectId] = projectId

    const selectedUserAssignments =
      userAssignmentValues?.filter((userAssignment) =>
        userIds.some((userId) => userId === userAssignment.userId && selectedProjectId === userAssignment.projectId)
      ) || []

    const getExistingTaskIdsForUser = (userId: string) =>
      selectedUserAssignments
        .filter((userAssignment) => userAssignment.taskId && userAssignment.userId === userId)
        .map((userAssignment) => userAssignment.taskId!) || []

    const fullProjectAssignmentExists = (userId: string) =>
      selectedUserAssignments.some((assignment) => userId === assignment.userId && assignment.taskId === "")

    const taskAssignmentsToCreate = userIds.reduce<UserAssignmentCreateInput[]>((assignments, userId) => {
      const existingTaskIds = getExistingTaskIdsForUser(userId)

      // create new task assignments if they don't already exist
      if (!allowFullProjectAccess) {
        const newTaskAssignments =
          selectedTaskIds
            ?.filter((taskId) => !existingTaskIds?.includes(taskId))
            ?.map((taskId) => ({
              projectId: selectedProjectId,
              taskId,
              userId,
            })) ?? []

        return [...assignments, ...newTaskAssignments]
      }

      // create a "full project" assignment if there isn't one already
      if (!fullProjectAssignmentExists(userId)) {
        return [
          ...assignments,
          {
            projectId: selectedProjectId,
            taskId: null,
            userId,
          },
        ]
      }

      return assignments
    }, [])

    const assignmentsToDelete =
      selectedUserAssignments?.filter((assignment) => {
        const notCurrentAssignment = !assignment.isCurrentAssignment
        if (allowFullProjectAccess) {
          // Case 1: Full project access will be considered
          // delete all assignments that are not the current assignment with a specific task assignment
          if (assignment.taskId && notCurrentAssignment) {
            return true
          }
        }

        // Case 2: Full project access is not considered
        // delete assignments that grant full project access if we are assigning to a task.
        if (!assignment.taskId && notCurrentAssignment) {
          return true
        }

        return false // assignment should not be deleted
      }) || []

    const result = await bulkUpdateUserAssignmentMutation({
      assignmentsToDelete: assignmentsToDelete.map((assignment) => assignment.id),
      assignmentsToCreate: taskAssignmentsToCreate,
    })

    if (result.error) {
      errorSnack("Error updating user assignment")
    } else {
      successSnack("Update successful")
      modalProps.handleCloseModal()
      if (refetch) refetch()
    }
  }

  const taskIds = userAssignments
    ?.filter((userAssignment) => userAssignment.taskId)
    .map((userAssignment) => userAssignment.taskId!)
  const userId = userAssignments?.at(0)?.userId || ""
  const allowFullProjectAccess = userAssignments?.some((userAssignment) => !userAssignment.taskId) || false
  const projectId = userAssignments?.at(0)?.projectId || ""

  const initialValues: UserAssignmentFormValues = userAssignments
    ? {
        projectId: [projectId],
        taskId: taskIds,
        userIds: [userId],
        allowFullProjectAccess,
      }
    : {
        projectId: preassignments.projectId ? [preassignments.projectId] : [projectPlaceholder.value],
        taskId: preassignments.taskId ? [preassignments.taskId] : [],
        userIds: preassignments.userId ? [preassignments.userId] : [userPlaceholder.value],
        allowFullProjectAccess: preassignments.taskId ? !preassignments.taskId : false,
      }

  const updateTasksBasedOnSelection = (
    value: string[],
    setFieldValue: FormikHelpers<UserAssignmentFormValues>["setFieldValue"],
    type: "projectId" | "userId"
  ) => {
    if (!contextUserAssignments) return

    // check if the user is already assigned to the project
    const existingAssignments =
      contextUserAssignments?.filter((userAssignment) => userAssignment[type] === value.at(0)) || []

    if (existingAssignments.length > 0) {
      setFieldValue(
        "allowFullProjectAccess",
        existingAssignments.some((userAssignment) => !userAssignment.taskId)
      )

      setFieldValue("taskId", existingAssignments?.map((ua) => ua.taskId)?.filter(Boolean))
    } else {
      // If we are already preassigned, we don't want to do anything to mutate that now
      if (preassignments.taskId) return
      setFieldValue("allowFullProjectAccess", true)
      setFieldValue("taskId", [])
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        userIds: Yup.array().of(Yup.string()).required().label("Users").min(1, "At least one user must be selected."),
        projectId: Yup.array().length(1).of(Yup.string()).required().label("Project"),
        allowFullProjectAccess: Yup.boolean(),
        taskId: Yup.array().when("allowFullProjectAccess", {
          is: false,
          then: (schema) => schema.min(1, "Must choose at least one task").of(Yup.string()).required().label("Task"),
        }),
      })}
      onSubmit={handleSubmit}
    >
      {({ submitForm, setFieldValue, resetForm, values }) => (
        <MuiModal
          {...modalProps}
          isOpen={modalProps.isOpen}
          isLoading={bulkUpdateUserAssignmentFetching}
          contentLabel={`${userAssignments ? "Edit" : "Add"} User Access`}
          submitForm={submitForm}
          submitButtonText={`${userAssignments ? "Save" : "Add"} Access`}
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
            if (refetch) refetch()
          }}
        >
          <Form>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", minHeight: "200px" }}>
              {preassignments.userId ? (
                <>
                  <Typography>To assign project and task access, please select a project first.</Typography>
                  <hr />
                </>
              ) : preassignments.taskId ? (
                <>
                  <Typography>To assign task access, please select team member(s).</Typography>
                  <hr />
                </>
              ) : (
                <>
                  <Typography>To assign project and task access, please select at least one user.</Typography>
                  <hr />
                </>
              )}

              {!preassignments.userId && (
                <UserSelect
                  name="userIds"
                  label="Team Member(s)"
                  onChangeNotify={(val) => updateTasksBasedOnSelection(val, setFieldValue, "userId")}
                  userExclusionFilter={(user) =>
                    !contextUserAssignments?.some((assignment) => assignment.userId === user.id)
                  }
                  multiple
                />
              )}

              {!(preassignments.projectId || preassignments.taskId) && (
                <MuiMultiSelect
                  containerClassName="flex-1"
                  label="Project"
                  name="projectId"
                  selectedLabel="Project"
                  options={
                    data?.activeProjects.map((p) => ({
                      id: p.id,
                      label: p.name,
                      searchableTextString: p.name,
                      value: p.id,
                    })) || [projectPlaceholder]
                  }
                  onChange={(value) => {
                    updateTasksBasedOnSelection(value, setFieldValue, "projectId")
                  }}
                  required
                  isSingleSelect
                  withErrorHandling
                />
              )}

              {!preassignments.taskId && <Switch name="allowFullProjectAccess" label="Allow full project access" />}

              {!(values.allowFullProjectAccess || preassignments.taskId) && (
                <MuiMultiSelect
                  containerClassName="flex-1"
                  label="Task"
                  name="taskId"
                  selectedLabel="Task"
                  options={
                    values.projectId[0] === projectPlaceholder.value
                      ? [taskPlaceholder]
                      : (data?.activeProjects || [])
                          .find((p) => p.id === values.projectId[0])
                          ?.tasks.map((t) => ({
                            disabled:
                              !!userAssignments?.find(
                                (userAssignment) => userAssignment.taskId === t.id && userAssignment.isCurrentAssignment
                              ) ||
                              !!contextUserAssignments?.find(
                                (userAssignment) => userAssignment.taskId === t.id && userAssignment.isCurrentAssignment
                              ),
                            id: t.id,
                            label: t.name,
                            searchableTextString: t.name,
                            value: t.id,
                          })) || []
                  }
                  withErrorHandling
                  withSelectAll
                />
              )}
            </Box>
          </Form>
        </MuiModal>
      )}
    </Formik>
  )
}
