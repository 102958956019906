import { clsx } from "clsx"
import { FC, memo, ReactNode } from "react"
import { TailwindIcon } from "../types/tailwind"

type Props = {
  Icon: TailwindIcon
  color: "blue" | "red"
  children: ReactNode
  className?: string
}

const InfoBannerComponent: FC<Props> = ({ Icon, color, children, className }) => {
  const colorClasses = {
    blue: "text-blue-600 bg-blue-50",
    red: "text-red-600 bg-red-50",
  }

  return (
    <div className={clsx(`flex rounded px-3 py-2 gap-2 mb-2`, colorClasses[color], className)}>
      <Icon className="size-6" />
      <p className="grow">{children}</p>
    </div>
  )
}

export const InfoBanner = memo(InfoBannerComponent, (prevProps, nextProps) => {
  const childrenMatch = prevProps.children === nextProps.children
  const classNameMatches = prevProps.className === nextProps.className

  return childrenMatch && classNameMatches
})
