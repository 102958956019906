import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import { Button } from "@mui/material"
import { BiCamera, BiRefresh } from "react-icons/bi"

type VideoProps = {
  onFail: (message: string) => void
  withFaceFrame?: boolean
  imageBlob: Blob | undefined
  setImageBlob: Dispatch<SetStateAction<Blob | undefined>>
  height?: number
  width?: number
}

const PHOTO_BOOTH_IMAGE_TYPE = "image/webp"

const FaceFrame: FC = () => {
  return (
    <div className="absolute h-3/5 w-1/2 inset-0 m-auto border-8 border-gray-100 border-dashed rounded-[50%] opacity-50" />
  )
}

export const VideoWithFrameGrab: FC<VideoProps> = ({
  onFail,
  withFaceFrame,
  imageBlob,
  setImageBlob,
  height = 200,
  width = 200,
}) => {
  const [facingMode, _setFacingMode] = useState<"user" | { exact: "environment" }>("user")
  const [_showCameraFlipButton, setShowCameraFlipButton] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const ref = useRef<Webcam>(null)

  const imageDimensions = { height, width }

  // If the user doesn't have multiple cameras, don't render the "Switch cameras" button.
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      setShowCameraFlipButton(devices.filter((device) => device.kind === "videoinput").length > 1)
    })
  })

  // Stop all active media stream tracks, then change the camera facing mode.
  // const switchCameras = () => {
  //   setFacingMode(facingMode === "user" ? { exact: "environment" } : "user")
  // }

  const captureImage = () => {
    try {
      if (ref.current) {
        const canvas = ref.current.getCanvas()
        canvas &&
          canvas.toBlob(
            (blob) => {
              setImageBlob(blob || undefined)
            },
            PHOTO_BOOTH_IMAGE_TYPE,
            0.9
          )
      }
    } catch (e) {
      if (e instanceof Error) {
        onFail(e.message)
      } else {
        onFail("An unknown error occurred")
      }
    }
  }

  const retakePhoto = () => {
    setImageBlob(undefined)
  }

  return (
    <div className="relative w-full aspect-1">
      {imageBlob ? (
        <>
          <img src={URL.createObjectURL(imageBlob)} alt="Captured image" className="h-full aspect-1" />
          <Button
            className="absolute bottom-3 right-3"
            variant="contained"
            onClick={retakePhoto}
            startIcon={<BiRefresh />}
            color="black"
          >
            Retake photo
          </Button>
        </>
      ) : (
        <>
          <Webcam
            ref={ref}
            screenshotFormat={PHOTO_BOOTH_IMAGE_TYPE}
            videoConstraints={{ facingMode, ...imageDimensions }}
            className="w-full aspect-1"
            onUserMedia={() => setIsReady(true)}
          />
          {withFaceFrame && <FaceFrame />}

          <Button
            className="absolute bottom-3 right-3"
            variant="contained"
            disabled={!isReady}
            onClick={captureImage}
            startIcon={<BiCamera />}
          >
            Take photo
          </Button>
        </>
      )}
    </div>
  )
}
