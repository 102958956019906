import { clsx } from "clsx"
import { FC } from "react"
import { BiDislike, BiLike } from "react-icons/bi"
import { AssetReportInput, AssetStatus } from "../../../graphql/generated/client-types-and-hooks"

export const InventoryHistoryResult: FC<{ report: AssetReportInput }> = ({ report }) => {
  const thumbStyles = "text-white p-1 rounded size-6"
  const numStyles = "mx-1.5 text-base flex items-center"

  const missingCount = report.statusChange?.missingCount ?? 0
  const availableCount = report?.quantityReported ?? 0 - missingCount

  const isActive = report.statusChange?.active
  const isMissing = report.statusChange?.status === AssetStatus.Missing
  const isQuantityAsset = report.quantityReported !== null

  return (
    <div className="flex items-center justify-center">
      {isActive && !isMissing && (
        <>
          <BiLike className={clsx("bg-green-600", thumbStyles)} />
          {isQuantityAsset && availableCount > 0 && (
            <div className={clsx("min-w-[20px]", numStyles)}>{availableCount}</div>
          )}
        </>
      )}
      {!isQuantityAsset && isMissing && (
        <>
          <BiDislike className={clsx(" bg-orange-600", thumbStyles)} />
          {isQuantityAsset && missingCount > 0 && <div className={clsx(numStyles)}>{missingCount}</div>}
          {!isQuantityAsset && isMissing && <div className="pl-2 text-orange-400">Missing</div>}
        </>
      )}
    </div>
  )
}
