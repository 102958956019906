import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { ListVisibilityFilter } from "../../../../graphql/generated/client-types-and-hooks"
import { CustomMenu } from "../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "../../Assets/DataGrid/AssetFilters/AssetFilterState"
import { sentenceCase } from "../../../../helpers/strings/sentenceCase"
import { testLabel_ListFilterButton, testLabel_ListFilterMenu } from "./labels"

// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  visibilityFilter?: ListVisibilityFilter
  setVisibilityFilter: Dispatch<SetStateAction<ListVisibilityFilter>>
}

export const TeamListVisibilityFilter = ({
  visibilityFilter = ListVisibilityFilter.All,
  setVisibilityFilter,
}: Props): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const visibilityFilterOptions = [ListVisibilityFilter.My, ListVisibilityFilter.All]

  const getFilterLabel = (visFilter: ListVisibilityFilter) => {
    switch (visFilter) {
      case ListVisibilityFilter.All:
        return "All"
      case ListVisibilityFilter.My:
        return "My team"
      default:
        return sentenceCase(visFilter)
    }
  }

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        test-label={testLabel_ListFilterMenu}
        anchor={
          <div
            className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800"
            test-label={testLabel_ListFilterButton}
          >
            <span className="text-gray-800">{getFilterLabel(visibilityFilter)}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {visibilityFilterOptions.map((visFilterOption) => (
            <MenuItem
              key={visFilterOption}
              onClick={() => setVisibilityFilter(visFilterOption as ListVisibilityFilter)}
            >
              <SingleOrMultiSelectionMenuItem selected={visibilityFilter === visFilterOption} />
              <ListItemText>{getFilterLabel(visFilterOption)}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
