import { Checkbox, Divider, Stack } from "@mui/material"
import { FC, useCallback } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { MuiModal } from "./Elements/MuiModal"
import { ModalProps } from "../hooks/useModalProps"
import { RadioGroup } from "../../Formik/RadioGroup"
import { DivisionSelect, DivisionSelectionExpectation } from "../../Formik/DivisionSelect"
import { ProjectAndTasksMultiSelects } from "../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { UserExpectation } from "../../Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import {
  UserAssetRetainmentOption,
  useSwitchDivisionAssignmentForUserMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"

type DivisionAssignmentValues = {
  divisionId: string | null
  assignment: {
    selectedProjectId: string[]
    selectedTaskId: string
  }
  assetRetainment: UserAssetRetainmentOption
  maintainDivisionAccess: boolean
}

type SwitchDivisionAssignmentModalWrapperProps = {
  user: UserExpectation
  organizationId: string
  userDivisionAssignment: DivisionSelectionExpectation[]
  modalProps: ModalProps
}

export const SwitchDivisionAssignmentModalWrapper: FC<SwitchDivisionAssignmentModalWrapperProps> = ({
  user,
  organizationId,
  userDivisionAssignment,
  modalProps,
}) => {
  const [, switchDivisionAssignmentForUserMutation] = useSwitchDivisionAssignmentForUserMutation()
  const userId = user.id

  const handleDivisionAssignment = useCallback(
    async (values: DivisionAssignmentValues) => {
      const {
        divisionId,
        assignment: {
          selectedProjectId: [projectId],
          selectedTaskId,
        },
        assetRetainment,
        maintainDivisionAccess,
      } = values || {}

      const result = await switchDivisionAssignmentForUserMutation({
        userId,
        organizationId,
        divisionId,
        projectId: projectId,
        taskId: selectedTaskId,
        assetRetainment,
        maintainDivisionAccess,
      })

      if (result.error) {
        console.error(result.error)
        errorSnack("Failed to switch division assignment")
      } else {
        successSnack("Division assignment successfully switched")
      }
      modalProps.handleCloseModal()
    },
    [organizationId, switchDivisionAssignmentForUserMutation, modalProps, userId]
  )

  return (
    <SwitchDivisionAssignmentModal
      organizationId={organizationId}
      divisions={userDivisionAssignment}
      user={user}
      modalProps={modalProps}
      handleFormSubmit={handleDivisionAssignment}
    />
  )
}

type SwitchDivisionAssignmentModalType = {
  organizationId: string
  divisionId?: string
  divisions: DivisionSelectionExpectation[]
  handleFormSubmit: (assignment: DivisionAssignmentValues) => void
  modalProps: ModalProps
  user: UserExpectation
}

export const SwitchDivisionAssignmentModal: FC<SwitchDivisionAssignmentModalType> = ({
  organizationId,
  divisionId,
  divisions,
  handleFormSubmit,
  modalProps,
  user,
}) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = user.id === currentUser?.id
  const isCurrentDivision = currentUser?.isCurrentDivision
  const maintainDivisionAccess = isCurrentUser && isCurrentDivision
  return (
    <Formik<DivisionAssignmentValues>
      enableReinitialize
      initialValues={{
        divisionId: divisionId || "",
        assignment: {
          selectedProjectId: [],
          selectedTaskId: "",
        },
        assetRetainment: UserAssetRetainmentOption.Offload,
        maintainDivisionAccess: maintainDivisionAccess || false,
      }}
      validationSchema={Yup.object().shape({
        divisionId: Yup.string().required("Division is required"),
        assignment: Yup.object().shape({
          selectedProjectId: Yup.array()
            .test(
              "is-string-or-array-of-string",
              "You must select a project to assign this user to another division.",
              (value) =>
                typeof value === "string" ||
                (Array.isArray(value) && value.length > 0 && value.every((item) => typeof item === "string"))
            )
            .required("You must select a project to assign this user to another division."),
          selectedTaskId: Yup.string().required("Task is required"),
        }),
        assetRetainment: Yup.mixed().oneOf([UserAssetRetainmentOption.Offload, UserAssetRetainmentOption.Retain]),
        maintainDivisionAccess: Yup.boolean().required("Maintain division access is required"),
      })}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, isSubmitting, resetForm, values, setFieldValue }) => (
        <MuiModal
          isOpen={modalProps.isOpen}
          contentLabel="Switch Division"
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
          }}
          submitButtonText="Confirm"
          submitForm={submitForm}
          submitButtonColor="primary"
          disabled={isSubmitting}
          isLoading={false}
        >
          <Stack rowGap="xl">
            <p className="mb-8">
              To transfer the user to a new division, reassignment to a new project or task is required. The user&apos;s
              equipment can be offloaded or retained.
            </p>
            <DivisionSelect
              divisions={divisions.filter((division) => !division.isCurrentAssignment)}
              preselected={[{ value: divisionId ?? "", label: divisionId ?? "" }]}
              name="divisionId"
              label="Division"
              className="w-full mb-0"
            />
            <ProjectAndTasksMultiSelects
              disabled={!values.divisionId}
              divisionId={values.divisionId ?? organizationId}
              formGroupId="assignment"
              users={[user]}
              includeCurrentAssignment
              withErrorHandling
            />
            <div className="flex items-center gap-3 my-4 ml-2">
              <RadioGroup
                name="assetRetainment"
                options={[
                  {
                    label: "Offload assets",
                    value: UserAssetRetainmentOption.Offload,
                  },
                  {
                    label: "Retain assets",
                    value: UserAssetRetainmentOption.Retain,
                  },
                ]}
              />
            </div>

            <Divider className="mb-8" />
            <p className="mb-5">
              You can grant the user access to their current division, allowing them to freely move between divisions if
              desired.
            </p>
            <label className="flex gap-1 items-center mt-2">
              <Checkbox
                disabled={maintainDivisionAccess}
                checked={!!values.maintainDivisionAccess}
                onChange={() => setFieldValue("maintainDivisionAccess", !values.maintainDivisionAccess)}
              />
              Maintain current user division access
            </label>
          </Stack>
        </MuiModal>
      )}
    </Formik>
  )
}
