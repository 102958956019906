import { compareAsc, parse } from "date-fns"
import { GroupedObject } from "./groupByDate"

export function sortGroupedItems(groupedItems: { [key: string]: GroupedObject[] }): { [key: string]: GroupedObject[] } {
  const sortedKeys = Object.keys(groupedItems).sort((dateStringA, dateStringB) => {
    const formatString = "yyyy-MM-dd"
    const dateA = parse(dateStringA, formatString, new Date())
    const dateB = parse(dateStringB, formatString, new Date())

    return compareAsc(dateA, dateB)
  })

  const sortedGroupedItems: { [key: string]: GroupedObject[] } = {}
  for (const key of sortedKeys) {
    const sortedArray = groupedItems[key].sort((a, b) => compareAsc(a.uploaded, b.uploaded))
    sortedGroupedItems[key] = sortedArray
  }

  return sortedGroupedItems
}
