import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import DeprecatedModal from "../../../deprecated/StandardModal"
import { TransferAssetModal } from "./TransferAssetModal"

const TransferAssetToUserQuery = graphql(`
  query TransferAssetToUserQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    users {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          imageUrl
          jobTitle
          lastName
          projectId
          project {
            id
            name
          }
          task {
            id
            name
          }
        }
      }
    }
  }
`)

const TransferAssetSelectedUserQuery = graphql(`
  query TransferAssetSelectedUser($userId: String!) {
    user(id: $userId) {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
    }
  }
`)

export const TransferAssetToUser: FC<{
  formModalProps: ModalProps
  selectedAssets?: string[]
  selectedUserIds?: string[]
  onSuccess?: () => void
}> = ({ formModalProps, selectedAssets = [], selectedUserIds, onSuccess }) => {
  const [{ data: queryData }] = useQuery({
    query: TransferAssetToUserQuery,
  })
  
  const userId = selectedUserIds ? selectedUserIds[0] : ""
  const [{ data: selectedUserData }] = useQuery({
    query: TransferAssetSelectedUserQuery,
    variables: { userId },
    pause: !userId,
  })

  const assets = useMemo(() => {
    if (!queryData?.assets) return []
    return queryData.assets.filter((asset) => !selectedUserIds?.includes(asset.assignableId!))
  }, [queryData?.assets, selectedUserIds])

  const users = useMemo(() => {
    if (!queryData?.users) return []
    return queryData.users?.edges?.map((edge) => edge!.node!)
  }, [queryData?.users])

  return (
    <DeprecatedModal {...formModalProps}>
      <TransferAssetModal
        formModalProps={formModalProps}
        assets={assets}
        assetsSelectedToTransfer={selectedAssets}
        selectedUserIds={selectedUserIds}
        onSuccess={onSuccess}
        teamMembers={users}
        includeAllProjects 
        selectedUser={selectedUserData?.user}
      />
    </DeprecatedModal>
  )
}
