export const ALL_SUMMARY_FILTER_TYPES = [
  "all",
  "assets",
  "images",
  "notes",
  "tasks",
  "team",
  "timeline",
  "units",
] as const
export type SummaryFilter = typeof ALL_SUMMARY_FILTER_TYPES

export type SummaryFilterType = SummaryFilter[number]

export type DateRangeFilterString = "daily" | "weekly" | "monthly" | "all-time" | "custom"

export type SummarySectionProps = {
  projectId: string
  taskId?: string
  rangeEnd: Date
  rangeStart: Date
  dateRangeType?: DateRangeFilterString
  filter?: SummaryFilterType
  NoRowsOverlay?: React.ReactNode
}
