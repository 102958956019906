import { forwardRef, useCallback, useState } from "react"
import { BiX } from "react-icons/bi"
import SignatureCanvas from "react-signature-canvas"
import { colors } from "../helpers/styles/colors"
import { useWindowSize } from "../hooks/useWindowSize"

type Props = {
  setIsEmpty?: (isEmpty: boolean) => void
  onEnd?: () => void
  onClear?: () => void
  signature?: Blob
}

export const SignaturePad = forwardRef<SignatureCanvas, Props>(({ setIsEmpty, onEnd, signature, onClear }, ref) => {
  const [signatureSize, setSignatureSize] = useState(0)
  const size = useWindowSize()

  const useSignatureRef = useCallback(
    (current: HTMLDivElement) => {
      if (current) {
        setSignatureSize(current.offsetWidth - 32)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size]
  )

  return (
    <div className="border border-gray-200 rounded-lg p-4" ref={useSignatureRef}>
      <p className="uppercase text-gray-400 font-semibold text-sm">Signature</p>
      <div className="relative">
        {signature && <img src={URL.createObjectURL(signature)} alt="Captured image" className="h-full aspect-1" />}
        {!signature && (
          <SignatureCanvas
            ref={ref}
            penColor={colors.gray[800]}
            canvasProps={{ width: signatureSize, height: signatureSize * 0.5 }}
            minWidth={1}
            maxWidth={4}
            onEnd={() => {
              onEnd?.()
              setIsEmpty?.(false)
            }}
          />
        )}
        <div className="absolute bottom-0 w-full pointer-events-none -translate-y-2">
          <BiX className="size-8 text-gray-400" />
          <div className="bg-gray-500 h-1 rounded" />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="text-gray-400"
          onClick={() => {
            onClear?.()
            if (typeof ref !== "function" && ref?.current) {
              ref.current.clear()
            }
          }}
          type="button"
        >
          Clear
        </button>
      </div>
    </div>
  )
})

SignaturePad.displayName = "SignaturePad"
