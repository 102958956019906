import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns"
import { DateRange } from "@mui/x-date-pickers-pro"
import { DateRangeFilterString } from "../../components/Partials/Summary/types"

export function getRangeByPeriod(startDate: Date, dateRangeType: DateRangeFilterString): DateRange<Date> {
  return [startDate, endOfPeriod(startDate, dateRangeType)]
}

export function startOfPeriod(startDate: Date, dateRangeType: DateRangeFilterString) {
  switch (dateRangeType) {
    case "monthly":
      return startOfMonth(startDate)
    case "weekly":
      return startOfWeek(startDate)
    case "custom":
    case "daily":
      return startOfDay(startDate)
    default:
      return startDate
  }
}

export function endOfPeriod(endDate: Date, dateRangeType: DateRangeFilterString) {
  switch (dateRangeType) {
    case "monthly":
      return endOfMonth(endDate)
    case "weekly":
      return endOfWeek(endDate)
    case "custom":
    case "daily":
      return endOfDay(endDate)
    default:
      return endDate
  }
}

export function adjustDate(rangeStart: Date, amount: number, dateRangeType: DateRangeFilterString): DateRange<Date> {
  switch (dateRangeType) {
    case "monthly":
      return getRangeByPeriod(addMonths(rangeStart, amount), dateRangeType)
    case "weekly":
      return getRangeByPeriod(addDays(rangeStart, 7 * amount), dateRangeType)
    case "daily":
    default:
      return getRangeByPeriod(addDays(rangeStart, amount), dateRangeType)
  }
}
