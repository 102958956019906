import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab"
import { Box, Tooltip, Typography } from "@mui/material"
import { format, parse } from "date-fns"
import { FC, useMemo } from "react"
import { BiImage, BiNote, BiRuler } from "react-icons/bi"
import { useQuery } from "urql"
import { TimeLineProgressEventsQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { getFriendlyFloat } from "../../../../helpers/numbers/getFriendlyFloat"
import { pluralize } from "../../../../helpers/strings/pluralize"
import { TaskTimelineSkeleton } from "../TaskTimeline.skeleton"
import { SummarySectionProps } from "../types"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { colors } from "../../../../helpers/styles/colors"

export const testLabel_TimelineListItem = "summary-tab-timeline-list-item"

const TimeLineQueryDocument = graphql(`
  query TimeLineProgressEvents($taskId: String, $projectId: String!, $rangeStart: String!, $rangeEnd: String!) {
    taskProgressEvents(taskId: $taskId, projectId: $projectId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      id
      imageUrls
      note
      task {
        id
        name
      }
      reporter {
        id
        firstName
        lastName
        jobTitle
        imageUrl
      }
      updatedAt
      unitGoalProgressReports {
        id
        progress
        unitGoalId
      }
    }
    unitGoals(taskId: $taskId, projectId: $projectId) {
      id
      deliverableUnit {
        id
        description
        unitOfMeasure
      }
    }
  }
`)

type DeliverableUnit = {
  id: string
  description: string
  unitOfMeasure: string
}

type DeliverableUnits = Record<string, DeliverableUnit>

type Item = {
  id: string
  taskProgressEvent: TimeLineProgressEventsQuery["taskProgressEvents"][0]
}

type DayItemTuple = [string, Item[]]

export const SummaryTimeline: FC<SummarySectionProps> = ({ taskId, projectId, rangeStart, rangeEnd }) => {
  const [{ data, fetching }] = useQuery<TimeLineProgressEventsQuery>({
    query: TimeLineQueryDocument,
    variables: {
      projectId,
      taskId,
      rangeStart: format(rangeStart, "yyyy-MM-dd"),
      rangeEnd: format(rangeEnd, "yyyy-MM-dd"),
    },
    pause: !projectId,
  })

  const deliverableUnits: DeliverableUnits = useMemo(() => {
    const unitGoals = data?.unitGoals

    if (!unitGoals) {
      return {}
    }

    return unitGoals.reduce((acc: DeliverableUnits, { id, deliverableUnit }) => {
      acc[id] = deliverableUnit
      return acc
    }, {} as DeliverableUnits)
  }, [data])

  const groupedItems = useMemo(() => {
    const items = (data?.taskProgressEvents || []).map((event) => ({
      id: event.id,
      taskProgressEvent: event,
    }))

    const groupedByDay = items.reduce<{ [key: string]: Item[] }>((acc, item) => {
      const day = format(new Date(item.taskProgressEvent.updatedAt), "MMM do, yyyy")
      if (!acc[day]) {
        acc[day] = []
      }
      acc[day].push(item)
      return acc
    }, {})

    const tuplesArray = Object.entries(groupedByDay).map(([day, dayItems]) => [day, dayItems] as DayItemTuple)

    // Sort by date
    return tuplesArray.sort((a, b) => {
      const dateA = parse(a[0], "MMM do, yyyy", new Date())
      const dateB = parse(b[0], "MMM do, yyyy", new Date())
      return dateB.getTime() - dateA.getTime()
    })
  }, [data?.taskProgressEvents])

  if (data?.taskProgressEvents.length && fetching) return <TaskTimelineSkeleton />

  if (!groupedItems.length) {
    return (
      <>
        <Typography variant="h4">Timeline</Typography>
        <EmptyStateBlock label="No timeline events in the selected range" />
      </>
    )
  }

  return (
    Boolean(data?.taskProgressEvents.length) && (
      <Box
        paddingY={3}
        paddingX={2}
        style={{
          border: `1px solid ${colors.slate[200]}`,
          borderRadius: "8px",
        }}
      >
        <div>
          <Typography variant="h4">Timeline</Typography>
          <div className="flex flex-col gap-y-4">
            {groupedItems.map(([day, items]) => (
              <div key={day}>
                <Typography variant="h5" marginBottom="0" fontSize={16}>
                  {day}
                </Typography>
                <RenderedTimeline items={items} deliverableUnits={deliverableUnits} />
              </div>
            ))}
          </div>
        </div>
      </Box>
    )
  )
}

const RenderedTimeline: FC<{ items: Item[]; deliverableUnits: DeliverableUnits }> = ({ items, deliverableUnits }) => (
  <Timeline>
    {items.map((item, i) => (
      <TimelineSection key={item.id} index={i} length={items.length} item={item} deliverableUnits={deliverableUnits} />
    ))}
  </Timeline>
)

const TimelineSection: FC<{
  item: Item
  deliverableUnits: DeliverableUnits
  index: number
  length: number
}> = ({ item, deliverableUnits, index, length }) => {
  const { taskProgressEvent } = item
  const { firstName, lastName } = item.taskProgressEvent.reporter
  const { units, notes, images } = {
    units: taskProgressEvent.unitGoalProgressReports.length > 0,
    notes: !!taskProgressEvent.note,
    images: taskProgressEvent.imageUrls.length > 0,
  }

  return (
    <TimelineItem test-label={testLabel_TimelineListItem}>
      <TimelineOppositeContent>
        <Typography>{format(taskProgressEvent.updatedAt, "p")}</Typography>
        <Typography>{`${firstName} ${lastName}`}</Typography>
        <Typography>{item.taskProgressEvent.task.name}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {index === 0 ? <TimelineConnector sx={{ background: "white" }} /> : <TimelineConnector />}
        <div className="flex flex-col justify-around ">
          {Array.from({ length: taskProgressEvent.unitGoalProgressReports.length }, (_, i) => (
            <TimelineDot key={i}>
              <BiRuler />
            </TimelineDot>
          ))}
          {notes && (
            <TimelineDot>
              <BiNote />
            </TimelineDot>
          )}
          {images && (
            <TimelineDot>
              <BiImage />
            </TimelineDot>
          )}
        </div>
        {index === length - 1 ? <TimelineConnector sx={{ background: "white" }} /> : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <div className="text-gray-800 font-medium text-base py-[3px] h-full flex flex-col justify-around max-w-md">
          {units &&
            taskProgressEvent.unitGoalProgressReports.map((report) => {
              const description = deliverableUnits[report.unitGoalId]?.unitOfMeasure
              if (description) {
                return (
                  <p key={report.id}>
                    {`${getFriendlyFloat(report.progress)} ${pluralize(description, report.progress)} of ${
                      deliverableUnits[report.unitGoalId]?.description
                    }`}
                  </p>
                )
              }
            })}
          {notes && (
            <Tooltip title={taskProgressEvent.note} arrow>
              <p className="truncate">{taskProgressEvent.note}</p>
            </Tooltip>
          )}
          {images && (
            <p>{`${taskProgressEvent.imageUrls.length} ${pluralize("image", taskProgressEvent.imageUrls.length)}`}</p>
          )}
        </div>
      </TimelineContent>
    </TimelineItem>
  )
}
