import { TextField } from "@mui/material"
import { clsx } from "clsx"
import { useField, useFormikContext } from "formik"
import { FC, useState } from "react"
import { BiCamera, BiDotsHorizontalRounded, BiNote } from "react-icons/bi"
import { AssetStatus } from "../../../graphql/generated/client-types-and-hooks"
import { useImageUpload } from "../../../hooks/useImageUpload"
import { IconButton } from "../../IconButton"
import { EagerUploadImage } from "../../ImageUploadPreviewList"
import { PHOTO_BOOTH_IMAGE_TYPE, PhotoBooth } from "../../PhotoBooth"
import { QuickMenu } from "../../QuickMenu"
import { InventoryAssetExpectation } from "../Drawer/components/InventoryReportDrawer"
import { YesNoInput } from "../Inspection/YesNoInput"
import { InventoryState } from "./InventoryReportRow"

type InventoryReportActionsProps = {
  asset: InventoryAssetExpectation
  isQuantized: boolean
  inventoryState: InventoryState
  updateInventoryState: (updates: Partial<InventoryState>) => void
}

export const InventoryReportActions: FC<InventoryReportActionsProps> = ({
  asset,
  isQuantized,
  inventoryState,
  updateInventoryState,
}) => {
  const { inventoryRequirements } = asset
  const { isMissing, noteIsVisible } = inventoryState
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const { submitCount } = useFormikContext()
  const [{ value: photosValue }, { error: photosError, touched }, { setValue: setPhotosValue }] = useField(
    `assetReports.${asset.id}.photos`
  )
  const [{ value: numberValue }, { error: numberError }, { setValue: setNumberValue }] = useField(
    `assetReports.${asset.id}.quantityReported`
  )

  const [{ value: currentStatusValue }, _error, { setValue: setCurrentStatusValue }] = useField(
    `assetReports.${asset.id}.currentStatus`
  )

  const { setFiles, removeFileById, setUploadedById, fileObjects } = useImageUpload(
    `asset/${asset.id}/image`,
    (photos) => setPhotosValue(photos)
  )

  const handleQuantityReportedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value) || 0
    const isNowMissing = isQuantized ? value < asset.assetChildCount : false

    setNumberValue(value)
    updateInventoryState({ isMissing: isNowMissing })
    updateInventoryState({ quantityReported: value })
  }

  const handleStatusChange = (status: boolean) => {
    const newIsMissing = !status
    updateInventoryState({ isMissing: newIsMissing })

    // If the status indicates not missing, hide the note
    if (status) {
      updateInventoryState({ noteIsVisible: false, isMissing: false })
      if (currentStatusValue === AssetStatus.Missing) {
        setCurrentStatusValue(AssetStatus.Available)
      }
    }
  }

  const renderPhotoButton = () => (
    <div
      className={clsx(
        (touched && !!photosError) || (submitCount > 0 && !!photosError)
          ? "border-2 border-red-600 rounded"
          : "border-2 border-white",
        "mx-[5px]"
      )}
    >
      <IconButton
        Icon={BiCamera}
        onClick={() => setPhotoBoothIsOpen(true)}
        color={photosValue?.length ? "green" : "gray"}
      />
    </div>
  )

  const renderQuickMenu = (includePhotoOption: boolean) => {
    const menuItems = [
      {
        value: "Note (optional)",
        onClick: () => updateInventoryState({ noteIsVisible: !noteIsVisible }),
        Icon: BiNote,
      },
    ]
    if (includePhotoOption) {
      menuItems.unshift({
        value: "Take photo (optional)",
        onClick: () => setPhotoBoothIsOpen(true),
        Icon: BiCamera,
      })
    }

    return (
      <QuickMenu className="p-0 w-4 relative left-[20px] ml-7 border-2 border-white" items={[menuItems]}>
        <IconButton Icon={BiDotsHorizontalRounded} color={photosValue?.length ? "green" : "white"} />
      </QuickMenu>
    )
  }

  const renderPhotoUploadOrQuickMenu = () => {
    if (inventoryRequirements?.photoRequired && !isMissing) {
      return renderPhotoButton()
    } else if (inventoryRequirements?.photoRequired && isMissing) {
      return (
        <div className="flex flex-row">
          {renderPhotoButton()}
          {renderQuickMenu(false)}
        </div>
      )
    } else {
      return renderQuickMenu(true)
    }
  }

  return (
    <>
      {isQuantized ? (
        <TextField
          name={`assetReports.${asset.id}.quantityReported`}
          value={numberValue}
          error={!!numberError}
          label="Active"
          onChange={handleQuantityReportedChange}
          inputProps={{ max: asset.assetChildCount }}
          type="number"
        />
      ) : (
        <YesNoInput name={`assetReports.${asset.id}.isAvailable`} onStatusChange={handleStatusChange} />
      )}
      <div>
        {Array.isArray(fileObjects) && fileObjects.length > 0 ? (
          <div className="size-10 mx-2">
            {fileObjects.map((fileObject) => (
              <EagerUploadImage
                key={fileObject.fileId}
                fileObject={fileObject}
                removeFile={removeFileById}
                setUploaded={setUploadedById}
              />
            ))}
          </div>
        ) : (
          renderPhotoUploadOrQuickMenu()
        )}

        <PhotoBooth
          title={
            <div>
              Asset Report
              <span className="text-gray-400">/ {asset.name}</span>
            </div>
          }
          isOpen={photoBoothIsOpen}
          setIsOpen={setPhotoBoothIsOpen}
          onAcceptPhoto={(photo) => {
            setFiles([
              new File([photo], new Date().toISOString(), {
                type: PHOTO_BOOTH_IMAGE_TYPE,
              }),
            ])
          }}
        />
      </div>
    </>
  )
}
