import MobileStepper from "@mui/material/MobileStepper"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { OrganizationSettingsContext } from "../../../providers/OrganizationSettingsProvider"
import { useContext, useEffect, useRef, useState } from "react"
import SignatureCanvas from "react-signature-canvas"
import { EndEvidenceInput, User, useClockOutUserMutation } from "../../../graphql/generated/client-types-and-hooks"
import { InjurySignature, signatureCanvasToBlob } from "./InjurySignature"
import { VideoWithFrameGrab } from "./VideoFrame"
import { PickPlus } from "../../../types/helpers"
import { errorSnack } from "../../Notistack/ThemedSnackbars"
import { Confirmation } from "./Confirmation"
import { Divider } from "@mui/material"
import { GeolocationProviderContext } from "../../../providers/GeolocationProvider"
import { uploadTimeEntryEvidence } from "../../../helpers/timeEntries/uploadTimeEntryEvidence"
import { uploadInjuryReportSignature } from "../../../helpers/injuryReports/uploadInjuryReportSignature"
import { v4 } from "uuid"
import { toastClockOutError } from "../../../helpers/clockInOut/toastClockOutError"

type Steps = "timeEntryEvidence" | "injuryReportOnClockout" | "confirmation"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "isClockedIn"> & {
    currentProject: PickPlus<User["currentProject"], "id" | "name">
    currentTask: PickPlus<User["currentTask"], "id" | "name">
  }
  onSuccess?: () => void
  overrideDate?: Date
}

export default function ClockOutWorkflow({ user, onSuccess, overrideDate }: Props) {
  const settings = useContext(OrganizationSettingsContext)
  const { location, locationError } = useContext(GeolocationProviderContext)
  const [steps, setSteps] = useState<Steps[]>([])
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [signature, setSignature] = useState<Blob>()
  const [isInjured, setIsInjured] = useState(false)
  const [signaturePadTouched, setSignaturePadTouched] = useState(false)
  const [injuryNotes, setInjuryNotes] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [_, clockOutMutation] = useClockOutUserMutation()
  const signatureCanvasRef = useRef<SignatureCanvas>(null)

  useEffect(() => {
    const newSteps: Steps[] = []
    if (settings.requireTimeEntryEvidence) newSteps.push("timeEntryEvidence")
    if (settings.requireInjuryReportOnClockout) newSteps.push("injuryReportOnClockout")
    newSteps.push("confirmation")
    setSteps(newSteps)
  }, [settings])

  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const activeStep = steps[activeStepIndex]

  const handleNext = () => {
    if (activeStep == "timeEntryEvidence" && !imageBlob) return errorSnack("Please take a photo to continue")

    if (activeStep == "injuryReportOnClockout") {
      if (!signaturePadTouched) return errorSnack("Please sign the form to continue")
      signatureCanvasToBlob(signatureCanvasRef).then((blob) => setSignature(blob))
    }

    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClockOut = async () => {
    try {
      setIsLoading(true)
      let injuryReportId

      const endEvidence: EndEvidenceInput = {
        location,
        locationError: locationError?.message,
        injured: isInjured,
        injuryNotes,
      }

      if (settings.requireTimeEntryEvidence) {
        if (!imageBlob) throw new Error("Photo is required")
        const imagePath = await uploadTimeEntryEvidence(imageBlob, user.id)
        endEvidence.imagePath = imagePath
      }

      if (settings.requireInjuryReportOnClockout) {
        if (!signature) throw new Error("Signature is required")

        injuryReportId = v4()
        let signaturePath

        if (isInjured) {
          signaturePath = await uploadInjuryReportSignature(signature, injuryReportId)
        } else {
          signaturePath = await uploadTimeEntryEvidence(signature, user.id)
        }

        endEvidence.signaturePath = signaturePath
      }

      const response = await clockOutMutation({
        userId: user.id,
        injuryReportId,
        endAt: overrideDate,
        endEvidence,
      })
      if (response.error) throw new Error(response.error.message)
      onSuccess?.()
    } catch (error) {
      toastClockOutError(error as Error, user)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className="py-2">
        {activeStep === "timeEntryEvidence" && (
          <VideoWithFrameGrab
            imageBlob={imageBlob}
            setImageBlob={setImageBlob}
            onFail={() => {}}
            withFaceFrame={true}
          />
        )}
        {activeStep === "injuryReportOnClockout" && (
          <InjurySignature
            setIsInjured={setIsInjured}
            isInjured={isInjured}
            setInjuryNotes={setInjuryNotes}
            injuryNotes={injuryNotes}
            setSignature={setSignature}
            signature={signature}
            setSignaturePadTouched={setSignaturePadTouched}
            signatureCanvasRef={signatureCanvasRef}
          />
        )}
        {activeStep === "confirmation" && (
          <Confirmation
            endAt={overrideDate}
            isInjured={isInjured}
            photo={imageBlob}
            signature={signature}
            user={user}
          />
        )}
      </div>

      <Divider className="mx-[-24px] my-2" />
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStepIndex}
        nextButton={
          activeStepIndex === steps.length - 1 ? (
            <Button variant="contained" onClick={handleClockOut} disabled={isLoading}>
              Clock out
            </Button>
          ) : (
            <Button size="small" onClick={handleNext} endIcon={<KeyboardArrowRight className="size-5" />}>
              Next
            </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStepIndex === 0}
            startIcon={<KeyboardArrowLeft className="size-5" />}
          >
            Back
          </Button>
        }
      />
    </div>
  )
}
