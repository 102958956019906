import { Box } from "@mui/material"
import { FC } from "react"
import { BiLayer, BiListUl } from "react-icons/bi"
import { Project, Task } from "../graphql/generated/client-types-and-hooks"
import { colors } from "../helpers/styles/colors"
import { PickPlus } from "../types/helpers"
import { DrawerLink } from "./Partials/Drawer/components/Elements/DrawerLink"
import { TaskDrawer } from "./Partials/Drawer/components/Task/TaskDrawer"

type Props = {
  project: PickPlus<Project, "id" | "name">
  task: PickPlus<Task, "id" | "name">
}

export const TaskBadge: FC<Props> = ({ project, task }) => {
  const href = `projects/${task.id}/tasks`

  return (
    task && (
      <DrawerLink href={href} className="text-left" component={<TaskDrawer taskId={task.id} />} drawerName="Task">
        <div className="flex items-center">
          <TaskIcon />

          <div className="grid justify-center content-center gap-y-0.5">
            <div className="mt-1 truncate leading-tight font-medium md:font-normal text-base cursor-pointer hover:underline underline-offset-1">
              {task.name}
            </div>
            {project && (
              <div className="flex items-center text-gray-600 text-xs">
                <BiLayer />
                <div className="truncate pl-1">{project.name}</div>
              </div>
            )}
          </div>
        </div>
      </DrawerLink>
    )
  )
}

const TaskIcon = () => (
  <Box
    bgcolor={colors.gray[200]}
    marginRight="1rem"
    className="size-10 rounded-full flex items-center justify-center"
  >
    <BiListUl className="size-6" />
  </Box>
)
