import { clsx } from "clsx"
import { FC, ReactNode } from "react"

type QuickActionRowProps = {
  disableIconBackground?: boolean
  icon: ReactNode
  title: ReactNode
  subTitle: ReactNode
  action: ReactNode
}

export const QuickActionRow: FC<QuickActionRowProps> = ({
  disableIconBackground = false,
  icon,
  title,
  subTitle,
  action,
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-y-4 lg:gap-y-8">
      <div className="flex gap-4 items-center">
        <div
          className={clsx(
            "size-10 flex justify-center items-center shrink-0",
            !disableIconBackground && "rounded-lg bg-gray-100"
          )}
        >
          {icon}
        </div>
        <div className="md:flex flex-wrap gap-x-4">
          <div className="text-gray-800 font-medium">{title}</div>
          <div className="flex gap-2 text-gray-500">{subTitle}</div>
        </div>
      </div>
      <div>{action}</div>
    </div>
  )
}
