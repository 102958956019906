import { FC } from "react"
import { CreateOrEditAssetForm } from "../../../Assets/CreateOrEditAssetForm"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

export const AssetCreationDrawer: FC = () => {
  return (
    <>
      <DrawerHeader href="/assets/create" />

      <DrawerBody>
        <CreateOrEditAssetForm />
      </DrawerBody>
    </>
  )
}
