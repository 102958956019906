import { endOfWeek, format, getWeekOfMonth, parse } from "date-fns"
import { DateRangeFilterString } from "../types"

export function formatDisplayDate(key: string, dateRangeType: DateRangeFilterString, shortFormat?: boolean) {
  switch (dateRangeType) {
    case "monthly":
      const startDate = parse(key, "yyyy-MM-dd", new Date())
      const weekOfMonth = getWeekOfMonth(startDate)
      return shortFormat
        ? `Week ${weekOfMonth}`
        : `Week ${weekOfMonth}: ${format(startDate, "MMMM d, yyyy")} - ${format(
            endOfWeek(startDate, { weekStartsOn: 0 }),
            "MMMM d, yyyy"
          )}`
    case "all-time":
      return shortFormat
        ? format(parse(key, "yyyy-MM-01", new Date()), "MMM yy")
        : format(parse(key, "yyyy-MM-01", new Date()), "MMMM yyyy")
    default:
      return shortFormat
        ? format(parse(key, "yyyy-MM-dd", new Date()), "MMM dd")
        : format(parse(key, "yyyy-MM-dd", new Date()), "EEEE MMMM do, yyyy")
  }
}
