import { useEffect, useRef, useState } from "react"
import { User } from "../graphql/generated/client-types-and-hooks"
import { secondsToDuration } from "../helpers/dateAndTime/time-utility"
import { PickPlus } from "../types/helpers"

export const padNumber = (number: number) => {
  return String(number).padStart(2, "0")
}

export const useUpdatingUserClockedInHoursAndMinutes = (
  user: PickPlus<User, "isClockedIn" | "secondsClockedSinceOrgMidnight"> | undefined,
  withSeconds = false,
  isToday: boolean = true
) => {
  const [counter, setCounter] = useState<number>(0.001)
  const [lastKnownUserSecondsClocked, setLastKnownUserSecondsClocked] = useState<number | null>()
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const intervalRef = useRef<number>(0)
  intervalRef.current = counter

  const [duration, setDuration] = useState({
    hoursClockedIn: "00",
    minutesClockedIn: "00",
    secondsClockedIn: "00",
    fullDurationInSeconds: 0,
  })

  const setHoursAndMinutesClocked = (seconds: number) => {
    const newDuration = secondsToDuration(seconds)
    setDuration({
      hoursClockedIn: padNumber(newDuration.hours || 0),
      minutesClockedIn: padNumber(newDuration.minutes || 0),
      secondsClockedIn: padNumber(newDuration.seconds || 0),
      fullDurationInSeconds: seconds,
    })
  }

  // Run the timer loop
  useEffect(() => {
    if (!isToday) return

    const clockInterval = withSeconds ? 1 : 60

    const intervalId = setInterval(
      () => {
        setCounter(intervalRef.current + clockInterval)
      },
      clockInterval * 1000 // 1 or 60 seconds
    )

    setTimer(intervalId)
    return () => clearInterval(intervalId)
  }, [isToday, user?.secondsClockedSinceOrgMidnight, user?.isClockedIn, withSeconds])

  // Run the 'update' effect
  useEffect(() => {
    // If we are clocked in... we shouldn't be running the timer and can remove it if it exists
    if (user && !user.isClockedIn && timer) {
      clearInterval(timer)
      setTimer(null)
    }

    if (lastKnownUserSecondsClocked !== user?.secondsClockedSinceOrgMidnight) {
      // Ok, if we've got new data, just reset our 'while rendered' interval offset
      setCounter(0)
    }

    setHoursAndMinutesClocked(
      user?.isClockedIn
        ? user?.secondsClockedSinceOrgMidnight + intervalRef.current
        : user?.secondsClockedSinceOrgMidnight || 0
    )

    setLastKnownUserSecondsClocked(user?.secondsClockedSinceOrgMidnight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isClockedIn, user?.secondsClockedSinceOrgMidnight, counter])

  return duration
}
