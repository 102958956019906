import { FC } from "react"
import { Role } from "../../../../../graphql/generated/client-types-and-hooks"
import { AdminRoleName } from "../../../../../types/Role"
import { PickPlus } from "../../../../../types/helpers"
import { H2 } from "../../../../deprecated"
import { CreateUserForm } from "../../../User/CreateUserForm"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

type Props = {
  href?: string
  selectedRole?: PickPlus<Role, "id" | "name">
  organizationId?: string
  createAdmin?: boolean
}

const getURLRoute = (href?: string, createAdmin?: boolean, organizationId?: string) => {
  if (href) {
    return href
  }
  if (createAdmin) {
    if (organizationId) {
      return `/admin/organizations/${organizationId}/admins/create`
    }
    return `/organization/admins/create`
  }
  return "/team/create"
}

export const UserCreationDrawer: FC<Props> = ({ href, selectedRole, organizationId, createAdmin }) => {
  const urlRoute = getURLRoute(href, createAdmin, organizationId)

  return (
    <>
      <DrawerHeader href={urlRoute} />

      <DrawerBody>
        <H2>{`Add new ${(createAdmin ? AdminRoleName : selectedRole?.name ?? "user").toLowerCase()}`}</H2>
        <hr className="mb-8" />
        <CreateUserForm selectedRole={selectedRole} organizationId={organizationId} createAdmin={createAdmin} />
      </DrawerBody>
    </>
  )
}
