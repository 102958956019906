import { FC } from "react"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"
import { ColumnTitles } from "../../../Table/ColumnTitles"
import { Row } from "../../../Table/Row"
import { Table } from "../../../Table/Table"

const SkeletonRow: FC = () => {
  return (
    <Row className="grid auto-rows-min grid-cols-12 grid-flow-col gap-x-4" includeSeparator={false}>
      <div className="col-span-6 flex gap-x-3 items-center">
        <SkeletonElement className="size-4" />
        <SkeletonElement className="size-8" />
        <SkeletonElement className="h-4 w-32" />
      </div>
      <div className="col-span-3">
        <SkeletonElement className="h-8 w-full" />
      </div>
      <div className="col-span-3">
        <SkeletonElement className="h-8 w-full" />
      </div>
    </Row>
  )
}

export const TaskReassignmentWorkflowSkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <div className="border rounded-lg p-8">
        <ColumnTitles>
          <SkeletonElement className="col-span-6 h-5 w-36" />
          <SkeletonElement className="col-span-3 h-5 w-16" />
          <SkeletonElement className="col-span-3 h-5 w-12" />
        </ColumnTitles>
        <Table>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </Table>
      </div>
    </SkeletonContainer>
  )
}
