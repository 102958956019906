import { createContext, Dispatch, FC, ReactNode, useReducer } from "react"
import { initialTaskListState, TaskListAction, taskListReducer, TaskListState } from "./taskListReducer"

export const TaskListDispatchContext = createContext<Dispatch<TaskListAction>>({} as Dispatch<TaskListAction>)
export const TaskListStateContext = createContext<TaskListState>(initialTaskListState)

export const TaskListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(taskListReducer, initialTaskListState)

  return (
    <TaskListDispatchContext.Provider value={dispatch}>
      <TaskListStateContext.Provider value={state}>{children}</TaskListStateContext.Provider>
    </TaskListDispatchContext.Provider>
  )
}
