import { Typography } from "@mui/material"
import { FC } from "react"

type HoursAndMinutesFromHoursDecimalProps = {
  hoursInput: number
}

export const HoursAndMinutesFromHoursDecimal: FC<HoursAndMinutesFromHoursDecimalProps> = ({ hoursInput }) => {
  const hours = Math.floor(hoursInput)
  const minutes = Math.round((hoursInput - hours) * 60)
  return (
    <Typography component="span">
      {hours}h {minutes}m
    </Typography>
  )
}
