import { FormikValues } from "formik"
import { FC, useContext } from "react"
import { BiChart, BiCheckSquare, BiEdit, BiNote, BiTrash } from "react-icons/bi"
import { PieProgressIndicator } from "../../../features/GanttChart/components/PieProgressIndicator"
import { Task, useTaskEditMutation } from "../../../graphql/generated/client-types-and-hooks"
import { useGetTaskProgressSummary } from "../../../hooks/queries/useGetTaskProgressSummary"
import { useReassignAndCloseTaskWorkflow } from "../../../hooks/useReassignAndCloseTaskWorkflow"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext } from "../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../providers/TaskListProvider/taskListReducer"
import { ProjectExpectation } from "../../../types/ProjectExpectation"
import { PickPlus } from "../../../types/helpers"
import { AddNoteModal } from "../../Modals/components/AddNoteModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import Pill from "../../Pill"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"
import { H2 } from "../../deprecated"
import { DeprecatedTabGroup, Tab } from "../../deprecated/TabGroup"
import { DrawerBody } from "../Drawer/components/Elements/DrawerBody"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { CompletedTaskBanner } from "./Tasks/CompletedTaskBanner"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"

type Props = {
  task: PickPlus<
    Task,
    "id" | "assetCount" | "isComplete" | "isDefault" | "name" | "metadata" | "projectId" | "userCount"
  > & { project: ProjectExpectation; group?: PickPlus<Task, "id" | "name"> | null }

  withTabs: boolean
}

const tabs: Tab[] = [
  {
    label: "Details",
    href: "/projects/[_project_id]/[_task_id]/details",
  },
  {
    label: "Team",
    href: "/projects/[_project_id]/[_task_id]/team",
  },
  {
    label: "Assets",
    href: "/projects/[_project_id]/[_task_id]/assets",
  },
  {
    label: "Summary",
    href: "/projects/[_project_id]/[_task_id]/summary",
  },
]

export const TaskDetailsLayout: FC<Props> = ({ task, withTabs = true }) => {
  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const deleteTaskModalProps = useModalProps("Delete Task")

  // Easy Note Add
  const { handleCloseModal, ...otherModalProps } = useModalProps("Add Note")
  const [, updateOneTask] = useTaskEditMutation()
  const modalProps = {
    ...otherModalProps,
    handleCloseModal,
  }

  const handleSubmit = async (values: FormikValues) => {
    updateOneTask({
      id: task.id!,
      name: task.name!,
      projectId: task.projectId,
      metadataToAdd: [
        {
          label: values.label,
          content: values.content,
        },
      ],
    }).then(() => {
      successSnack("Note added successfully")
    })
  }

  const markTaskCompleteWorkflow = useReassignAndCloseTaskWorkflow()
  const taskListItem = {
    ...task,
    isComplete: Boolean(task.isComplete),
    taskId: task.id,
  }
  const dispatch = useContext(TaskListDispatchContext)
  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("task:update")) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () =>
          pushDrawer(
            <div>
              <DrawerBody>
                <CreateOrEditTaskForm task={task} taskType={task.group?.id ? "sub-task" : "task"} />
              </DrawerBody>
            </div>,
            "TaskEdit"
          ),
        Icon: BiEdit,
      },
      {
        value: "Add note",
        onClick: () => {
          modalProps.handleOpenModal()
        },
        Icon: BiNote,
      },
    ])
  }

  // can't delete the default task
  if (!task.isDefault && hasPermissionTo("task:complete")) {
    menuItems.push([
      {
        value: "Complete Task",
        onClick: () => {
          markTaskCompleteWorkflow({
            taskListItem: { ...taskListItem, project: task.project },
            onSuccessCallback: () => {
              if (typeof dispatch === "function") {
                dispatch({
                  type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                  payload: { taskId: task.id, isComplete: true },
                })
              }
              successSnack("Success!")
            },
            onErrorCallback: () => {
              errorSnack(`${task.name} completion error; please try again`)
            },
          })
        }, // Need to change this to complete workflow
        Icon: BiCheckSquare,
      },
      {
        value: "Delete",
        onClick: () => deleteTaskModalProps.handleOpenModal(),
        Icon: BiTrash,
        color: "red",
        requiredPermission: "task:update",
      },
    ])
  } else if (!task.isDefault) {
    menuItems.push([
      {
        value: "Delete",
        onClick: () => deleteTaskModalProps.handleOpenModal(),
        Icon: BiTrash,
        color: "red",
        requiredPermission: "task:update",
      },
    ])
  }

  const { data } = useGetTaskProgressSummary(task.id)
  const progressPercentage = Math.ceil((data?.completedUnits / data?.targetUnits) * 100) || 0

  return (
    <>
      {task?.isComplete && <CompletedTaskBanner task={task} />}
      <div className="flex col-span-2 content-start">
        <>
          <div className="flex items-center mr-2">
            <PieProgressIndicator progress={progressPercentage} color="green" sizeLarge={true} />
          </div>
          <div className="grow ml-2 flex gap-x-2 h-16 items-center">
            <H2 className="text-gray-800 my-0">{task?.name}</H2>
            {!task.isDefault && (
              <>
                <Pill color="gray" className="text-sm flex flex-row gap-2 min-w-fit items-center">
                  <BiChart />
                  <span className="text-gray-500">{`${progressPercentage}%`}</span>
                </Pill>
                <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
                  <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
                </QuickMenuMui>
              </>
            )}

            <DeleteTaskModal
              modalProps={deleteTaskModalProps}
              closeModal={deleteTaskModalProps.handleCloseModal}
              task={task}
              isTaskGroup={false}
              onSuccess={closeDrawer}
            />
          </div>
        </>
      </div>
      {withTabs && task?.project && (
        <DeprecatedTabGroup
          tabs={tabs.filter((tab) => {
            const showTeamTab = task?.isComplete ? tab.href !== "/projects/[_project_id]/[_task_id]/team" : true
            return showTeamTab
          })}
        />
      )}
      {modalProps.isOpen && (
        <AddNoteModal
          modalProps={modalProps}
          parentName={task.name || "Task"}
          onSave={async (values: FormikValues) => {
            await handleSubmit(values)
          }}
        />
      )}
    </>
  )
}
