import { FC } from "react"
import { AssetHistoryReportQuery } from "../../../../graphql/generated/client-types-and-hooks"

type InventoryReport = AssetHistoryReportQuery["assetReport"]["inventoryReport"]

export const ImagesGrid: FC<{ inventoryReport: InventoryReport }> = ({ inventoryReport }) => {
  if (!inventoryReport) {
    return null
  }

  return (
    <div className="grid grid-cols-2 gap-3 md:grid-cols-6">
      {inventoryReport?.photoUrls?.map((url) => (
        <img className="rounded object-cover w-full aspect-1" alt="asset report evidence" key={url} src={url} />
      ))}
    </div>
  )
}
