import { FC } from "react"
import { Project } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { AssetCountWidget } from "./AssetCountWidget"
import { HoursClockedWidget } from "./HoursClockedWidget"
import { ProjectProductionRateWidget } from "./ProductionRateWidget"
import { UserCountWidget } from "./UserCountWidget"

type ExtendedProject = PickPlus<Project, "id" | "userCount" | "assetsCount" | "estimatedHours"> & {
  totalSeconds: number
  currentSeconds: number
  previousSeconds: number
}

export const ProjectOverviewWidgets: FC<{
  project: ExtendedProject
  rangeEnd: Date
  rangeStart: Date
  isLoading: boolean
}> = ({ project, rangeEnd, rangeStart, isLoading }) => {
  const { estimatedHours, previousSeconds, currentSeconds, totalSeconds } = project

  return (
    <div>
      <div className="grid grid-flow-col overflow-x-auto gap-3">
        <HoursClockedWidget
          estimatedHours={estimatedHours}
          previousSeconds={previousSeconds}
          currentSeconds={currentSeconds}
          completedHours={totalSeconds / 3600}
          isLoading={isLoading}
        />
        <UserCountWidget userCount={project.userCount} id={project.id} />
        <AssetCountWidget project={project} assetCount={project.assetsCount} />
        <ProjectProductionRateWidget projectId={project.id} rangeEnd={rangeEnd} rangeStart={rangeStart} />
      </div>
    </div>
  )
}
