import { Transition } from "@headlessui/react"
import { clsx } from "clsx"
import { FC, useRef, useState } from "react"
import { UserNotificationModelType } from "../../../../../graphql/generated/client-types-and-hooks"
import { DevelopmentFeatureFlagName } from "../../../../../helpers/api/developmentFeatureFlags"
import { TeamMemberPageContent } from "../../../../../pages/team/[_id]/_layout"
import { UserAssetsPage } from "../../../../../pages/team/[_id]/assets"
import { UserPageDetails } from "../../../../../pages/team/[_id]/details"
import { UserPageDocuments } from "../../../../../pages/team/[_id]/documents"
import { UserPageOfflineEvents } from "../../../../../pages/team/[_id]/offline-events"
import { UserPageTimeCards } from "../../../../../pages/team/[_id]/time-cards"
import { useFlags } from "../../../../../providers/DevelopmentFeatureFlagProvider"
import { usePermissions } from "../../../../../providers/PermissionsProvider/PermissionsProvider"
import { NotificationBubble } from "../../../../UserNotifications/NotificationBubble"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

type Props = {
  userId: string
  tab?: string
}

type Tab = {
  value: string
  label: string
  permissions: "user:read" | "timeEntry:read"
  TabNotificationBubble?: FC
  featureFlag?: DevelopmentFeatureFlagName
}

export const UserDrawer: FC<Props> = ({ userId, tab = "details" }) => {
  const [currentTab, setCurrentTab] = useState(tab)
  const { flagIsEnabled } = useFlags()
  const { hasPermissionTo } = usePermissions()

  const drawerRef = useRef(null)

  const tabs: Tab[] = [
    {
      value: "details",
      label: "Details",
      permissions: "user:read",
    },
    {
      value: "assets",
      label: "Assets",
      permissions: "user:read",
    },
    {
      value: "time-cards",
      label: "Time Cards",
      permissions: "timeEntry:read",
      TabNotificationBubble: () => (
        <NotificationBubble
          shouldRender={(userNotifications) =>
            userNotifications.some((un) => un.modelType === UserNotificationModelType.User && un.user?.id === userId)
          }
        />
      ),
    },
    {
      value: "documents",
      label: "Documents",
      permissions: "user:read",
    },
    {
      value: "offline-events",
      label: "Offline Events",
      permissions: "user:read",
      featureFlag: "Show Offline Events for User",
    },
  ]

  return (
    <>
      <DrawerHeader href={`/team/${userId}/${currentTab}`} />

      <DrawerBody>
        <TeamMemberPageContent userId={userId} withTabs={false}>
          <div className="flex gap-6 overflow-x-auto" ref={drawerRef}>
            {tabs.map((tabOption) => {
              const isSelected = tabOption.value === currentTab

              if (isSelected) {
                // Skip the following conditions if we this is the selected item
              } else {
                if (tabOption.featureFlag && !flagIsEnabled(tabOption.featureFlag)) return
                if (tabOption.permissions && !hasPermissionTo(tabOption.permissions, { userId })) return
              }

              const { TabNotificationBubble } = tabOption

              return (
                <button
                  key={tabOption.value}
                  onClick={() => setCurrentTab(tabOption.value)}
                  className={clsx(
                    "relative my-2 pb-2 font-bold uppercase whitespace-nowrap",
                    "md:my-8",
                    !isSelected && "text-gray-400"
                  )}
                >
                  {TabNotificationBubble && <TabNotificationBubble />}
                  {tabOption.label}

                  <Transition
                    appear={true}
                    show={true}
                    enter="transition ease-in-out duration-150 transform"
                    enterFrom="opacity-0 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                  >
                    <div
                      className={clsx(
                        "w-full relative top-1 h-1 transition-all rounded-sm",
                        isSelected ? "bg-blue-600" : "bg-transparent"
                      )}
                    />
                  </Transition>
                </button>
              )
            })}
          </div>

          {currentTab === "assets" && <UserAssetsPage userId={userId} />}
          {currentTab === "details" && <UserPageDetails userId={userId} />}
          {currentTab === "documents" && <UserPageDocuments />}
          {currentTab === "offline-events" && <UserPageOfflineEvents userId={userId} drawerRef={drawerRef} />}
          {currentTab === "time-cards" && <UserPageTimeCards userId={userId} drawerRef={drawerRef} />}
        </TeamMemberPageContent>
      </DrawerBody>
    </>
  )
}
