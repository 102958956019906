import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { BiCar, BiCollection, BiLayer, BiListUl, BiPackage, BiSortAlt2, BiUserCircle } from "react-icons/bi"
import { Asset, AssetAssignableType } from "../../../../graphql/generated/client-types-and-hooks"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetImage } from "../../../AssetImage"
import { IconLabel } from "../../../IconLabel"
import { Monogram } from "../../../Monogram"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { AssetDrawer } from "../../Drawer/components/Asset/AssetDrawer"
import {
  AssetGroupReassignmentFilterDefinition,
  ReassignAssetDrawer,
} from "../../Drawer/components/Asset/ReassignAssetDrawer"
import { TaskDrawer } from "../../Drawer/components/Task/TaskDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"

export const AssigneeCell: FC<{ asset: Asset }> = ({ asset }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const router = useRouter()

  const assigneeQuickMenuItems: MenuItem[][] = []

  switch (asset.assignableType) {
    case AssetAssignableType.User:
      // We don't need the 'View User' button if we're already on the team page
      if (!router.asPath.includes("/team")) {
        assigneeQuickMenuItems.push([
          {
            value: "View user",
            Icon: BiUserCircle,
            onClick: () => {
              router.push(`/team/${asset.assignedUserId}`)
            },
          },
        ])
      }
      break
    case AssetAssignableType.Task:
      assigneeQuickMenuItems.push([
        {
          value: "View project",
          Icon: BiLayer,
          onClick: () => {
            router.push(`/projects/${asset.assignedTask?.projectId}`)
          },
        },
        {
          value: "View task",
          Icon: BiListUl,
          onClick: () => {
            if (asset.assignedTask?.id) {
              pushDrawer(<TaskDrawer taskId={asset.assignedTask.id} />, "Task")
            } else {
              errorSnack("Assigned task not found")
            }
          },
        },
      ])
      break
    case AssetAssignableType.Asset:
      assigneeQuickMenuItems.push([
        {
          value: "View asset",
          Icon: BiCar,
          onClick: () => {
            router.push(`/assets/${asset.assignedAsset?.id}`)
          },
        },
      ])
  }

  if (hasPermissionTo("asset:transfer") && !asset.assetGroupId && !asset.deletedAt) {
    assigneeQuickMenuItems.push([
      {
        value: "Transfer asset",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(
            <ReassignAssetDrawer
              assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
              assetGroupToReassign={
                asset.assetGroupId
                  ? ({
                      assetGroupId: asset.assetGroupId!,
                      assignableId: asset.assignableId,
                      assignableType: asset.assignableType,
                      status: asset.status!,
                      count: asset.assetChildCount,
                      groupParent: asset?.childAssetGroups?.[0]?.groupParent,
                      compositeKey: asset.compositeKey,
                    } as AssetGroupReassignmentFilterDefinition)
                  : null
              }
            />,
            "ReassignAsset"
          )
        },
      },
    ])
  }

  if (hasPermissionTo("asset:transfer") && asset.assetGroupId && !asset.deletedAt) {
    assigneeQuickMenuItems.push([
      {
        value: "Transfer assets",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(<AssetDrawer assetId={asset.id} />, "Asset")
        },
      },
    ])
  }

  const isAssetGroup = asset.isAssetGroup && asset.groupQuantity && asset.groupQuantity > 1

  const isInventoryAsset =
    asset.assignableType === AssetAssignableType.Asset &&
    asset?.assignedAsset &&
    asset.assignableId === asset.assetGroupId

  if (isAssetGroup && asset.assignmentsCount > 1) {
    return (
      <div className="w-full p-2 bg-gray-100 rounded hover:rounded transition-all col-span-12 mt-2 md:mt-0 md:col-span-2">
        <IconLabel icon={BiCollection} label={`${asset.assignmentsCount} assignments`} />
      </div>
    )
  }

  if ((isAssetGroup && asset.assignmentsCount === 1) || isInventoryAsset) {
    return (
      <div className="w-full p-2 bg-gray-100 rounded hover:rounded transition-all col-span-12 mt-2 md:mt-0 md:col-span-2">
        <IconLabel icon={BiPackage} label="Inventory" />
      </div>
    )
  }

  return (
    <QuickMenu
      items={assigneeQuickMenuItems}
      className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
      disabled={asset.assignableType === AssetAssignableType.User && asset.assignedUser?.archived}
    >
      <div className="w-full truncate leading-tight flex gap-2 items-center">
        {asset.assignableType === AssetAssignableType.User && asset.assignedUser && (
          <>
            <Monogram height="h-6" width="w-6" user={asset.assignedUser} />
            {asset.assignedUser?.firstName} {asset.assignedUser?.lastName}
          </>
        )}
        {asset.assignableType === AssetAssignableType.Asset && asset?.assignedAsset && (
          <>
            <AssetImage
              width="w-6"
              height="h-6"
              padding="1"
              placeholderImageTextColor="white"
              placeholderImageBgColor="gray-800"
              asset={asset.assignedAsset}
              isGroupedAsset={Boolean(asset?.assetGroupId)}
            />
            {asset.assignedAsset?.name}
          </>
        )}
        {asset.assignableType === AssetAssignableType.Task && asset.assignedTask && (
          <IconLabel icon={BiListUl} label={asset.assignedTask.name} />
        )}
      </div>
    </QuickMenu>
  )
}
