import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { BiChevronDown, BiDotsHorizontal } from "react-icons/bi"
import { CustomMenu } from "../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "../../Assets/DataGrid/AssetFilters/AssetFilterState"
import { useState } from "react"
import { testLabel_ListFilterButton, testLabel_ListFilterMenu } from "./labels"

// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  invitationPending: boolean | null
  setInvitationPending: (invitationPending: boolean | null) => void
}

export const TeamListOtherFilter = ({ invitationPending, setInvitationPending }: Props): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        test-label={testLabel_ListFilterMenu}
        anchor={
          <div
            className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800"
            test-label={testLabel_ListFilterButton}
          >
            <BiDotsHorizontal />
            <span className="text-gray-800">Other</span>
            <span className="text-gray-500">{invitationPending ? "Pending app invitation" : "None"}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          <MenuItem onClick={() => setInvitationPending(!invitationPending || null)}>
            <SingleOrMultiSelectionMenuItem selected={invitationPending || false} isCheckbox />
            <ListItemText>Pending app invitation</ListItemText>
          </MenuItem>
        </MenuList>
      </CustomMenu>
    </>
  )
}
