import { clsx } from "clsx"
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react"

type Props = {
  children: ReactNode
  className?: string
}

export const ChipContainer: FC<Props> = ({ children, className }) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(false)
  const [requiresScroll, setRequiresScroll] = useState(false)
  const scrollableRef = useRef<HTMLDivElement>(null)

  const useHandleScroll = useCallback(() => {
    if (scrollableRef.current) {
      const scrollHeight = scrollableRef.current.scrollHeight
      const clientHeight = scrollableRef.current.clientHeight
      const scrollTop = scrollableRef.current.scrollTop
      const refRequiresScroll = scrollHeight > clientHeight

      setRequiresScroll(refRequiresScroll)
      if (refRequiresScroll) {
        setIsScrolledToTop(!scrollTop)
      }
    }
  }, [])

  useEffect(() => {
    const div = scrollableRef.current

    if (div) {
      div.addEventListener("scroll", useHandleScroll)
      new ResizeObserver(useHandleScroll).observe(div)
    }
  }, [useHandleScroll])

  return (
    <div className="relative">
      <div ref={scrollableRef} className={clsx("flex gap-2 my-3 flex-wrap overflow-y-auto", className)}>
        {children}
      </div>
      <div
        className={clsx(
          `absolute top-0 inset-x-0 h-8 bg-gradient-to-t from-transparent via-white to-white pointer-events-none transition-all`,
          requiresScroll && !isScrolledToTop ? "opacity-100" : "opacity-0"
        )}
      />
      <div
        className={clsx(
          `absolute bottom-0 inset-x-0 h-8 bg-gradient-to-b from-transparent via-white to-white pointer-events-none transition-all`,
          requiresScroll && isScrolledToTop ? "opacity-100" : "opacity-0"
        )}
      />
    </div>
  )
}
