import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { SkeletonTextSmall } from "../../Skeletons/SkeletonTextSmall"

export const UnitReportExportFormSkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonElement className="h-8 w-64" />
      <div className="grid grid-cols-1 gap-8 mt-8">
        <div>
          <SkeletonTextSmall className="mb-2" />
          <SkeletonElement className="h-10 w-full" />
        </div>
        <div>
          <SkeletonTextSmall className="mb-2" />
          <SkeletonElement className="h-10 w-full" />
        </div>
        <div>
          <SkeletonElement className="h-10 w-32" />
        </div>
      </div>
    </SkeletonContainer>
  )
}
