import { Button, Divider, Skeleton, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { PayType, WorkersCompCode, useUserEditMutation } from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { dataURLToBlob } from "../../../../../helpers/files/dataURLToBlob"
import { fileUpload } from "../../../../../helpers/files/fileUpload"
import { getDataUrlForImage } from "../../../../../helpers/files/getDataUrlForImage"
import { formatPhoneNumber } from "../../../../../helpers/formatters/formatPhoneNumber"
import { roleSelectOptions } from "../../../../../helpers/permissions/roleHelpers"
import { getFullName } from "../../../../../helpers/strings/getFullName"
import { PermissionsContext } from "../../../../../providers/PermissionsProvider/PermissionsProvider"
import { warnOnExitStoreActions } from "../../../../../stores/warnOnExit"
import { DevelopmentFeatureFlag } from "../../../../DevelopmentFeatureFlag"
import { FormRow } from "../../../../FormRow"
import { ChipSelect } from "../../../../Formik/ChipSelect"
import { CreatableAutocomplete } from "../../../../Formik/CreatableAutocomplete"
import { RadioGroup } from "../../../../Formik/RadioGroup"
import { Switch } from "../../../../Formik/Switch"
import { TextField } from "../../../../Formik/TextField"
import { UserBillingClassificationSelect } from "../../../../Formik/UserBillingClassificationSelect"
import { WorkersCompCodeSelect } from "../../../../Formik/WorkersCompCodeSelect"
import { ImageUpload } from "../../../../ImageUpload2"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"
import { PageTitle } from "../../../../PageTitle"
import { RenderIf } from "../../../../RenderIf"
import { H2, H5 } from "../../../../deprecated"
import { SingleDrawerContext } from "../Elements/Drawer"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerFooter } from "../Elements/DrawerFooter"
import { DrawerHeader } from "../Elements/DrawerHeader"

type Values = {
  firstName: string
  lastName: string
  jobTitle: string
  email?: string | null
  phoneNumber: string
  phoneNumberExt: string
  roleId: string
  billingClassificationId: string
  workersCompCodeId: WorkersCompCode["id"]
  companyUserId: string
  payType?: PayType
  payRate?: string
  sendInvite: boolean
}

type Props = {
  userId: string
}

const EditUserFormQuery = graphql(`
  query EditUserFormQuery($id: String!, $fetchPay: Boolean!) {
    myOrganization {
      id
      notificationSettings
      roles {
        id
        name
        description
      }
    }
    getJobTitles
    user(id: $id) {
      id
      currentTaskId
      currentProjectId
      billingClassificationId
      workersCompCodeId
      email
      firstName
      imageUrl
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      companyUserId
      emailVerifiedAt
      roles {
        id
        name
      }
      task {
        id
        name
      }
      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)
    }
    usersList(status: "active") {
      id
      companyUserId
    }
  }
`)

export const UserEditDrawer: FC<Props> = ({ userId }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const { setHasUnsavedChanges } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  const [{ data, fetching: fetchingUsers }] = useQuery({
    query: EditUserFormQuery,
    variables: { id: userId!, fetchPay: hasPermissionTo("payRate:read") },
  })
  const user = data?.user
  const otherUsers = data?.usersList?.filter((u) => u.id !== userId) || []

  const [{ fetching: fetchingMutation }, editUserMutation] = useUserEditMutation()
  const [userImageSrc, setUserImageSrc] = useState<string>("")
  const [initialUserImageSrc, setInitialUserImageSrc] = useState<string>("")

  useEffect(() => {
    // Set initial user image after user is loaded
    setInitialUserImageSrc(user?.imageUrl || "")
  }, [user?.imageUrl])

  const onSubmit = async (values: Values, { setFieldError }: FormikHelpers<Values>) => {
    const {
      firstName,
      lastName,
      jobTitle,
      phoneNumber,
      phoneNumberExt,
      roleId,
      billingClassificationId,
      workersCompCodeId,
      companyUserId,
      payType,
      payRate,
      email,
      sendInvite,
    } = values

    let image = !initialUserImageSrc ? "" : undefined
    if (userImageSrc) {
      const imageBlob = dataURLToBlob(userImageSrc)
      const uploadedFile = await fileUpload(`user-image/${user?.id}`, "image/webp", imageBlob)
      image = uploadedFile?.objectKey || undefined
    }

    editUserMutation({
      user: {
        id: userId,
        email: email !== "" ? email : null,
        firstName,
        lastName,
        jobTitle,
        phoneNumber,
        phoneNumberExt: phoneNumberExt.toString(),
        roles: [roleId],
        billingClassificationId: billingClassificationId !== "" ? billingClassificationId : undefined,
        workersCompCodeId: workersCompCodeId !== "" ? workersCompCodeId : undefined,
        companyUserId: companyUserId !== "" ? companyUserId : null,
        image,
        payType: payType as PayType,
        payRate: payRate?.replace(/[^0-9.]/g, ""),
      },
      sendInvite: sendInvite,
    }).then((result) => {
      if (result.error) {
        const graphqlErrors = result.error.graphQLErrors
        if (graphqlErrors.filter((e) => e.message.startsWith("Email"))) {
          graphqlErrors.forEach((e) => e.message.startsWith("Email") && setFieldError("email", e.message))

          errorSnack(result.error.graphQLErrors.map((e) => e.message).join(", "))
        } else {
          console.error("error", result.error)
          errorSnack("Error updating the user")
        }
      } else {
        setHasUnsavedChanges(false)
        successSnack("User update complete")
        setUserImageSrc("")
        handleClose()
      }
    })
  }

  if ((fetchingUsers && !user?.id) || (fetchingMutation && !data?.myOrganization)) {
    return <Skeleton />
  }

  return (
    <>
      {user?.id && data?.myOrganization && (
        <Formik
          enableReinitialize
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            email: user.email ?? "",
            phoneNumber: formatPhoneNumber(user.phoneNumber ?? ""),
            phoneNumberExt: user.phoneNumberExt ?? "",
            roleId: user.roles[0].id,
            billingClassificationId: user.billingClassificationId ?? "",
            workersCompCodeId: user.workersCompCodeId ?? "",
            companyUserId: user.companyUserId ?? "",
            payType: user.payType ?? PayType.Hourly,
            payRate: user.payRate ?? "",
            sendInvite: user.emailVerifiedAt
              ? false
              : data?.myOrganization?.notificationSettings?.["USER_INVITE"] ?? false,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().trim().required("Required").label("First Name"),
            lastName: Yup.string().trim().required("Required").label("Last Name"),
            jobTitle: Yup.string().trim().nullable().label("Title"),
            roleId: Yup.string().required(),
            email: Yup.string()
              .trim()
              .email()
              .when("sendInvite", {
                is: true,
                then: (schema) => schema.required().label("Email"),
              })
              .label("Email"),
            phoneNumber: Yup.string()
              .trim()
              .nullable()
              .label("Phone")
              .matches(/\(([2-9])(\d{2})\) (\d{3})-(\d{4})$/, { message: "Invalid phone number format" }),
            phoneNumberExt: Yup.string()
              .trim()
              .nullable()
              .label("Ext.")
              .matches(/(^[0-9]{0,6})$/, { message: "Invalid number format" }),
            billingClassificationId: Yup.string().nullable().label("Billing Classification"),
            workersCompCodeId: Yup.string().nullable().label("Workers Comp Code"),
            companyUserId: Yup.string()
              .trim()
              .label("Company user ID")
              .nullable()
              .test("isUnique", "There is another user with this ID", (companyUserId) =>
                otherUsers.every((u) => u?.companyUserId !== companyUserId)
              ),
            payType: Yup.mixed<PayType>().oneOf(Object.values(PayType), "Invalid Pay Type").required(),
            payRate: Yup.string().trim().nullable().label("Pay Rate"),
            sendInvite: Yup.boolean().label("Send Invite"),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue, dirty }) => {
            setTimeout(
              () =>
                setIsDirty((previous) => {
                  if (previous !== dirty) {
                    setHasUnsavedChanges(dirty)
                    return dirty
                  }
                  return previous
                }),
              0
            )

            const sendInviteRequired = !!user.email && !!values.email && values.email !== user.email
            const noEmailError = !values.email && !!user.email

            return (
              <Form>
                <DrawerHeader href={`/team/${userId}/details`} />

                <DrawerBody>
                  <H2 className="mb-6 text-gray-800">Edit User</H2>
                  <PageTitle title={!user?.firstName ? "User details" : `${getFullName(user)} details`} />

                  <H5 className="mt-10 mb-0">Basic Info</H5>
                  <hr className="mt-2 mb-4 md:mb-6" />

                  <FormRow childrenContainerClassName="flex-col mb-6">
                    <ImageUpload
                      onFileSelected={([file]) => {
                        if (file) {
                          getDataUrlForImage(file, (dataUrl) => setUserImageSrc(dataUrl), 600, 600)
                        }
                      }}
                      onDelete={() => {
                        setUserImageSrc("")
                        setInitialUserImageSrc("")
                      }}
                      imageSrc={userImageSrc}
                      initialImageSrc={initialUserImageSrc}
                      accept="accept"
                    />
                  </FormRow>

                  <FormRow childrenContainerClassName="flex-col">
                    <div className="grid lg:grid-cols-2 gap-y-1.5 lg:space-x-3 mt-5">
                      <TextField name="firstName" label="First name" />
                      <TextField name="lastName" label="Last name" />
                    </div>
                  </FormRow>

                  <FormRow>
                    <CreatableAutocomplete
                      fullWidth
                      name="jobTitle"
                      label="Job title"
                      defaultValue={user.jobTitle}
                      options={(data?.getJobTitles || []).map((title) => ({ label: title, id: title }))}
                    />
                  </FormRow>

                  <DevelopmentFeatureFlag name="Billing Classifications">
                    <FormRow childrenContainerClassName="flex-col">
                      <RenderIf permissionsInclude="userBillingClassification:list">
                        <div className="pb-5">
                          <UserBillingClassificationSelect name="billingClassificationId" />
                        </div>
                      </RenderIf>
                    </FormRow>
                  </DevelopmentFeatureFlag>

                  <DevelopmentFeatureFlag name="Workers Comp Code">
                    <FormRow childrenContainerClassName="flex-col">
                      <RenderIf permissionsInclude="timeEntry:export">
                        <div className="pb-5">
                          <WorkersCompCodeSelect name="workersCompCodeId" />
                        </div>
                      </RenderIf>
                    </FormRow>
                  </DevelopmentFeatureFlag>

                  <FormRow>
                    <TextField fullWidth name="companyUserId" type="text" label="Company User ID" />
                  </FormRow>

                  <DevelopmentFeatureFlag name="Pay Rate">
                    <RenderIf permissionsInclude="payRate:update">
                      <FormRow childrenContainerClassName="flex-col">
                        <Typography variant="h5">Payment Details</Typography>
                        <Divider className="mb-5" />

                        <div className="flex items-center gap-3 mb-3">
                          <Typography className="font-semibold text-gray-800">Pay type</Typography>
                          <RadioGroup
                            name="payType"
                            options={[
                              { label: "Hourly", value: PayType.Hourly },
                              { label: "Salary", value: PayType.Salary },
                            ]}
                          />
                        </div>
                        <TextField fullWidth name="payRate" label="Pay Rate" type="money" />
                      </FormRow>
                    </RenderIf>
                  </DevelopmentFeatureFlag>

                  <ChipSelect
                    required
                    name="roleId"
                    options={roleSelectOptions(data?.myOrganization.roles)}
                    labelEl={
                      <>
                        <H5 className="my-0">App Role & Permissions</H5>
                        <hr className="mt-2 mb-4 md:mb-6" />
                      </>
                    }
                  />

                  <FormRow childrenContainerClassName="flex-col">
                    <div className="grid grid-cols-3 gap-y-1.5 gap-x-3">
                      <div className="col-span-2">
                        <TextField
                          fullWidth
                          name="phoneNumber"
                          label="Phone number"
                          type="tel"
                          placeholder="(888) 888-8888"
                        />
                      </div>
                      <div className="col-span-1">
                        <TextField
                          fullWidth
                          name="phoneNumberExt"
                          label="Ext"
                          type="number"
                          inputProps={{ min: 0, max: 999999 }}
                        />
                      </div>
                    </div>
                  </FormRow>

                  <FormRow>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      type="email"
                      required={values.sendInvite}
                      placeholder="you@example.com"
                      className="col-span-12 mb-0 pb-0"
                      onChange={(e) => {
                        const newValue = e.target.value
                        if (newValue.length && newValue !== user.email) {
                          // If email has changed, sendInvite is required
                          !values.sendInvite && setFieldValue("sendInvite", true)
                        } else if (!newValue.length) {
                          setFieldValue("sendInvite", false)
                        }
                      }}
                    />
                  </FormRow>

                  <div className="col-span-12 mt-0 pt-0 mb-6">
                    <Switch name="sendInvite" label="Send email invite for app access." disabled={sendInviteRequired} />
                    {sendInviteRequired && (
                      <Typography variant="caption" color="error">
                        {`When changing a user's email, a password reset email will automatically be sent to the new
                        email and the user will no longer have access to CrewView until they reset their password.`}
                      </Typography>
                    )}
                    {noEmailError && (
                      <Typography variant="caption" color="error">
                        {`When removing a user's email, they will no longer have access to CrewView`}
                      </Typography>
                    )}
                  </div>

                  <DrawerFooter>
                    <div className="pt-4 flex flex-wrap items-center gap-x-8 gap-y-3">
                      <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting
                          ? "Saving"
                          : values.sendInvite && values.email !== user.email
                            ? "Update & invite"
                            : "Update user"}
                      </Button>
                      <Button variant="text" type="button" onClick={handleClose} size="large">
                        Cancel
                      </Button>
                      {dirty && (
                        <Typography className="text-red-500" fontSize={12} fontWeight={400}>
                          You have unsaved changes
                        </Typography>
                      )}
                    </div>
                  </DrawerFooter>
                </DrawerBody>
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}
