import { Button } from "@mui/material"
import { clsx } from "clsx"
import { FC } from "react"
import { BiPlus } from "react-icons/bi"

type Props = {
  label: string
  onClick?: () => void
  shadow?: boolean
  disabled?: boolean
  component?: React.ElementType
  className?: string
}

export const AddRow: FC<Props> = ({
  label,
  onClick,
  shadow = false,
  disabled = false,
  component = "button",
  className,
}) => {
  return (
    <div
      className={clsx(
        className,
        "w-full text-center text-gray-400 font-light text-xs mt-2 mb-8 rounded-lg border border-gray-200 bg-white p-4 md:border-hidden md:bg-transparent px-0",
        shadow ? "shadow-2xl" : ""
      )}
    >
      <Button
        variant="contained"
        color="secondary"
        endIcon={<BiPlus />}
        disableElevation
        className="w-full text-left text-gray-800 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all cursor-pointer px-0"
        onClick={onClick}
        disabled={disabled}
        component={component}
      >
        {label}
      </Button>
    </div>
  )
}
