import { IconButton } from "@mui/material"
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRenderCellParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import { isValidElement } from "react"
import { BiChevronDown, BiChevronUp } from "react-icons/bi"

export const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props
  const apiRef = useGridApiContext()

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  const hasDetail = isValidElement(contentCache[id])

  return (
    <IconButton color="inherit" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      {isExpanded ? <BiChevronUp /> : <BiChevronDown />}
    </IconButton>
  )
}

export const CustomDetailPanelToggleColDef = {
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  width: 25,
  flex: undefined,
  renderCell: (params: GridRenderCellParams) => {
    const { id, value } = params
    return <CustomDetailPanelToggle id={id} value={value} />
  },
}
