import { Box, IconButton, LinearProgress, Skeleton, Typography, colors } from "@mui/material"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridCellParams, GridColDef } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { NextPage } from "next"
import Error from "next/error"
import { FC, useState } from "react"
import { BiChevronDown, BiChevronUp, BiImage, BiNote } from "react-icons/bi"
import { gray } from "tailwindcss/colors"
import { useQuery } from "urql"
import { InspectButton } from "../../../components/InspectionButton"
import { InventoryButton } from "../../../components/InventoryButton"
import { NAButton } from "../../../components/NAbutton"
import { PageTitle } from "../../../components/PageTitle"
import { AssetHistoryDropDown } from "../../../components/Partials/Assets/AssetHistoryDropdown"
import { AssetReportStatusPill } from "../../../components/Partials/Assets/AssetReportStatusPill"
import { AssetReportTypeTitle } from "../../../components/Partials/Assets/AssetReportTypeTitle"
import { AssetRepairRequests } from "../../../components/Partials/Inspection/AssetRepairRequests"
import { RenderIf } from "../../../components/RenderIf"
import { CompactUserBadge } from "../../../components/UserBadge"
import { NotificationsTable } from "../../../components/UserNotifications/NotificationTable"
import {
  AssetHistoryQuery,
  AssetReport,
  UserNotificationModelType,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import AssetPageLayout from "./_layout"
import { useRouter } from "next/router"
import { InspectionResult } from "../../../components/Partials/Assets/InspectionResult"
import { isNil } from "../../../helpers/util-functions"
import { EmptyStateBlock } from "../../../components/Table/EmptyStateBlock"

type Props = {
  assetId: string
}

const AssetHistoryDocument = graphql(`
  query AssetHistory($assetId: String!) {
    asset(id: $assetId) {
      id
      active
      groupQuantity
      name
      status
      assetChildCount
      assignableId
      assignableType
      isAssetGroup
      ownershipType
      repairRequestCount
      inferredOwner {
        id
      }
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
    }
    assetReports(assetId: $assetId) {
      id
      assetId
      notes
      createdAt
      quantityReported
      reporter {
        id
        archived
        firstName
        imageUrl
        jobTitle
        lastName
        isClockedIn
      }
      statusChange {
        active
        status
        missingCount
      }
      type
      inspectionReport {
        id
        label
        fileIds
        photoUrls
      }
      inspectionReportAggregations {
        trueCount
        falseCount
      }
      inventoryReport {
        fileIds
        note
        photoUrls
      }
    }
  }
`)

const isEmpty = (item: unknown) => item === null
const isTypeDismissRepair = (type: string) => type === "DismissRepair"
const isTypeRepairWithoutNotesAndPhotos = (type: string, notes?: string | null, inspectionReport?: unknown) =>
  type === "Repair" &&
  !notes &&
  Array.isArray(inspectionReport) &&
  inspectionReport.some((r) => (r.photoUrls?.length ?? 0) === 0)

const getTitleForAssetHistory = (repairRequestCount: number = 0) => {
  if (repairRequestCount > 0) {
    return "Repair Requests"
  }
  return "Asset History"
}

export const AssetHistory: FC<Props> = ({ assetId }) => {
  const [clickedIndex, setClickedIndex] = useState<string | null>(null)

  const gridCellCenterStyling = "flex gap-2 items-center "

  const [{ data, fetching }] = useQuery({
    query: AssetHistoryDocument,
    variables: { assetId },
  })
  const columns: GridColDef[] = [
    { field: "id" },
    {
      headerName: "Date",
      field: "createdAt",
      flex: 2,
      minWidth: 60,
      valueFormatter: (params) => {
        return format(params.value, "M/d/yyyy")
      },
    },
    {
      headerName: "Type",
      field: "type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <AssetReportTypeTitle type={params.row.type} />
        </Box>
      ),
    },
    {
      headerName: "By",
      field: "reporter",
      flex: 3,
      minWidth: 180,
      renderCell: (params) => (
        <Box>
          <CompactUserBadge user={params.row.reporter} />
        </Box>
      ),
    },

    {
      headerName: "Status",
      field: "status",
      flex: 2,
      minWidth: 175,
      renderCell: (params) => (
        <Box>
          <AssetReportStatusPill report={params.row} />
        </Box>
      ),
    },
    {
      headerName: "Result",
      field: "inspection",
      sortable: false,
      minWidth: 110,
      flex: 2,
      renderCell: (params: GridCellParams) => {
        return (
          <InspectionResult
            report={params.row}
            inspectionReportAggregations={params.row.inspectionReportAggregations}
          />
        )
      },
    },
    {
      headerName: "Notes",
      field: "notes",
      sortable: false,
      filterable: false,
      width: 75,
      renderCell: (params: GridCellParams) => {
        const reportNote = params.row.inventoryReport?.note || params.row.notes

        return params.row.type === "Transfer" ? (
          <NAButton />
        ) : (
          reportNote && (
            <div className={gridCellCenterStyling}>
              <BiNote className="rounded size-6 p-1 bg-gray-200" />
            </div>
          )
        )
      },
    },
    {
      headerName: "Img.",
      field: "image",
      sortable: false,
      filterable: false,
      width: 75,
      renderCell: (params: GridCellParams<AssetReport>) => {
        const photoCount = (
          params.row.inventoryReport?.photoUrls ||
          params.row?.inspectionReport?.flatMap((r) => r?.photoUrls)?.filter((url) => url) ||
          []
        ).length
        return (
          photoCount > 0 && (
            <div className={gridCellCenterStyling}>
              <BiImage className="rounded size-6 p-1 bg-gray-200" />
              <p className="ml-2 flex">{photoCount}</p>
            </div>
          )
        )
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params: GridCellParams<AssetReport>) => {
        const noDetails =
          (isEmpty(params.row.inventoryReport) && isEmpty(params.row.inspectionReport) && isEmpty(params.row.notes)) ||
          isTypeDismissRepair(params.row.type) ||
          isTypeRepairWithoutNotesAndPhotos(params.row.type, params.row.notes, params.row.inspectionReport)

        return (
          <IconButton
            key="collapse-history-row"
            disabled={noDetails}
            style={{ color: noDetails ? gray[300] : "inherit" }}
            onClick={() => {
              setClickedIndex(params.row?.id === clickedIndex ? null : params.row?.id)
            }}
          >
            {clickedIndex === params.row.id ? <BiChevronUp /> : <BiChevronDown>Collapse</BiChevronDown>}
          </IconButton>
        )
      },
    },
  ]

  const title = getTitleForAssetHistory(data?.asset?.repairRequestCount)
  const repairRequestCount = data?.asset?.repairRequestCount ?? 0

  return (
    <Box sx={{ position: "relative" }}>
      <NotificationsTable modelType={UserNotificationModelType.Asset} assetId={assetId} />
      <PageTitle title={`${data?.asset?.name || "Asset"} history`} />
      <Box sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <div className="flex items-center flex-wrap gap-8 justify-between mb-5">
          {title && (
            <Typography variant="h4" component="h4" className="text-2xl p-0 m-0">
              {title}
            </Typography>
          )}
          <Box display="flex" flexWrap="wrap" gap={1} alignItems="center">
            {data && (
              <InventoryButton
                asset={data.asset}
                assetGroups={data.assetGroups}
                includeIcon={true}
                style="secondary"
                className="mr-2"
              />
            )}
            {data &&
              data.asset?.inspectionTemplatesAssignments?.map((inspectionTemplatesAssignment) => (
                <Box display="flex" flexWrap="wrap" gap={1} alignItems="center" key={inspectionTemplatesAssignment.id}>
                  <InspectButton
                    asset={{
                      id: data.asset.id,
                      inspectionTemplatesAssignment,
                    }}
                    includeIcon={true}
                    style="secondary"
                    label={
                      data.asset?.inspectionTemplatesAssignments?.length > 1
                        ? inspectionTemplatesAssignment.inspectionTemplate?.name
                        : undefined
                    }
                  />
                </Box>
              ))}
          </Box>
        </div>

        {repairRequestCount > 0 || (fetching && isNil(data?.asset?.repairRequestCount)) ? (
          <AssetRepairRequests assetId={assetId} />
        ) : null}

        {fetching && !data?.assetReports ? (
          <Skeleton variant="rectangular" width="100%" height="250px" className="mt-[40px] border-2 rounded-md" />
        ) : (
          <>
            {title === "Repair Requests" && (
              <Typography variant="h4" component="h4" className="text-2xl mt-5">
                Asset History
              </Typography>
            )}
            <DataGridPro<AssetHistoryQuery["assetReports"][0]>
              autoHeight
              columns={columns}
              disableRowSelectionOnClick={true}
              getEstimatedRowHeight={() => 70}
              getRowHeight={() => "auto"}
              sx={{
                border: `1px solid ${colors.grey[300]}`,
              }}
              getDetailPanelHeight={() => "auto"}
              getDetailPanelContent={({ row }) => <AssetHistoryDropDown assetReportId={row.id} />}
              rows={data?.assetReports || []}
              slots={{
                loadingOverlay: LinearProgress,
                noRowsOverlay: () => <EmptyStateBlock label="No asset history" />,
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    projectId: true,
                    quantityReported: (data?.asset?.groupQuantity || 0) > 1,
                    taskId: true,
                    inspection: true,
                    status: true,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

const AssetPage: NextPage = () => {
  const { query } = useRouter()
  const assetId = query._id as string

  return (
    <RenderIf permissionsInclude="asset:read" fallbackComponent={<Error statusCode={404} />}>
      <AssetPageLayout assetId={assetId}>
        <AssetHistory assetId={assetId} />
      </AssetPageLayout>
    </RenderIf>
  )
}

export default AssetPage
