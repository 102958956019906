import { Box, Button } from "@mui/material"
import { clsx } from "clsx"
import { FC, useState } from "react"
import { GiCheckMark } from "react-icons/gi"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { RenderIf } from "../../RenderIf"
import { H2 } from "../../deprecated"
import { DrawerFooter } from "../Drawer/components/Elements/DrawerFooter"
import { AssetReassignment } from "./AssetReassignment"

export type Props = {
  asset: PickPlus<Asset, "id" | "name" | "assetChildCount" | "status">
  onCancel?: () => void
  onError?: () => void
  onSuccess?: () => void
  isDrawer?: boolean
}

export const ArchiveAssetWorkflow: FC<Props> = ({ onCancel, onSuccess, asset, isDrawer = false }) => {
  const [assetsReassigned, setAssetsReassigned] = useState<boolean>(false)
  const id = asset?.id
  const name = asset.name

  const handleArchiveAsset = async () => {
    onSuccess?.()
  }

  return (
    <RenderIf permissionsInclude="asset:update" fallbackComponent={<Box>No permission!</Box>}>
      <Box className="h-[74vh] w-full">
        <div className="flex flex-row flex-wrap">
          <H2 className="mr-4 truncate w-full">
            Archive Asset
            <span className="text-4xl mt-2 text-gray-400"> {name} </span>
          </H2>
        </div>
        {assetsReassigned ? (
          <Box className="text-gray-400 border-gray-200 border-2 flex flex-row justify-center items-center p-4 rounded-lg">
            <GiCheckMark className="text-lg mr-2" />

            <span>All equipment transferred</span>
          </Box>
        ) : (
          <div className={clsx(isDrawer ? "h-full" : "h-[68vh]", "min-h-[164px]")}>
            <AssetReassignment
              assetId={id}
              isArchiveEnabled
              onSuccess={() => setAssetsReassigned(true)}
              onLoad={(assetCount) => {
                setAssetsReassigned(assetCount === 0)
              }}
            />
          </div>
        )}
        <DrawerFooter className={isDrawer ? "ml-[-32px]" : "xl:left-[256px] left-0"}>
          <Box>
            <Button
              sx={{ marginLeft: 2 }}
              variant="contained"
              color="primary"
              disabled={!assetsReassigned}
              onClick={async () => {
                await handleArchiveAsset()
                onSuccess?.()
              }}
            >
              <span className="font-semibold">Select status</span>
            </Button>
            <Button sx={{ marginLeft: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </DrawerFooter>
      </Box>
    </RenderIf>
  )
}
