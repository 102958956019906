import { format, startOfMonth, startOfWeek } from "date-fns"
import { DateRangeFilterString } from "../types"

export function getGroupingKey(date: Date, dateRangeType: DateRangeFilterString): string {
  switch (dateRangeType) {
    case "monthly":
      // Using the first day of the week as the key
      return getMonthlyDate(date)
    case "all-time":
      // Using the first day of the month as the key
      return format(date, "yyyy-MM-01")
    default:
      // For daily and weekly, using the day itself as the key
      return format(date, "yyyy-MM-dd")
  }
}

function getMonthlyDate(date: Date) {
  const firstDayOfMonth = startOfMonth(date)
  const startOfWeekDate = startOfWeek(date)
  const groupingDate = startOfWeekDate < firstDayOfMonth ? firstDayOfMonth : startOfWeekDate
  return format(groupingDate, "yyyy-MM-dd")
}
