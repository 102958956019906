import { clsx } from "clsx"
import { FC, useState } from "react"
import { AiOutlineCamera } from "react-icons/ai"
import { User } from "../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../types/helpers"
import { Monogram } from "../../../Monogram"
import { PhotoBooth } from "../../../PhotoBooth"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "jobTitle" | "lastName">
  hasBeenPhotographed: boolean
  cancelPhoto: () => void
  collectPhoto: (photoBlob: Blob) => void
}

export const ClockInTeamMemberRow: FC<Props> = ({ user, hasBeenPhotographed, cancelPhoto, collectPhoto }) => {
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)

  return (
    <>
      <div className="flex gap-4 items-center">
        <Monogram user={user} />
        <div className="grow">
          <p>
            {user.firstName} {user.lastName}
          </p>
          <p className="text-sm text-gray-400">{user.jobTitle}</p>
        </div>
        <button
          type="button"
          className={clsx(
            "flex justify-center items-center rounded size-10 bg-gray-100 transition-all",
            hasBeenPhotographed && "bg-green-600"
          )}
          onClick={() => {
            if (hasBeenPhotographed) {
              cancelPhoto()
            } else {
              setPhotoBoothIsOpen(true)
            }
          }}
        >
          <AiOutlineCamera className={clsx("size-6", hasBeenPhotographed ? "text-white" : "text-gray-600")} />
        </button>
      </div>
      <PhotoBooth
        title={
          <div>
            Bulk Clock In{" "}
            <span className="text-gray-400">
              / {user.firstName} {user.lastName}
            </span>
          </div>
        }
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={collectPhoto}
        onFail={() => {}}
        withFaceFrame={true}
      />
    </>
  )
}
