import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { Task } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import { PickPlus } from "../../../../types/helpers"
import DeprecatedModal from "../../../deprecated/StandardModal"
import { TransferAssetModal } from "./TransferAssetModal"
import { TransferAssetModalSkeleton } from "./TransferAssetModal.skeleton"

const TransferAssetToTaskUserQuery = graphql(`
  query TransferAssetToTaskUserQuery($taskId: String!, $filter: UserFilter!) {
    allAssets: assets {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
      assignableType
    }
    taskAssets: assets(taskId: $taskId) {
      id
    }
    users(filter: $filter) {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          lastName
          jobTitle
          taskId
          projectId
        }
      }
    }
  }
`)

export const TransferAssetToTaskUser: FC<{
  formModalProps: ModalProps
  task: PickPlus<Task, "id" | "name" | "projectId"> | null
  selectedAssets?: string[]
  selectedUserIds?: string[]
  onSuccess?: () => void
}> = ({ formModalProps, task, selectedAssets = [], selectedUserIds, onSuccess }) => {
  const [{ data: componentData, fetching }] = useQuery({
    query: TransferAssetToTaskUserQuery,
    variables: { taskId: task!.id, filter: { taskId: task!.id } },
    pause: !task?.id,
  })

  const taskTeamMembers = useMemo(() => {
    return componentData?.users?.edges?.map((edge) => edge!.node) || []
  }, [componentData?.users?.edges])

  const assetsAvailableForAssignment = useMemo(() => {
    const taskAssetIds = componentData?.taskAssets?.map((asset) => asset.id) || []
    return componentData?.allAssets?.filter((asset) => !taskAssetIds.includes(asset.id)) || []
  }, [componentData?.allAssets, componentData?.taskAssets])

  const componentUIState = fetching ? "fetching" : "loaded"

  return (
    <DeprecatedModal {...formModalProps}>
      {componentUIState === "fetching" && <TransferAssetModalSkeleton handleClose={formModalProps.handleCloseModal} />}

      {componentUIState === "loaded" && task && (
        <TransferAssetModal
          formModalProps={formModalProps}
          assets={assetsAvailableForAssignment}
          assetsSelectedToTransfer={selectedAssets}
          selectedUserIds={selectedUserIds}
          onSuccess={onSuccess}
          projectId={task.projectId}
          teamMembers={taskTeamMembers}
          task={task}
        />
      )}
    </DeprecatedModal>
  )
}
