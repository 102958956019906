import { Box, Button } from "@mui/material"
import { clsx } from "clsx"
import { FC, useState } from "react"
import { GiCheckMark } from "react-icons/gi"
import {
  Task,
  useMarkProjectAsCompleteMutation,
  useMarkTaskAsCompleteMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { ProjectExpectation } from "../../../types/ProjectExpectation"
import { PickPlus } from "../../../types/helpers"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { RenderIf } from "../../RenderIf"
import { H2 } from "../../deprecated"
import { AssetReassignment } from "../Assets/AssetReassignment"
import { DrawerFooter } from "../Drawer/components/Elements/DrawerFooter"
import { DeleteTaskModal } from "../Projects/Tasks/DeleteTaskModal"
import { UserReassignment } from "../User/UserReassignment"

export type TaskAction = "completeTask" | "deleteTask"
const taskActionLabels: Record<TaskAction, string> = {
  completeTask: "complete",
  deleteTask: "delete",
}

export const getTaskActionLabel = (action: TaskAction) => taskActionLabels[action] || "complete"

interface TaskActionLabelProps {
  taskAction: TaskAction
  completeProject: boolean
  isDrawer?: boolean
}

const TaskActionLabel = ({ taskAction, completeProject }: TaskActionLabelProps) => {
  if (taskAction === "completeTask") {
    return <span className="font-semibold">{completeProject ? "Complete task and project" : "Complete Task"}</span>
  }

  return <span className="font-semibold">Delete task</span>
}

export type Props = {
  onCancel?: () => void
  onError?: () => void
  onSuccess?: () => void
  project: ProjectExpectation
  task: PickPlus<Task, "id" | "name">
  taskAction?: TaskAction
  isDrawer?: boolean
}

const enum Stage {
  ReassignUsers,
  ReassignAssets,
  CloseTask,
}

type StageCompleted = Record<Stage, boolean>

const stageDefaults = {
  [Stage.ReassignUsers]: false,
  [Stage.ReassignAssets]: false,
  [Stage.CloseTask]: false,
}

export const CloseTaskWorkflow: FC<Props> = ({
  onCancel,
  onError,
  onSuccess,
  project,
  task,
  taskAction = "completeTask",
  isDrawer = false,
}) => {
  const { id, name } = task
  const taskToDelete = { ...task, projectId: project?.id ?? "" }
  const [completed, setCompleted] = useState<StageCompleted>(stageDefaults)

  const projectIsEligibleForCompletion = project?.assetsCount === 0 && project?.userCount === 0
  const deleteTaskModalProps = useModalProps("Delete Task")

  const [{}, markTaskCompleteMutation] = useMarkTaskAsCompleteMutation()
  const [{}, markProjectAsCompleteMutation] = useMarkProjectAsCompleteMutation()

  const handleCompleteTask = async () => {
    const result = await markTaskCompleteMutation({ id })
    if (result.error) {
      console.error(result.error)
      onError?.()
      errorSnack("Error completing task; please try again")
    } else {
      handleCompleteStage(Stage.CloseTask)
      if (projectIsEligibleForCompletion) {
        const projectCompleteResult = await markProjectAsCompleteMutation({ id: project?.id })
        if (projectCompleteResult.error) {
          console.error(projectCompleteResult.error)
          errorSnack("Error completing project; please try again")
          onError?.()
        } else {
          successSnack("Success! Task and project are completed!")
          onSuccess?.()
        }
      } else {
        successSnack("Success! Task completed!")

        onSuccess?.()
      }
    }
  }

  const handleCompleteStage = (completeStage: Stage) => {
    setCompleted((stages) => ({ ...stages, [completeStage]: true }))
  }

  const handleDeleteTask = async () => {
    deleteTaskModalProps.handleOpenModal()
  }

  return (
    <RenderIf permissionsInclude="task:complete" fallbackComponent={<Box>No permission!</Box>}>
      <Box className="h-[66vh] w-full">
        <div className="flex flex-row flex-wrap">
          <H2 className="mr-4 truncate w-full">
            {taskAction === "completeTask" ? "Complete task" : "Delete task"}
            <span className="text-4xl mt-2 text-gray-400"> {name} </span>
          </H2>
        </div>
        {completed[Stage.ReassignUsers] ? (
          <Box className="text-gray-400 border-gray-200 border-2 flex flex-row justify-center items-center p-4 rounded-lg mb-[16px]">
            <GiCheckMark className="text-lg mr-2" />

            <span>All team members reassigned</span>
          </Box>
        ) : (
          <div className={clsx(isDrawer ? "h-[36vh]" : "h-[34vh]", "min-h-[164px] mb-[16px]")}>
            <UserReassignment
              projectId={project?.id || ""}
              taskId={id}
              onSuccess={() => handleCompleteStage(Stage.ReassignUsers)}
            />
          </div>
        )}
        {completed[Stage.ReassignAssets] ? (
          <Box className="text-gray-400 border-gray-200 border-2 flex flex-row justify-center items-center p-4 rounded-lg">
            <GiCheckMark className="text-lg mr-2" />

            <span>All equipment transferred</span>
          </Box>
        ) : (
          <div className={clsx(isDrawer ? "h-[36vh]" : "h-[34vh]", "min-h-[164px]")}>
            <AssetReassignment taskId={id} onSuccess={() => handleCompleteStage(Stage.ReassignAssets)} />
          </div>
        )}
        <DrawerFooter className={isDrawer ? "ml-[-32px]" : "xl:left-[256px] left-0"}>
          <Box>
            <Button
              sx={{ marginLeft: 2 }}
              variant="contained"
              color="primary"
              disabled={!completed[Stage.ReassignUsers] || !completed[Stage.ReassignAssets]}
              onClick={async () => {
                if (taskAction === "completeTask") {
                  await handleCompleteTask()
                  onSuccess?.()
                } else {
                  await handleDeleteTask()
                }
              }}
            >
              <TaskActionLabel taskAction={taskAction} completeProject={projectIsEligibleForCompletion} />
            </Button>
            <Button sx={{ marginLeft: 2 }} onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </DrawerFooter>
        {deleteTaskModalProps.isOpen && (
          <DeleteTaskModal
            modalProps={deleteTaskModalProps}
            closeModal={deleteTaskModalProps.handleCloseModal}
            task={taskToDelete}
            isTaskGroup={false}
            onSuccess={() => {
              onSuccess?.()
            }}
          />
        )}
      </Box>
    </RenderIf>
  )
}
