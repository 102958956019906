import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { UserAssignmentsTable } from "../../UserAssignmentsTable"

const TaskExtraAssignmentTableDocument = graphql(`
  query TaskExtraAssignmentTable($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      name
      projectId
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        task {
          id
          isComplete
          name
          projectId
        }
        userId
        user {
          id
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
        }
      }
    }
  }
`)

type TaskExtraAssignmentTableProps = {
  taskId: string
  projectId: string
}

export const TaskExtraAssignmentTable: FC<TaskExtraAssignmentTableProps> = ({ taskId, projectId }) => {
  const [{ data }, refetch] = useQuery({
    query: TaskExtraAssignmentTableDocument,
    variables: { taskId },
  })

  return (
    <UserAssignmentsTable
      assignments={data?.task.assignees || []}
      preassignments={{ taskId, projectId }}
      refetch={refetch}
      title="User Task Access"
      groupBy="task"
    />
  )
}
