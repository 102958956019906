import { Button, ButtonGroup, Checkbox, Typography, useTheme, useMediaQuery } from "@mui/material"
import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react"
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { graphql } from "../../../graphql/generated/gql"
import { colors } from "../../../helpers/styles/colors"
import { UnitsData } from "./helpers/getGraphData"
import { BiChevronLeft, BiChevronRight, BiSolidCircle } from "react-icons/bi"
import { NormalizedDateRange } from "../../../hooks/useMuiDateRange"
import { addDays, endOfDay, format, startOfDay } from "date-fns"
import { HoursAndMinutesFromHoursDecimal } from "../../Time/HoursAndMinutesFromHoursDecimal"

export const ProjectUnitsGraphProgressDocument = graphql(`
  query ProjectUnitsGraphProgress(
    $entityId: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $rangeStartDay: String!
    $rangeEndDay: String!
  ) {
    project(id: $entityId) {
      id
      hoursDataByDateRangeType(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeStart: $rangeStartDay, rangeEnd: $rangeEndDay) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            isPrimary
            deliverableUnit {
              description
              color
              unitOfMeasure
            }
          }
        }
      }
    }
  }
`)

export const TaskUnitsGraphProgressDocument = graphql(`
  query TaskUnitsGraphProgress(
    $entityId: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $rangeStartDay: String!
    $rangeEndDay: String!
  ) {
    task(id: $entityId) {
      id
      hoursDataByDateRangeType(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeEnd: $rangeEndDay, rangeStart: $rangeStartDay) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            isPrimary
            deliverableUnit {
              description
              color
              unitOfMeasure
            }
          }
        }
      }
    }
  }
`)

type SummaryUnitsGraphProps = {
  filteredData: {
    [x: string]: string | number | boolean | {[taskId: string]: number}
    name: string
  }[]
  hoursVisibility: boolean
  setHoursVisibility: (value: boolean) => void
  units: UnitsData[]
  setGraphDateRange: Dispatch<SetStateAction<NormalizedDateRange>>
  graphRangeStart: Date
  graphRangeEnd: Date
  minDate: Date
  isTaskDrawer?: boolean
  rangeStart: Date
  rangeEnd: Date
}

export const SummaryUnitsGraph: FC<SummaryUnitsGraphProps> = ({
  filteredData,
  hoursVisibility,
  setHoursVisibility,
  units,
  setGraphDateRange,
  graphRangeStart,
  graphRangeEnd,
  minDate,
  isTaskDrawer = false,
  rangeStart,
  rangeEnd,
}) => {
  const isNextDisabled = graphRangeEnd >= new Date()
  const isPrevDisabled = graphRangeStart <= minDate
  const minDateFormatted = format(addDays(minDate, -1), "MMM dd yyyy")
  const isArrowClicked = useRef(false)

  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth
    }
  }, [])

  useEffect(() => {
    if (isArrowClicked.current) return

    if (startOfDay(rangeStart) < startOfDay(graphRangeStart)) {
      setGraphDateRange({
        rangeStart: startOfDay(rangeStart),
        rangeEnd: endOfDay(addDays(rangeStart, 30)),
      })
    }
    if (endOfDay(rangeEnd) > endOfDay(graphRangeEnd) && endOfDay(rangeEnd) < endOfDay(new Date())) {
      setGraphDateRange({
        rangeStart: startOfDay(addDays(rangeEnd, -30)),
        rangeEnd: endOfDay(rangeEnd),
      })
    }
    if (
      (endOfDay(rangeEnd) > endOfDay(graphRangeEnd) && endOfDay(rangeEnd) > endOfDay(new Date())) ||
      startOfDay(rangeStart).getTime() === startOfDay(rangeEnd).getTime() ||
      (startOfDay(graphRangeStart) < startOfDay(rangeStart) &&
        endOfDay(rangeEnd).getTime() === endOfDay(new Date()).getTime())
    ) {
      const newRangeStart = startOfDay(addDays(new Date(), -30))
      const newRangeEnd = endOfDay(new Date())

      if (newRangeStart.getTime() !== graphRangeStart.getTime() || newRangeEnd.getTime() !== graphRangeEnd.getTime()) {
        setGraphDateRange({
          rangeStart: newRangeStart,
          rangeEnd: newRangeEnd,
        })
      }
    }
  }, [rangeStart, graphRangeStart, rangeEnd, graphRangeEnd, setGraphDateRange])

  return (
    filteredData && (
      <>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <Typography variant="h4" className="flex items-center ml-8 my-0">
              Units
            </Typography>
            <ButtonGroup>
              <Button
                className="min-w-0 p-2"
                color="secondary"
                variant="contained"
                onClick={() => {
                  isArrowClicked.current = true
                  if (addDays(graphRangeStart, -7) <= minDate) {
                    setGraphDateRange({
                      rangeStart: startOfDay(minDate),
                      rangeEnd: endOfDay(addDays(minDate, 30)),
                    })
                  } else {
                    setGraphDateRange((prevRange) => ({
                      rangeStart: addDays(prevRange.rangeStart, -7),
                      rangeEnd: addDays(prevRange.rangeEnd, -7),
                    }))
                  }
                  setTimeout(() => {
                    isArrowClicked.current = false
                  }, 0)
                }}
                disabled={isPrevDisabled}
              >
                <BiChevronLeft className="size-6" />
              </Button>
              <Button
                className="min-w-0 p-2"
                color="secondary"
                variant="contained"
                onClick={() => {
                  isArrowClicked.current = true
                  if (addDays(graphRangeEnd, 7) > new Date()) {
                    setGraphDateRange({
                      rangeStart: startOfDay(addDays(new Date(), -30)),
                      rangeEnd: endOfDay(new Date()),
                    })
                  } else {
                    setGraphDateRange((prevRange) => ({
                      rangeStart: addDays(prevRange.rangeStart, 7),
                      rangeEnd: addDays(prevRange.rangeEnd, 7),
                    }))
                  }
                  setTimeout(() => {
                    isArrowClicked.current = false
                  }, 0)
                }}
                disabled={isNextDisabled}
              >
                <BiChevronRight className="size-6" />
              </Button>
            </ButtonGroup>
          </div>
          <div className="flex mr-10">
            <Checkbox checked={hoursVisibility} onClick={() => setHoursVisibility(!hoursVisibility)} />
            <Typography variant="body1" className="flex items-center">
              Show Hours
            </Typography>
          </div>
        </div>

        {!mobileScreen && (
          <UnitsGraph
            filteredData={filteredData}
            isTaskDrawer={isTaskDrawer}
            minDate={minDate}
            units={units}
            hoursVisibility={hoursVisibility}
            minDateFormatted={minDateFormatted}
          />
        )}
        {mobileScreen && (
          <div ref={scrollContainerRef} style={{ width: "100%", overflowX: "auto" }}>
            <UnitsGraph
              filteredData={filteredData}
              isTaskDrawer={isTaskDrawer}
              minDate={minDate}
              units={units}
              hoursVisibility={hoursVisibility}
              minDateFormatted={minDateFormatted}
              isMobile
            />
          </div>
        )}
      </>
    )
  )
}

interface CustomTickPayload {
  coordinate: number
  value: string
  index: number
  offset: number
}

interface CustomTickProps {
  x?: number
  y?: number
  payload?: CustomTickPayload
  minDate: Date
}

const CustomTick: FC<CustomTickProps> = ({ x, y, payload, minDate }) => {
  let color
  if (payload && new Date(payload.value) < minDate) {
    color = colors.gray[400]
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill={color} fontSize={12}>
        {payload && format(new Date(payload.value), "EEEEEdd")}
      </text>
    </g>
  )
}

interface CustomTooltipPayloadProps {
  color: string
  name: string
  value: number
}
interface CustomTooltipProps {
  payload?: CustomTooltipPayloadProps[]
  label?: string
  active?: boolean
  units: UnitsData[]
}

const CustomToolTip: FC<CustomTooltipProps> = ({ payload, label, active, units }) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  if (active && payload && label) {
    return (
      <div className="bg-white p-2 shadow-lg rounded-md">
        <Typography variant="body1" className="mb-2">
          {label}
        </Typography>
        {payload.map((unit, key) => {
          const unitOfMeasure = units.find((u) => u.description === unit.name)?.unitOfMeasure
          return (
            <div key={key} className="flex justify-between gap-16">
              <div className="flex items-center gap-1">
                <BiSolidCircle color={unit.color} className="size-2" />
                <Typography key={key} variant="body1">
                  {capitalizeFirstLetter(unit.name)}
                </Typography>
              </div>
              <div className="flex justify-start gap-1 w-40">
                {unit.name === "hours" ? (
                  <HoursAndMinutesFromHoursDecimal hoursInput={unit.value} />
                ) : (
                  <div className="flex gap-1">
                    <Typography variant="body1">{unit.value}</Typography>
                    <Typography variant="body1">{unitOfMeasure}</Typography>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

type UnitsGraphType = {
  filteredData: {
    [x: string]: string | number | boolean | {[taskId: string]: number}
    name: string
  }[]
  isTaskDrawer?: boolean
  minDate: Date
  units: UnitsData[]
  hoursVisibility: boolean
  minDateFormatted: string
  isMobile?: boolean
}

const UnitsGraph: FC<UnitsGraphType> = ({
  filteredData,
  isTaskDrawer = false,
  minDate,
  units,
  hoursVisibility,
  minDateFormatted,
  isMobile = false,
}) => {
  const chart = (
    <ComposedChart
      width={isTaskDrawer ? 900 : 1200}
      height={300}
      data={filteredData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 20,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis
        dy={15}
        dataKey="name"
        tick={<CustomTick minDate={minDate} />}
        interval={0}
        tickFormatter={(tickItem) => format(new Date(tickItem), "EEEEEdd")}
      />
      <YAxis
        yAxisId="left"
        orientation="left"
        label={{ value: "Units", dy: 18, position: "bottom", style: { fontSize: 14 } }}
        tick={{ fontSize: 12 }}
      />
      <YAxis
        yAxisId="right"
        orientation="right"
        label={{ value: "Hours", dy: 18, position: "bottom", style: { fontSize: 14 } }}
        tick={{ fontSize: 12 }}
      />

      <Tooltip content={<CustomToolTip units={units} />} />
      {hoursVisibility && (
        <Line
          activeDot={{ r: 8 }}
          dataKey="hours"
          dot={false}
          stroke={colors.blue[600]}
          strokeDasharray="4 4"
          strokeWidth={2}
          type="monotone"
          yAxisId="right"
        />
      )}
      {units?.map((unit, i) => (
        <Bar yAxisId="left" key={i} dataKey={unit.description} stackId="a" barSize={20} fill={unit.color}>
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={unit.color} fillOpacity={entry.highlightBar ? 1 : 0.3} />
          ))}
        </Bar>
      ))}
      <ReferenceArea x2={minDateFormatted} fill={colors.gray[300]} fillOpacity={0.5} yAxisId="left" />
    </ComposedChart>
  )
  return isMobile ? (
    chart
  ) : (
    <ResponsiveContainer width="100%" height={300}>
      {chart}
    </ResponsiveContainer>
  )
}
