import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC, useContext } from "react"
import { TransferAssetToTaskUser } from "../../../../components/Modals/components/TransferAssetModal/TransferAssetToTaskUser"
import { useModalProps } from "../../../../components/Modals/hooks/useModalProps"
import { PageTitle } from "../../../../components/PageTitle"
import { AssetListDataGrid } from "../../../../components/Partials/Assets/DataGrid/AssetListDataGrid"
import { RenderIf } from "../../../../components/RenderIf"
import TaskLayout, { TaskLayoutContext } from "./_layout"

export const TaskAssetsPageContents: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const { task } = useContext(TaskLayoutContext)

  const assetModalProps = useModalProps("Transfer Assets")

  const handleSuccess = () => {
    onSuccess?.()
  }

  return (
    <>
      <PageTitle title={`${task?.name || "Task"} assets`} />
      {task && (
        <AssetListDataGrid
          taskId={task.id}
          isComplete={!!task.isComplete}
          handleAssignAsset={assetModalProps.handleOpenModal}
        />
      )}
      {assetModalProps.isOpen && task && (
        <TransferAssetToTaskUser formModalProps={assetModalProps} task={task} onSuccess={handleSuccess} />
      )}
    </>
  )
}

const TaskPage: NextPage = () => {
  const { query } = useRouter()
  const projectId = query._project_id as string
  const taskId = query._task_id as string

  return (
    <RenderIf
      permissionsInclude="project:read"
      context={{ projectId, taskId }}
      fallbackComponent={<Error statusCode={404} />}
    >
      <TaskLayout taskId={taskId} useDataGridStyles>
        <TaskAssetsPageContents />
      </TaskLayout>
    </RenderIf>
  )
}

export default TaskPage
