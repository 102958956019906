import { TextField } from "@mui/material"
import { clsx } from "clsx"
import { Dispatch, FC, RefObject, SetStateAction, useState } from "react"
import { BiHealth } from "react-icons/bi"
import SignatureCanvas from "react-signature-canvas"
import { SignaturePad } from "../../SignaturePad"
import Checkbox from "../../deprecated/Checkbox"

type Props = {
  setIsInjured: Dispatch<SetStateAction<boolean>>
  isInjured: boolean
  setInjuryNotes: Dispatch<SetStateAction<string>>
  injuryNotes: string
  setSignature?: Dispatch<SetStateAction<Blob | undefined>>
  signature?: Blob
  setSignaturePadTouched: Dispatch<SetStateAction<boolean>>
  signatureCanvasRef: RefObject<SignatureCanvas>
}

export const signatureCanvasToBlob = (signatureCanvasRef: RefObject<SignatureCanvas>) => {
  return new Promise<Blob>((resolve, reject) => {
    if (!signatureCanvasRef.current) return reject("Signature ref not found")
    signatureCanvasRef.current.getCanvas().toBlob((blob) => {
      if (!blob) return reject("Signature not found")
      return resolve(blob)
    }, "image/webp")
  })
}

export const InjurySignature: FC<Props> = ({
  setIsInjured,
  isInjured,
  setInjuryNotes,
  injuryNotes,
  setSignaturePadTouched,
  signature,
  setSignature,
  signatureCanvasRef,
}) => {
  const [_isDisabled, setIsDisabled] = useState(true)

  return (
    <>
      <div className="flex justify-between p-4 bg-gray-100 rounded-lg mb-4 items-center text-gray-400 transition-all">
        <div className="w-full">
          <div className="flex justify-between">
            <div>
              <p className="uppercase font-semibold text-sm mb-4">Were you injured?</p>
              <div className="flex gap-8">
                <label className="flex gap-2 items-center">
                  <Checkbox checked={!isInjured} onChange={() => setIsInjured(false)} />
                  No
                </label>
                <label className="flex gap-2 items-center">
                  <Checkbox
                    checked={isInjured}
                    onChange={() => setIsInjured(true)}
                    className={isInjured ? "text-orange-600" : ""}
                  />
                  Yes
                </label>
              </div>
            </div>
            <BiHealth
              className={clsx("size-10 text-gray-400 mr-2 transition-all self-center", isInjured && "text-orange-600")}
            />
          </div>
          {isInjured && (
            <div className="pt-6 w-full">
              <TextField
                fullWidth
                variant="outlined"
                label="Injury report notes"
                type="text"
                multiline
                value={injuryNotes}
                onChange={(e) => setInjuryNotes?.(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <SignaturePad
        ref={signatureCanvasRef}
        setIsEmpty={setIsDisabled}
        onEnd={() => setSignaturePadTouched(true)}
        signature={signature}
        onClear={() => {
          setSignature?.(undefined)
          setSignaturePadTouched(false)
        }}
      />
    </>
  )
}
