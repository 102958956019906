import { clsx } from "clsx"
import { FC } from "react"
import { User } from "../graphql/generated/client-types-and-hooks"
import { useUpdatingUserClockedInHoursAndMinutes } from "../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { PickPlus } from "../types/helpers"
import { TotalTimeWithEdits } from "./Partials/User/TimeEntries/TotalTimeWithEdits"

type Props = {
  user: PickPlus<User, "isClockedIn" | "secondsClockedSinceOrgMidnight">
}

export const UserTimeCounter: FC<Props> = ({ user }) => {
  const { fullDurationInSeconds } = useUpdatingUserClockedInHoursAndMinutes(user)

  return (
    <div className={clsx("flex items-center gap-x-1 w-full justify-center text-base", "md:justify-start")}>
      <TotalTimeWithEdits
        totalTimeInSeconds={fullDurationInSeconds}
        totalEditedTimeInSeconds={null}
        className="flex-col"
        isClockedIn={user.isClockedIn}
        showSeconds={false}
      />
    </div>
  )
}
