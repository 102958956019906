import { Button, InputAdornment, TextField, Typography } from "@mui/material"
import { Formik, FormikValues } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { AssetGroup, useArchiveQuantitiesMutation } from "../../../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../../../helpers/strings/pluralize"
import { ModalProps } from "../../../../Modals/hooks/useModalProps"
import { ModalBody } from "../../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../../Modals/components/Elements/ModalFooter"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"
import DeprecatedModal from "../../../../deprecated/StandardModal"

export const ArchiveQuantizedAssetModal: FC<{ modalProps: ModalProps; asset: AssetGroup | undefined }> = ({
  modalProps,
  asset,
}) => {
  const [_, archiveAssets] = useArchiveQuantitiesMutation()

  const handleSubmit = (values: FormikValues) => {
    if (!asset || values.quantityToArchive <= 0 || values.quantityToArchive > (asset.count || 0)) {
      modalProps.handleCloseModal()
    }

    archiveAssets({
      assetGroupId: asset!.assetGroupId,
      assignableId: asset!.assignableId,
      assignableType: asset!.assignableType,
      status: asset!.status,
      quantityToArchive: Number(values.quantityToArchive),
    }).then((result) => {
      result.error
        ? errorSnack("An error occurred while archiving assets. Please try again.")
        : successSnack(`Archived ${+values.quantityToArchive} ${pluralize("asset", +values.quantityToArchive)}`)

      modalProps.handleCloseModal()
    })
  }

  return (
    <DeprecatedModal {...modalProps}>
      <Formik
        enableReinitialize
        initialValues={{ quantityToArchive: asset?.count || 0 }}
        validationSchema={Yup.object().shape({
          quantityToArchive: Yup.number()
            .min(1, "Quantity must be 1 or greater")
            .max(asset?.count || 1, `Quantity cannot be greater than ${asset?.count || 1}`)
            .integer("Quantity must be a whole number")
            .required("A value must be entered"),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, values, setFieldValue, isValid }) => (
          <>
            <ModalBody>
              <div className="flex flex-col">
                <Typography variant="body1">How many units would you like to archive?</Typography>
                <TextField
                  name="quantityToArchive"
                  className="py-4"
                  value={values.quantityToArchive}
                  onChange={(e) => {
                    setFieldValue("quantityToArchive", e.target.value)
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={14} className="text-gray-400">
                          of {asset?.count || 0}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "150px" }}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="text" onClick={modalProps.handleCloseModal}>
                Cancel
              </Button>
              <Button variant="contained" color="error" onClick={submitForm} disabled={isSubmitting || !isValid}>
                Archive
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </DeprecatedModal>
  )
}
