import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { useState } from "react"
import { BiChevronDown, BiStopwatch } from "react-icons/bi"
import { CustomMenu } from "../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "../../Assets/DataGrid/AssetFilters/AssetFilterState"
import { testLabel_ListFilterButton, testLabel_ListFilterMenu } from "./labels"
type Label = "Clocked in" | "Clocked out" | "All"

type TimeFilterOption = {
  value: boolean | null
  label: Label
  onSelect: () => void
}
// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  isClockedIn: boolean | null
  setIsClockedIn: (isClockedIn: boolean | null) => void
}

export const TeamListTimeFilter = ({ isClockedIn, setIsClockedIn }: Props): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const timeFilterOptions: TimeFilterOption[] = [
    {
      value: null,
      label: "All",
      onSelect: () => setIsClockedIn(null),
    },
    {
      value: true,
      label: "Clocked in",
      onSelect: () => setIsClockedIn(true),
    },
    {
      value: false,
      label: "Clocked out",
      onSelect: () => setIsClockedIn(false),
    },
  ]

  const getFilterLabel = (bool: boolean | null) =>
    timeFilterOptions.find((option) => option.value === bool)?.label || timeFilterOptions[0].label

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        test-label={testLabel_ListFilterMenu}
        anchor={
          <div
            className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800"
            test-label={testLabel_ListFilterButton}
          >
            <BiStopwatch />
            <span className="text-gray-800">Time</span>
            <span className="text-gray-500">{getFilterLabel(isClockedIn)}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {timeFilterOptions.map((visFilterOption) => (
            <MenuItem
              key={visFilterOption.label}
              onClick={() => {
                visFilterOption.onSelect()
                setIsClockedIn(visFilterOption.value)
              }}
            >
              <SingleOrMultiSelectionMenuItem selected={isClockedIn === visFilterOption.value} />
              <ListItemText>{visFilterOption.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
