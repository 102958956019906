import { gql } from "urql"

export const TASK_CREATE_MANY = gql`
  mutation CreateManyTasks($projectId: String!, $tasks: [TaskInput!], $taskGroups: [GroupInput!]) {
    insertManyTasks(projectId: $projectId, tasks: $tasks, taskGroups: $taskGroups) {
      createdTasksCount
      createdTaskGroupsCount
      createdSubTasksCount
      createdUnitGoalsCount
    }
  }
`

export const TASK_CREATE = gql`
  mutation TaskCreate(
    $groupId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $estimatedWorkHours: Int
    $metadata: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $unitGoals: [UnitGoalInput!]
    $workersCompCodeId: String
    $schedule: ScheduleInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    insertOneTask(
      groupId: $groupId
      projectId: $projectId

      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      estimatedWorkHours: $estimatedWorkHours
      metadata: $metadata
      name: $name
      startDate: $startDate
      unitGoals: $unitGoals
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      id
      groupId
      projectId

      createdAt
      description
      endDate
      estimatedHours
      isDefault
      metadata {
        label
        content
      }
      name
      startDate
      sumOfDeliveredUnits
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
      workersCompCode {
        id
        name
        ncciCode
        ncciDescription
        override
        rate
      }
      unitGoals {
        id
      }
    }
  }
`

export const MARK_TASK_COMPLETE = gql`
  mutation MarkTaskAsComplete($id: String!) {
    markTaskCompletion(id: $id, completed: true) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
`

export const MARK_TASK_INCOMPLETE = gql`
  mutation MarkTaskAsIncomplete($id: String!) {
    markTaskCompletion(id: $id, completed: false) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
`

export const EDIT_TASK = gql`
  mutation TaskEdit(
    $id: String!
    $groupId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $estimatedWorkHours: Int
    $metadata: [MetadataNoteInput!]
    $metadataToAdd: [MetadataNoteInput!]
    $metadataToRemove: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $unitGoals: [UnitGoalInput!]
    $workersCompCodeId: String
    $schedule: ScheduleInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    updateOneTask(
      id: $id
      groupId: $groupId
      projectId: $projectId

      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      estimatedWorkHours: $estimatedWorkHours
      metadata: $metadata
      metadataToAdd: $metadataToAdd
      metadataToRemove: $metadataToRemove
      name: $name
      startDate: $startDate
      unitGoals: $unitGoals
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      ...TaskBase
      ...TaskAdditional
    }
  }
`

export const DELETE_TASK = gql`
  mutation DeleteTask($id: String!) {
    deleteOneTask(id: $id) {
      id
      name
    }
  }
`

export const DELETE_TASK_GROUP = `
  mutation DeleteTaskGroup($id: String!) {
    deleteOneTaskGroup(id: $id) {
      id
      name
    }
  }
`

export const REPORT_TASK_PROGRESS = gql`
  mutation ReportTaskProgress(
    $projectId: String!
    $taskId: String!
    $fileIds: [String!]
    $note: String
    $unitGoalProgress: [UnitGoalProgressInput!]
  ) {
    reportTaskProgress(
      projectId: $projectId
      taskId: $taskId
      fileIds: $fileIds
      note: $note
      unitGoalProgress: $unitGoalProgress
    ) {
      id
    }
  }
`

export const CREATE_MANY_TASK_REPORTS = gql`
  mutation CreateManyTaskReports($projectId: String!, $taskReports: [TaskReportInput!]!) {
    createManyTaskReports(projectId: $projectId, taskReports: $taskReports) {
      ...TaskBase
      unitGoals {
        ...UnitGoalBase
      }
    }
  }
`

export const CREATE_TASK_DEPENDENCY = gql`
  mutation CreateTaskDependency(
    $dependentTaskId: String!
    $predecessorTaskId: String!
    $projectId: String!
    $type: TaskDependencyType!
    $metadata: TaskDependencyDataInput!
  ) {
    createTaskDependency(
      dependentTaskId: $dependentTaskId
      predecessorTaskId: $predecessorTaskId
      projectId: $projectId
      type: $type
      metadata: $metadata
    ) {
      dependentTaskId
      predecessorTaskId
      type
      metadata {
        offsetDays
      }
    }
  }
`

export const UPDATE_TASK_DEPENDENCY = gql`
  mutation UpdateTaskDependency(
    $id: String!
    $dependentTaskId: String!
    $predecessorTaskId: String!
    $projectId: String!
    $type: TaskDependencyType!
    $metadata: TaskDependencyDataInput!
  ) {
    updateTaskDependency(
      id: $id
      dependentTaskId: $dependentTaskId
      predecessorTaskId: $predecessorTaskId
      projectId: $projectId
      type: $type
      metadata: $metadata
    ) {
      dependentTaskId
      predecessorTaskId
      type
      metadata {
        offsetDays
      }
    }
  }
`

export const DELETE_TASK_DEPENDENCY = gql`
  mutation DeleteTaskDependency($id: String!) {
    deleteTaskDependency(id: $id) {
      dependentTaskId
      predecessorTaskId
      projectId
      type
    }
  }
`

export const GET_TASK_SCHEDULE = gql`
  query GetTaskSchedule($taskId: String!) {
    task(id: $taskId) {
      id
      orgScheduleId
      projectScheduleId
      schedule {
        ...ScheduleBase
      }
      scheduledBreaks {
        ...ScheduledBreaksBase
      }
    }
  }
`

export const DELETE_CUSTOM_TASK_SCHEDULE = gql`
  mutation DeleteCustomTaskSchedule($scheduleId: String!) {
    deleteTaskScheduleAndBreaks(scheduleId: $scheduleId)
  }
`
