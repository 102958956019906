import { format } from "date-fns"
import { ModernUserWithTimeEntry } from ".."
import {
  TimeEntry,
  User,
  useBulkClockInMutation,
  useBulkClockOutMutation,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../../../helpers/strings/pluralize"
import { useModalProps } from "../../../../Modals/hooks/useModalProps"
import { PickPlus } from "../../../../../types/helpers"
import { errorSnack, infoSnack } from "../../../../Notistack/ThemedSnackbars"

export const handleBulkClockIn = (
  clockedOutUsers:
    | ModernUserWithTimeEntry[]
    | (PickPlus<
        User,
        "id" | "organizationId" | "projectId" | "taskId" | "currentProjectId" | "currentTaskId" | "isClockedIn"
      > & {
        latestTimeEntry?: PickPlus<TimeEntry, "endAt"> | null
      })[],
  bulkClockInMutation: ReturnType<typeof useBulkClockInMutation>[1],
  loadingIndicatorModalProps: ReturnType<typeof useModalProps>,
  onSuccess?: () => void
) => {
  const candidates = clockedOutUsers.map((user) => ({
    user,
  }))

  loadingIndicatorModalProps.handleOpenModal()
  bulkClockInMutation({
    candidates: candidates.map((candidate) => ({
      projectId: candidate.user.currentProjectId,
      taskId: candidate.user.currentTaskId,
      userId: candidate.user.id,
    })),
  }).then((result) => {
    if (result.error) {
      loadingIndicatorModalProps.handleCloseModal()
      errorSnack(`Error with Bulk Clock in`)
      console.error(result.error)
    } else {
      const { errors, success } = result.data?.bulkClockIn || { errors: [], success: 0 }
      const doubleBookings = errors?.filter((error) => error.reason === "ATTEMPTED_DOUBLE_BOOKING")
      const alreadyClockedInUsers = errors?.filter((error) => error.reason === "ALREADY_CLOCKED_IN")

      if (success > 0) {
        onSuccess?.()
        infoSnack(`Clocked in ${success} ${pluralize("user", success)}`)
      }
      if (doubleBookings.length) {
        const now = new Date()
        infoSnack(
          `${doubleBookings.length} ${pluralize("user", doubleBookings.length)} ${pluralize(
            "is",
            doubleBookings.length
          )} unable to be clocked in at ${format(now, "h:mm aaa")}`
        )
      }
      if (alreadyClockedInUsers.length) {
        infoSnack(
          `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} were already clocked in`
        )
      }

      loadingIndicatorModalProps.handleCloseModal()
    }
  })
}

export const handleBulkClockOut = (
  clockedInUsers:
    | ModernUserWithTimeEntry[]
    | (PickPlus<
        User,
        "id" | "organizationId" | "projectId" | "taskId" | "currentProjectId" | "currentTaskId" | "isClockedIn"
      > & {
        latestTimeEntry?: PickPlus<TimeEntry, "endAt"> | null
      })[],
  bulkClockOutMutation: ReturnType<typeof useBulkClockOutMutation>[1],
  loadingIndicatorModalProps: ReturnType<typeof useModalProps>,
  onSuccess?: () => void
) => {
  const candidates = clockedInUsers.map((user) => ({ user }))

  loadingIndicatorModalProps.handleOpenModal()
  bulkClockOutMutation({
    candidates: candidates.map((candidate) => ({
      userId: candidate.user.id,
    })),
  }).then((result) => {
    if (result.error) {
      loadingIndicatorModalProps.handleCloseModal()
      errorSnack(`Error with Bulk Clock out`)
      console.error(result.error)
    } else {
      const { errors, success } = result.data?.bulkClockOut || { errors: [], success: 0 }
      const attemptedTimeTravelers = errors.filter((error) => error.reason === "END_CANNOT_BE_BEFORE_START")
      const alreadyClockedInUsers = errors.filter((error) => error.reason === "ALREADY_CLOCKED_IN")

      if (success > 0) {
        onSuccess?.()
        infoSnack(`Clocked out ${success} ${pluralize("user", success)}`)
      }
      if (attemptedTimeTravelers.length) {
        const now = new Date()
        infoSnack(
          `${attemptedTimeTravelers.length} ${pluralize("user", attemptedTimeTravelers.length)} ${pluralize(
            "is",
            attemptedTimeTravelers.length
          )} unable to be clocked out at the clock-out time (${format(now, "h:mm aaa")})`
        )
      }
      if (alreadyClockedInUsers.length) {
        infoSnack(
          `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} ${pluralize(
            "is",
            alreadyClockedInUsers.length
          )} already clocked out`
        )
      }
      loadingIndicatorModalProps.handleCloseModal()
    }
  })
}
