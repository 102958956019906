import { useMemo } from "react"
import {
  Asset,
  AssetAssignableType,
  AssetGroup,
  Project,
  Task,
  User,
} from "../graphql/generated/client-types-and-hooks"
import { Available } from "../helpers/assets/assetStatus"
import { PickPlus } from "../types/helpers"

type AssetOrAssetGroupRenderable = PickPlus<
  Asset,
  | "id"
  | "active"
  | "assetChildCount"
  | "assetGroupId"
  | "assignableId"
  | "assignableType"
  | "assignedAsset"
  | "assignedAssetId"
  | "assignedTaskId"
  | "assignedUserId"
  | "compositeKey"
  | "imageUrl"
  | "isAssetGroup"
  | "name"
  | "ownershipType"
  | "status"
> & {
  assignedTask?: (PickPlus<Task, "id" | "name" | "projectId"> & { project: PickPlus<Project, "id" | "name"> }) | null
  assignedUser?: PickPlus<User, "id" | "firstName" | "lastName"> | null
}

type ThisAsset = PickPlus<
  Asset,
  | "id"
  | "active"
  | "assetChildCount"
  | "assignableId"
  | "assignableType"
  | "assignedUserId"
  | "compositeKey"
  | "isAssetGroup"
  | "imageUrl"
  | "name"
  | "ownershipType"
  | "status"
>

type ThisAssetGroup = PickPlus<
  AssetGroup,
  "assetGroupId" | "assignableId" | "assignableType" | "status" | "count" | "compositeKey"
> & {
  assignedTask?: (PickPlus<Task, "id" | "name" | "projectId"> & { project: PickPlus<Project, "id" | "name"> }) | null
  groupParent: PickPlus<Asset, "id" | "name" | "imageUrl" | "ownershipType">
  // @ts-expect-error - assignedAsset is not always defined
  assignedAsset?: PickPlus<ThisAssetGroup["assignedAsset"], "id" | "name" | "imageUrl" | "compositeKey"> & {
    assignedUser: PickPlus<ThisAssetGroup["assignedAsset"]["assignedUser"], "id"> & {
      currentProject: PickPlus<ThisAssetGroup["assignedAsset"]["assignedUser"]["currentProject"], "id" | "name">
    }
  }
  assignedUser?: PickPlus<User, "id" | "firstName" | "lastName"> | null
}

export function useAssetListGroupConsolidation<T extends ThisAsset, U extends ThisAssetGroup>(
  data: { assets: T[]; assetGroups: U[] } | undefined
) {
  return useMemo(() => {
    return data ? assetAndGroupConsolidation(data) : []
  }, [data])
}

function assetAndGroupConsolidation<T extends ThisAsset, U extends ThisAssetGroup>(
  params:
    | {
        assets: T[]
        assetGroups: U[]
      }
    | undefined
) {
  const { assets, assetGroups } = params || {
    assets: [],
    assetGroups: [],
  }
  const assetsForRender: AssetOrAssetGroupRenderable[] = []

  assetsForRender.push(...assets)
  assetsForRender.push(
    ...assetGroups.map((ag) => ({
      id: ag.groupParent?.id ?? "",
      assetGroupId: ag.assetGroupId,
      assignableId: ag.assignableId,
      assignableType: ag.assignableType,
      assignedAsset: ag.assignedAsset,
      assignedAssetId: ag.assignableType === AssetAssignableType.Asset ? ag.assignableId : undefined,
      assignedTaskId: ag.assignableType === AssetAssignableType.Task ? ag.assignableId : undefined,
      assignedUserId: ag.assignableType === AssetAssignableType.User ? ag.assignableId : undefined,
      assignedTask: ag.assignedTask,
      assignedUser: ag.assignedUser,

      active: ag.status === Available,
      assetChildCount: ag.count,
      imageUrl: ag.groupParent?.imageUrl,
      isAssetGroup: false,
      name: ag.groupParent?.name ?? "",
      ownershipType: ag.groupParent?.ownershipType,
      status: ag.status,
      compositeKey: ag.compositeKey,
    }))
  )

  return assetsForRender
}
