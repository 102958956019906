import { Form, Formik } from "formik"
import { FC, useContext } from "react"
import { useMutation } from "urql"
import * as Yup from "yup"
import { User } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ProjectAndTasksMultiSelects } from "../../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { ModalBody } from "../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../Modals/components/Elements/ModalFooter"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { ButtonFilled, ButtonHollow } from "../../../deprecated"
import { ModalLoadingContext } from "../../../deprecated/StandardModal"
import { ReassignUserSelect } from "../../../Formik/ReassignUserSelect"

export type UserExpectation = Pick<
  User,
  | "id"
  | "currentProjectId"
  | "currentTaskId"
  | "firstName"
  | "imageUrl"
  | "jobTitle"
  | "lastName"
  | "projectId"
  | "taskId"
>

type Props = {
  onCancel: () => void
  onSuccess: (
    assignments: {
      userId: string
      taskId: string
    }[]
  ) => void
  preselectedUsers?: UserExpectation[]
  projectId?: string
  previousTaskId?: string
  excludedProjectId?: string
  newProjectId?: string
  newTaskId?: string
}

type Values = {
  assignment: {
    selectedProjectId: string[]
    selectedTaskId: string
    previousProjectId: string
    previousTaskId: string
  }
  userIds: string[]
}

const ReassignUsersMutation = graphql(`
  mutation ReassignUserTaskForm($assignments: [UserAssignmentAssignment!]!) {
    reassignUsers(assignments: $assignments) {
      id
      imageUrl
      currentProjectId
      currentTaskId
      projectId
      project {
        id
        name
      }
      taskId
      task {
        id
        name
      }
    }
  }
`)

export const ReassignUserTaskForm: FC<Props> = ({
  projectId,
  previousTaskId,
  excludedProjectId,
  newProjectId,
  newTaskId,
  onSuccess,
  onCancel,
  preselectedUsers,
}) => {
  const { setIsLoading } = useContext(ModalLoadingContext)
  const [_, reassignUsersMutation] = useMutation(ReassignUsersMutation)
  const initialValues: Values = {
    assignment: {
      selectedProjectId: newProjectId ? [newProjectId] : [""],
      selectedTaskId: newTaskId ?? "",
      previousProjectId: projectId ? projectId : "",
      previousTaskId: previousTaskId ? previousTaskId : "",
    },
    userIds: (preselectedUsers || []).map(({ id }) => id),
  }

  return (
    <Formik<Values>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        userIds: Yup.array(Yup.string()).min(1, "At least one user must be selected.").required(),
        assignment: Yup.object().shape({
          selectedProjectId: Yup.array(Yup.string()).min(1, "A project must be selected.").required(),
          selectedTaskId: Yup.string().required("A task must be selected."),
        }),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        const {
          userIds,
          assignment: { selectedTaskId, selectedProjectId },
        } = values
        const assignments = userIds.map((userId) => ({
          userId,
          taskId: selectedTaskId,
          projectId: selectedProjectId[0] || "",
          previousTaskId,
        }))

        reassignUsersMutation({ assignments }).then((result) => {
          if (result.error) {
            setIsLoading(false)
            errorSnack("An error was encountered while reassigning users. Please try again.")
          } else {
            onSuccess?.(assignments)
            successSnack("Users have been reassigned.")
          }
        })
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="h-full flex flex-col justify-around">
            <ModalBody>
              <div className="grid gap-y-6 mb-8">
                <ReassignUserSelect preselectedUsers={preselectedUsers} />
                <div className="col-span-12">
                  <ProjectAndTasksMultiSelects
                    formGroupId="assignment"
                    users={preselectedUsers}
                    includeCurrentAssignment={!previousTaskId}
                    excludedProjectId={excludedProjectId}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonHollow onClick={onCancel} type="button">
                Cancel
              </ButtonHollow>
              <ButtonFilled type="submit" disabled={isSubmitting}>
                Reassign
              </ButtonFilled>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}
