import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { FC } from "react"
import { BiChevronDown, BiHealth } from "react-icons/bi"
import { useQuery } from "urql"
import { InjuryReport, User } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { PickPlus } from "../../../types/helpers"
import { CustomLabeledSection } from "../../LabeledSection"
import { SkeletonBlock } from "../../Skeletons/SkeletonBlock"
import { CustomDetailPanelToggle } from "../DataGrid/CustomDetailPanelToggle"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"

/*
  Specific components which render the underlying table after making their specific queries

  - UserInjuryReports
  - ProjectInjuryReports
  - TaskInjuryReports
*/

const UserInjuryReportsTableDocument = graphql(`
  query UserInjuryReports($id: String!) {
    user(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        signatureUrl

        project {
          id
          name
        }

        task {
          id
          name
        }

        reporter {
          id
          fullName
        }

        user {
          id
          fullName
        }
      }
    }
  }
`)

export const UserInjuryReports: FC<{ userId: string }> = ({ userId }) => {
  const [{ data }] = useQuery({
    query: UserInjuryReportsTableDocument,
    variables: { id: userId },
  })

  if (!data || data?.user?.injuryReports?.length === 0) return null

  return <InjuryReports injuryReports={data?.user?.injuryReports || []} />
}

const ProjectInjuryReportsTableDocument = graphql(`
  query ProjectInjuryReports($id: String!) {
    project(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        signatureUrl

        project {
          id
          name
        }

        task {
          id
          name
        }

        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`)

export const ProjectInjuryReports: FC<{ projectId: string }> = ({ projectId }) => {
  const [{ data }] = useQuery({
    query: ProjectInjuryReportsTableDocument,
    variables: { id: projectId },
  })

  if (!data) {
    return <SkeletonBlock className="h-12 w-full" />
  }

  return (
    <div className="grid">
      {data?.project?.injuryReports?.length === 0 ? (
        <EmptyStateBlock label="No injury reports" />
      ) : (
        <InjuryReports injuryReports={data?.project?.injuryReports || []} />
      )}
    </div>
  )
}

const TaskInjuryReportsTableDocument = graphql(`
  query TaskInjuryReports($id: String!) {
    task(id: $id) {
      id
      injuryReports {
        id
        createdAt
        notes
        signatureUrl

        project {
          id
          name
        }

        task {
          id
          name
        }

        reporter {
          id
          fullName
        }
        user {
          id
          fullName
        }
      }
    }
  }
`)

export const TaskInjuryReports: FC<{ taskId: string }> = ({ taskId }) => {
  const [{ data }] = useQuery({
    query: TaskInjuryReportsTableDocument,
    variables: { id: taskId },
  })

  if (!data) {
    return <SkeletonBlock className="h-12 w-full" />
  }

  return (
    <div className="grid">
      {data?.task?.injuryReports?.length === 0 ? (
        <EmptyStateBlock label="No injury reports" />
      ) : (
        <InjuryReports injuryReports={data?.task?.injuryReports || []} />
      )}
    </div>
  )
}

// Base TABLE implementation
type InjuryReportUserReference = PickPlus<User, "id" | "fullName">
type InjuryReportRenderable = PickPlus<InjuryReport, "id" | "createdAt"> & {
  reporter: InjuryReportUserReference
  user: InjuryReportUserReference
  project?: {
    name: string
  } | null
  task?: {
    name: string
  } | null
}

const defaultColumnProps: Pick<GridColDef, "disableColumnMenu" | "editable" | "filterable" | "sortable" | "flex"> = {
  editable: false,
  disableColumnMenu: true,
  filterable: false,
  sortable: false,
  flex: 1,
}

const InjuryReports: FC<{ injuryReports: InjuryReportRenderable[] }> = ({ injuryReports }) => {
  return (
    <>
      <Accordion sx={{ border: "1px solid #E0E0E0" }}>
        <AccordionSummary expandIcon={<BiChevronDown className="size-6" />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex">
                <BiHealth className="text-orange-600 size-5 mr-1 relative top-[2px]" />
                <Typography className="text-orange-600">Injury Reports</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography className="text-gray-400">{injuryReports.length} Reports</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="pb-0">
          <DataGridPro
            autoHeight
            hideFooter
            columns={[
              {
                ...defaultColumnProps,
                field: "date",
                headerName: "Date",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => format(row.createdAt, "EEE, LLL do "),
              },
              {
                ...defaultColumnProps,
                field: "time",
                headerName: "Time",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => format(row.createdAt, "h:mm aaa"),
              },
              {
                ...defaultColumnProps,
                field: "user",
                headerName: "Individual",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.user.fullName,
              },
              {
                ...defaultColumnProps,
                field: "project",
                headerName: "Project",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.project?.name || "",
              },
              {
                ...defaultColumnProps,
                field: "task",
                headerName: "Task",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.task?.name || "",
              },

              {
                ...defaultColumnProps,
                field: "reportedBy",
                headerName: "Reported By",
                valueGetter: ({ row }: { row: InjuryReportRenderable }) => row.reporter.fullName,
              },
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                width: 25,
                renderCell: ({ id, value }) => <CustomDetailPanelToggle id={id} value={value} />,
              },
            ]}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            rows={injuryReports}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

const getDetailPanelContent = (params: GridRowParams<InjuryReport>) => {
  return (
    <div className="inline-block mb-4">
      <CustomLabeledSection
        imageUrl={params.row.signatureUrl}
        className="p-4 m-2 bg-yellow-100 shadow-md flex-col border-gray-200 rounded-md grow shrink inline-block"
        label="Injury report note"
      >
        <span>{params.row.notes}</span>
      </CustomLabeledSection>
    </div>
  )
}

const getDetailPanelHeight = (): "auto" => "auto"
