import { Box, Chip, Typography } from "@mui/material"
import { TaskDependencyRelationalType } from "@prisma/client"
import { FormikValues } from "formik"
import { FC, useMemo, useState } from "react"
import { BiPencil, BiPlus, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { TaskDependencyModal } from "../../../../../features/Scheduling/components/TaskDependencyModal"
import {
  Task,
  TaskDependency,
  useCreateTaskDependencyMutation,
  useDeleteTaskDependencyMutation,
  useUpdateTaskDependencyMutation,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { titleCase } from "../../../../../helpers/strings/titleCase"
import { PickPlus } from "../../../../../types/helpers"
import { LabeledSection } from "../../../../LabeledSection"
import { ModalProps, useModalProps } from "../../../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"
import { MenuItem, QuickMenuMui } from "../../../../QuickMenuMui"

export const TaskDependencyQuery = graphql(`
  query GetTaskDependencies($taskId: String!) {
    task(id: $taskId) {
      id
      projectId
      predecessorTaskDependencies {
        id
        projectId
        predecessorTaskId
        dependentTaskId
        type
        metadata {
          offsetDays
        }
      }

      dependentTaskDependencies {
        id
        projectId
        predecessorTaskId
        dependentTaskId
        type
        metadata {
          offsetDays
        }
      }

      predecessorTasks {
        id
        name
        endDate
      }
      dependentTasks {
        id
        name
        endDate
      }
      driverTaskDependency {
        predecessorTaskId
      }
    }
  }
`)

export const TaskDependencyDetails: FC<{
  taskId: string
}> = ({ taskId }) => {
  const [dependency, setDependency] = useState<Partial<TaskDependency> | undefined>()
  const taskDependencyModalProps = useModalProps("Add Finish/Start Dependency")
  const [, createTaskDependency] = useCreateTaskDependencyMutation()
  const [, updateTaskDependency] = useUpdateTaskDependencyMutation()
  const [, deleteTaskDependencyMutation] = useDeleteTaskDependencyMutation()

  const [{ data }] = useQuery({
    query: TaskDependencyQuery,
    variables: { taskId },
  })

  const handleSubmit = async (values: FormikValues) => {
    try {
      const response = values.id
        ? await updateTaskDependency({
            id: values.id,
            dependentTaskId: values.dependentTaskId,
            predecessorTaskId: values.predecessorTaskId,
            projectId: values.projectId,
            type: values.type,
            metadata: values.metadata,
          })
        : await createTaskDependency({
            dependentTaskId: values.dependentTaskId,
            predecessorTaskId: values.predecessorTaskId,
            projectId: values.projectId,
            type: values.type,
            metadata: values.metadata,
          })

      if (response.error) {
        errorSnack(`Error ${values.id ? "updating" : "creating"} task dependency`)
      } else {
        successSnack(`Task dependency ${values.id ? "updated" : "created"} successfully`)
        taskDependencyModalProps.handleCloseModal()
      }
    } catch (error) {
      errorSnack(`Error ${values.id ? "updating" : "creating"} task dependency`)
    }
  }

  const handleDelete = async () => {
    if (!dependency?.id) return

    const response = await deleteTaskDependencyMutation({
      id: dependency?.id,
    })

    if (response.error) {
      errorSnack("Error deleting task dependency")
    } else {
      successSnack("Task dependency deleted successfully")
    }
  }

  const menuItems: MenuItem[][] = [
    [
      {
        value: "Edit",
        Icon: BiPencil,
        onClick: () => taskDependencyModalProps.handleOpenModal(),
      },
      {
        value: "Delete",
        Icon: BiTrash,
        onClick: async () => handleDelete(),
        color: "red",
        iconStyles: "text-red-500 size-5",
      },
    ],
  ]

  return (
    <div>
      {data?.task && (
        <>
          <TaskDependencyChips
            currentTaskId={taskId}
            tasks={[...(data?.task.predecessorTasks || []), ...(data?.task.dependentTasks || [])]}
            taskDependencies={[
              ...(data?.task.predecessorTaskDependencies || []),
              ...(data?.task.dependentTaskDependencies || []),
            ]}
            menuItems={menuItems}
            driverDependencyId={data?.task.driverTaskDependency?.predecessorTaskId}
            modalProps={taskDependencyModalProps}
            setDependency={setDependency}
          />
          {taskDependencyModalProps.isOpen && data?.task.projectId && dependency && (
            <TaskDependencyModal
              modalProps={taskDependencyModalProps}
              projectId={data?.task.projectId}
              taskId={taskId}
              dependency={dependency}
              onSave={async (values: FormikValues) => {
                await handleSubmit(values)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

const TaskDependencyChip: FC<{
  label: string
  driverDependencyId?: string
  menuItems: MenuItem[][]
  offsetDays?: number | null
  setDependency: (dependency: Partial<TaskDependency>) => void
  taskId: string
  taskDependency: TaskDependency
  taskName: string
}> = ({ label, driverDependencyId, menuItems, offsetDays, setDependency, taskId, taskDependency, taskName }) => {
  return (
    <QuickMenuMui
      key={`task-dep-chip-${taskId}-${taskDependency.id}`}
      items={menuItems}
      className="hover:bg-transparent -mx-3"
    >
      <Chip
        label={
          <TaskDependencyLabel
            labelTaskId={label}
            driverTaskId={driverDependencyId}
            taskName={taskName}
            type={taskDependency.type}
            offsetDays={offsetDays}
          />
        }
        onClick={() => {
          setDependency(taskDependency)
        }}
      />
    </QuickMenuMui>
  )
}

type DependencyMap = {
  predecessor: Record<string, JSX.Element>
  dependent: Record<string, JSX.Element>
}

const TaskDependencyChips: FC<{
  currentTaskId: string
  tasks: PickPlus<Task, "id" | "name" | "endDate">[]
  taskDependencies: TaskDependency[]
  menuItems: MenuItem[][]
  driverDependencyId?: string
  modalProps: ModalProps
  setDependency: (dependency: Partial<TaskDependency>) => void
}> = ({ currentTaskId, tasks, taskDependencies, menuItems, driverDependencyId, modalProps, setDependency }) => {
  const dependencyChips = useMemo(() => {
    return tasks.reduce<DependencyMap>(
      (acc, task): DependencyMap => {
        const predecessorDependency = taskDependencies.find(
          (td) => td.predecessorTaskId === task.id && td.dependentTaskId === currentTaskId
        )
        if (predecessorDependency && !acc.predecessor[predecessorDependency.id]) {
          return {
            ...acc,
            predecessor: {
              ...acc.predecessor,
              [predecessorDependency.id]: (
                <TaskDependencyChip
                  key={`predecessor-dependency-${predecessorDependency.id}`}
                  label={task.id}
                  driverDependencyId={driverDependencyId}
                  menuItems={menuItems}
                  offsetDays={predecessorDependency.metadata?.offsetDays}
                  setDependency={setDependency}
                  taskId={task.id}
                  taskDependency={predecessorDependency}
                  taskName={task.name}
                />
              ),
            },
          }
        }

        const dependentDependency = taskDependencies.find(
          (td) => td.predecessorTaskId === currentTaskId && td.dependentTaskId === task.id
        )
        if (dependentDependency && !acc.dependent[dependentDependency.id]) {
          return {
            ...acc,
            dependent: {
              ...acc.dependent,
              [dependentDependency.id]: (
                <TaskDependencyChip
                  key={`dependent-dependency-${dependentDependency.id}`}
                  label=""
                  driverDependencyId={driverDependencyId}
                  menuItems={menuItems}
                  offsetDays={dependentDependency.metadata?.offsetDays}
                  setDependency={setDependency}
                  taskId={task.id}
                  taskDependency={dependentDependency}
                  taskName={task.name}
                />
              ),
            },
          }
        }

        return acc
      },
      {
        predecessor: {},
        dependent: {},
      } as DependencyMap
    )
  }, [taskDependencies, tasks, currentTaskId, driverDependencyId, menuItems, setDependency])

  return (
    <div className="flex-col grid gap-y-4">
      <LabeledSection label="Predecessor(s)">
        <Box display="flex" gap={0} alignItems="start" marginTop={-1}>
          {Object.values(dependencyChips.predecessor)}
          <AddChip
            key="add-predecessor"
            isPredecessor={true}
            taskId={currentTaskId}
            modalProps={modalProps}
            setDependency={setDependency}
          />
        </Box>
      </LabeledSection>
      <LabeledSection label="Successor(s)">
        <Box display="flex" gap={0} alignItems="start" marginTop={-1}>
          {Object.values(dependencyChips.dependent)}
          <AddChip
            key="add-dependent"
            isPredecessor={false}
            taskId={currentTaskId}
            modalProps={modalProps}
            setDependency={setDependency}
          />
        </Box>
      </LabeledSection>
    </div>
  )
}

const AddChip = ({
  isPredecessor,
  taskId,
  modalProps,
  setDependency,
}: {
  isPredecessor: boolean
  taskId: string
  modalProps: ModalProps
  setDependency: (dependency: Partial<TaskDependency>) => void
}) => {
  return (
    <Chip
      key="add-chip"
      className="mt-1 ml-1"
      label={
        <div className="flex gap-x-2 ml-0">
          <BiPlus className="size-4 mt-1" />
          <Typography>Add</Typography>
        </div>
      }
      onClick={() => {
        isPredecessor ? setDependency({ dependentTaskId: taskId }) : setDependency({ predecessorTaskId: taskId })
        modalProps.handleOpenModal()
      }}
    />
  )
}

const TaskDependencyLabel: FC<{
  labelTaskId: string
  driverTaskId?: string
  taskName: string
  type: TaskDependencyRelationalType
  offsetDays: number | null | undefined
}> = ({ labelTaskId, driverTaskId, taskName, type, offsetDays }) => {
  if (offsetDays !== undefined && offsetDays !== null) {
    const typeText = titleCase(type.replace(/_/g, " "))

    return (
      <Box display="flex">
        <Typography>{taskName}</Typography>
        <Typography className="text-slate-500 text-sm mt-0.5 ml-2">{` ${typeText} | ${offsetDays > -1 ? "+" : ""} ${offsetDays} days`}</Typography>
        {driverTaskId && driverTaskId === labelTaskId && (
          <Typography className="text-slate-500 text-sm mt-0.5 ml-2">(Driver)</Typography>
        )}
      </Box>
    )
  } else {
    return <Typography>{taskName}</Typography>
  }
}
