import { FC } from "react"
import { Project, Task } from "../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../types/helpers"
import { H5 } from "../../../deprecated"
import { TeamList } from "../../Team/TeamList"

type Props = {
  project: PickPlus<Project, "id">
  task?: PickPlus<Task, "id" | "name" | "isDefault" | "isComplete" | "hasReportableUnit"> & {
    project: PickPlus<Project, "id" | "isArchived">
  }
  onSuccess?: () => void
}

export const TeamAssignments: FC<Props> = ({ project, task, onSuccess: _onSuccess }) => {
  return (
    <div>
      <H5>{task ? "Task Assignments" : "Team List"}</H5>

      <TeamList project={project} task={task} style={{ height: "calc(100vh - 345px)" }} />
    </div>
  )
}
