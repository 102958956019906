import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { getTaskListItemSortOrder } from "../../helpers/sorts/getTaskListItemSortOrder"
import { getNewTaskGroupProgress } from "../../helpers/progress/summarizeProgressFromTaskList"

const GetTaskList = graphql(`
  query GetTaskList($projectId: String!, $archived: Boolean!) {
    taskList(projectId: $projectId, archived: $archived) {
      taskId
      taskGroupId
      groupId
      completedHours
      estimatedHours
      isComplete
      isDefault
      unitProgress
      unitTargetGoal
      assetCount
      completedTaskCount
      name
      createdAt
      taskCount
      userCount
    }
  }
`)

export const useGetNewTaskGroupProgress = (projectId: string, taskGroupId: string, isArchived: boolean) => {
  const [{ data }] = useQuery({
    query: GetTaskList,
    variables: { projectId, archived: isArchived },
    pause: !projectId,
  })
  const getTaskList = data?.taskList || []
  const subtasks = getTaskList
    .filter((t) => t.groupId !== null && t.groupId === taskGroupId)
    .map((task) => ({
      ...task,
      sortOrder: getTaskListItemSortOrder(task),
    }))
    .sort((taskA, taskB) => {
      return taskA.sortOrder > taskB.sortOrder ? 1 : -1
    })
  return {
    data: getNewTaskGroupProgress(subtasks),
  }
}
