import { FC, useContext } from "react"
import { TailwindIcon } from "../../../types/tailwind"
import { format } from "date-fns"
import { BiCamera, BiHealth, BiLayer, BiListUl, BiTimeFive } from "react-icons/bi"
import { User } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { FaSignature } from "react-icons/fa"
import { Typography } from "@mui/material"
import { OrganizationSettingsContext } from "../../../providers/OrganizationSettingsProvider"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "isClockedIn"> & {
    currentProject: PickPlus<User["currentProject"], "id" | "name">
    currentTask: PickPlus<User["currentTask"], "id" | "name">
  }
  photo: Blob | undefined
  signature?: Blob
  isInjured?: boolean
  endAt?: Date
}

export const Confirmation: FC<Props> = ({ user, photo, signature, isInjured, endAt }) => {
  const settings = useContext(OrganizationSettingsContext)

  return (
    <div className="flex flex-col gap-3 p-2">
      {settings.requireTimeEntryEvidence && (
        <Row Icon={BiCamera} primaryText="Time entry photo" secondaryText="Yes" image={photo} />
      )}
      {settings.requireInjuryReportOnClockout && isInjured !== undefined && (
        <Row
          Icon={BiHealth}
          primaryText="Injury"
          secondaryText={isInjured ? "Yes" : "No"}
          secondaryTextColor={isInjured ? "text-orange-600" : "text-gray-500"}
        />
      )}
      {settings.requireInjuryReportOnClockout && signature && (
        <Row Icon={FaSignature} primaryText="Signature" secondaryText="Yes" image={signature} />
      )}
      <Row Icon={BiLayer} primaryText="Project" secondaryText={user.currentProject.name} />
      <Row Icon={BiListUl} primaryText="Task" secondaryText={user.currentTask.name} />
      <Row Icon={BiTimeFive} primaryText="Date & Time" secondaryText={format(endAt ?? new Date(), "h:mm aaa")} />
    </div>
  )
}

type RowProps = {
  Icon: TailwindIcon
  primaryText: string
  secondaryText: string
  secondaryTextColor?: string
  image?: Blob
  imageAlt?: string
}

const Row: FC<RowProps> = ({
  Icon,
  primaryText,
  secondaryText,
  secondaryTextColor = "text-gray-500",
  image,
  imageAlt,
}) => {
  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center gap-3 w-full">
        <div className="size-10 flex justify-center items-center bg-gray-100 rounded">
          <Icon className="size-5 text-gray-600" />
        </div>
        <Typography className="font-medium">{primaryText}</Typography>
        <Typography className={secondaryTextColor}>{secondaryText}</Typography>
      </div>
      <div className="rounded-sm size-10 justify-self-end">
        {image && <img src={URL.createObjectURL(image)} alt={imageAlt} />}
      </div>
    </div>
  )
}
