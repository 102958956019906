import { FC } from "react"
import { BiCar } from "react-icons/bi"
import { Project } from "../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../helpers/strings/pluralize"
import { PickPlus } from "../../../types/helpers"
import { SummaryWidget } from "./SummaryWidget"

export const AssetCountWidget: FC<{
  assetCount: number
  project: PickPlus<Project, "id">
}> = ({ assetCount, project }) => {
  return (
    <SummaryWidget
      Icon={BiCar}
      value={`${assetCount}`}
      description={pluralize("asset", assetCount)}
      href={`/projects/${project.id}/assets`}
    />
  )
}
