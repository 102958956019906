import { FC, useMemo } from "react"
import { assetStatusOptions } from "../../../../helpers/assets/assetStatus"
import { MultiSelect } from "../MultiSelect"
import { AutoCompleteWithSearch } from "../../AutoCompleteWithSearch"

type Props = {
  disabled?: boolean
  name: string
  withErrorHandling?: boolean
  onChange?: (selectedValues: string[]) => void
  useNewDesign?: boolean
}

export const AssetStatusMultiSelect: FC<Props> = ({
  disabled = false,
  name,
  onChange,
  withErrorHandling = false,
  useNewDesign,
}) => {
  const statuses = useMemo(
    () =>
      assetStatusOptions.map((status) => ({
        id: status.value,
        label: status.label,
        value: status.value,
        searchableTextString: status.label,
        template: () => <div>{status.label}</div>,
      })),
    []
  )

  return (
    <>
      {useNewDesign ? (
        <AutoCompleteWithSearch<{ id: string; label: string; value: string }>
          label="Status"
          name={name}
          disabled={disabled}
          options={statuses}
          placeholder="Status"
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
        />
      ) : (
        <MultiSelect
          disabled={disabled}
          name={name}
          options={[statuses]}
          isSingleSelect={true}
          selectedLabel="status"
          onChange={onChange}
          withErrorHandling={withErrorHandling}
        />
      )}
    </>
  )
}
