import { Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  ReportTaskProgressMutationVariables,
  useReportTaskProgressMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { taskReportImagePrefix } from "../../../helpers/files/s3UploadFilePrefixHelpers"
import { useHandleError } from "../../../hooks/useHandleError"
import { warnOnExitStoreActions } from "../../../stores/warnOnExit"
import { TextField } from "../../Formik/TextField"
import { ImageUploadPreviewList } from "../../ImageUploadPreviewList"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { UnitGoalRow } from "../../UnitGoalRow"
import { MuiModal } from "./Elements/MuiModal"
import { unitReportModalCanBeSubmitted } from "./unitReportModalCanBeSubmitted"

export type FileObject = {
  file: File
  fileId: string
  id: string
  preview: string
  signedUrl: string
  uploaded: boolean
}

const TasksQuery = graphql(`
  query GetTaskForReporting($taskId: String!) {
    task(id: $taskId) {
      id
      name
      isDefault
      projectId
      group {
        id
        name
      }
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }

      project {
        id
        contract {
          deliverableUnits {
            id
            customerDescription
            deliverableUnitId
          }
        }
      }
    }
  }
`)

export type UnitReportModalUnitGoalProgressExpectation = {
  unitGoalId: string
  progress: number
}

export type UnitReportModalValueType = {
  taskId: string
  note: string
  files: FileObject[]
  unitGoalProgress: UnitReportModalUnitGoalProgressExpectation[]
}

export const UnitReportModal: FC<{
  taskId: string
  isOpen: boolean
  closeModal: () => void
  onSuccess?: () => void
}> = ({ taskId, isOpen, closeModal, onSuccess = () => null }) => {
  const [, reportTaskProgressMutation] = useReportTaskProgressMutation()

  const { setHasUnsavedChanges, confirmExit } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  const [{ data: taskData, error }, _refetchTasks] = useQuery({
    query: TasksQuery,
    pause: !taskId,
    variables: { taskId },
  })

  useHandleError(error, "Could not fetch Task")

  const handleSubmit = async (values: UnitReportModalValueType, resetForm: () => void) => {
    if (values.taskId !== taskId) {
      // This is a sort of failsafe for all the other protections to manage "initialValues" being stable despite changing props.
      errorSnack("Something went wrong; please refresh the page")
    }

    const data: ReportTaskProgressMutationVariables = {
      projectId: taskData?.task?.projectId ?? "",
      taskId: values.taskId,
      note: values.note,
      unitGoalProgress: values.unitGoalProgress.filter((unitGoal) => unitGoal), // Protect against null values which are definitely possible because of the way the modal form contents edits the data
      fileIds: values.files.map(({ fileId }: FileObject) => fileId),
    }

    await reportTaskProgressMutation(data).then((result) => {
      if (result.error) {
        errorSnack("Something went wrong with submitting the form")
      } else {
        setHasUnsavedChanges(false)
        closeModal()
        successSnack("Report saved successfully")
        resetForm()
        onSuccess()
      }
    })
  }

  if (!taskData) return null

  const contractDeliverableUnitMap =
    taskData?.task?.project?.contract?.deliverableUnits?.reduce(
      (
        acc: {
          [key: string]: string
        },
        unit: (typeof taskData.task.project.contract.deliverableUnits)[0]
      ) => ({
        ...acc,
        [unit.deliverableUnitId]: unit.customerDescription ?? "",
      }),
      {}
    ) || {}

  const task = taskData.task

  const unitGoals = (task.unitGoals || []).sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    if (!a.isPrimary && b.isPrimary) return 1
    return 0
  })

  return (
    <Formik<UnitReportModalValueType>
      enableReinitialize
      initialValues={{
        taskId,
        note: "",
        files: [],
        unitGoalProgress: [],
      }}
      validationSchema={Yup.object().shape({
        taskId: Yup.string(),
        note: Yup.string(),
        files: Yup.array(
          Yup.object().shape({
            uploaded: Yup.bool().required().oneOf([true], "Files must finish uploading"),
            fileId: Yup.string(),
          })
        ),
        unitGoalProgress: Yup.array(
          Yup.object().shape({
            unitGoalId: Yup.string(),
            progress: Yup.number()
              .typeError("Progress must be a number")
              .test(
                "maxDigits",
                "Amount cannot exceed 7 digits",
                (value) => String(Math.floor(Math.abs(value ?? 0))).length <= 7
              ),
          })
        ),
      })}
      onSubmit={async (values, { resetForm }) => await handleSubmit(values, resetForm)}
    >
      {({ submitForm, resetForm, values, isSubmitting, dirty }) => {
        setTimeout(
          () =>
            setIsDirty((previous) => {
              if (previous !== dirty) {
                setHasUnsavedChanges(dirty)
                return dirty
              }
              return previous
            }),
          0
        )

        return (
          <MuiModal
            contentLabel="Task Report"
            submitButtonText="Report"
            isOpen={isOpen}
            handleCloseModal={() => {
              confirmExit(() => {
                resetForm()
                closeModal()
              })
            }}
            disabled={isSubmitting || !unitReportModalCanBeSubmitted(values)}
            submitForm={submitForm}
          >
            <Form className="h-full flex flex-col">
              <div className="flex flex-col gap-2">
                <Typography variant="subtitle2" className="uppercase mb-2 font-semibold" color="primary">
                  {!task?.group ? (
                    task?.name
                  ) : (
                    <div>
                      <span className="text-gray-400">{task?.group.name} / </span>
                      {task?.name}
                    </div>
                  )}
                </Typography>

                {unitGoals.map((unitGoal, i) => (
                  <UnitGoalRow
                    contractDeliverableUnitCustomerDescription={contractDeliverableUnitMap[unitGoal.deliverableUnit.id]}
                    key={unitGoal.id}
                    progressFieldName={`unitGoalProgress[${i}].progress`}
                    unitGoal={unitGoal}
                    unitGoalFieldName={`unitGoalProgress[${i}].unitGoalId`}
                  />
                ))}

                <TextField multiline fullWidth label="Notes" name="note" required={false} rows={2} />

                <ImageUploadPreviewList disabled={!task?.id} prefix={taskReportImagePrefix(task?.id)} name="files" />
              </div>
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}
