import { Typography } from "@mui/material"
import { FC, useContext } from "react"
import { BiCheck, BiPackage, BiSortAlt2, BiTrash } from "react-icons/bi"
import { AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../../../Drawer/providers/DrawerProvider"
import { QuickMenu } from "../../../../QuickMenu"
import { MenuItem } from "../../../../QuickMenuMui"
import { ReassignAssetDrawer } from "../../../Drawer/components/Asset/ReassignAssetDrawer"

export const QuantizedQuantityCell: FC<{
  assetGroup: AssetGroup
  isArchived?: boolean
  handleArchive: (assetGroup: AssetGroup) => void
  handleUnarchive: (assetGroup: AssetGroup) => void
  handleReturnToInventory: (assetGroup: AssetGroup) => void
}> = ({ assetGroup, handleArchive, handleReturnToInventory, handleUnarchive, isArchived = false }) => {
  const { push: pushDrawer } = useContext(DrawerContext)

  const isNotInventoryAsset = assetGroup.assetGroupId !== assetGroup.assignableId

  const menuItems: MenuItem[][] = [
    [
      {
        value: "Reassign quantities",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(
            <ReassignAssetDrawer assetsIdsToReassign={[]} assetGroupToReassign={assetGroup} />,
            "ReassignAsset"
          )
        },
      },
    ],
  ]

  const menuGroup: MenuItem[] = []

  if (isNotInventoryAsset) {
    menuGroup.push({
      value: "Return to inventory",
      Icon: BiPackage,
      onClick: () => handleReturnToInventory(assetGroup),
    })
  }
  menuGroup.push(
    isArchived
      ? {
          value: "Unarchive assets",
          Icon: BiCheck,
          color: "green",
          iconStyles: "text-green-600",
          onClick: () => handleUnarchive(assetGroup),
        }
      : {
          value: "Archive",
          color: "red",
          Icon: BiTrash,
          iconStyles: "text-red-600",
          onClick: () => handleArchive(assetGroup),
        }
  )

  menuItems.push(menuGroup)

  return (
    <QuickMenu
      items={menuItems}
      className="w-full mt-2 md:mt-0"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
    >
      <div className="bg-gray-100 w-full min-h-[40px] items-center rounded pl-1 flex">
        <Typography fontSize={16}>{assetGroup.count}</Typography>
      </div>
    </QuickMenu>
  )
}
