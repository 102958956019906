import { Box, Typography } from "@mui/material"
import { format } from "date-fns"
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"
import {
  ProjectUnitsTableProgressQuery,
  TaskUnitsTableProgressQuery,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ProjectUnitsGraphProgressDocument, SummaryUnitsGraph, TaskUnitsGraphProgressDocument } from "../UnitsGraph"
import { getTaskOrProjectQueryDocument } from "../helpers/getQueryDocument"
import { SummarySectionProps } from "../types"
import { SummaryUnitsTable } from "../SummaryUnitTable"
import { prepareDataForRecharts } from "../helpers/getGraphData"
import { mergeDataWithPlaceholders } from "../helpers/generateGraphLabels"
import { useInitialVisibility } from "../helpers/useInitialVisibilityHook"
import { NormalizedDateRange } from "../../../../hooks/useMuiDateRange"
import { colors } from "../../../../helpers/styles/colors"

const ProjectUnitsTableProgressDocument = graphql(`
  query ProjectUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    project(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        task {
          id
          name
          estimatedHours
          timeEntriesSumDurationInSeconds(rangeStart: $rangeStart)
        }
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
    }
  }
`)
const TaskUnitsTableProgressDocument = graphql(`
  query TaskUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    task(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
      estimatedHours
      timeEntriesSumDurationInSeconds
    }
  }
`)

type ProjectOrTaskData = ProjectUnitsTableProgressQuery | TaskUnitsTableProgressQuery

export type TaskDetails = {
  id: string
  name: string
  unitType: string
  targetQuantity: number
  totalProgress: number
  previousProgress: number
  currentProgress: number
  recentProgress: number
  estimatedHours: number
  timeEntriesSumDurationInSeconds: number
}

export type SummaryUnitsGraphAndTableProps = Omit<SummarySectionProps, "projectId"> & {
  entityType: "project" | "task"
  entityId: string
  displayUnits?: "primary" | "additional"
  graphRangeStart: Date
  graphRangeEnd: Date
  setGraphDateRange: Dispatch<SetStateAction<NormalizedDateRange>>
  minDate: Date
  isTaskDrawer?: boolean
}

type TaskProgressEvent = {
  id: string
  createdAt: Date
  unitGoalProgressReports: UnitGoalReports[]
}

type TaskReportingUnitsData = {
  id: string
  units: { [description: string]: boolean }
}

type UnitsGraphDataType = {
  project?: {
    id: string
    startDate: Date
    endDate: Date
    taskProgressEventsWithUnitReports: TaskProgressEvent[]
    hoursDataByDateRangeType: Record<string, unknown>
  }
  task?: {
    id: string
    startDate: Date
    endDate: Date
    taskProgressEventsWithUnitReports: TaskProgressEvent[]
    hoursDataByDateRangeType: Record<string, unknown>
  }
}

export type UnitGoalReports = {
  id: string
  progress: number
  unitGoal: {
    id: string
    isPrimary: boolean
    deliverableUnit: {
      description: string
      color: string
      unitOfMeasure: string
    }
  }
}

export const SummaryUnitsGraphAndTable: FC<SummaryUnitsGraphAndTableProps> = ({
  dateRangeType = "daily",
  graphRangeStart,
  graphRangeEnd,
  rangeStart,
  rangeEnd,
  entityType,
  entityId,
  displayUnits = "primary",
  setGraphDateRange,
  minDate,
  isTaskDrawer = false,
}) => {
  const [{ data }] = useQuery<ProjectOrTaskData>({
    query: getTaskOrProjectQueryDocument(entityType, TaskUnitsTableProgressDocument, ProjectUnitsTableProgressDocument),
    variables: {
      rangeStart,
      rangeEnd,
      entityId,
    },
    pause: !entityId,
  })

  const projectUnitGoals = useMemo(() => {
    return data && "project" in data ? data.project?.unitGoals || [] : []
  }, [data])
  const taskUnitGoals = data && "task" in data ? data.task?.unitGoals || [] : []
  const unitGoals = entityType === "project" ? projectUnitGoals : taskUnitGoals
  const goals = unitGoals.filter((unitGoal) =>
    displayUnits === "additional" ? !unitGoal.isPrimary : unitGoal.isPrimary
  )

  const [{ data: unitGraphData }] = useQuery<UnitsGraphDataType>({
    query: entityType === "task" ? TaskUnitsGraphProgressDocument : ProjectUnitsGraphProgressDocument,
    variables: {
      entityId,
      rangeStart: graphRangeStart,
      rangeEnd: graphRangeEnd,
      rangeStartDay: format(graphRangeStart, "yyyy-MM-dd"),
      rangeEndDay: format(graphRangeEnd, "yyyy-MM-dd"),
    },
    pause: !entityId || displayUnits === "additional",
  })

  const dataForGraph = unitGraphData?.task || unitGraphData?.project
  const groupedUnits = useMemo(() => {
    const defaultGroupedUnits = { data: [], units: [] }
    if (dataForGraph) {
      const preparedData = prepareDataForRecharts(dataForGraph, rangeStart, rangeEnd, entityType)
      const mergedData = mergeDataWithPlaceholders(preparedData.data, graphRangeStart, graphRangeEnd, entityType)
      return { ...preparedData, data: mergedData }
    }
    return defaultGroupedUnits
  }, [dataForGraph, graphRangeStart, graphRangeEnd, rangeStart, rangeEnd, entityType])

  const { data: chartData, units } = groupedUnits

  const [unitsVisibility, toggleUnitVisibility] = useInitialVisibility(units)
  const [hoursVisibility, setHoursVisibility] = useState(true)

  const [taskIdsHoursToSum, setTaskIdsHoursToSum] = useState<string[]>([])

  useEffect(() => {
    const newTaskReportingUnits: TaskReportingUnitsData[] = []
    if (entityType === "project") {
      projectUnitGoals.forEach((unitGoal) => {
        let taskUnit = newTaskReportingUnits.find((task) => task.id === unitGoal.task.id)

        if (!taskUnit) {
          taskUnit = { id: unitGoal.task.id, units: {} }
          newTaskReportingUnits.push(taskUnit)
        }

        const unitVisibility = unitsVisibility[unitGoal.deliverableUnit.description]
        if (unitVisibility !== undefined && unitGoal.isPrimary) {
          taskUnit.units[unitGoal.deliverableUnit.description] = unitVisibility
        }
      })

      const taskIdsToSum = newTaskReportingUnits
        .filter((task) => Object.values(task.units).some(Boolean))
        .map((task) => task.id)

      setTaskIdsHoursToSum(taskIdsToSum)
    }
  }, [unitsVisibility, projectUnitGoals, entityType])

  const filteredChartData = chartData.map((dataToFilter) => {
    const filteredData = { ...dataToFilter }
    Object.keys(unitsVisibility).forEach((unitDescription) => {
      if (!unitsVisibility[unitDescription]) {
        delete filteredData[unitDescription]
      }
    })
    if (entityType === "project") {
      const hours = filteredData.hours || {}
      const projectHour = Object.keys(hours).reduce((acc, taskId) => {
        if (taskIdsHoursToSum.includes(taskId)) {
          if (typeof hours === "object" && hours !== null) {
            return acc + hours[taskId]
          }
        }
        return acc
      }, 0)
      filteredData.hours = projectHour
    }
    return filteredData
  })

  if (goals.length === 0) return null

  return (
    <Box
      paddingY={3}
      paddingX={2}
      style={{
        border: `1px solid ${colors.slate[200]}`,
        borderRadius: "8px",
      }}
    >
      <Typography variant="h4">{displayUnits === "additional" ? "Additional Units" : "Reporting Units"}</Typography>
      <div className="flex flex-col gap-y-4">
        {displayUnits === "primary" && (
          <SummaryUnitsGraph
            filteredData={filteredChartData}
            units={units}
            hoursVisibility={hoursVisibility}
            setHoursVisibility={setHoursVisibility}
            setGraphDateRange={setGraphDateRange}
            graphRangeEnd={graphRangeEnd}
            graphRangeStart={graphRangeStart}
            minDate={minDate}
            isTaskDrawer={isTaskDrawer}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
          />
        )}
        <SummaryUnitsTable
          goals={goals}
          entityType={entityType}
          displayUnits={displayUnits}
          dateRangeType={dateRangeType}
          unitsVisibility={unitsVisibility}
          toggleUnitVisibility={toggleUnitVisibility}
        />
      </div>
    </Box>
  )
}
