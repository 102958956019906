import { Typography } from "@mui/material"
import { clsx } from "clsx"
import { format } from "date-fns"
import { FC, useState } from "react"
import { BiError } from "react-icons/bi"
import { StandardTextarea } from "../../Formik/StandardTextarea"
import { NAButton } from "../../NAbutton"
import { Row } from "../../Table/Row"
import { AssetBadgeCell } from "../Assets/DataGrid/AssetBadgeCell"
import { AssetInventoryDueIn } from "../Assets/ReportDueIn"
import { InventoryAssetExpectation } from "../Drawer/components/InventoryReportDrawer"
import { InventoryReportActions } from "./InventoryReportActions"

type AssetInventoryRowProps = {
  asset: InventoryAssetExpectation
  updateFormData?: (assetId: string, formData: FormData) => void
  isMobile: boolean
}
export type InventoryState = {
  isMissing: boolean
  noteIsVisible: boolean
  quantityReported: number
}

export const AssetInventoryRow: FC<AssetInventoryRowProps> = ({ asset, isMobile }) => {
  const [inventoryState, setInventoryState] = useState<InventoryState>({
    isMissing: false,
    noteIsVisible: false,
    quantityReported: asset.assetChildCount,
  })

  const updateInventoryState = (updates: Partial<InventoryState>) => {
    setInventoryState((prev) => ({ ...prev, ...updates }))
  }

  const isQuantized = Boolean(asset.assetGroupId)

  return (
    <Row>
      <div>
        <div className="grid grid-cols-12 gap-x-6">
          <div className={clsx(isMobile ? "col-span-6" : "col-span-4", "flex items-center")}>
            <AssetBadgeCell asset={asset} depth={asset.depth ?? 0} indent={false} />
          </div>
          {!isMobile && (
            <div className="col-span-3 flex items-center">
              <MissingBlurb
                quantityReported={inventoryState.quantityReported}
                isMissing={inventoryState.isMissing}
                asset={asset}
                isQuantized={isQuantized}
              />
            </div>
          )}
          {!isMobile && (
            <div className="col-span-2 flex items-center">
              <LastInventoriedBlurb lastInventoriedAt={asset.lastInventoriedAt} />
            </div>
          )}
          <div className={clsx(isMobile ? "col-span-6" : "col-span-2", "flex items-center justify-start")}>
            <InventoryReportActions
              asset={asset}
              isQuantized={isQuantized}
              inventoryState={inventoryState}
              updateInventoryState={updateInventoryState}
            />
          </div>
        </div>
      </div>
      {inventoryState.noteIsVisible && (
        <StandardTextarea
          name={`assetReports.${asset.id}.note`}
          placeholder="Inventory notes"
          className="w-full rounded mt-4"
          rows={3}
        />
      )}
    </Row>
  )
}

const MissingBlurb: FC<{
  isMissing: boolean
  isQuantized: boolean
  quantityReported: number
  asset: InventoryAssetExpectation
}> = ({ isMissing, isQuantized, quantityReported, asset }) => {
  const missingAmount = isQuantized ? asset.assetChildCount - quantityReported : 0

  return isMissing ? (
    <div className="inline my-auto text-orange-600">
      <BiError className="inline size-4 mx-1 mb-1" />
      <span>
        Missing
        {isQuantized && missingAmount > 0 && ` (-${missingAmount})`}
      </span>
    </div>
  ) : (
    <AssetInventoryDueIn className="my-auto" asset={asset} dueInOnly />
  )
}

const LastInventoriedBlurb: FC<{
  lastInventoriedAt: InventoryAssetExpectation["lastInventoriedAt"]
}> = ({ lastInventoriedAt }) => {
  return lastInventoriedAt ? (
    <Typography fontSize={16} fontWeight={400}>
      {format(lastInventoriedAt, "MM/dd/yyy")}
    </Typography>
  ) : (
    <NAButton />
  )
}
