import { Masonry } from "@mui/lab"
import { Box, Typography } from "@mui/material"
import { format } from "date-fns"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { TaskOrProjectSummaryNotesSectionQuery } from "../../../../graphql/generated/gql/graphql"
import { StickyNote } from "../../../StickyNote"
import { formatDisplayDate } from "../helpers/formatDisplayDate"
import { groupImageAndNotesByDate } from "../helpers/groupByDate"
import { SummarySectionProps } from "../types"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { colors } from "../../../../helpers/styles/colors"

const SummaryNotesSectionDocument = graphql(`
  query TaskOrProjectSummaryNotesSection(
    $projectId: String!
    $taskId: String
    $rangeEnd: String!
    $rangeStart: String!
    $filter: TaskProgressEventFilter
  ) {
    taskProgressEvents(
      projectId: $projectId
      taskId: $taskId
      rangeEnd: $rangeEnd
      rangeStart: $rangeStart
      filter: $filter
    ) {
      id
      note
      createdAt
      reporter {
        id
        fullName
      }
      task {
        id
        name
      }
    }
  }
`)

export const SummaryNotesSection: FC<SummarySectionProps> = ({
  taskId,
  projectId,
  rangeEnd,
  rangeStart,
  dateRangeType = "daily",
  filter,
}) => {
  const notesQueryVariables = {
    projectId,
    taskId,
    filter: filter === "notes" ? "NOTES" : undefined,
    rangeStart: format(rangeStart, "yyyy-MM-dd"),
    rangeEnd: format(rangeEnd, "yyyy-MM-dd"),
  }
  const [{ data }] = useQuery<TaskOrProjectSummaryNotesSectionQuery>({
    query: SummaryNotesSectionDocument,
    variables: notesQueryVariables,
    pause: !projectId,
  })

  const taskProgressEvents = data?.taskProgressEvents

  const groupedNotes = useMemo(() => {
    return taskProgressEvents ? groupImageAndNotesByDate(taskProgressEvents, dateRangeType) : {}
  }, [dateRangeType, taskProgressEvents])
  const showDate = dateRangeType === "monthly" || dateRangeType === "all-time"

  return (
    <Box
      paddingY={3}
      paddingX={2}
      style={{
        border: `1px solid ${colors.slate[200]}`,
        borderRadius: "8px",
      }}
    >
      {Object.values(groupedNotes).length !== 0 ? (
        <div>
          <Typography variant="h4">Notes</Typography>
          <div className="flex flex-col gap-y-4">
            {Object.entries(groupedNotes).map(([key, content], i) => (
              <div key={i}>
                <Typography variant="h5" fontSize={16}>
                  {formatDisplayDate(key, dateRangeType)}
                </Typography>
                {content?.length > 0 && (
                  <Masonry columns={3} spacing={2} defaultHeight={450} defaultColumns={4}>
                    {(content || []).map((note) =>
                      note.note ? (
                        <StickyNote
                          key={note.id}
                          id={note.id}
                          timeStamp={
                            (showDate ? `${format(note.uploaded, "MMM do")} at ` : "") +
                            format(note.uploaded, "h:mm a").toLowerCase()
                          }
                          reporterName={note.reporterName}
                          content={note.note}
                          taskName={note.taskName}
                        />
                      ) : null
                    )}
                  </Masonry>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <Typography variant="h4">Notes</Typography>
          <EmptyStateBlock label="No notes" />
        </>
      )}
    </Box>
  )
}
