import { Typography } from "@mui/material"
import { clsx } from "clsx"
import { FC } from "react"
import { BiDislike, BiHash, BiLike, BiParagraph } from "react-icons/bi"

type InspectionItem = {
  id: string
  input: string
  label?: string
  photoRequired?: boolean
  type: string
}

export const InspectionItemResult: FC<{ item: InspectionItem }> = ({ item }) => {
  const containerStyles = "flex items-center"
  const iconStyles = "text-white p-1 rounded size-6"

  switch (item.type) {
    case "number":
      return (
        <div className={containerStyles}>
          <BiHash className={clsx("bg-gray-900", iconStyles)} />
          <Typography variant="body1" className="pl-2">
            {item.input}
          </Typography>
        </div>
      )
    case "text":
    case "textarea":
      return (
        <div className={containerStyles}>
          <BiParagraph className={clsx("bg-gray-900", iconStyles)} />
          <Typography variant="body1" className="pl-2">
            {item.input}
          </Typography>
        </div>
      )
    default:
      return (
        <div className={containerStyles}>
          {item.input === "true" ? (
            <BiLike className={clsx("bg-green-600", iconStyles)} />
          ) : (
            <BiDislike className={clsx("bg-orange-600", iconStyles)} />
          )}

          <Typography variant="body1" className="pl-2">
            {item.input === "true" ? "Pass" : "Fail"}
          </Typography>
        </div>
      )
  }
}
