import { Button, useMediaQuery, useTheme } from "@mui/material"
import { FC, useContext } from "react"
import { BiChevronDown } from "react-icons/bi"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { MenuItem, QuickMenuMui } from "./QuickMenuMui"

type Props = {
  items: MenuItem[][]
}

export const testLabel_HeaderActions = "header-actions"

export const HeaderActions: FC<Props> = (props) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  // If there is only one item in the list, no need to adjust the mobile view
  // Since the whole point is that mobile view does not have enough horizontal space
  // for many buttons
  const length = props.items.flat(1).length

  return <div>{md || length === 1 ? <Desktop {...props} /> : <Mobile {...props} />}</div>
}

const Desktop: FC<Props> = ({ items = [] }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  return (
    <div test-label={testLabel_HeaderActions} className="flex gap-2 items-center justify-end">
      {items
        .flat(1)
        .filter((item) =>
          item.requiredPermission || item.requiredPermissionContext
            ? hasPermissionTo(item.requiredPermission, item.requiredPermissionContext)
            : true
        )
        .map(({ value, Icon, iconStyles, onClick }, i) => (
          <Button
            key={`HeaderActions ${i}`}
            variant="contained"
            color="secondary"
            endIcon={Icon && <Icon className={iconStyles} />}
            onClick={onClick}
          >
            {value}
          </Button>
        ))}
    </div>
  )
}

const Mobile: FC<Props> = ({ items = [] }) => {
  return (
    <QuickMenuMui
      items={items}
      buttonProps={{
        variant: "contained",
        color: "secondary",
        endIcon: <BiChevronDown className="size-5" />,
      }}
    >
      <div>Actions</div>
    </QuickMenuMui>
  )
}
