import { TextField } from "@mui/material"
import { useField } from "formik"
import { FC, useCallback, useContext, useEffect, useState } from "react"
import { useQuery } from "urql"
import { DeliverableUnit } from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { uniqBy } from "../helpers/util-functions"
import { useHandleError } from "../hooks/useHandleError"
import { useModalProps } from "./Modals/hooks/useModalProps"
import { OrganizationSettingsContext } from "../providers/OrganizationSettingsProvider"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { AddOrEditUnitModal } from "./CustomReportingUnits/AddOrEditUnitModal"
import { CreatableAutocomplete } from "./Formik/CreatableAutocomplete"

const UnitInputQueryDocument = graphql(`
  query UnitInput {
    deliverableUnits {
      id
      description
      referenceNumber
      unitOfMeasure
    }
  }
`)

const ContractUnitInputQueryDocument = graphql(`
  query UnitInputForContract($contractId: String!) {
    contractDeliverableUnits(contractId: $contractId) {
      id
      referenceNumber

      deliverableUnit {
        id
        description
        referenceNumber
        unitOfMeasure
      }
    }
  }
`)

type UnitInputProps = {
  label?: string
  deliverableUnitId?: string
  disabled?: boolean
  selectedUnitsMap?: Record<string, boolean>
  placeholder?: string
  chevron?: boolean
  opacity?: string
  isCreatable?: boolean
  isPrimary?: boolean
  unitName?: string
  quantityName?: string
  contractId?: string
}

export const UnitInput: FC<UnitInputProps> = ({
  label = "Unit",
  disabled = false,
  deliverableUnitId,
  selectedUnitsMap,
  placeholder = "Select Unit",
  isCreatable = false,
  isPrimary = true,
  unitName = "deliverableUnitId",
  quantityName = "unitGoalTarget",
  contractId,
}) => {
  // fetch all enabled deliverable units if no contractId is passed
  const [{ data: deliverableUnitsData, error: unitsError }, refetchDeliverableUnits] = useQuery({
    query: UnitInputQueryDocument,
    pause: !!contractId,
  })

  // if the contractId is passed, we need to fetch the deliverable units for that contract instead
  const [{ data: contractDeliverableUnitsData }] = useQuery({
    query: ContractUnitInputQueryDocument,
    variables: { contractId: contractId || "" },
    pause: !contractId,
  })

  const deliverableUnits = uniqBy(
    contractDeliverableUnitsData?.contractDeliverableUnits?.map((contractDeliverableUnit) => ({
      ...contractDeliverableUnit.deliverableUnit,
      referenceNumber:
        contractDeliverableUnit.referenceNumber || contractDeliverableUnit.deliverableUnit.referenceNumber,
    })) ||
      deliverableUnitsData?.deliverableUnits ||
      [],
    (unit) => unit.description
  )

  const { unitsOfMeasure } = useContext(OrganizationSettingsContext)

  useHandleError(unitsError, "Error fetching units")

  const { hasPermissionTo } = useContext(PermissionsContext)
  const hasPermissionToCreateUnit = hasPermissionTo("unit:create")
  const shouldBeCreatable = isCreatable && hasPermissionToCreateUnit && !disabled && !contractId

  const [deliverableUnitIdField, ,] = useField(unitName)
  const [fieldProps, meta] = useField(quantityName)

  const selected = deliverableUnits.find(({ id }) => id === deliverableUnitIdField.value)
  const { unitOfMeasure, description } = selected || {}
  const uom = unitsOfMeasure.find((u) => u.name === unitOfMeasure)

  return (
    <>
      <div className="grid grid-cols-12 gap-2.5 md:gap-2 w-full ">
        <div className="col-span-7 h-10">
          <CreatableUnitSelect
            disabled={disabled}
            defaultValue={deliverableUnitId ?? ""}
            label={label}
            name={unitName}
            placeholder={placeholder}
            deliverableUnits={deliverableUnits}
            refetchDeliverableUnits={refetchDeliverableUnits}
            shouldBeCreatable={shouldBeCreatable}
            selectedUnitsMap={selectedUnitsMap}
          />
        </div>
        <div className="col-span-5 flex">
          <TextField
            {...fieldProps}
            value={fieldProps.value ?? ""}
            disabled={!description}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            label="Amount"
            placeholder="Amount"
            type="number"
            variant="outlined"
            required={isPrimary}
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
              },
              endAdornment: unitOfMeasure && (
                <div className="flex px-2 items-center rounded-r border bg-gray-50  border-gray-400 text-sm h-full">
                  {uom?.symbol || unitOfMeasure}
                </div>
              ),
            }}
            sx={{ "& .MuiOutlinedInput-root": { paddingRight: "0px" } }}
          />
        </div>
      </div>
    </>
  )
}

type CreatableUnitSelectProps = {
  label?: string
  defaultValue?: string
  disabled?: boolean
  placeholder?: string
  deliverableUnits: Pick<DeliverableUnit, "id" | "description" | "referenceNumber">[]
  name: string
  refetchDeliverableUnits: () => void
  shouldBeCreatable?: boolean
  selectedUnitsMap?: Record<string, boolean>
}

export const CreatableUnitSelect: FC<CreatableUnitSelectProps> = ({
  disabled = false,
  defaultValue,
  label = "Unit",
  placeholder = "Select Unit",
  deliverableUnits,
  name,
  refetchDeliverableUnits,
  shouldBeCreatable,
  selectedUnitsMap,
}) => {
  const [, , deliverableUnitIdHelpers] = useField(name)
  const newDeliverableUnitModalProps = useModalProps("Add new unit")

  const [descriptionToCreate, setDescriptionToCreate] = useState("")

  const onCreateUnit = useCallback(
    (value: string) => {
      newDeliverableUnitModalProps.handleOpenModal()
      setDescriptionToCreate(value)
    },
    [newDeliverableUnitModalProps]
  )

  const onSuccess = useCallback(
    (data: Partial<DeliverableUnit>) => {
      if (data?.id) deliverableUnitIdHelpers.setValue(data.id)
      newDeliverableUnitModalProps.handleCloseModal()
      refetchDeliverableUnits()
    },
    [deliverableUnitIdHelpers, newDeliverableUnitModalProps, refetchDeliverableUnits]
  )

  useEffect(() => {
    deliverableUnitIdHelpers.setValue(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <>
      <CreatableAutocomplete
        disabled={disabled}
        disableCreate={!shouldBeCreatable}
        defaultValue={defaultValue ?? ""}
        label={label}
        name={name}
        placeholder={placeholder}
        options={deliverableUnits.map((unit) => ({
          id: unit.id,
          label: `${unit.description}${unit.referenceNumber ? ` (${unit.referenceNumber})` : ""}`,
        }))}
        onCreate={shouldBeCreatable ? onCreateUnit : undefined}
        optionsToDisable={selectedUnitsMap}
      />
      {newDeliverableUnitModalProps.isOpen && (
        <AddOrEditUnitModal
          {...newDeliverableUnitModalProps}
          initialValues={{ description: descriptionToCreate }}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}
