import { Button, Typography } from "@mui/material"
import { FC } from "react"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"
import { CheckedPermission } from "../types/Permission"
import { QuickMenuDotsHorizontal } from "./QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "./QuickMenuMui"
import { RenderIf } from "./RenderIf"
import { ModalProps } from "./Modals/hooks/useModalProps"

type Props = {
  isEditing: boolean
  permission?: CheckedPermission
  quickMenuItems?: MenuItem[][]
  title: string
  modalProps?: ModalProps
}

export const QuickMenuSectionTitle: FC<Props> = ({ title, quickMenuItems = [], isEditing, permission, modalProps }) => {
  const { organizationId } = useCurrentUser()

  return (
    <>
      <div className="flex justify-between items-center min-h-[40px]">
        <Typography variant="h5" className="my-auto">
          {title}
        </Typography>
        {permission && (
          <RenderIf permissionsInclude={permission} context={{ organizationId }}>
            {quickMenuItems && !isEditing && (
              <QuickMenuMui items={quickMenuItems} buttonShape="round">
                <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
              </QuickMenuMui>
            )}
          </RenderIf>
        )}
        {permission && modalProps && (
          <RenderIf permissionsInclude={permission} context={{ organizationId }}>
            <Button
              variant="text"
              onClick={() => {
                modalProps.handleOpenModal()
              }}
            >
              Add
            </Button>
          </RenderIf>
        )}
      </div>
      <hr className="mt-2 mb-4 md:mb-6" />
    </>
  )
}
