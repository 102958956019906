import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import { FC, ReactNode } from "react"
import { BiX } from "react-icons/bi"
import { ModalProps } from "../../hooks/useModalProps"

type Props = {
  children: ReactNode
  modalProps: ModalProps
}

export const BasicModal: FC<Props> = ({ children, modalProps }) => {
  return (
    <Dialog
      open={modalProps.isOpen}
      onClose={modalProps.handleCloseModal}
      aria-labelledby="dialog-title"
      PaperProps={{ sx: { minWidth: 500 } }}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <DialogTitle className="shrink truncate" variant="h3" id="dialog-title">
          {modalProps.contentLabel}
        </DialogTitle>
        <IconButton aria-label="close" onClick={modalProps.handleCloseModal} className="mr-2.5">
          <BiX className="size-7" />
        </IconButton>
      </div>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}
