import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  assignment: Yup.object().shape({
    assignmentType: Yup.string().oneOf(["User", "Asset", "Task"]).required("Required"),
    selectedAssetId: Yup.string().when("assignmentType", {
      is: "Asset",
      then: (schema) => schema.required("You must select an asset to reassign."),
    }),
    selectedProjectId: Yup.mixed().when("assignmentType", {
      is: "Task",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedProjectId must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must have a project to reassign these assets."),
    }),
    selectedTaskId: Yup.mixed().when("assignmentType", {
      is: "Task",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedTaskId must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must select a task to reassign these assets."),
    }),
    selectedUserIds: Yup.mixed().when("assignmentType", {
      is: "User",
      then: (schema) =>
        schema
          .test(
            "is-string-or-array-of-string",
            "selectedUserIds must be a string or an array of strings",
            (value) =>
              typeof value === "string" || (Array.isArray(value) && value.every((item) => typeof item === "string"))
          )
          .required("You must select a user to reassign these assets."),
    }),
  }),
  assets: Yup.array(Yup.string().required("Required")),
})
