import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { BiChevronDown, BiPulse, BiPurchaseTagAlt } from "react-icons/bi"
import { sentenceCase } from "../../../../../helpers/strings/sentenceCase"
import { CustomMenu } from "../../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "./AssetFilterState"
import { useStore } from "@tanstack/react-store"
import { assetListStore } from "../../../../../stores/assetList"

type ExtraFilterSpecification = {
  label: string
  onSelect: () => void
  onDeselect?: () => void
  exclusiveTo?: string | string[]
  disabled?: boolean
  isOnByDefault?: boolean
}

// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  extraFilters?: {
    items: ExtraFilterSpecification[]
    label?: string
  }[]
  filterType: "Status" | "Category"
}

type FilterButtonProps = {
  filterType: string
  labelName: string
}

export const AssetFilterStatusAndCategory = ({ extraFilters = [], filterType }: Props): JSX.Element => {
  const filters = useStore(assetListStore, (s) => s.filters)
  const filterKey = filterType === "Status" ? "statuses" : "categories"
  const storeFilters = filters[filterKey] as string[]

  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const extraFiltersDefault = extraFilters
    .map((section) => section.items)
    .flat(1)
    .reduce((acc, filter) => ({ ...acc, [filter.label]: filter.isOnByDefault ? true : false }), {})

  const [activeFilters, setActiveFilters] = useState<Record<string, boolean>>(extraFiltersDefault)

  const checkExtraFilters = extraFilters
    .map((section) => ({ ...section, items: section.items.filter((f) => !f.disabled) })) // Filter out all disabled items
    .filter((section) => section.items.length !== 0) // Filter out all empty sections

  const handleMenuItemClick = (
    label: string,
    onSelect: () => void,
    onDeselect: (() => void) | undefined,
    exclusiveTo: string | string[] | undefined
  ) => {
    const filterIsActive = !activeFilters[label]
    const newActiveFilters = { ...activeFilters, [label]: filterIsActive }
    if (!filterIsActive) onDeselect?.()
    if (filterIsActive && Array.isArray(exclusiveTo)) {
      exclusiveTo.forEach((exclusion) => (newActiveFilters[exclusion] = false))
    }
    setActiveFilters(newActiveFilters)
    if (filterIsActive) onSelect()
  }

  const labelName = useMemo(() => {
    const filtersCount = storeFilters.length
    const extraFiltersItems = extraFilters[0].items
    const ifOneFilterName = extraFiltersItems.find((item) => activeFilters[item.label])?.label || "All"
    const filterLabel = filtersCount === 1 ? ifOneFilterName : filtersCount > 1 ? `${filtersCount} selected` : "All"
    return filterLabel
  }, [activeFilters, extraFilters, storeFilters.length])

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        anchor={<FilterButton filterType={filterType} labelName={labelName} />}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {checkExtraFilters.map((section, i) => {
            return (
              <div key={`section-${i}`}>
                {section.items.map(({ label, onSelect, exclusiveTo, onDeselect }) => (
                  <MenuItem key={label} onClick={() => handleMenuItemClick(label, onSelect, onDeselect, exclusiveTo)}>
                    {exclusiveTo ? (
                      <SingleOrMultiSelectionMenuItem selected={activeFilters[label]} />
                    ) : (
                      <SingleOrMultiSelectionMenuItem selected={activeFilters[label]} isCheckbox={!exclusiveTo} />
                    )}
                    <ListItemText>{sentenceCase(label)}</ListItemText>
                  </MenuItem>
                ))}
              </div>
            )
          })}
        </MenuList>
      </CustomMenu>
    </>
  )
}

const FilterButton: FC<FilterButtonProps> = ({ filterType, labelName }) => {
  return (
    <>
      <div className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800">
        {filterType === "Status" && <BiPulse />}
        {filterType === "Category" && <BiPurchaseTagAlt />}
        <span>{filterType}</span>
        <span className="text-gray-500">{labelName}</span>
        <BiChevronDown className="inline" />
      </div>
    </>
  )
}
