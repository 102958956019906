import { clsx } from "clsx"
import { useField } from "formik"
import { FC, InputHTMLAttributes } from "react"
import { IconType } from "react-icons"
import { overrideTailwindClasses } from "tailwind-override"
import { formatMoney } from "../../helpers/formatters/formatMoney"
import { formatPhoneNumber } from "../../helpers/formatters/formatPhoneNumber"
import { Input, Label } from "../deprecated"

type Props = {
  name: string
  label?: string
  tooltipLabel?: string
  tooltipText?: string
  labelClassName?: string
  Icon?: IconType
  errorStyle?: "contained"
  containerClassName?: string
  inputClassName?: string
  inputContainerClassName?: string
}

/*
/ @deprecated  You probably want `components/formik/TextField`, but other MUI based helpers might also be a good fit.
*/
export const DeprecatedInput: FC<InputHTMLAttributes<HTMLInputElement> & Props> = ({
  name,
  label,
  tooltipLabel,
  tooltipText,
  labelClassName,
  Icon,
  errorStyle,
  containerClassName,
  inputClassName,
  inputContainerClassName = "",
  required = false,
  type = "text",
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)
  const displayError = meta.touched && meta.error && !disabled
  const errorContained = errorStyle === "contained"
  return (
    <div
      className={overrideTailwindClasses(clsx(`flex flex-col gap-y-2`, disabled && `opacity-40`, containerClassName))}
    >
      {label && (
        <div className="flex justify-between flex-wrap items-start">
          <Label
            htmlFor={field.name}
            tooltipText={tooltipText}
            tooltipLabel={tooltipLabel}
            className={labelClassName}
            isRequired={required}
          >
            {label}
          </Label>
        </div>
      )}
      <div className={inputContainerClassName}>
        <Input
          Icon={Icon}
          className={clsx(displayError ? `border-red-600 border-2` : "border", inputClassName)}
          type={type}
          value={field.value}
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value
            if (type === "tel") {
              helpers.setValue(formatPhoneNumber(value))
            } else if (type === "money") {
              const append = value.at(-1) === "." ? "." : ""
              const decimalPlaces = value.split(".").at(1)?.length || 0
              const valueAsNumber = Number(value.replace(/[^0-9.-]+/g, ""))
              helpers.setValue(`${formatMoney(valueAsNumber, Math.min(decimalPlaces, 2))}${append}`)
            } else if (type === "number" && value !== "") {
              helpers.setValue(parseFloat(value))
            } else {
              helpers.setValue(value)
            }
          }}
          onBlur={() => {
            const value = type === "number" && field.value !== "" ? parseFloat(field.value) : field.value?.trim()
            helpers.setValue(value)
          }}
          {...props}
        />
        {!errorContained &&
          (displayError ? (
            <div className="text-red-600 text-sm min-h-5">{meta.error}</div>
          ) : (
            <div className="text-sm min-h-5">&nbsp;</div>
          ))}
      </div>
    </div>
  )
}
