import { LoadingButton } from "@mui/lab"
import { Typography } from "@mui/material"
import { FC } from "react"
import { BiBox, BiLoaderAlt, BiSortAlt2 } from "react-icons/bi"
import { User, useClockOutUserMutation, useUserDeleteMutation } from "../../../graphql/generated/client-types-and-hooks"
import { toastClockOutError } from "../../../helpers/clockInOut/toastClockOutError"
import { useReassignAndArchiveUserWorkflow } from "../../../hooks/useReassignAndArchiveUserWorkflow"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { PickPlus } from "../../../types/helpers"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { ButtonHollow } from "../../deprecated"
import DeprecatedModal from "../../deprecated/StandardModal"
import { ModalProps } from "../hooks/useModalProps"
import { ModalBody } from "./Elements/ModalBody"
import { ModalFooter } from "./Elements/ModalFooter"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "assetCount" | "isClockedIn">
  modalProps: ModalProps
  onSuccess?: (userId: string) => void
}

const CannotDeleteUser: FC<{
  name: string | undefined
  assetCount: number
}> = ({ name, assetCount }) => {
  return (
    <>
      <p className="pb-5">
        You cannot archive <span className="font-semibold">{name}</span> because they have assigned assets.
      </p>
      <p className="pb-5">
        This user has{" "}
        <span className="font-semibold">
          {assetCount} {assetCount > 1 ? "assets " : "asset "}
        </span>
        assigned to them.
      </p>
      <p className="pb-5">Please reassign them before archiving the user.</p>
    </>
  )
}

export const ConfirmArchiveUserModal: FC<Props> = ({ user, modalProps, onSuccess }) => {
  const archiveUserWorkflow = useReassignAndArchiveUserWorkflow()

  const currentUser = useCurrentUser()
  const [, clockOutMutation] = useClockOutUserMutation()
  const [, deleteUserMutation] = useUserDeleteMutation()

  const handleArchiveUser = async () => {
    if (user.id === currentUser.id) {
      errorSnack(
        "You can't archive yourself. If you really want to archive yourself, ask another admin user to archive you."
      )
      return
    }

    if (user.isClockedIn) {
      const clockOutResult = await clockOutMutation({ userId: user.id })
      if (clockOutResult.error) {
        toastClockOutError(clockOutResult.error, user)
        return
      }
    }

    const deleteUserResult = await deleteUserMutation({
      id: user.id,
    })

    if (deleteUserResult.error) {
      errorSnack("Error archiving user", { variant: "error" })
      return
    } else {
      successSnack("User archived successfully")
    }

    onSuccess?.(user.id)
    modalProps.handleCloseModal()
  }

  const { firstName, lastName, assetCount } = user
  const name = `${firstName} ${lastName}`

  const hasAssets = assetCount > 0

  const handleReassignments = () => {
    const userToArchive = {
      id: user?.id ?? "",
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      assetCount: user?.assetCount ?? 0,
      isClockedIn: user?.isClockedIn ?? false,
    }

    modalProps.handleCloseModal()
    archiveUserWorkflow({
      user: userToArchive,
      onErrorCallback: () => {
        errorSnack(`User deletion error; please try again`)
      },
      onSuccessCallback: () => {
        handleArchiveUser()
      },
    })
  }

  return (
    <DeprecatedModal {...modalProps}>
      {modalProps.isOpen && (
        <>
          <ModalBody>
            {assetCount > 0 ? (
              <CannotDeleteUser name={name} assetCount={assetCount} />
            ) : (
              <Typography variant="body1" className="mb-4">
                {`Are you sure you want to archive ${name}?`}
              </Typography>
            )}
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={modalProps.handleCloseModal}>
              Cancel
            </ButtonHollow>

            <LoadingButton
              type="button"
              variant="contained"
              color={hasAssets ? "primary" : "error"}
              loadingIndicator={<BiLoaderAlt className="animate-spin" size="20px" color="white" />}
              startIcon={hasAssets ? <BiSortAlt2 className="rotate-90 size-5" /> : <BiBox size="20px" color="white" />}
              loadingPosition="start"
              onClick={(e) => {
                if (hasAssets) {
                  e.preventDefault()
                  handleReassignments()
                } else {
                  handleArchiveUser()
                }
              }}
            >
              <span>{hasAssets ? "Transfer" : "Archive"}</span>
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </DeprecatedModal>
  )
}
