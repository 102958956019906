import { eachDayOfInterval, format } from "date-fns"

type ChartDataType = Record<string, number | string | boolean | { [taskId: string]: number }>

export function mergeDataWithPlaceholders<T extends ChartDataType>(
  chartData: T[],
  rangeStart: Date,
  rangeEnd: Date,
  entityType: "project" | "task"
): T[] {
  const currentLabels = eachDayOfInterval({ start: rangeStart, end: rangeEnd }).map((day) => format(day, "MMM dd yyyy"))
  // Extract units from the provided chartData
  const allUnits = new Set<string>()
  chartData.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (key !== "name") {
        allUnits.add(key)
      }
    })
  })

  // Generate a default data structure for each label
  const defaultChartData = currentLabels.map((label) => {
    const defaultItem: ChartDataType = { name: label }
    allUnits.forEach((unit) => {
      if (unit === "highlightBar") {
        defaultItem[unit] = false
      } else if (unit === "hours" && entityType === "project") {
        defaultItem[unit] = {}
      } else {
        defaultItem[unit] = 0
      }
    })
    return defaultItem as T
  })

  // Merge the placeholder data with the actual chartData
  const values = defaultChartData.map((defaultItem) => {
    const actualData = chartData.find((item) => item.name === defaultItem.name)
    return actualData || defaultItem
  })
  return values
}
