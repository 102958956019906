import { Box, Button, Typography } from "@mui/material"
import { addDays, endOfDay, startOfDay } from "date-fns"
import { FC, useMemo, useState } from "react"
import { BiCalendar, BiCar, BiGridAlt, BiGroup, BiImage, BiNote, BiRuler } from "react-icons/bi"
import { useQuery } from "urql"
import { z } from "zod"
import { graphql } from "../../graphql/generated/gql"
import { endOfPeriod, startOfPeriod } from "../../helpers/dateAndTime/dateRange"
import { useHashState } from "../../hooks/useHashState"
import { DateSelect } from "../DateSelect"
import { MuiModal } from "../Modals/components/Elements/MuiModal"
import { useModalProps } from "../Modals/hooks/useModalProps"
import { PageTitle } from "../PageTitle"
import { RevisedProjectSummarySkeleton } from "../Partials/Projects/ProjectSummary.skeleton"
import { FilterGroupOption, FilterGroupWithQueryStringParam } from "../Partials/Summary/FilterGroupWithQueryStringParam"
import { SummaryImageSection } from "../Partials/Summary/Sections/ProjectAndTaskSummaryImagesSection"
import { SummaryNotesSection } from "../Partials/Summary/Sections/ProjectAndTaskSummaryNotesSection"
import { ProjectAndTaskSummaryTeamTable } from "../Partials/Summary/Sections/ProjectAndTaskSummaryTeamTable"
import { SummaryTimeline } from "../Partials/Summary/Sections/ProjectAndTaskSummaryTimeline"
import { SummaryUnitsGraphAndTable } from "../Partials/Summary/Sections/ProjectAndTaskSummaryUnitsGraphAndTable"
import { ProjectSummaryTaskTable } from "../Partials/Summary/Sections/ProjectSummaryTaskTable"
import { ALL_SUMMARY_FILTER_TYPES, DateRangeFilterString, SummaryFilterType } from "../Partials/Summary/types"
import { ProjectOverviewWidgets } from "../Widgets/SummaryWidgets/ProjectOverviewWidgets"
import { TaskOverviewWidgets } from "../Widgets/SummaryWidgets/TaskOverviewWidgets"
import { UnitReportExportForm } from "../Widgets/UnitReportWidget/UnitReportExportForm"
import { PickPlus } from "../../types/helpers"

const ProjectSummaryDocument = graphql(`
  query ProjectSummary($projectId: String!, $rangeStart: DateTime, $rangeEnd: DateTime) {
    myOrganization {
      id
      timezone
    }
    project(id: $projectId) {
      __typename
      id
      estimatedHours
      name
      createdAt
      lastExport
      startDate
      assetsCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      previousSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeStart)
      totalSeconds: timeEntriesSumDurationInSeconds
      currentSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      userCount
    }
  }
`)

export const TaskSummaryDocument = graphql(`
  query TaskSummary($taskId: String!) {
    task(id: $taskId) {
      __typename
      id
      projectId
      groupId
      group {
        id
        name
      }
      organizationId
      archived
      createdAt
      description
      startDate
      endDate
      estimatedHours
      completedHours
      hasReportableUnit
      isComplete
      isDefault
      metadata {
        label
        content
      }
      name
      assetCount
      userCount
      workersCompCode {
        id
        name
        ncciCode
      }
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
          progress
        }
      }
      project {
        id
        isArchived
        name
        userCount
        assetsCount
      }
    }
  }
`)

const stringToDate = z.string().pipe(z.coerce.date())

const ZodSummaryData = z.object({
  filter: z.enum(ALL_SUMMARY_FILTER_TYPES),
  rangeStart: z.date().or(stringToDate),
  rangeEnd: z.date().or(stringToDate),
})
const ZodSummaryPageHashDataSchema = z.record(z.string(), ZodSummaryData)

type SummaryPageHashData = z.infer<typeof ZodSummaryPageHashDataSchema>
type DateRange = PickPlus<z.infer<typeof ZodSummaryData>, "rangeStart" | "rangeEnd">

export const SummaryPageContent: FC<{ isTaskDrawer?: boolean; projectId: string; taskId?: string }> = ({
  isTaskDrawer = false,
  projectId,
  taskId,
}) => {
  const subjectKey = `summary-${taskId || projectId}`
  const [dateRangeType, setDateRangeType] = useState<DateRangeFilterString>("daily")

  const [summaryPageHashData, setSummaryPageHashData] = useHashState<SummaryPageHashData>(
    {
      [`summary-${taskId || projectId}`]: {
        filter: "all",
        rangeStart: startOfPeriod(new Date(), "daily"),
        rangeEnd: endOfPeriod(new Date(), "daily"),
      },
    },
    ZodSummaryPageHashDataSchema
  )

  const subjectHashData = summaryPageHashData[subjectKey]
  const filter = subjectHashData.filter
  const rangeStart = subjectHashData.rangeStart
  const rangeEnd = subjectHashData.rangeEnd

  const setFilter = (value: SummaryFilterType) => {
    setSummaryPageHashData({
      [subjectKey]: { filter: value, rangeStart: subjectHashData.rangeStart, rangeEnd: subjectHashData.rangeEnd },
    })
  }

  const setDateRange = (dateRange: DateRange) => {
    setSummaryPageHashData({
      [subjectKey]: {
        filter: subjectHashData.filter,
        rangeStart: dateRange.rangeStart,
        rangeEnd: dateRange.rangeEnd,
      },
    })
  }

  const [graphDateRange, setGraphDateRange] = useState<DateRange>({
    rangeStart: startOfDay(addDays(new Date(), -20)),
    rangeEnd: endOfDay(new Date()),
  })
  const { rangeStart: graphRangeStart, rangeEnd: graphRangeEnd } = graphDateRange

  const [{ data: taskSummaryData }] = useQuery({
    query: TaskSummaryDocument,
    variables: { taskId: taskId ?? "" },
    pause: !taskId,
  })

  const [{ data: projectSummaryData }] = useQuery({
    query: ProjectSummaryDocument,
    variables: { projectId, rangeStart, rangeEnd },
    pause: !!taskId,
  })

  const subject = taskSummaryData?.task ?? projectSummaryData?.project

  const unitReportExportModalProps = useModalProps("Export Report")

  const summaryFilterOptions: FilterGroupOption[] = [
    { title: "All", value: "all", icon: <BiGridAlt /> },
    { title: "Units", value: "units", icon: <BiRuler /> },
    { title: "Team", value: "team", icon: <BiGroup /> },
    { title: "Assets", value: "assets", icon: <BiCar /> },
    { title: "Images", value: "images", icon: <BiImage /> },
    { title: "Notes", value: "notes", icon: <BiNote /> },
    { title: "Timeline", value: "timeline", icon: <BiCalendar /> },
  ]

  const pageTitle = useMemo(() => {
    if (taskSummaryData?.task) {
      return `Summary for ${taskSummaryData.task.name}`
    }

    if (projectSummaryData?.project) {
      return `Summary for ${projectSummaryData.project.name}`
    }

    return "Summary"
  }, [projectSummaryData?.project, taskSummaryData?.task])

  if (!subject) {
    return <RevisedProjectSummarySkeleton />
  }

  if (!summaryPageHashData) return null

  return (
    <>
      <PageTitle title={pageTitle} />
      <header className="flex flex-col gap-y-5 pb-10">
        <Typography variant="h3">{subject.__typename === "Project" ? "Project Summary" : "Task Summary"}</Typography>
        <Box display="flex" justifyContent="space-between">
          <DateSelect
            minDate={subject.startDate ? subject.startDate : subject.createdAt}
            dateRangeType={dateRangeType}
            onChange={(newDate: DateRange) => setDateRange(newDate)}
            setFilter={setDateRangeType}
            initialRange={{ rangeStart, rangeEnd }}
          />

          {subject.__typename === "Project" && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  unitReportExportModalProps.handleOpenModal()
                }}
              >
                Export
              </Button>
              {unitReportExportModalProps.isOpen && (
                <MuiModal {...unitReportExportModalProps} disableSaveButton showActions={false}>
                  <UnitReportExportForm
                    isModal
                    projectId={projectId}
                    onClose={unitReportExportModalProps.handleCloseModal}
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    lastExport={subject?.lastExport}
                  />
                </MuiModal>
              )}
            </>
          )}
        </Box>

        {subject.__typename === "Project" ? (
          <ProjectOverviewWidgets project={subject} rangeStart={rangeStart} rangeEnd={rangeEnd} isLoading={false} />
        ) : (
          <TaskOverviewWidgets taskId={subject.id} rangeStart={rangeStart} rangeEnd={rangeEnd} />
        )}

        <FilterGroupWithQueryStringParam
          defaultFilterValue="all"
          filter={filter}
          options={summaryFilterOptions}
          queryStringParameter="filter"
          setFilter={setFilter}
        />
      </header>
      <main className="flex flex-col gap-y-10 pb-28">
        {(filter === "all" || filter === "units") && (
          <SummaryUnitsGraphAndTable
            entityType={subject.__typename === "Project" ? "project" : "task"}
            entityId={subject.id}
            dateRangeType={dateRangeType}
            graphRangeEnd={graphRangeEnd}
            graphRangeStart={graphRangeStart}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
            setGraphDateRange={setGraphDateRange}
            minDate={subject.startDate ?? subject.createdAt}
            isTaskDrawer={isTaskDrawer}
          />
        )}

        {subject.__typename === "Project" && (filter === "all" || filter === "tasks") && (
          <ProjectSummaryTaskTable projectId={projectId} rangeEnd={rangeEnd} rangeStart={rangeStart} />
        )}

        {projectId && (filter === "all" || filter === "team") && (
          <ProjectAndTaskSummaryTeamTable
            taskId={subject.__typename === "Task" ? subject.id : undefined}
            projectId={projectId}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
            dateRangeType={dateRangeType}
          />
        )}

        {(filter === "all" || filter === "images") && (
          <SummaryImageSection
            taskId={subject.__typename === "Task" ? subject.id : undefined}
            projectId={projectId}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            dateRangeType={dateRangeType}
            filter={filter}
          />
        )}
        {(filter === "all" || filter === "notes") && (
          <SummaryNotesSection
            taskId={subject.__typename === "Task" ? subject.id : undefined}
            projectId={projectId}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            dateRangeType={dateRangeType}
            filter={filter}
          />
        )}

        {(filter === "all" || filter === "timeline") && (
          <SummaryTimeline
            taskId={subject.__typename === "Task" ? subject.id : undefined}
            projectId={projectId}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            filter={filter}
          />
        )}
      </main>
    </>
  )
}
