import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"

const TimelineSkeletonChild: FC = () => (
  <div className="flex flex-col gap-6 w-2/5">
    <div className="flex flex-col items-center">
      <div className="col-span-10 md:col-span-5 flex gap-x-4 mb-5">
        <div className="flex flex-col justify-center gap-y-2 items-end">
          <SkeletonElement className="h-4 w-16" />
          <SkeletonElement className="h-4 w-24" />
        </div>
        <SkeletonElement className="size-8" />
        <div className="flex flex-col justify-center gap-y-2">
          <SkeletonElement className="h-4 w-20" />
          <SkeletonElement className="h-4 w-40" />
        </div>
      </div>
    </div>
  </div>
)

export const TaskTimelineSkeleton: FC = () => (
  <SkeletonContainer>
    <div className="flex flex-col items-center w-2/5 ml-10">
      <TimelineSkeletonChild />
      <SkeletonElement className="relative right-8 h-12 mb-5 w-1" />
      <TimelineSkeletonChild />
      <SkeletonElement className="relative right-8 h-12 mb-5 w-1" />
      <TimelineSkeletonChild />
    </div>
  </SkeletonContainer>
)
