import { FC, useContext } from "react"
import { UserExpectation } from "../Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { UserSelect } from "./UserSelect"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { PermissionsContext } from "../../providers/PermissionsProvider/PermissionsProvider"

type Props = {
  preselectedUsers?: UserExpectation[]
}
export const ReassignUserSelect: FC<Props> = ({ preselectedUsers }) => {
  const currentUser = useCurrentUser()
  const { hasGrantedPermission } = useContext(PermissionsContext)

  // This will check if the user is a team member or admin/manager/team lead. Team member should only be able to see themselves in the reasign modal. Other roles have the users that appear controlled by ListVisibilityFilter
  const assignOtherUsers = hasGrantedPermission("user:read:*") || hasGrantedPermission("user:read:assigned-project")

  return (
    <UserSelect
      className="col-span-12"
      label="Team Member"
      multiple
      name="userIds"
      preselected={preselectedUsers}
      userExclusionFilter={(user) => {
        if (assignOtherUsers) return true
        return user.id === currentUser.id
      }}
    />
  )
}
