import { FC } from "react"
import { BiGroup } from "react-icons/bi"
import { SummaryWidget } from "./SummaryWidget"

export const UserCountWidget: FC<{
  userCount: number
  id: string
  projectId?: string
}> = ({ userCount, id, projectId = undefined }) => {
  const teamTabLink = `/projects/${projectId ? `${projectId}/` : ""}${id}/team`
  return <SummaryWidget Icon={BiGroup} value={String(userCount)} description="team" href={teamTabLink} />
}
