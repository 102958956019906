import { useMemo } from "react"
import { useHandleError } from "../hooks/useHandleError"
import { PickPlus } from "../types/helpers"
import {
  TeamDetailsPageGetUserQuery,
  useAllOrganizationDivisionsQuery,
} from "../graphql/generated/client-types-and-hooks"
import { DivisionSelectionExpectation } from "../components/Formik/DivisionSelect"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"

export const useUserDivisionAssignments = (
  assignedDivision?: PickPlus<TeamDetailsPageGetUserQuery["user"]["assignedDivision"], "id" | "name"> | null,
  divisionAccess?: PickPlus<TeamDetailsPageGetUserQuery["user"]["divisionAccess"][number], "id" | "name">[]
): {
  hasOrgDivisions: boolean
  isAssignedToPartialDivisions: boolean
  organizationId: string
  userDivisionAssignment: DivisionSelectionExpectation[]
} => {
  const [{ data, error }] = useAllOrganizationDivisionsQuery()
  const currentUser = useCurrentUser()

  useHandleError(error, "There was an error fetching divisions.")

  const userDivisionAssignment = useMemo(() => {
    if (!data?.myDivisions) return []

    const divisions = data.myDivisions

    const sortedDivisions = divisions.sort((a, b) => (a.name || "").localeCompare(b.name || ""))

    return sortedDivisions.map((division) => {
      const isCurrentAssignment =
        // Assigned to specific division
        division.id === assignedDivision?.id ||
        // No assignment defined, default to main org division
        (!assignedDivision?.id && division.isPrimary)
      return {
        ...division,
        isCurrentAssignment,
        divisionAccess:
          isCurrentAssignment ||
          divisionAccess?.some(
            (access) =>
              // Specific division access
              access.id === division.id ||
              // Main organization access defined
              (access?.id === null && division.isPrimary)
          ),
      }
    })
  }, [data, assignedDivision, divisionAccess])

  const organizationId = currentUser.organizationId
  const hasOrgDivisions = userDivisionAssignment?.length > 1
  const isAssignedToPartialDivisions = hasOrgDivisions && divisionAccess?.length !== userDivisionAssignment.length

  return {
    hasOrgDivisions,
    isAssignedToPartialDivisions,
    organizationId,
    userDivisionAssignment,
  }
}
