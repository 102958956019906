import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro"
import { useStore } from "@tanstack/react-store"
import { FC, useCallback, useMemo } from "react"
import { useQuery } from "urql"
import { Asset, AssetGroup } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useAssetListGroupConsolidation } from "../../../../hooks/useAssetListGroupConsolidation"
import { useWindowSize } from "../../../../hooks/useWindowSize"
import { assetListStore } from "../../../../stores/assetList"
import { getAssetListColumns, showAssetGroups } from "./AssetDataGrid"
import { QuantizedAssetDataGrid } from "./Quantized/QuantizedAssetDataGrid"

const AssetListRowChildrenDocument = graphql(`
  query AssetDetailsPanel($filter: AssignedAssetFilter!) {
    assignedAssets(filter: $filter) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      assignedTaskId
      assignedUserId
      assignmentsCount
      companyAssetNumber
      compositeKey
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status

      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }

      assignedUser {
        id
        archived
        firstName
        lastName
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }

      groupParentAsset {
        id
        companyAssetNumber
        name
      }

      inferredOwner {
        id
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
    }
  }
`)

const GroupsByAssetGroupIdDocument = graphql(`
  query GroupsByAssetGroupId($assetId: String!) {
    groupsByAssetGroupId: assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      compositeKey
      status

      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }

      assignedAsset {
        id
        compositeKey
        name
        imageUrl
        assignedUser {
          id
          currentProject {
            id
            name
          }
        }
      }

      assignedUser {
        id
        firstName
        lastName
        currentProject {
          id
          name
        }
      }

      groupParent {
        id
        assetGroupId
        imageUrl
        name
        ownershipType
        isAssetGroup
      }

      count
      compositeKey
    }
  }
`)

export const AssetDetailsPanel: FC<{ assetId: string; indentLevel: number; showTaskColumn?: boolean }> = ({
  assetId,
  indentLevel = 0,
  showTaskColumn = false,
}) => {
  const { isArchived } = useStore(assetListStore, (s) => s.filters)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])

  const [{ data: childAssetsData }] = useQuery({
    query: AssetListRowChildrenDocument,
    variables: {
      filter: {
        assignableId: assetId,
        isArchived: false,
        searchText: undefined,
      },
    },
    pause: isArchived,
  })

  const [{ data: assetGroupIdData }] = useQuery({
    query: GroupsByAssetGroupIdDocument,
    variables: { assetId },
    pause: isArchived,
  })

  const apiRef = useGridApiRef()
  const columns = getAssetListColumns(apiRef, indentLevel, isMobile)

  const assets = childAssetsData?.assignedAssets ? childAssetsData.assignedAssets : []
  const assetGroups: AssetGroup[] = []

  if (assetGroupIdData?.groupsByAssetGroupId) {
    assetGroups.push(...(assetGroupIdData.groupsByAssetGroupId as AssetGroup[]))
  }

  const childAssets = useAssetListGroupConsolidation({ assets, assetGroups }).map((asset) => ({
    ...asset,
    assignedAsset: asset,
  }))

  const getDetailPanelContent = useCallback(
    ({ row: asset }: GridRowParams) => {
      const isGroup = showAssetGroups(asset)
      const groupIdOrAssetId = isGroup ? (asset as AssetGroup).assetGroupId : (asset as Asset).id

      if (isGroup) {
        return <QuantizedAssetDataGrid assetId={groupIdOrAssetId} />
      }

      return <AssetDetailsPanel assetId={groupIdOrAssetId} indentLevel={indentLevel + 1} />
    },
    [indentLevel]
  )
  const getDetailPanelHeight = useCallback(() => "auto", [])

  return (
    <DataGridPro
      apiRef={apiRef}
      columns={columns}
      rows={childAssets}
      getRowId={(row) => row.compositeKey}
      rowHeight={67}
      initialState={{
        columns: {
          columnVisibilityModel: {
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            project: showTaskColumn ? false : true,
            task: showTaskColumn ? true : false,
          },
        },
      }}
      disableRowSelectionOnClick
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      sx={{
        border: "none",
        "& .MuiDataGrid-withBorderColor": { border: "none" },
        "& .MuiDataGrid-columnHeaders": { display: "none" },
        "& .MuiDataGrid-footerContainer": { display: "none" },
        "& .MuiDataGrid-cell:focus": { outline: "none" },
        "& .MuiDataGrid-cell:focus-within": { outline: "none" },
        "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
        "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
      }}
    />
  )
}
