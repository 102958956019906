import { Typography } from "@mui/material"
import { FC } from "react"

export const RepairNotes: FC<{ notes: string | null | undefined }> = ({ notes }) => {
  if (!notes) {
    return null
  }

  return (
    <div>
      <div className="mb-6 p-0">
        <Typography fontSize={14} fontWeight={700} textTransform="uppercase" className="pb-2 text-gray-800">
          Repair Notes
        </Typography>
        <Typography fontSize={16} className="text-gray-600">
          {notes}
        </Typography>
      </div>
    </div>
  )
}
