import Link from "next/link"
import { FC, ReactNode } from "react"

type Props = {
  children: ReactNode
  className?: string
  href?: string
}

export const ConditionalLink: FC<Props> = ({ children, className, href }) => {
  if (href) {
    return (
      <Link className={className} href={href}>
        {children}
      </Link>
    )
  }

  return <div className={className}>{children}</div>
}
