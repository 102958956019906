import { FileObject, UnitReportModalUnitGoalProgressExpectation, UnitReportModalValueType } from "./UnitReportModal"

export const unitReportModalCanBeSubmitted = (
  values: Omit<UnitReportModalValueType, "files" | "unitGoalProgress"> & {
    // These type expectations are firm in TS land but not in the logic we need to provide to our validation logic. As a result, here's a relaxing of field requirements to make it a more valid test
    files: ({ uploaded: boolean } | FileObject)[]
    // These type expectations are firm in TS land but not in the logic we need to provide to our validation logic. As a result, here's a relaxing of field requirements to make it a more valid test
    unitGoalProgress: (
      | UnitReportModalUnitGoalProgressExpectation
      | { unitGoalId?: string | null; progress?: number | null }
      | undefined
    )[]
  }
) => {
  const units = values.unitGoalProgress.filter(Boolean)

  const filesProvided = values.files.length > 0
  const allFilesAreUploaded = filesProvided && values.files.every((file) => file.uploaded)
  const unitsProvided = units.length > 0
  const allActualUnitsAreValid = units.every((ug) => ug && ug.progress && ug.unitGoalId)

  if (!values.taskId) {
    return false
  }

  // Any of the 3 are not empty
  if (!(filesProvided || unitsProvided || values.note)) {
    return false
  }

  // If files are provided, then they all need to be uploaded
  if (filesProvided && !allFilesAreUploaded) {
    return false
  }

  // If unit goal progresses are provided, then they all need both progress and the unitGoal reported
  if (unitsProvided && !allActualUnitsAreValid) {
    return false
  }

  /*
    Ok, we must have:
    - The taskId
    - One of the 3 items
    - All files are uploaded if files are provided
    - All units have progress if units are provided
  */
  return true
}
