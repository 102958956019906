import { FC, useContext } from "react"
import { ArchiveAssetWorkflow, Props } from "../Assets/ArchiveAssetWorkflow"
import { SingleDrawerContext } from "./components/Elements/Drawer"
import { DrawerBody } from "./components/Elements/DrawerBody"
import { DrawerHeader } from "./components/Elements/DrawerHeader"

export const ArchiveAssetDrawer: FC<Props> = ({ onError, onSuccess, asset }) => {
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <>
      <DrawerHeader href={`/assets/${asset?.id}/archive`} />
      <DrawerBody>
        <ArchiveAssetWorkflow
          onError={() => {
            onError?.()
          }}
          onSuccess={() => {
            onSuccess?.()
            handleClose()
          }}
          onCancel={() => {
            handleClose()
          }}
          asset={asset}
          isDrawer
        />
      </DrawerBody>
    </>
  )
}
