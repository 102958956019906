import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import { Formik, FormikValues } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  Asset,
  AssetAssignableType,
  AssetGroup,
  GetAllAssetsQuery,
  useAddQuantitiesToGroupMutation,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { pluralize } from "../../../../../helpers/strings/pluralize"
import { ModalProps } from "../../../../Modals/hooks/useModalProps"
import { PickPlus } from "../../../../../types/helpers"
import { UserSelect } from "../../../../Formik/UserSelect"
import { ModalBody } from "../../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../../Modals/components/Elements/ModalFooter"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"
import DeprecatedModal from "../../../../deprecated/StandardModal"

const GetAllAssetsDocument = graphql(`
  query GetAllAssets {
    assets(deleted: false) {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
    }
  }
`)

const GetAllTasksByProjectDocument = graphql(`
  query GetAllTasks {
    projectsByStatus(status: active) {
      id
      name
      tasks {
        id
        name
      }
    }
  }
`)

const getAssignableId = (values: FormikValues, assetId: string) => {
  switch (values.assignableType) {
    case AssetAssignableType.Asset:
      return values.asset.id
    case AssetAssignableType.Task:
      return values.task.id
    case AssetAssignableType.User:
      return values.userId
    default:
      return assetId
  }
}

type QuantizedAsset = PickPlus<
  Asset,
  | "id"
  | "assetChildCount"
  | "assetGroupId"
  | "assignableId"
  | "companyAssetNumber"
  | "groupQuantity"
  | "imageUrl"
  | "isAssetGroup"
  | "name"
  | "ownershipType"
>

export const AddQuantizedAssetsModal: FC<{
  formModalProps: ModalProps
  asset: QuantizedAsset
  selectedGroup?: AssetGroup | undefined
}> = ({ formModalProps, asset, selectedGroup }) => {
  const [_, addQuantity] = useAddQuantitiesToGroupMutation()

  const [{ data: assetsQuery }] = useQuery({
    query: GetAllAssetsDocument,
  })

  const [{ data: tasksQuery }] = useQuery({
    query: GetAllTasksByProjectDocument,
  })

  const handleSubmit = async (values: FormikValues, resetForm: () => void) => {
    const assignableType: AssetAssignableType =
      values.assignableType === "inventory" ? AssetAssignableType.Asset : values.assignableType

    addQuantity({
      assetGroupId: asset.id,
      assignableId: getAssignableId(values, asset.id),
      assignableType: assignableType,
      quantityToAdd: Number(values.quantity),
    }).then(({ error }) => {
      if (error) {
        return errorSnack("There was an error adding assets, please try again.")
      }
      successSnack(`${values.quantity} ${pluralize("asset", values.quantity)} added`)

      resetForm()
      formModalProps.handleCloseModal()
    })
  }

  const taskOptions: { projectName: string; id: string; name: string }[] = []

  tasksQuery?.projectsByStatus?.forEach((project) => {
    project.tasks.forEach((task) => taskOptions.push({ projectName: project.name, id: task.id, name: task.name }))
  })

  const initialValues = getInitialValues(asset, selectedGroup, assetsQuery, taskOptions)

  return (
    <DeprecatedModal {...formModalProps}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          quantity: Yup.number(),
          assignableType: Yup.string(),
          asset: Yup.object().shape({
            id: Yup.string(),
            name: Yup.string(),
          }),
          task: Yup.object().shape({
            projectName: Yup.string(),
            id: Yup.string(),
            name: Yup.string(),
          }),
          userId: Yup.string(),
        })}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
          <>
            <ModalBody>
              <div className="flex flex-col">
                <Typography variant="body1">How many would you like to add?</Typography>
                <TextField
                  name="quantity"
                  className="py-4"
                  value={values.quantity}
                  onChange={(e) => setFieldValue("quantity", e.target.value)}
                  type="number"
                />
                <FormControl>
                  <RadioGroup
                    name="assignableType"
                    value={values.assignableType}
                    onChange={(_e, value) => {
                      setFieldValue("assignableType", value)
                    }}
                    row
                  >
                    <FormControlLabel value="inventory" control={<Radio />} label="Inventory" />
                    <FormControlLabel value={AssetAssignableType.User} control={<Radio />} label="User" />
                    <FormControlLabel value={AssetAssignableType.Asset} control={<Radio />} label="Asset" />
                    <FormControlLabel value={AssetAssignableType.Task} control={<Radio />} label="Task" />
                  </RadioGroup>

                  {values.assignableType === AssetAssignableType.User && (
                    <UserSelect
                      name="userId"
                      label=""
                      preselected={selectedGroup?.assignedUser ? [selectedGroup.assignedUser] : []}
                    />
                  )}
                  {values.assignableType === AssetAssignableType.Asset && assetsQuery?.assets && (
                    <Autocomplete
                      value={values.asset}
                      options={assetsQuery?.assets.map((a) => ({ id: a.id, name: a.name }))}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id}>
                          {option.name}
                        </MenuItem>
                      )}
                      onChange={(_e, v) => setFieldValue("asset", v)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" onChange={(e) => setFieldValue("asset", e.target.value)} />
                      )}
                      isOptionEqualToValue={(option, value) => value?.id === option?.id}
                    />
                  )}
                  {values.assignableType === AssetAssignableType.Task && (
                    <Autocomplete
                      value={values.task}
                      options={taskOptions}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id}>
                          {option.name}
                        </MenuItem>
                      )}
                      onChange={(_e, v) => setFieldValue("task", v)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" onChange={(e) => setFieldValue("task", e.target.value)} />
                      )}
                      isOptionEqualToValue={(option, value) => value?.id === option?.id}
                      groupBy={(option) => option.projectName}
                    />
                  )}
                </FormControl>
                {errors && (
                  <Typography>
                    {errors.asset || errors.assignableType || errors.quantity || errors.task || errors.userId}
                  </Typography>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="text" onClick={formModalProps.handleCloseModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={submitForm} disabled={isSubmitting}>
                Add
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </DeprecatedModal>
  )
}

const getInitialValues = (
  asset: QuantizedAsset,
  selectedGroup: AssetGroup | undefined,
  assetsQuery: GetAllAssetsQuery | undefined,
  taskOptions: {
    projectName: string
    id: string
    name: string
  }[]
) => {
  if (!selectedGroup) {
    return {
      quantity: 1,
      assignableType: "inventory",
      asset: undefined,
      task: undefined,
      userId: undefined,
    }
  }

  const assignedId = selectedGroup.assignableId
  const selectedType = selectedGroup.assignableType
  let type: AssetAssignableType | "inventory" = selectedType
  let selectedAsset, selectedTask, selectedUserId

  if (selectedType === AssetAssignableType.Asset && assignedId === asset.id) {
    type = "inventory"
  }

  if (type === AssetAssignableType.Asset) {
    const addToAsset = assetsQuery?.assets.find((a) => a.id === assignedId)
    selectedAsset = { id: addToAsset?.id || "", name: addToAsset?.name || "" }
  }

  if (type === AssetAssignableType.Task) {
    selectedTask = taskOptions.find((t) => t.id === assignedId)
  }

  if (type === AssetAssignableType.User) {
    selectedUserId = assignedId
  }

  return {
    quantity: 1,
    assignableType: type,
    asset: selectedAsset,
    task: selectedTask,
    userId: selectedUserId,
  }
}
