import { clsx } from "clsx"
import { FC } from "react"
import { CheckedPermission } from "../../../types/Permission"
import { RenderIf } from "../../RenderIf"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { SkeletonIcon } from "../../Skeletons/SkeletonIcon"
import { SkeletonTextSmall } from "../../Skeletons/SkeletonTextSmall"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { Row } from "../../Table/Row"
import { Table } from "../../Table/Table"

export const SkeletonRow: FC = () => {
  return (
    <Row
      className={clsx(
        "grid auto-rows-min grid-cols-12 grid-flow-col gap-y-6",
        "md:grid-flow-row md:grid-cols-12 md:grid-rows-1 md:items-center md:gap-x-6"
      )}
    >
      <div className="col-span-10 md:col-span-5 flex gap-x-4">
        <SkeletonElement className="size-10" />
        <div className="flex flex-col justify-center gap-y-2">
          <SkeletonTextSmall />
          <SkeletonTextSmall />
        </div>
      </div>
      <div className="col-span-10 md:col-span-3">
        <SkeletonElement className="h-8 w-40" />
      </div>
      <div className="col-span-10 md:col-span-3 flex justify-between">
        <SkeletonTextSmall />
      </div>
      <div className="row-span-3 col-span-2 justify-self-end md:row-auto md:col-span-1">
        <SkeletonIcon />
      </div>
    </Row>
  )
}

type DocumentListSkeletonProps = {
  permissions: CheckedPermission | CheckedPermission[]
}

export const DocumentListSkeleton: FC<DocumentListSkeletonProps> = ({ permissions }) => {
  return (
    <SkeletonContainer>
      <div className="flex items-center justify-between text-center my-6">
        <div className="flex gap-6 my-8">
          <SkeletonElement className="h-4 w-16" />
          <SkeletonElement className="h-4 w-16" />
          <SkeletonElement className="h-4 w-16" />
        </div>
        <RenderIf permissionsInclude={permissions}>
          <div className="flex gap-2">
            <SkeletonElement className="size-10" />
            <SkeletonElement className="size-10" />
          </div>
        </RenderIf>
      </div>
      <ColumnTitles>
        <SkeletonElement className="h-4 w-16 col-span-5" />
        <SkeletonElement className="h-4 w-16 col-span-3" />
        <SkeletonElement className="h-4 w-16 col-span-2" />
      </ColumnTitles>
      <Table>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Table>
      <div className="flex justify-center mt-6">
        <SkeletonTextSmall />
      </div>
    </SkeletonContainer>
  )
}
