import { ModalProps } from "@mui/material"
import { clsx } from "clsx"
import Link from "next/link"
import { FC, ReactNode, useState } from "react"
import { FaCheckCircle, FaCircle } from "react-icons/fa"
import { MdAddChart } from "react-icons/md"
import { TbPlugConnected } from "react-icons/tb"
import { Task } from "../../../../graphql/generated/gql/graphql"
import { ProjectExpectation } from "../../../../types/ProjectExpectation"
import { HandleDeleteFunc, HandleReportFunc } from "../../../../types/Task"
import { PickPlus } from "../../../../types/helpers"
import { UnitReportModal } from "../../../Modals/components/UnitReportModal"
import { RenderIf } from "../../../RenderIf"
import { Row } from "../../../Table/Row"
import { UserCount } from "../../../UserCount"
import { DrawerLink } from "../../Drawer/components/Elements/DrawerLink"
import { TaskDrawer } from "../../Drawer/components/Task/TaskDrawer"
import { TaskRowActions } from "../Tasks/TaskRowActions"
import ToggleCompletionButtonWrapper from "../ToggleCompletionButtonWrapper"
import { TaskProgressBar } from "./TaskProgressBar"

type TaskExpectation = PickPlus<
  Task,
  | "id"
  | "assetCount"
  | "hasReportableUnit"
  | "isComplete"
  | "isDefault"
  | "name"
  | "groupId"
  | "sortOrder"
  | "projectId"
  | "userCount"
  | "completedHours"
>

type Props = {
  children?: ReactNode
  task: TaskExpectation
  createTaskModalProps?: ModalProps
  isProgressBarVisible?: boolean
  project: ProjectExpectation
  handleReport: HandleReportFunc
  handleDelete: HandleDeleteFunc
  onUpdateSuccess?: () => void

  setSelectedParentTask?: (task: TaskExpectation) => void
  openConvertToSubTaskModal?: () => void
  openReassignModal: () => void
}

export const TaskRow: FC<Props> = ({ children, isProgressBarVisible, project, handleReport, handleDelete, task }) => {
  const isDefaultTask = task.isDefault

  const CircleFallBack = (
    <>
      {task?.isComplete ? (
        <FaCheckCircle className="size-6 text-green-200" />
      ) : (
        <FaCircle className="size-6 text-gray-100" />
      )}
    </>
  )

  const hasUsers = task.userCount > 0
  const [reportModalOpen, setReportModalOpen] = useState(false)

  return (
    <Row includeSeparator={true} isCardOnMobile={true} className="group grid gap-4 relative md:hover:bg-white">
      <div className="pl-px grid gap-5 grid-cols-6 xl:grid-cols-5 items-start md:gap-16 justify-between p-2 md:p-0">
        <div
          className={clsx(
            "col-span-5 pl-px grid gap-5 items-center h-full",
            "md:grid-cols-3",
            "xl:col-span-4 xl:grid-cols-4 xl:gap-16"
          )}
        >
          <div className="col-span-2 flex">
            {isDefaultTask && <TbPlugConnected className="size-6 pt-0.5 text-gray-400" />}
            {!isDefaultTask && (
              <>
                <div className="pt-0.5">
                  <RenderIf
                    context={{ projectId: task?.projectId }}
                    permissionsInclude="task:complete"
                    fallbackComponent={CircleFallBack}
                  >
                    <ToggleCompletionButtonWrapper className="size-6" task={task} project={project} />
                  </RenderIf>
                </div>
              </>
            )}
            <div className="grow ml-4 md:inline-flex items-center">
              <DrawerLink
                href={`/projects/${task.projectId}/${task.id}/details`}
                component={<TaskDrawer taskId={task.id} />}
                className={clsx(
                  "line-clamp-2 font-bold text-xl",
                  !task?.isComplete && "text-blue-600 decoration-blue-600 underline",
                  !!task?.isComplete && "text-gray-400 decoration-gray-400 line-through"
                )}
                drawerName="Project"
              >
                {task.name}
              </DrawerLink>
            </div>
          </div>
          {(!isProgressBarVisible || isDefaultTask) && hasUsers && (
            <div className="col-span-2">
              <div className="grid grid-flow-row gap-y-2">
                <Link href={`/projects/${task.projectId}/${task.id}/team`} className="col-span-2">
                  <UserCount count={task.userCount} isWarning={!!isDefaultTask} />
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-2 justify-end">
          <TaskRowActions
            isTaskComplete={Boolean(task?.isComplete)}
            isDefault={task.isDefault}
            isTaskGroup={false}
            handleDelete={handleDelete}
            handleReport={handleReport}
            projectId={task.projectId}
            taskId={task.id ?? ""}
            groupId={task.groupId || undefined}
            sortOrder={task.sortOrder || 0}
            taskName={task.name}
            assetCount={task.assetCount}
            userCount={task.userCount}
          />
        </div>
      </div>
      {isProgressBarVisible && !isDefaultTask && (
        <div className="pl-px md:pb-4 grid gap-5 md:grid-cols-4 xl:grid-cols-5 items-start md:gap-16">
          <div className="col-span-2">
            <TaskProgressBar task={task} />
          </div>

          <div className="col-span-2">
            <div className="grid grid-flow-row gap-y-1.5">
              <Link href={`/projects/${task.projectId}/${task.id}/team`} className="col-span-2">
                <UserCount count={task.userCount} />
              </Link>
            </div>
          </div>
          {task.hasReportableUnit && (
            <RenderIf permissionsInclude="task:update">
              <button
                className={clsx(
                  "bg-gray-700 text-white rounded-full size-10 items-center justify-center transition-colors flex ml-9 mb-1",
                  "hover:bg-gray-800",
                  "md:hidden"
                )}
                onClick={() => setReportModalOpen(true)}
                type="button"
              >
                <MdAddChart className="size-6" />
              </button>
            </RenderIf>
          )}
        </div>
      )}
      {children}

      <UnitReportModal isOpen={reportModalOpen} closeModal={() => setReportModalOpen(false)} taskId={task.id} />
    </Row>
  )
}
