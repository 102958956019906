import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"

export const ProjectAssignmentTableSkeleton: FC = () => {
  return (
    <>
      <SkeletonContainer>
        <div className="flex gap-x-6 mb-4 overflow-x-auto pb-4 md:grid md:grid-cols-12 md:pb-0">
          <div className="col-span-4">
            <SkeletonElement className="h-6 w-16" />
          </div>
          <div className="col-span-6">
            <SkeletonElement className="h-6 w-16" />
          </div>
        </div>
        <div className="border-t">
          {[...Array(20)].map((_, index) => (
            <div
              key={index}
              className="md:rounded-none md:border-0 md:px-0 p-4 md:border-b grid grid-cols-12 md:gap-6 md:grid-flow-row md:grid-rows-1 md:items-center md:py-3"
            >
              <div className="col-span-4">
                <SkeletonElement className="h-6 w-16" />
              </div>
              <div className="col-span-6">
                <SkeletonElement className="h-6 w-16" />
              </div>
              <div className="col-span-2 flex justify-end">
                <SkeletonElement className="h-8 w-24" />
              </div>
            </div>
          ))}
        </div>
      </SkeletonContainer>
    </>
  )
}
