import React, { FC, ReactElement, useMemo } from "react"
import { Skeleton, Typography } from "@mui/material"
import { format } from "date-fns"
import { SectionHeader } from "../../../../PageSectionHeader"
import { BiExit, BiStopwatch, BiTimeFive } from "react-icons/bi"
import { IconType } from "react-icons"
import { FaSignature } from "react-icons/fa"

import { TotalTimeWithEdits } from "../TotalTimeWithEdits"
import { Day } from "./TimeEntryDayDrawer"

export const Summary: FC<{ day: Day | undefined; loading: boolean }> = ({ day, loading }) => {
  const { clockInContent, clockOutContent, startTimeLabel, endTimeLabel } = useTimeEntryDrawerContentLabels(day)

  if (loading) {
    const loadingContent = <Skeleton width={100} height={40} animation="wave" />
    return (
      <>
        <SectionHeader title="Summary" />
        <SummarySection Icon={BiTimeFive} label="Today's Total">
          {loadingContent}
        </SummarySection>
        <SummarySection Icon={BiStopwatch} label="Clock In">
          {loadingContent}
        </SummarySection>
        <SummarySection Icon={BiExit} label="Clock Out">
          {loadingContent}
        </SummarySection>
        <SummarySection Icon={FaSignature} label="Signature">
          {loadingContent}
        </SummarySection>
      </>
    )
  }

  if (!day?.timeEntryWrappers) return <div className="h-12" />

  const hasAtLeastOneSignature = day?.timeEntryWrappers?.some(
    ({ timeEntry }) => timeEntry.evidenceType.end?.signatureUrl
  )
  const signatureContent = hasAtLeastOneSignature ? "Yes" : "No"

  return (
    <div className="mb-8">
      <SectionHeader title="Summary" />
      <SummarySection Icon={BiTimeFive} label="Today's Total">
        <TotalTimeWithEdits
          totalTimeInSeconds={day.totalPaidTimeInSeconds ?? 0}
          totalEditedTimeInSeconds={day.totalEditedTimeInSeconds ?? 0}
          showTimeDiff
        />
      </SummarySection>
      <SummarySection Icon={BiStopwatch} label={startTimeLabel} content={clockInContent} />
      <SummarySection Icon={BiExit} label={endTimeLabel} content={clockOutContent} />
      <SummarySection Icon={FaSignature} label="Signature" content={signatureContent} />
    </div>
  )
}

function useTimeEntryDrawerContentLabels(day: Day | undefined) {
  const dateFormat = "h:mm a (EEE, MMM do, yyyy)"

  return useMemo(() => {
    let clockInContent = null
    let clockOutContent = null
    let startTimeLabel = "Clock In"
    let endTimeLabel = "Clock Out"

    const timeEntryWrappers = day?.timeEntryWrappers || []

    const sorted = timeEntryWrappers.sort((a, b) => a.timeEntry.startAt.valueOf() - b.timeEntry.startAt.valueOf())

    if (sorted.length > 0) {
      const firstTimeEntryWrapper = sorted[0]
      const lastTimeEntryWrapper = sorted[sorted.length - 1]
      const firstClockInTimestamp = firstTimeEntryWrapper.splitStartAt || firstTimeEntryWrapper.timeEntry.startAt
      const lastClockOutTimestamp = lastTimeEntryWrapper.splitEndAt || lastTimeEntryWrapper.timeEntry.endAt

      clockInContent = firstClockInTimestamp ? format(firstClockInTimestamp, dateFormat) : null
      clockOutContent = lastClockOutTimestamp ? format(lastClockOutTimestamp, dateFormat) : null
      startTimeLabel = firstTimeEntryWrapper.splitStartAt ? "Day Start" : "Clock In"
      endTimeLabel = lastTimeEntryWrapper.splitEndAt ? "Day End" : "Clock Out"
    }

    return {
      clockInContent,
      clockOutContent,
      startTimeLabel,
      endTimeLabel,
    }
  }, [day])
}

const SummarySection: FC<{
  Icon: IconType
  label: string
  content?: string | null | undefined
  children?: ReactElement
}> = ({ Icon, label, content, children }) => {
  return (
    <div className="flex gap-3 items-center my-3">
      <div className="h-10 min-w-[40px] flex bg-gray-100 rounded-lg">
        <Icon className="h-5 w-[20px] m-auto" />
      </div>
      <Typography className="font-medium">{label}</Typography>
      {content && <Typography className="text-gray-500">{content}</Typography>}
      {children}
      {!content && !children && <Typography className="text-gray-400">Empty</Typography>}
    </div>
  )
}
