import { Dispatch, SetStateAction, useState } from "react"
import { endOfPeriod, startOfPeriod } from "../helpers/dateAndTime/dateRange"
import { DateRangeFilterString } from "../components/Partials/Summary/types"

export type NormalizedDateRange = { rangeStart: Date; rangeEnd: Date }

type Props = {
  defaultDate?: Date
  defaultRangeType?: DateRangeFilterString
}
export function useDateRange({
  defaultDate = new Date(),
  defaultRangeType = "daily",
}: Props): [NormalizedDateRange, Dispatch<SetStateAction<NormalizedDateRange>>] {
  const [dateRange, setDateRange] = useState<NormalizedDateRange>({
    rangeStart: startOfPeriod(defaultDate, defaultRangeType),
    rangeEnd: endOfPeriod(defaultDate, defaultRangeType),
  })

  return [dateRange, setDateRange]
}
