import { Autocomplete, Avatar, Checkbox, Chip, MenuItem, TextField, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, ReactElement, SyntheticEvent, useMemo, useState } from "react"
import { BiCar, BiLink, BiListUl } from "react-icons/bi"
import { MdOutlineAccountCircle } from "react-icons/md"
import { SelectableAsset } from "../../../../types/Asset"
import { AssetImage } from "../../../AssetImage"

interface ExtendedSelectableAsset extends SelectableAsset {
  assignableType?: string
}

type Props = {
  assets: ExtendedSelectableAsset[]
  name: string
  onChange?: (selectedValues: string[]) => void
}

export const AssetsMultiSelect: FC<Props> = ({ assets = [], name, onChange }) => {
  const [searchText, setSearchText] = useState("")
  const [{ value }, , { setValue }] = useField(name)

  const assetMap: Record<string, ExtendedSelectableAsset> = useMemo(() => {
    return assets.reduce(
      (acc: Record<string, ExtendedSelectableAsset>, asset) => {
        acc[asset.id] = asset
        return acc
      },
      {} as Record<string, ExtendedSelectableAsset>
    )
  }, [assets])

  const handleChange = (_e: SyntheticEvent<Element, Event>, selectedValues: string | string[]) => {
    if (!selectedValues || selectedValues.length === 0) {
      setValue([])
      return
    }

    // we always want to work with an array
    const updatedValue = Array.isArray(selectedValues) ? selectedValues : [selectedValues]

    onChange && onChange(updatedValue)
    setValue(updatedValue)
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      multiple
      value={value}
      disableCloseOnSelect
      onChange={handleChange}
      noOptionsText="No assets available to assign"
      filterOptions={() => {
        return assets
          .filter(
            (asset) =>
              asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
              asset.companyAssetNumber?.toLowerCase().includes(searchText?.toLowerCase())
          )
          .map((a) => a.id)
      }}
      autoHighlight={false}
      options={assets.map((a) => a.id)}
      results={value}
      groupBy={() => ""}
      renderGroup={(params) => params.children}
      renderOption={(props, option, { selected }) => {
        const asset = assetMap[option]
        if (!option || !asset) {
          return null
        }

        return (
          <MenuItem {...props} key={`asset-${asset.id}-opt`} className="flex">
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <div className="w-full flex">
              <AssetImage width="w-10 md:w-10" height="h-10 md:h-10" asset={asset} />
              <div className="pl-4">
                <Typography fontSize={14}>{asset?.name}</Typography>
                <Typography fontSize={14} className="text-gray-400">
                  {asset?.companyAssetNumber}
                </Typography>
              </div>
            </div>
            {asset.assignableType && <AssetAssignableTypeChip assignableType={asset.assignableType} />}
          </MenuItem>
        )
      }}
      renderTags={(val) => {
        return (
          <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
            {val
              .map((assetId) => assetMap[assetId])
              .map((asset) => {
                if (!asset) return null
                return (
                  <Chip
                    sx={{ margin: "3px" }}
                    key={asset.id}
                    label={asset.name}
                    variant="outlined"
                    onDelete={() => {
                      setValue(value.filter((assetId: string) => assetId !== asset.id))
                    }}
                    avatar={asset.imageUrl ? <Avatar src={asset.imageUrl!} /> : <BiCar />}
                  />
                )
              })}
          </div>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Select asset"
          onChange={(e) => setSearchText(e.target.value)}
          inputProps={{
            ...(params.inputProps || {}),
            style: { boxShadow: "none" },
          }}
          FormHelperTextProps={{ className: "my-0.5" }}
        />
      )}
    />
  )
}

const AssetAssignableTypeChip: FC<{ assignableType: string }> = ({ assignableType }) => {
  const iconMapping: Record<string, ReactElement> = {
    User: <MdOutlineAccountCircle />,
    Asset: <BiCar />,
    Task: <BiListUl />,
  }

  // Default to <BiLink /> if no matching type is found
  const icon = iconMapping[assignableType] || <BiLink />

  return (
    <Chip
      className="pl-2"
      sx={{
        "& .MuiChip-label": {
          fontSize: "0.75rem",
        },
      }}
      label={`On ${assignableType.toLowerCase()}`}
      icon={icon}
    />
  )
}
