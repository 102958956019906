import { FC, useContext } from "react"
import { BiEdit, BiTrash } from "react-icons/bi"
import { FaCheckCircle, FaCircle, FaRegCircle } from "react-icons/fa"
import { TaskGroup } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { H2 } from "../../deprecated"
import { DeprecatedTabGroup, Tab } from "../../deprecated/TabGroup"
import { DrawerBody } from "../Drawer/components/Elements/DrawerBody"
import { DrawerHeader } from "../Drawer/components/Elements/DrawerHeader"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { CompletedTaskBanner } from "./Tasks/CompletedTaskBanner"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"

type Props = {
  taskGroup: PickPlus<TaskGroup, "id" | "name" | "completedTaskCount" | "taskCount">
  projectId: string
  withTabs: boolean
}

const tabs: Tab[] = [
  {
    label: "Assigned",
    href: "/projects/[_project_id]/[_task_id]/team",
  },
  {
    label: "Sub-tasks",
    href: "/projects/[_project_id]/[_task_id]/sub-tasks",
  },
  {
    label: "Summary",
    href: "/projects/[_project_id]/[_task_id]/summary",
  },
  {
    label: "Details",
    href: "/projects/[_project_id]/[_task_id]/details",
  },
]

export const TaskGroupDetailsLayout: FC<Props> = ({ taskGroup, projectId, withTabs = true }) => {
  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const deleteTaskModalProps = useModalProps("Delete Task")

  const isComplete = taskGroup?.completedTaskCount ? taskGroup?.completedTaskCount === taskGroup?.taskCount : false

  const menuItems: MenuItem[][] = [
    [
      {
        value: "Edit",
        onClick: () =>
          pushDrawer(
            <RenderIf permissionsInclude="task:create">
              <DrawerHeader href={`projects/${projectId}/tasks/create`} />
              <DrawerBody>
                <CreateOrEditTaskForm
                  task={{ id: taskGroup.id, projectId }}
                  taskGroupId={taskGroup.id}
                  taskType="summary-task"
                />
              </DrawerBody>
            </RenderIf>,
            "TaskEdit"
          ),
        Icon: BiEdit,
      },
    ],
    [
      {
        value: "Delete",
        onClick: () => deleteTaskModalProps.handleOpenModal(),
        Icon: BiTrash,
        color: "red",
      },
    ],
  ]

  const CircleFallBack = (
    <>
      {isComplete ? <FaCheckCircle className="size-8 text-green-200" /> : <FaCircle className="size-8 text-gray-100" />}
    </>
  )

  return (
    <>
      {isComplete && <CompletedTaskBanner taskGroup={taskGroup} />}
      <div className="flex col-span-2 content-start">
        <>
          <div className="mt-2 md:mt-3">
            {taskGroup && (
              <>
                <RenderIf permissionsInclude="task:complete" context={{ projectId }} fallbackComponent={CircleFallBack}>
                  {isComplete ? (
                    <button disabled type="button">
                      <FaCheckCircle className="size-8 text-green-600" />
                    </button>
                  ) : (
                    <button disabled type="button">
                      <FaRegCircle className="size-8 text-gray-400" />
                    </button>
                  )}
                </RenderIf>
              </>
            )}
          </div>
          <div className="grow ml-4 flex gap-x-2">
            <H2 className="text-gray-800">{taskGroup?.name}</H2>
            <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
              <QuickMenuDotsHorizontal className="text-2xl text-gray-800" />
            </QuickMenuMui>
            <DeleteTaskModal
              modalProps={deleteTaskModalProps}
              closeModal={deleteTaskModalProps.handleCloseModal}
              task={{ ...taskGroup, projectId }}
              isTaskGroup
              onSuccess={closeDrawer}
            />
          </div>
        </>
      </div>
      {withTabs && projectId && (
        <DeprecatedTabGroup
          tabs={tabs.filter((tab) => {
            const showTeamTab = isComplete ? tab.href !== "/projects/[_project_id]/[_task_id]/details" : true
            return showTeamTab
          })}
        />
      )}
    </>
  )
}
