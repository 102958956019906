import { FC } from "react"
import { BiCalendarStar, BiChart, BiStopwatch, BiTimeFive } from "react-icons/bi"
import { useQuery } from "urql"
import { Project } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useGetNewTaskGroupProgress } from "../../../../hooks/queries/useGetNewTaskGroupProgress"
import { PickPlus } from "../../../../types/helpers"
import { DateProgressBar } from "../../../ProgressBars/DateProgressBar"
import { DateProgressGroup } from "../../../ProgressBars/DateProgressGroup"
import { ProgressBar } from "../../../ProgressBars/ProgressBar"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { IconStatusPill, calculateDaysLeft } from "./TaskPillCluster"

// Query for fetching task group data
const TaskGroupProgress = graphql(`
  query TaskGroupHours($groupId: String!) {
    taskGroup(id: $groupId) {
      id
      startDate
      endDate
      unitProgress
      unitTargetGoal
      completedHours
      estimatedHours
      taskCount
      completedTaskCount
    }
  }
`)

type TaskProgressBarProps = {
  taskGroupId: string
  project: PickPlus<Project, "id" | "name" | "isArchived" | "unassignedTaskId">
  totalManHours?: number | null | undefined
  totalCompletedHours?: number
  hidePills?: boolean
}

/** Renders a task group's progress bar cluster. */
export const TaskGroupProgressBar: FC<TaskProgressBarProps> = ({
  taskGroupId,
  project,
  totalManHours = 0,
  totalCompletedHours = 0,
  hidePills,
}) => {
  const [{ data: progressData, fetching: progressDataIsLoading }] = useQuery({
    query: TaskGroupProgress,
    variables: { groupId: taskGroupId },
    pause: !taskGroupId,
  })

  const taskGroup = progressData?.taskGroup

  const taskGroupProgress =
    useGetNewTaskGroupProgress(project.id, taskGroupId || "", Boolean(project.isArchived)).data.completionPercentage /
    100

  if (progressDataIsLoading) {
    return (
      <SkeletonContainer>
        <div className="grid gap-1">
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
        </div>
      </SkeletonContainer>
    )
  }

  const isComplete = taskGroup
    ? taskGroup.completedTaskCount > 0 && taskGroup.completedTaskCount === taskGroup.taskCount
    : false
  const { estimatedHours, completedHours, unitProgress, startDate, endDate } = progressData?.taskGroup ?? {}

  return (
    <>
      {(startDate && endDate) || completedHours || unitProgress ? (
        <>
          <DateProgressGroup start={startDate} end={endDate} isComplete={isComplete}>
            <DateProgressBar start={startDate} end={endDate} isComplete={isComplete} />
            <ProgressBar
              Icon={BiTimeFive}
              isComplete={isComplete}
              itemLabel="hours"
              max={estimatedHours}
              textColor={completedHours ? "text-blue-800" : "text-gray-400"}
              value={completedHours}
            />
            <ProgressBar
              bgColor="bg-teal-600"
              Icon={BiChart}
              isComplete={isComplete}
              itemLabel="units"
              textColor={unitProgress ? "bg-teal-600" : "text-gray-400"}
              percentage={taskGroupProgress}
            />
          </DateProgressGroup>
          {hidePills ? null : (
            <div className="flex flex-wrap gap-2 overflow-auto w-full mt-4 mb-16">
              <IconStatusPill icon={<BiCalendarStar />} title="Schedule">
                {endDate ? calculateDaysLeft(endDate) : "None"}
              </IconStatusPill>
              <IconStatusPill icon={<BiStopwatch />} title="Hours">
                {totalManHours
                  ? `${totalCompletedHours.toFixed(2)} of ${totalManHours} hours`
                  : `${totalCompletedHours.toFixed(2)} hours`}
              </IconStatusPill>
            </div>
          )}
        </>
      ) : (
        <EmptyStateBlock label="No progress" />
      )}
    </>
  )
}
