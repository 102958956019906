import { gql } from "urql"

export const UPDATE_LAST_EXPORT = gql`
  mutation UpdateLastExport($entityId: String, $entityType: String) {
    updateLastExport(entityId: $entityId, entityType: $entityType) {
      userId
      userName
      exportedAt
    }
  }
`
