import { Button } from "@mui/material"
import { NextPage } from "next"
import { FC, useMemo } from "react"
import { BiEditAlt } from "react-icons/bi"
import { useQuery } from "urql"
import { AssetInspectionReportModal } from "../../../components/Modals/components/AssetInspectionReportModal"
import { H5 } from "../../../components/deprecated"
import { PageTitle } from "../../../components/PageTitle"
import { AssignmentQuantityReportModal } from "../../../components/Partials/Assets/AssignmentQuantityReportModal"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { AssetGroupAssignmentInventory } from "../../../types/Asset"
import AssetsPageLayout from "./_layout"
import { AssetInventoryDueIn } from "../../../components/Partials/Assets/AssetInventoryDueIn"
import { useRouter } from "next/router"

type Props = {
  assetId: string
}

const AssetReportsDocument = graphql(`
  query AssetReports($assetId: String!) {
    asset(id: $assetId) {
      id
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      companyAssetNumber
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
      assetGroupId
    }
  }
`)

export const AssetReports: FC<Props> = ({ assetId }) => {
  const inventoryCheckModal = useModalProps("Inventory Check")
  const inspectionCheckProps = useModalProps("Inspection Check")

  const [{ data, error }] = useQuery({
    query: AssetReportsDocument,
    variables: { assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const asset = useMemo(() => {
    if (!data?.asset) return {} as Asset

    return data.asset
  }, [data])

  const assignment = data?.assetGroups?.find((ag) => ag.assignableId === asset.assignableId) || {
    assetGroupId: asset.assetGroupId,
    assignableId: asset.assignableId,
    assignableType: asset.assignableType,
    count: asset.groupQuantity || 1,
    status: asset.status,
  }

  return (
    <>
      <PageTitle title={`${asset?.name || "Asset"} reports`} />
      <H5>Asset Reports</H5>
      <div className="flex flex-col md:flex-row justify-between border-gray-200 border-y py-6 gap-6 md:items-center">
        <div>
          <div className="font-semibold">Inventory Report</div>
          <AssetInventoryDueIn asset={asset} />
        </div>
        <Button
          variant="contained"
          type="button"
          color="primary"
          onClick={inventoryCheckModal.handleOpenModal}
          endIcon={<BiEditAlt size={18} />}
        >
          Report
        </Button>
      </div>
      {asset?.inspectionTemplatesAssignments?.map(({ inspectionTemplate }) => (
        <div
          key={inspectionTemplate.id}
          className="flex flex-col md:flex-row justify-between border-gray-200 border-b py-6 gap-6 md:items-center"
        >
          <div>
            <div className="font-semibold">{inspectionTemplate.name}</div>
            <div className="text-gray-400 text-sm">Inspection Report</div>
          </div>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={inspectionCheckProps.handleOpenModal}
            endIcon={<BiEditAlt size={18} />}
          >
            Report
          </Button>

          <AssetInspectionReportModal
            assetId={assetId}
            assetReportTemplateId={inspectionTemplate.id}
            formModalProps={inspectionCheckProps}
          />
        </div>
      ))}

      <AssignmentQuantityReportModal
        inventoryModalProps={inventoryCheckModal}
        assignment={assignment as AssetGroupAssignmentInventory}
        asset={asset}
      />
    </>
  )
}

const AssetPage: NextPage = () => {
  const { query } = useRouter()
  const assetId = query._id as string

  return (
    <AssetsPageLayout assetId={assetId}>
      <AssetReports assetId={assetId} />
    </AssetsPageLayout>
  )
}

export default AssetPage
