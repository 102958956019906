import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import DeprecatedModal from "../../../deprecated/StandardModal"
import { TransferAssetModal } from "./TransferAssetModal"
import { TransferAssetModalSkeleton } from "./TransferAssetModal.skeleton"

const TransferAssetToAnyQuery = graphql(`
  query TransferAssetToAnyQuery {
    allAssets: assets {
      id
      name
      imageUrl
      companyAssetNumber
      assignableId
      assignableType
    }

    users {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          imageUrl
          jobTitle
          lastName
          projectId
          project {
            id
            name
          }
          task {
            id
            name
          }
        }
      }
    }
  }
`)

export const TransferAssetsToAny: FC<{
  formModalProps: ModalProps
  excludedTaskIds?: string[]
  excludedProjectId?: string
  excludedUserId?: string
  excludedAssetId?: string
  assetsSelectedToTransfer?: string[]
  selectedAssetIds?: string[]
  onSuccess?: () => void
}> = ({
  formModalProps,
  excludedTaskIds = [],
  excludedProjectId,
  excludedUserId,
  excludedAssetId,
  assetsSelectedToTransfer = [],
  selectedAssetIds,
  onSuccess,
}) => {
  const [{ data, fetching }] = useQuery({
    query: TransferAssetToAnyQuery,
  })

  const assets = data?.allAssets || []

  const users = useMemo(() => {
    if (!data?.users) return []
    return data.users?.edges
      ?.map((edge) => edge!.node!)
      .filter((user) => user.currentProjectId !== excludedProjectId || user.id !== excludedUserId)
  }, [data?.users, excludedProjectId, excludedUserId])

  const componentUIState = fetching ? "fetching" : "loaded"

  return (
    <DeprecatedModal {...formModalProps}>
      {componentUIState === "fetching" && <TransferAssetModalSkeleton handleClose={formModalProps.handleCloseModal} />}

      {componentUIState === "loaded" && (
        <TransferAssetModal
          excludedTaskIds={excludedTaskIds}
          excludedProjectId={excludedProjectId}
          excludedUserId={excludedUserId}
          excludedAssetId={excludedAssetId}
          formModalProps={formModalProps}
          assetsSelectedToTransfer={assetsSelectedToTransfer}
          selectedAssetIds={selectedAssetIds}
          onSuccess={onSuccess}
          assets={assets}
          teamMembers={users}
          includeAllProjects
        />
      )}
    </DeprecatedModal>
  )
}
