import { clsx } from "clsx"
import { useField, useFormikContext } from "formik"
import { FC, useState } from "react"
import { BiCamera, BiChevronDown, BiDotsHorizontalRounded, BiLike } from "react-icons/bi"
import { Asset, AssetStatus } from "../../../../graphql/generated/client-types-and-hooks"
import {
  Available,
  assetStatusOptions,
  getAssetStatusLabel,
  isOperational,
  stringToAssetStatus,
} from "../../../../helpers/assets/assetStatus"
import { useImageUpload } from "../../../../hooks/useImageUpload"
import { PickPlus } from "../../../../types/helpers"
import { AssetBadge } from "../../../AssetBadge"
import { StandardTextarea } from "../../../Formik/StandardTextarea"
import { IconButton } from "../../../IconButton"
import { EagerUploadImage } from "../../../ImageUploadPreviewList"
import { PHOTO_BOOTH_IMAGE_TYPE, PhotoBooth } from "../../../PhotoBooth"
import Pill from "../../../Pill"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { AssignmentQuantityFormValues } from "../AssignmentQuantityReportModal"

type Props = {
  asset: PickPlus<
    Asset,
    "id" | "inventoryRequirements" | "name" | "status" | "isAssetGroup" | "assetChildCount" | "ownershipType"
  >
  showStatusPill?: boolean
  children?: React.ReactNode
  assignedQuantity?: number | string
}

export const AssetInventoryReportItem: FC<Props> = ({ asset, showStatusPill = true, children }) => {
  const [value, meta, helpers] = useField<{
    active: boolean
    status: AssetStatus | ""
    photos?: { uploaded: boolean; fileId: string; objectKey: string }[]
    note?: string
  }>(`assets.${asset.id}`)
  const { setFiles, removeFileById, setUploadedById, fileObjects } = useImageUpload(
    `asset/${asset.id}/image`,
    (photos) => {
      helpers.setValue({
        ...value.value,
        photos: photos,
      })
    }
  )

  const { values, setValues } = useFormikContext<AssignmentQuantityFormValues>()
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)

  const options: MenuItem[] = assetStatusOptions.map((option) => ({
    ...option,
    onClick: () => toggleInventoryStatus(option.value),
  }))

  const statusQuickMenuItems: MenuItem[][] = [options]

  const toggleInventoryStatus = (status: AssetStatus | "") => {
    const formValues = values
    const totalQuantity = formValues.statuses.reduce(
      (total: number, current: { status: string[]; quantity: string[] }) => (total += +current.quantity[0] || 0),
      0
    )
    const assetStatus = stringToAssetStatus(status)

    const newValues: AssignmentQuantityFormValues = {
      assets: {
        ...formValues.assets,

        [asset.id]: {
          ...formValues.assets[asset.id],
          status: assetStatus,
          active: isOperational(assetStatus),
        },
      },
      statuses: [
        {
          status: [status],
          quantity: [totalQuantity.toString()],
        },
      ],
    }

    setValues(newValues)
  }

  // @ts-expect-error Need to expand meta error to include status
  const errorStatus = meta.error?.status || false
  // @ts-expect-error Need to expand meta error to include photos
  const errorPhotos = meta.error?.photos || false
  // @ts-expect-error Need to expand meta error to include active
  const errorActive = meta.error?.active || false

  return (
    <div key={asset.id} className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <AssetBadge asset={asset} className="grow" />
        <div className="flex gap-1">
          <div
            className={clsx(
              "flex gap-0.5 border-2 rounded",
              meta.touched && (errorStatus || errorActive) ? "border-red-600" : "border-white"
            )}
          >
            <IconButton
              Icon={BiLike}
              roundedRight={false}
              onClick={() => {
                toggleInventoryStatus(value.value.active ? "" : Available)
              }}
              color={value?.value?.active ? "green" : "gray"}
              className={clsx(meta.touched && errorStatus && "text-red-600")}
            />
            <QuickMenu items={statusQuickMenuItems} menuButtonClassName="h-10 w-11">
              <IconButton
                Icon={BiChevronDown}
                roundedLeft={false}
                color={value.value?.status === "" || value.value?.status === Available ? "gray" : "orange"}
                className={clsx(meta.touched && errorStatus && "text-red-600")}
              />
            </QuickMenu>
          </div>
          <div
            className={clsx(
              "rounded border-2",

              meta.touched && errorPhotos ? "border-red-600 text-red-600" : "border-white"
            )}
          >
            {asset.inventoryRequirements?.photoRequired ? (
              <IconButton
                Icon={BiCamera}
                onClick={() => setPhotoBoothIsOpen(true)}
                color={value.value.photos?.length ? "green" : "gray"}
              />
            ) : (
              <QuickMenu
                menuButtonClassName="h-10 w-11"
                items={[
                  [
                    {
                      value: "Take photo (optional)",
                      onClick: () => setPhotoBoothIsOpen(true),
                      Icon: BiCamera,
                    },
                  ],
                ]}
              >
                <IconButton Icon={BiDotsHorizontalRounded} color={value.value?.photos?.length ? "green" : "white"} />
              </QuickMenu>
            )}
          </div>
        </div>
      </div>
      <>
        {showStatusPill && value.value.status !== "" && (
          <div>
            <Pill color="orange">{getAssetStatusLabel(value.value.status)}</Pill>
          </div>
        )}
        {children}
        {isOperational(stringToAssetStatus(value.value?.status)) && (
          <StandardTextarea
            name={`${asset.id}.note`}
            placeholder="Status notes (optional)"
            errorContained={true}
            onChange={(e) => helpers.setValue({ ...value.value, note: e.target.value })}
          />
        )}
      </>
      {Array.isArray(fileObjects) && fileObjects.length > 0 && (
        <div className="grid grid-cols-4 md:grid-cols-6 gap-2">
          {fileObjects.map((fileObject) => (
            <EagerUploadImage
              key={fileObject.fileId}
              fileObject={fileObject}
              removeFile={removeFileById}
              setUploaded={setUploadedById}
            />
          ))}
        </div>
      )}
      <PhotoBooth
        title={
          <div>
            Asset Report <span className="text-gray-400">/ {asset.name}</span>
          </div>
        }
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={(photo) => {
          setFiles([
            new File([photo], new Date().toISOString(), {
              type: PHOTO_BOOTH_IMAGE_TYPE,
            }),
          ])
        }}
        onFail={() => {}}
      />
    </div>
  )
}
