import { FC } from "react"

type StickyNoteProps = {
  id: string
  label?: string
  reporterName?: string
  timeStamp?: string
  content: string
  taskName?: string
}

export const StickyNote: FC<StickyNoteProps> = ({ id, label, reporterName, content, timeStamp, taskName }) => {
  return (
    <div key={id} className="p-4 m-2 max-h-56 overflow-scroll bg-yellow-100 shadow-md flex-col rounded-md">
      {(reporterName || label) && (
        <>
          <p className="mb-1 text-gray-600 font-bold text-xs uppercase">{`${reporterName || label} - ${timeStamp}`}</p>
          <p className="mb-1 text-gray-600 font-bold text-xs uppercase">{taskName}</p>
        </>
      )}
      <div className="text-base text-black">{content}</div>
    </div>
  )
}
