import { format } from "date-fns"
import { TimeEntry, User } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { infoSnack, errorSnack } from "../../components/Notistack/ThemedSnackbars"

export const toastClockOutError = (
  error: Error,
  user: PickPlus<User, "firstName" | "lastName"> & {
    latestTimeEntry?: PickPlus<TimeEntry, "startAt"> | null
  }
) => {
  switch (error.message) {
    case "ALREADY_CLOCKED_OUT":
      infoSnack(`${user.firstName} ${user.lastName} is already clocked out.`)
      break
    case "END_CANNOT_BE_BEFORE_START":
      infoSnack(
        `${user.firstName} ${user.lastName} is unable to be clocked out because the specified time (${format(
          new Date(),
          "h:mm aaa"
        )}) is before ${user.firstName}'s start time (${
          user.latestTimeEntry?.startAt ? format(user.latestTimeEntry?.startAt, "h:mm aaa") : "---"
        })`
      )
      break
    default:
      errorSnack(`There was an error clocking ${user.firstName} ${user.lastName} out`)
      break
  }
}
