import { FC, useState } from "react"
import { TaskGroupDetails } from "../../../Projects/TaskGroupDetails"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

type Props = {
  taskGroupId: string
  projectId: string
  onSuccess?: () => void
}

export const TaskGroupDrawer: FC<Props> = ({ taskGroupId, projectId, onSuccess = () => {} }) => {
  const [currentTab, setCurrentTab] = useState("details")

  return (
    <>
      <DrawerHeader href={`/projects/${projectId}/group/${taskGroupId}/details`} />

      <DrawerBody>
        <TaskGroupDetails
          taskGroupId={taskGroupId}
          projectId={projectId}
          currentTab={currentTab}
          withTitle
          setCurrentTab={setCurrentTab}
          onSuccess={onSuccess}
        />
      </DrawerBody>
    </>
  )
}
