import { InputAdornment, TextField } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { BiHash, BiParagraph } from "react-icons/bi"
import { YesNoInput } from "./YesNoInput"

type InspectionItem = {
  id: string
  label: string
  photoRequired: boolean
  photoLabel?: string | null | undefined
  rule?: string | null | undefined
  failedStatus?: string | null | undefined
  required: boolean
  type: string
}

export const InspectionFieldInput: FC<{ activeStep: number; field: InspectionItem }> = ({ activeStep, field }) => {
  const [{ value }, { error }, { setValue }] = useField(field.id)
  const [previousStep, setPreviousStep] = useState<number>(0)

  useEffect(() => {
    if (activeStep !== previousStep) {
      setValue("")
      setPreviousStep(activeStep)
    }
  }, [activeStep, setValue, setPreviousStep, previousStep])

  switch (field.type) {
    case "textarea":
      return (
        <div className="py-3">
          <TextField
            name={field.id}
            placeholder={field.label}
            rows={2}
            value={value}
            error={!!error}
            onChange={(e) => setValue(e.target.value)}
            multiline
          />
        </div>
      )
    case "number":
      return (
        <div className="py-3">
          <TextField
            name={field.id}
            value={value}
            error={!!error}
            onChange={(e) => setValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiHash className="text-gray-400 size-6" />
                </InputAdornment>
              ),
            }}
            type="number"
          />
        </div>
      )
    case "text":
      return (
        <div className="py-3">
          <TextField
            name={field.id}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            error={!!error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiParagraph className="text-gray-400 size-6" />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )
    case "y/n":
      return (
        <div className="relative top-[10px]">
          <YesNoInput name={field.id} className="mb-5" />
        </div>
      )
    default:
      return null
  }
}
