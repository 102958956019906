/**
 *  A little note on this.
 *
 *  Calling JSON.stringify on a native GeolocationPosition returns an empty object,
 *  as GeolocationPosition keys are prototypal. This implementation returns a new object
 *  with the same interface as GeolocationPosition that can be stringified for back-end
 *  persistence.
 */

export const getCurrentLocation = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject: (err: GeolocationPositionError) => void) => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) =>
        resolve({
          coords: {
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            speed: position.coords.speed,
          },
          timestamp: position.timestamp,
        }),
      reject,
      { enableHighAccuracy: true }
    )
  })
}
