import { Button } from "@mui/material"
import { FC } from "react"
import Pill from "../../Pill"
import { SummaryFilterType } from "./types"

export type FilterGroupOption = {
  title: string
  value: SummaryFilterType
  icon: JSX.Element
}

type Props = {
  options: FilterGroupOption[]
  queryStringParameter: string
  filter: string
  setFilter: (value: SummaryFilterType) => void
  defaultFilterValue: SummaryFilterType
}

export const FilterGroupWithQueryStringParam: FC<Props> = ({ options, filter, setFilter }) => {
  return (
    <div className="flex gap-x-1 overflow-x-auto">
      {options.map((option) => (
        <div key={option.value} className="relative">
          <Button
            disableRipple
            onClick={() => {
              setFilter(option.value)
            }}
            className="px-1 uppercase"
          >
            <Pill
              color={option.value === filter ? "black" : "gray"}
              className="text-sm flex flex-row gap-2 min-w-fit items-center"
            >
              {option.icon}
              <span className={option.value === filter ? "text-white" : "text-gray-500"}>{option.title}</span>
            </Pill>
          </Button>
        </div>
      ))}
    </div>
  )
}
