import { FC } from "react"
import { AssetHistoryReportQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { Grid, Typography } from "@mui/material"

type InspectionReport = AssetHistoryReportQuery["assetReport"]["inspectionReport"]

export const RepairDetails: FC<{ inspection?: InspectionReport }> = ({ inspection }) => {
  if (!inspection?.length || !inspection[0] || !inspection[0].photoUrls) {
    return null
  }

  const [inspectionItem] = inspection

  return (
    <div>
      <div className="mb-3 p-0">
        <Typography fontSize={14} fontWeight={700} textTransform="uppercase" className="pb-2 text-gray-800">
          Images
        </Typography>
        <Grid container spacing={2}>
          {inspectionItem?.photoUrls?.map((url: string, i: number) => (
            <Grid item key={inspectionItem?.fileIds?.[i]} lg={2}>
              <img className="rounded object-cover w-full aspect-1" alt="repair item evidence" src={url} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
