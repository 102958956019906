import { clsx } from "clsx"
import { FC } from "react"
import { BiSolidArrowToRight } from "react-icons/bi"
import { AssetBadge } from "../../../AssetBadge"
import { AssetNode } from "./AssetListDataGrid"

type AssetBadgeCellAssetType = Pick<
  AssetNode,
  | "id"
  | "name"
  | "assetGroupId"
  | "companyAssetNumber"
  | "isAssetGroup"
  | "assetChildCount"
  | "ownershipType"
  | "groupQuantity"
>

type AssetBadgeCellProps = {
  asset: AssetBadgeCellAssetType
  addSubIcon?: boolean
  depth: number
  indent?: boolean
}

export const AssetBadgeCell: FC<AssetBadgeCellProps> = ({ addSubIcon = false, asset, depth = 0, indent = true }) => {
  return (
    <div className="flex gap-2 pl-[10px] items-center" style={{ marginLeft: depth > 0 && indent ? "50px" : "0" }}>
      {/*
      When searching for assets we drop the nesting functionality but need a way to
      still relay to the user that this asset is a child of another asset
       */}
      {!depth && addSubIcon && <BiSolidArrowToRight className="size-5 text-gray-400 ml-1 shrink-0" />}
      {depth > 0 && (
        <div className="flex items-center gap-[5px]">
          {[...Array(depth)].map((_e, i) => (
            <div
              key={i}
              className={clsx("w-[5px] h-12 md:h-10 rounded-full", i + 1 === depth ? "bg-gray-800" : "bg-gray-200")}
            />
          ))}
        </div>
      )}
      <AssetBadge asset={asset} />
    </div>
  )
}
