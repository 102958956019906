export const getHoursAndMinutesFromSeconds = (totalSeconds: number) => {
  if (totalSeconds === 0) {
    return { hours: 0, minutes: '00' }
  }
  const hours = Math.floor(totalSeconds / 60 / 60)
  const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60)
    .toString()
    .padStart(2, "0")

  return { hours, minutes }
}
