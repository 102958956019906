import { NextPage } from "next"
import Error from "next/error"
import { useMemo } from "react"
import { useQuery } from "urql"
import { AssetQuickActions } from "../../../components/Partials/Assets/AssetQuickActions"
import { BasicInfo } from "../../../components/Partials/Assets/BasicInfo"
import { QuantizedAssetDataGrid } from "../../../components/Partials/Assets/DataGrid/Quantized/QuantizedAssetDataGrid"
import { NestedAssetsGrid } from "../../../components/Partials/Assets/NestedAssetsGrid"
import { RenderIf } from "../../../components/RenderIf"
import { Asset, AssetAssignableType, AssetStatus } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { isOperational } from "../../../helpers/assets/assetStatus"
import { useHandleError } from "../../../hooks/useHandleError"
import AssetPageLayout from "./_layout"
import { useRouter } from "next/router"

type Props = {
  assetId: string
}

const AssetGroupDocument = graphql(`
  query assetGroupDocument($id: String!) {
    asset(id: $id) {
      id
      isAssetGroup
      name
      division {
        id
        name
      }

      childAssets {
        id
        active
        assetChildCount
        assetGroupId
        assignableId
        assignableType
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        division {
          id
          name
        }
        ownershipType
        status
      }

      childAssetGroups {
        assetGroupId
        assignableId
        assignableType
        count
        groupParent {
          id
          active
          assetChildCount
          assetGroupId
          assignableId
          assignableType
          companyAssetNumber
          imageUrl
          isAssetGroup
          name
          division {
            id
            name
          }
          ownershipType
          status
        }
        status
      }
    }
  }
`)

type GroupParentAggregate = {
  id: string
  active: boolean
  assetChildCount: number
  assetGroupId?: string | null
  assignableId: string
  assignableType: AssetAssignableType
  companyAssetNumber?: string | null
  imageUrl?: string | null
  isAssetGroup: boolean
  name: string
  ownershipType: string
  status: AssetStatus
} & {
  activeCount: number
  missingCount: number
}

const useAssetDetails = (assetId: string) => {
  const [{ data, error }] = useQuery({
    query: AssetGroupDocument,
    variables: { id: assetId },
  })

  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const { asset, childAssets } = useMemo(() => {
    if (!data?.asset) {
      return {
        asset: {} as Asset,
        childAssets: [],
      }
    }

    const children = data.asset.childAssets || []

    // Aggregate child asset groups by ID
    const childAssetGroupAggregation = (data.asset.childAssetGroups || []).reduce<Record<string, GroupParentAggregate>>(
      (acc, group) => {
        const id = group.groupParent.id
        const { status, count } = group

        acc[id] = acc[id] || { ...group.groupParent, activeCount: 0, missingCount: 0 }
        if (isOperational(status)) {
          acc[id].activeCount += count
        } else if (status === "Missing") {
          acc[id].missingCount += count
        }

        return acc
      },
      {}
    )

    const aggregatedChildAssetGroups = Object.values(childAssetGroupAggregation)
    return {
      asset: data.asset,
      childAssets: [...children, ...aggregatedChildAssetGroups],
    }
  }, [data?.asset])

  return { asset, childAssets }
}

export const AssetDetails = ({ assetId }: Props) => {
  const { asset, childAssets } = useAssetDetails(assetId)

  return (
    <div className="grid gap-12">
      {!asset?.isAssetGroup && <AssetQuickActions assetId={assetId} template={{ id: assetId, name: asset.name }} />}
      {asset?.isAssetGroup ? (
        <QuantizedAssetDataGrid assetId={assetId} />
      ) : (
        <NestedAssetsGrid childAssets={childAssets} asset={asset} />
      )}
      <BasicInfo assetId={assetId} />
    </div>
  )
}

const AssetDetailsPage: NextPage = () => {
  const { query } = useRouter()
  const assetId = query._id as string

  return (
    <RenderIf permissionsInclude="asset:read" fallbackComponent={<Error statusCode={404} />}>
      <AssetPageLayout assetId={assetId}>
        <AssetDetails assetId={assetId} />
      </AssetPageLayout>
    </RenderIf>
  )
}

export default AssetDetailsPage
