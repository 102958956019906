import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { ListVisibilityFilter } from "../../../../../graphql/generated/client-types-and-hooks"
import { sentenceCase } from "../../../../../helpers/strings/sentenceCase"
import { assetListStoreActions } from "../../../../../stores/assetList"
import { ListVisibilityFilterValues } from "../../../../../types/ListVisibilityFilter"
import { CustomMenu } from "../../../../CustomMenu"
import { SingleOrMultiSelectionMenuItem } from "./AssetFilterState"

type InternalVisFilter = ListVisibilityFilter

type visibilityFilterOption = {
  value: InternalVisFilter
  label: string
  onSelect: () => void
}

export const AssetFilterType = (): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [internalVisFilter, setInternalVisFilter] = useState<InternalVisFilter>(ListVisibilityFilter.All)

  const visFilterLabel = (visFilter: string | ListVisibilityFilter) => {
    switch (visFilter) {
      case "all":
        return "All"
      case "my":
        return "My assets"
      default:
        return sentenceCase(visFilter)
    }
  }

  const visFilterValue = (str: string) => {
    switch (str) {
      case "all":
        return ListVisibilityFilter.All
      case "my":
        return ListVisibilityFilter.My
      default:
        return ListVisibilityFilter.My
    }
  }

  const visibilityFilterOptions: visibilityFilterOption[] = Object.values(ListVisibilityFilterValues).map((value) => ({
    value: visFilterValue(value),
    label: visFilterLabel(value),
    onSelect: () => {
      assetListStoreActions.setListFilterItems({
        isArchived: false,
        visibility: visFilterValue(value),
      })
    },
  }))

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        anchor={
          <div className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800">
            <span className="text-gray-800">{visFilterLabel(internalVisFilter)}</span>
            <BiChevronDown className="inline" />
          </div>
        }
        autoFocus={false}
      >
        <MenuList sx={{ minWidth: 250 }}>
          {visibilityFilterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                option.onSelect()
                setInternalVisFilter(option.value)
              }}
            >
              <SingleOrMultiSelectionMenuItem selected={internalVisFilter === option.value} />
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </CustomMenu>
    </>
  )
}
