import { differenceInSeconds } from "date-fns"

export const getSecondsFromRange = (range: { startAt: Date; endAt?: Date | null }) => {
  if (range.endAt) {
    return differenceInSeconds(range.endAt, range.startAt)
  }

  if (range.startAt > new Date()) {
    return 0
  }

  return differenceInSeconds(new Date(), range.startAt)
}
