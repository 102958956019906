import { Button, ButtonGroup } from "@mui/material"
import {
  DateRange,
  DateRangePicker,
  DateRangeValidationError,
  PickerChangeHandlerContext,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro"
import { addDays, endOfDay, isSameDay, startOfDay } from "date-fns"
import { FC, useState } from "react"
import { BiChevronLeft, BiChevronRight } from "react-icons/bi"
import { adjustDate, endOfPeriod, getRangeByPeriod, startOfPeriod } from "../helpers/dateAndTime/dateRange"
import { NormalizedDateRange } from "../hooks/useMuiDateRange"
import { DateRangeFilterString } from "./Partials/Summary/types"

type Props = {
  minDate?: Date | null
  startDate?: Date
  dateRangeType: DateRangeFilterString
  initialRange?: {
    rangeStart: Date
    rangeEnd: Date
  }
  setFilter?: (filter: DateRangeFilterString) => void
  onChange?: (range: NormalizedDateRange) => void
  initialDate?: Date
  disableShortcuts?: boolean
  disabled?: boolean
}

enum DateRangeTypeName {
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  allTime = "all-time",
  custom = "custom",
}

enum DateShortcutLabels {
  today = "Today",
  lastSevenDays = "Last 7 days",
  lastThirtyDays = "Last 30 days",
  weekly = "Weekly",
  monthly = "Monthly",
  allTime = "All Time",
}

const fromDatePicker = (dateRange: [Date, Date]) => ({ rangeStart: dateRange[0], rangeEnd: dateRange[1] })
const toDatePicker = (dateRange: NormalizedDateRange): DateRange<Date> => [dateRange.rangeStart, dateRange.rangeEnd]

export const DateSelect: FC<Props> = ({
  minDate,
  startDate,
  dateRangeType,
  initialRange,
  setFilter,
  onChange,
  initialDate = new Date(),
  disableShortcuts = false,
  disabled = false,
}) => {
  const [dateRange, setDateRange] = useState<NormalizedDateRange>({
    rangeStart: initialRange?.rangeStart ?? startOfPeriod(initialDate, dateRangeType),
    rangeEnd: initialRange?.rangeEnd ?? endOfPeriod(initialDate, dateRangeType),
  })

  const dateRangeTypeButton = {
    monthly: "This Month",
    weekly: "This Week",
    daily: "Today",
    "all-time": "Today",
    custom: "Today",
  }

  interface DateRangeTypeSelectionFilters {
    isArrowClicked?: boolean
    isResetButtonClicked?: boolean
  }

  const handleUpdateDateRange = (
    newDateRange: DateRange<Date>,
    ctx?: PickerChangeHandlerContext<DateRangeValidationError>,
    dateRangeTypeFilters: DateRangeTypeSelectionFilters = {}
  ) => {
    const { isArrowClicked, isResetButtonClicked } = dateRangeTypeFilters
    const [start, end] = newDateRange
    if (ctx?.shortcut && setFilter) {
      if (ctx.shortcut.label === DateShortcutLabels.today) {
        setFilter(DateRangeTypeName.daily)
      } else if (ctx.shortcut.label === DateShortcutLabels.weekly) {
        setFilter(DateRangeTypeName.weekly)
      } else if (ctx.shortcut.label === DateShortcutLabels.monthly) {
        setFilter(DateRangeTypeName.monthly)
      } else if (ctx.shortcut.label === DateShortcutLabels.allTime) {
        setFilter(DateRangeTypeName.allTime)
      } else {
        setFilter(DateRangeTypeName.custom)
      }
    } else if (!ctx?.shortcut && !isArrowClicked && !isResetButtonClicked) {
      if (start && end && startOfDay(start).getTime() === startOfDay(end).getTime()) {
        setFilter?.(DateRangeTypeName.daily)
      } else {
        setFilter?.(DateRangeTypeName.custom)
      }
    }
    if (!start || !end) return null

    const rangeStart = startOfDay(start)
    const rangeEnd = endOfDay(end)

    setDateRange(fromDatePicker([rangeStart, rangeEnd]))
    onChange?.(fromDatePicker([rangeStart, rangeEnd]))
  }

  function adjustRange(amount: number) {
    return handleUpdateDateRange(adjustDate(dateRange.rangeStart, amount, dateRangeType), undefined, {
      isArrowClicked: true,
    })
  }

  const isArrowDisabled = dateRangeType === "custom" || dateRangeType === "all-time"
  const isPrevDisabled =
    (isArrowDisabled || (minDate && startOfPeriod(dateRange.rangeStart, dateRangeType) <= minDate)) ?? false
  const isNextDisabled =
    isArrowDisabled || endOfPeriod(dateRange.rangeStart, dateRangeType).getTime() >= new Date().getTime()

  const isDateRangeTypeButtonDisabled =
    isSameDay(dateRange.rangeStart, startOfPeriod(new Date(), dateRangeType)) &&
    isSameDay(dateRange.rangeEnd, endOfPeriod(new Date(), dateRangeType))

  const today = new Date()
  const shortCutItems = [
    {
      label: DateShortcutLabels.today,
      getValue: (): DateRange<Date> => {
        return [today, today]
      },
    },
    {
      label: DateShortcutLabels.lastSevenDays,
      getValue: (): DateRange<Date> => {
        return [addDays(today, -6), today]
      },
    },
    {
      label: DateShortcutLabels.lastThirtyDays,
      getValue: (): DateRange<Date> => {
        return [addDays(today, -30), today]
      },
    },
    {
      label: DateShortcutLabels.weekly,
      getValue: (): DateRange<Date> => {
        return getRangeByPeriod(startOfPeriod(today, DateRangeTypeName.weekly), DateRangeTypeName.weekly)
      },
    },
    {
      label: DateShortcutLabels.monthly,
      getValue: (): DateRange<Date> => {
        return getRangeByPeriod(startOfPeriod(today, DateRangeTypeName.monthly), DateRangeTypeName.monthly)
      },
    },
    {
      label: DateShortcutLabels.allTime,
      getValue: (): DateRange<Date> => {
        return [startDate || today, today]
      },
    },
  ]

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <DateRangePicker
          disabled={disabled}
          slots={{ field: SingleInputDateRangeField }}
          format="MMM dd"
          value={toDatePicker(dateRange)}
          slotProps={{
            shortcuts: !disableShortcuts
              ? {
                  items: shortCutItems,
                  changeImportance: "set",
                }
              : undefined,
          }}
          onChange={handleUpdateDateRange}
        />

        <div>
          <ButtonGroup>
            <Button
              className="min-w-0 p-2"
              color="secondary"
              variant="contained"
              onClick={() => !isPrevDisabled && adjustRange(-1)}
              disabled={isPrevDisabled}
            >
              <BiChevronLeft className="size-6" />
            </Button>
            <Button
              className="min-w-0 p-2"
              color="secondary"
              variant="contained"
              onClick={() => !isNextDisabled && adjustRange(1)}
              disabled={isNextDisabled}
            >
              <BiChevronRight className="size-6" />
            </Button>
          </ButtonGroup>
        </div>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => {
            handleUpdateDateRange(
              getRangeByPeriod(startOfPeriod(new Date(), dateRangeType), dateRangeType),
              undefined,
              { isResetButtonClicked: true }
            )
            if (dateRangeType === "custom" || dateRangeType === "all-time") {
              setFilter?.(DateRangeTypeName.daily)
            }
          }}
          disabled={isDateRangeTypeButtonDisabled}
        >
          {dateRangeTypeButton[dateRangeType]}
        </Button>
      </div>
    </div>
  )
}
