import { useFormikContext } from "formik"
import { FC, useMemo } from "react"
import { Asset, AssetStatus } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"

type TypeExpectation = PickPlus<Asset, "id" | "name" | "status" | "inventoryRequirements">

type ChecklistCounterProps = {
  assets: TypeExpectation[]
}

type InventoryFormValues = {
  [assetId: string]: {
    active: boolean
    status: AssetStatus | ""
    photos?: {
      fileId: string
      uploaded: boolean
    }[]
    note?: string
  }
}

export const ChecklistCounter: FC<ChecklistCounterProps> = ({ assets }) => {
  const { values } = useFormikContext<InventoryFormValues>()

  const numberCompleted = useMemo(() => {
    return assets.filter((asset) => {
      const assetFormValue = values[asset.id]

      if (assetFormValue?.status === "") {
        return false
      } else if (asset.inventoryRequirements?.photoRequired && !assetFormValue?.photos?.length) {
        return false
      }

      return true
    }).length
  }, [assets, values])

  return (
    <div className="flex justify-between uppercase text-sm font-semibold text-gray-400 mb-2">
      <span>Inventory Checklist</span>
      <span>
        {numberCompleted} / {assets.length}
      </span>
    </div>
  )
}
