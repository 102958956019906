import { FC, useContext } from "react"
import { BiLayer, BiListUl, BiSortAlt2 } from "react-icons/bi"
import { Asset, AssetAssignableType, Task } from "../../../../graphql/generated/client-types-and-hooks"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../../types/helpers"
import { IconLabel } from "../../../IconLabel"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import {
  AssetGroupReassignmentFilterDefinition,
  ReassignAssetDrawer,
} from "../../Drawer/components/Asset/ReassignAssetDrawer"
import { TaskDrawer } from "../../Drawer/components/Task/TaskDrawer"
import { DrawerContext } from "../../Drawer/providers/DrawerProvider"

export const TaskCell: FC<{ asset: Asset }> = ({ asset }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)

  let task: PickPlus<Task, "id" | "name"> | undefined = asset.assignedUser?.currentTask

  if (asset.assignableType === AssetAssignableType.Asset) {
    task = asset?.assignedAsset?.assignedUser?.currentTask
  }

  if (asset.assignableType === AssetAssignableType.Task && asset?.assignedTask) {
    task = asset?.assignedTask
  }

  if (asset.inferredOwner) {
    task = asset.inferredOwner?.currentTask
  }

  const taskQuickMenuItems: MenuItem[][] = [
    [
      {
        value: "View task",
        Icon: BiLayer,
        onClick: () => {
          if (task?.id) {
            pushDrawer(<TaskDrawer taskId={task.id} />, "Task")
          } else {
            errorSnack("Task not found")
          }
        },
      },
    ],
  ]

  if (hasPermissionTo("asset:transfer") && !asset.isAssetGroup && !asset.deletedAt) {
    taskQuickMenuItems.push([
      {
        value: "Transfer asset",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(
            <ReassignAssetDrawer
              assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
              assetGroupToReassign={
                asset.assetGroupId
                  ? ({
                      assetGroupId: asset.assetGroupId!,
                      assignableId: asset.assignableId,
                      assignableType: asset.assignableType,
                      status: asset.status!,
                      count: asset.assetChildCount,
                      groupParent: asset?.childAssetGroups?.[0]?.groupParent,
                      compositeKey: asset.compositeKey,
                    } as AssetGroupReassignmentFilterDefinition)
                  : null
              }
            />,
            "ReassignAsset"
          )
        },
      },
    ])
  }

  return (
    <QuickMenu
      items={taskQuickMenuItems}
      className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
      disabled={asset.assignableType === AssetAssignableType.User && asset.assignedUser?.archived}
    >
      <IconLabel icon={BiListUl} label={task?.name || "N/A"} />
    </QuickMenu>
  )
}
