import { Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { BiSearchAlt, BiSolidMagicWand, BiSortAlt2, BiTask, BiXCircle } from "react-icons/bi"

enum AssetReportType {
  Inventory = "Inventory",
  Transfer = "Transfer",
  TransferDivision = "TransferDivision",
  Inspection = "Inspection",
  Repair = "Repair",
  DismissRepair = "DismissRepair",
}

enum AssetReportTypeLabel {
  Inventory = "Inventory",
  Transfer = "Transferred",
  TransferDivision = "Transferred Division",
  Inspection = "Inspection",
  Repair = "Repair",
  DismissRepair = "Dismissed Repair",
}

export const AssetReportTypeTitle: FC<{ type: string }> = ({ type }) => {
  let icon: ReactElement | null = null
  switch (type) {
    case AssetReportType.Inventory:
      icon = <BiTask />
      break
    case AssetReportType.TransferDivision:
      icon = <BiSortAlt2 />
      break
    case AssetReportType.Transfer:
      icon = <BiSortAlt2 />
      break
    case AssetReportType.Inspection:
      icon = <BiSearchAlt />
      break
    case AssetReportType.Repair:
      icon = <BiSolidMagicWand />
      break
    case AssetReportType.DismissRepair:
      icon = <BiXCircle />
      break
  }

  const label = AssetReportTypeLabel[type as keyof typeof AssetReportTypeLabel]

  return (
    <div className="flex flex-row items-center max-w-[150px]">
      <div className="bg-gray-800 mr-2 rounded size-6 p-1 text-white">{type && icon}</div>
      <Typography fontWeight={400}>{label ?? type}</Typography>
    </div>
  )
}
