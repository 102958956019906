import { Box, Button, Typography } from "@mui/material"
import { endOfDay, format, parseISO, startOfDay, subMonths, subYears } from "date-fns"
import { Form, Formik } from "formik"
import { FC, useMemo } from "react"
import { useMutation, useQuery } from "urql"
import * as Yup from "yup"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { sessionKeys } from "../../../lib/jwtHelpers"
import { FormikDateRangePicker } from "../../Formik/DateRangePicker"
import { MuiMultiSelect } from "../../Formik/MultiSelect/MuiMultiSelect"
import { UnitReportExportFormSkeleton } from "./UnitReportExportForm.skeleton"
import { UPDATE_LAST_EXPORT } from "../../../graphql/queries/lastExport.queries"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { colors } from "../../../helpers/styles/colors"

type InitialValues = {
  reportType: ["unit-report" | "unit-rate-sheet-report"]
  projectId: string[]
  dates: Date[]
}

type LastExportDataProps = {
  userId: string
  userName: string
  exportedAt: string
}
type UnitReportExportFormProps = {
  isModal?: boolean
  onClose?: () => void
  projectId?: string
  rangeStart?: Date
  rangeEnd?: Date
  lastExport?: LastExportDataProps
}

const UnitReportExportFormDocument = graphql(`
  query UnitReportExportForm {
    projects {
      id
      name
    }
  }
`)

export const UnitReportExportForm: FC<UnitReportExportFormProps> = ({
  isModal,
  onClose,
  projectId,
  rangeStart,
  rangeEnd,
  lastExport,
}) => {
  const [, updateLastExport] = useMutation(UPDATE_LAST_EXPORT)

  const [{ data, error: dataError }] = useQuery({ query: UnitReportExportFormDocument })

  const token = useMemo(() => {
    if (typeof localStorage === "undefined") return ""

    return localStorage.getItem(sessionKeys.accessToken)
  }, [])

  useHandleError(dataError, "There was an error loading users.")

  const initialValues: InitialValues = {
    reportType: ["unit-report"],
    projectId: [projectId || data?.projects?.at(0)?.id || ""],
    dates:
      rangeStart && rangeEnd
        ? [rangeStart, rangeEnd]
        : [subMonths(startOfDay(subYears(new Date(), 1)), 1), startOfDay(new Date())],
  }

  if (!data) {
    return <UnitReportExportFormSkeleton />
  }

  async function handleExport() {
    const exportedAt = new Date().toISOString()
    try {
      const updateResult = await updateLastExport({ entityId: projectId, entityType: "project" })

      if (updateResult.error) {
        throw updateResult.error
      }

      successSnack(
        `Project units data exported on ${format(parseISO(exportedAt), "M/d/yy")} at ${format(
          parseISO(exportedAt),
          "h:mm"
        )}`
      )
    } catch (error) {
      if (error instanceof Error) {
        console.error("Export error:", error.message)
      }
      errorSnack("There was an error during the export process")
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          reportType: Yup.array().of(Yup.string()),
          projectId: Yup.array().of(Yup.string().required()),
          dates: Yup.array().of(Yup.date().required()),
        })}
        onSubmit={(values) => {
          if (values.reportType[0] === "unit-rate-sheet-report") {
            const startDate = values.dates[0].toISOString()
            const endDate = endOfDay(values.dates[1]).toISOString()
            window.open(
              `/api/reports/unit-rate-sheet-report?projectId=${values.projectId}&start=${startDate}&end=${endDate}`
            )
          }

          return
        }}
      >
        {({ errors, values }) => {
          const urlValues = new URLSearchParams({
            projectId: values.projectId[0],
            startDate: values.dates[0].toISOString(),
            endDate: endOfDay(values.dates[1]).toISOString(),
          }).toString()
          const route = `/api/reports/${values.reportType[0]}?authorization=${token}&${urlValues}`

          return (
            <Form>
              <Box className="grid grid-cols-1 gap-8 items-end mt-8">
                <Box>
                  <MuiMultiSelect
                    isSingleSelect
                    name="reportType"
                    label="Report Type"
                    options={[
                      {
                        id: "unit-report",
                        label: "Unit Report",
                        value: "unit-report",
                        searchableTextString: "Unit Report",
                      },
                      {
                        id: "unit-rate-sheet-report",
                        label: "Unit Rate Sheet Report",
                        value: "unit-rate-sheet-report",
                        searchableTextString: "Unit Rate Sheet Report",
                      },
                    ]}
                  />
                </Box>

                <Box>
                  <MuiMultiSelect
                    isSingleSelect
                    name="projectId"
                    label="Project"
                    options={data?.projects?.map((project) => ({
                      id: project.id,
                      label: project.name,
                      searchableTextString: project.name,
                      value: project.id,
                    }))}
                  />
                </Box>

                <Box>
                  <FormikDateRangePicker name="dates" label="Date Range" />
                </Box>

                {lastExport && (
                  <Box>
                    <Typography fontSize={14} color={colors.gray[500]} className="my-auto mr-6">
                      {`Last export on ${format(parseISO(lastExport.exportedAt), "M/d/yy")} at ${format(
                        parseISO(lastExport.exportedAt),
                        "h:mm"
                      )} by ${lastExport.userName}`}
                    </Typography>
                  </Box>
                )}

                <Box>
                  {isModal && onClose ? (
                    <div className="flex justify-end gap-x-2">
                      <Button variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        disabled={Object.keys(errors).length > 0}
                        href={route}
                        variant="contained"
                        target="_blank"
                        onClick={() => {
                          handleExport()
                          onClose()
                        }}
                      >
                        Export
                      </Button>
                    </div>
                  ) : (
                    <Button disabled={Object.keys(errors).length > 0} href={route} variant="contained" target="_blank">
                      Export
                    </Button>
                  )}
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
