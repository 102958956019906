import { clsx } from "clsx"
import { FC } from "react"
import { BiDislike, BiLike } from "react-icons/bi"
import { InspectionReportAggregations } from "../graphql/generated/client-types-and-hooks"
import { NAButton } from "./NAbutton"

type Props = {
  report: InspectionReportAggregations
}

export const InspectionSummary: FC<Props> = ({ report }) => {
  const trueCount = report?.trueCount ?? 0
  const falseCount = report?.falseCount ?? 0
  const showThumbs = trueCount > 0 || falseCount > 0
  const thumbStyles = "text-white p-1 rounded size-6"
  const numStyles = "mx-1.5 text-base flex items-center"

  return (
    <div className="flex items-center justify-center">
      {showThumbs ? (
        <>
          <BiLike className={clsx("bg-green-600", thumbStyles)} />
          <div className={clsx("min-w-[20px]", numStyles)}>{trueCount}</div>
          {falseCount > 0 && (
            <>
              <BiDislike className={clsx(" bg-orange-600", thumbStyles)} />
              <div className={clsx(numStyles)}>{falseCount}</div>
            </>
          )}
        </>
      ) : (
        <NAButton />
      )}
    </div>
  )
}
