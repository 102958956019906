import { useContext } from "react"
import { User } from "../graphql/generated/client-types-and-hooks"
import { ModalContext } from "../providers/ModalProvider"
import { DrawerContext } from "../components/Partials/Drawer/providers/DrawerProvider"
import { useModalProps } from "../components/Modals/hooks/useModalProps"
import { PickPlus } from "../types/helpers"
import { ConfirmArchiveUserModal } from "../components/Modals/components/ConfirmArchiveUserModal"
import { ArchiveUserDrawer } from "../components/Partials/Drawer/ArchiveUserDrawer"

type ProjectWorkflowParams = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "assetCount" | "isClockedIn">
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
  onCancelCallback?: () => void
}

export const useReassignAndArchiveUserWorkflow = () => {
  const { push, pop: closeDrawer } = useContext(DrawerContext)
  const { showModal } = useContext(ModalContext)
  const modalProps = useModalProps("Archive User")

  return ({ onSuccessCallback, onCancelCallback, onErrorCallback, user }: ProjectWorkflowParams) => {
    const drawerUser: PickPlus<User, "id" | "firstName" | "lastName" | "assetCount" | "isClockedIn"> = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      assetCount: user.assetCount ?? 0,
      isClockedIn: user.isClockedIn ?? false,
    }
    const drawerProps = {
      onError: onErrorCallback,
      onSuccess: onSuccessCallback,
      onCancel: () => {
        onCancelCallback?.()
        modalProps.handleCloseModal()
        closeDrawer()
      },
      user: drawerUser,
    }

    const modalData = {
      user,
      modalProps,
      onConfirm: async () => {
        onSuccessCallback?.()
        modalProps.handleCloseModal()
        closeDrawer()
      },
    }

    if (user.assetCount && user.assetCount > 0) {
      push(
        <ArchiveUserDrawer
          onError={drawerProps.onError}
          onSuccess={drawerProps.onSuccess}
          onCancel={drawerProps.onCancel}
          user={drawerProps.user}
        />,
        "UserArchive"
      )
    } else {
      showModal(ConfirmArchiveUserModal, modalData)
    }
  }
}
