import { Button } from "@mui/material"
import { clsx } from "clsx"
import { Form, Formik, FormikValues, useField, useFormikContext } from "formik"
import { FC, ReactElement, useState } from "react"
import { BiCamera, BiDotsHorizontalRounded } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import {
  AssetReportTemplate,
  AssetReportType,
  AssetStatus,
  useInsertManyAssetReportsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { isOperational } from "../../../helpers/assets/assetStatus"
import { useImageUpload } from "../../../hooks/useImageUpload"
import { PickPlus } from "../../../types/helpers"
import { AssetStatusMultiSelect } from "../../Formik/MultiSelect/implementations/AssetStatusMultiSelect"
import { DeprecatedInput } from "../../Formik/StandardInput"
import { StandardTextarea } from "../../Formik/StandardTextarea"
import { IconButton } from "../../IconButton"
import { EagerUploadImage } from "../../ImageUploadPreviewList"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { YesNoInput } from "../../Partials/Inspection/YesNoInput"
import { PHOTO_BOOTH_IMAGE_TYPE, PhotoBooth } from "../../PhotoBooth"
import { QuickMenu } from "../../QuickMenu"
import { ButtonHollow } from "../../deprecated"
import DeprecatedModal from "../../deprecated/StandardModal"
import { ModalProps } from "../hooks/useModalProps"
import { ModalBody } from "./Elements/ModalBody"
import { ModalFooter } from "./Elements/ModalFooter"

const photoKey = "-photos"

const FormRow: FC<{
  children: ReactElement
  title: string
  subTitle: string
  photoRequired: boolean
  assetId: string
  fieldId: string
}> = ({ children, title, subTitle, photoRequired, assetId, fieldId }) => {
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const { setFiles, removeFileById, setUploadedById, fileObjects } = useImageUpload(
    `asset/${assetId}/image`,
    (photos) => setValue(photos)
  )
  const [{ value }, { error, touched }, { setValue }] = useField(fieldId + photoKey)
  const displayError = touched && error

  return (
    <>
      <div className="col-span-6">
        <div className="">{title}</div>
        <div className="text-gray-400 text-sm">{subTitle}</div>
      </div>
      <div className="col-span-6 flex justify-end">
        <div className="w-[94px]">{children}</div>
        <div className="rounded ml-2 min-w-[42px] h-[42px] hover:bg-gray-50">
          <div className={clsx("rounded border-2", displayError ? "border-red-600 text-red-600" : "border-white")}>
            {Array.isArray(fileObjects) && fileObjects.length > 0 ? (
              <div className="size-10">
                {fileObjects.map((fileObject) => (
                  <EagerUploadImage
                    key={fileObject.fileId}
                    fileObject={fileObject}
                    removeFile={removeFileById}
                    setUploaded={setUploadedById}
                  />
                ))}
              </div>
            ) : photoRequired ? (
              <IconButton
                Icon={BiCamera}
                onClick={() => setPhotoBoothIsOpen(true)}
                color={value?.length ? "green" : "gray"}
              />
            ) : (
              <QuickMenu
                items={[
                  [
                    {
                      value: "Take photo (optional)",
                      onClick: () => setPhotoBoothIsOpen(true),
                      Icon: BiCamera,
                    },
                  ],
                ]}
              >
                <IconButton Icon={BiDotsHorizontalRounded} color={value?.length ? "green" : "white"} />
              </QuickMenu>
            )}
          </div>
        </div>
      </div>
      <PhotoBooth
        title={
          <div>
            Asset Report <span className="text-gray-400">/ {title}</span>
          </div>
        }
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={(photo) => {
          setFiles([
            new File([photo], new Date().toISOString(), {
              type: PHOTO_BOOTH_IMAGE_TYPE,
            }),
          ])
        }}
      />
    </>
  )
}

type AssetInspectionReportModalContentsProps = {
  onCancel: () => void
  assetId: string
  reportTemplate?: PickPlus<AssetReportTemplate, "fields">
}
type StatusValues = {
  status: AssetStatus
}

const AssetInspectionReportModalContents: FC<AssetInspectionReportModalContentsProps> = ({
  onCancel,
  assetId,
  reportTemplate,
}) => {
  const { values } = useFormikContext<StatusValues>()
  const isStatusSelected = !!values.status // Check if status is selected

  if (!reportTemplate) return null
  // Get current values of the form

  return (
    <Form className="h-full flex flex-col">
      <ModalBody className="grid grid-cols-12 gap-x-2">
        <div key="status" className="col-span-12">
          <AssetStatusMultiSelect name="status" />
        </div>
        {reportTemplate?.fields?.map((field) => {
          const label = field.label || ""

          switch (field.type) {
            case "textarea":
              return (
                <div key={field.id} className="col-span-12">
                  <StandardTextarea name={field.id} placeholder={label} required={true} rows={2} />
                </div>
              )
            case "number":
              return (
                <FormRow
                  key={field.id}
                  title={label}
                  subTitle="Number Input"
                  photoRequired={!!field.photoRequired}
                  assetId={assetId}
                  fieldId={field.id}
                >
                  <DeprecatedInput type="number" name={field.id} />
                </FormRow>
              )
            case "text":
              return (
                <FormRow
                  key={field.id}
                  title={label}
                  subTitle="Text Input"
                  photoRequired={!!field.photoRequired}
                  assetId={assetId}
                  fieldId={field.id}
                >
                  <DeprecatedInput name={field.id} />
                </FormRow>
              )
            case "y/n":
              return (
                <FormRow
                  key={field.id}
                  title={label}
                  subTitle="Yes / No"
                  photoRequired={!!field.photoRequired}
                  assetId={assetId}
                  fieldId={field.id}
                >
                  <YesNoInput name={field.id} className="mb-5" />
                </FormRow>
              )
            default:
              return null
          }
        })}
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type="button" onClick={onCancel} disabled={false}>
          Cancel
        </ButtonHollow>
        <Button variant="contained" type="submit" disabled={!isStatusSelected}>
          Report
        </Button>
      </ModalFooter>
    </Form>
  )
}

const AssetInspectionReportModalQueryDocument = graphql(`
  query AssetInspectionReportModal($id: String!) {
    assetReportTemplate(id: $id) {
      id
      assetsCount
      createdAt
      deletedAt
      fields {
        id
        label
        photoRequired
        photoLabel
        rule
        failedStatus
        required
        type
      }
      name
      reusable
      universal
    }
  }
`)

type PhotoFile = {
  fileId: string
}

export const AssetInspectionReportModal: FC<{
  formModalProps: ModalProps
  assetReportTemplateId: string
  assetId: string
}> = ({ formModalProps, assetReportTemplateId, assetId }) => {
  const [{ data: reportTemplate }] = useQuery({
    query: AssetInspectionReportModalQueryDocument,
    variables: { id: assetReportTemplateId },
  })

  const [, createAssetReportsMutation] = useInsertManyAssetReportsMutation()

  const handleSubmit = (values: FormikValues) => {
    // FORM SUBMISSION

    const inspectionReport =
      reportTemplate?.assetReportTemplate.fields
        ?.map((field) => {
          const input = values[field.id]?.toString()
          const fileIds = values[field.id + photoKey]?.map((photo: PhotoFile) => photo.fileId)

          return {
            ...field,
            input,
            fileIds,
          }
        })
        .filter((field) => {
          return (typeof field.input === "string" && field.input) || field.fileIds !== undefined
        }) || []

    const reports = [
      {
        assetId,
        inspectionReport,
        statusChange: {
          status: values.status,
          active: isOperational(values.status),
        },
        type: AssetReportType.Inspection,
      },
    ]

    createAssetReportsMutation({ reports }).then((result) => {
      if (result.error) {
        errorSnack("Error reporting")
      } else {
        successSnack("Submitted Successfully")
      }
      formModalProps.handleCloseModal()
    })
  }

  if (!reportTemplate) return null

  return (
    <DeprecatedModal {...formModalProps}>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={handleSubmit}
        // TODO: Finish Form Validation
        // validationSchema={Yup.object().shape(validationSchema)}
      >
        <AssetInspectionReportModalContents
          onCancel={() => formModalProps.handleCloseModal()}
          assetId={assetId}
          reportTemplate={reportTemplate.assetReportTemplate}
        />
      </Formik>
    </DeprecatedModal>
  )
}
