import { FC } from "react"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { getAssetStatusLabel, isOperational } from "../../../helpers/assets/assetStatus"
import Pill from "../../Pill"

export const AssetStatusPill: FC<{
  asset: Pick<
    Asset,
    | "active"
    | "assignedAssetId"
    | "assignableId"
    | "assignedTaskId"
    | "assignableType"
    | "assignedUserId"
    | "deletedAt"
    | "groupQuantity"
    | "isAssetGroup"
    | "status"
  >
  isArchived?: boolean
}> = ({ asset }) => {
  const hasMultipleAssignments = asset.isAssetGroup && asset.groupQuantity && asset.groupQuantity > 1

  const statusLabel = getAssetStatusLabel(asset.status)
  const isActive = isOperational(asset.status)

  if (hasMultipleAssignments) {
    return <Pill color="violet">Multiple</Pill>
  }

  return (
    <>
      {asset.deletedAt && <Pill color="red">{statusLabel}</Pill>}
      {!asset.deletedAt && isActive && <Pill color="blue">{statusLabel}</Pill>}
      {!asset.deletedAt && !isActive && <Pill color="orange">{statusLabel}</Pill>}
    </>
  )
}
