import { FC, useMemo } from "react"

import { Form, Formik, FormikHelpers } from "formik"
import * as Yup from "yup"

import { LoadingButton } from "@mui/lab"
import { Alert, Button } from "@mui/material"
import { BiBox, BiInfoCircle, BiLoaderAlt, BiSortAlt2 } from "react-icons/bi"

import {
  Asset,
  AssetStatus,
  useBulkDeleteAssetsMutation,
  useDeleteAssetMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { assetStatusList } from "../../../helpers/assets/assetStatus"
import { useTransferAndArchiveAssetWorkflow } from "../../../hooks/useTransferAndArchiveAssetWorkflow"
import { PickPlus } from "../../../types/helpers"
import DeprecatedModal from "../../deprecated/StandardModal"
import { DropDownMUI } from "../../DropDownMUI"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { ModalProps } from "../hooks/useModalProps"
import { ModalBody } from "./Elements/ModalBody"
import { ModalFooter } from "./Elements/ModalFooter"

type Values = {
  status: AssetStatus | ""
}

type Props = {
  asset?: PickPlus<Asset, "id" | "name" | "assetChildCount" | "status">
  assets?: PickPlus<Asset, "id" | "name" | "assetChildCount" | "status">[]
  modalProps: ModalProps
  archiveChildAssets?: boolean
}

const CannotDeleteAsset: FC<{
  name: string | undefined
  assetChildCount: number
}> = ({ name, assetChildCount }) => {
  return (
    <>
      <p className="pb-5">
        The asset <span className="font-semibold">{name}</span> has{" "}
        <span className="font-semibold">
          {assetChildCount} {assetChildCount > 1 ? "assets " : "asset "}
        </span>{" "}
        assigned to it that must be transferred before archiving.
      </p>
      <p className="pb-5">Please transfer them before archiving the asset.</p>
    </>
  )
}

export const ConfirmArchiveAssetModal: FC<Props> = ({ asset, assets, modalProps, archiveChildAssets }) => {
  const archiveAssetWorkflow = useTransferAndArchiveAssetWorkflow()
  const [, archiveAssetMutation] = useDeleteAssetMutation()
  const [, bulkArchiveAssetMutation] = useBulkDeleteAssetsMutation()

  const handleSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      if (!values.status) throw new Error("Status is required")
      if (!asset?.id && (!assets || assets?.length === 0)) {
        throw new Error("Something went wrong, please refresh and try again.")
      }

      if (assets && assets.length > 0) {
        await bulkArchiveAssetMutation({
          assetIds: assets.map((a) => a.id),
          status: values.status,
        })
        successSnack("Asset archived successfully")
      } else if (asset?.id) {
        await archiveAssetMutation({ id: asset.id, status: values.status })
        successSnack("Asset archived successfully")
      }

      modalProps.handleCloseModal()
    } catch (error) {
      if (error instanceof Error) errorSnack(error.message)
      errorSnack("Error archiving asset")
    } finally {
      setSubmitting(false)
    }
  }

  const handleTransfer = () => {
    if (!asset) return

    const assetToArchive = {
      id: asset?.id,
      name: asset?.name,
      assetChildCount: asset?.assetChildCount,
      status: asset?.status,
    }

    modalProps.handleCloseModal()
    archiveAssetWorkflow({
      asset: assetToArchive,
      onSuccessCallback: () => {
        successSnack("Asset archived successfully")
        modalProps.handleOpenModal()
      },
    })
  }

  const archivableStatuses = assetStatusList.filter((status) => status.canBeArchived)
  const assetChildCount = useMemo((): number => {
    if (asset) {
      return asset?.assetChildCount ?? 0
    }
    if (assets && assets.length > 0) {
      return assets?.reduce((acc, a) => acc + (a?.assetChildCount ?? 0), 0)
    }
    return 0
  }, [asset, assets])
  const hasAssets = assetChildCount > 0
  const transferEnabled = hasAssets && !archiveChildAssets

  return (
    <DeprecatedModal {...modalProps}>
      {modalProps?.isOpen && (
        <Formik
          enableReinitialize
          initialValues={{
            status: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            status: Yup.mixed<AssetStatus>().required("Status is required"),
          })}
          className="p-0 m-0"
        >
          {({ isSubmitting }) => (
            <Form className="size-full p-0 m-0">
              <ModalBody>
                {transferEnabled ? (
                  <CannotDeleteAsset name={asset?.name} assetChildCount={assetChildCount} />
                ) : (
                  <>
                    {hasAssets && archiveChildAssets && (
                      <Alert
                        severity="warning"
                        className="mb-5 font-light"
                        icon={<BiInfoCircle />}
                        classes={{
                          root: "align-center",
                          icon: "flex items-center",
                        }}
                      >
                        One or more of the selected assets have nested assets. Archiving the selected assets will also
                        archive all nested assets. Are you sure you want to proceed?
                      </Alert>
                    )}
                    <div className="h-52">
                      <DropDownMUI items={archivableStatuses} fieldName="status" label="Status" />
                    </div>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button variant="outlined" onClick={modalProps.handleCloseModal} color="black">
                  Cancel
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  color={hasAssets ? "primary" : "error"}
                  loadingIndicator={<BiLoaderAlt className="animate-spin" size="20px" color="white" />}
                  loading={isSubmitting}
                  endIcon={
                    transferEnabled ? <BiSortAlt2 className="rotate-90 size-5" /> : <BiBox size="20px" color="white" />
                  }
                  disabled={isSubmitting}
                  loadingPosition="end"
                  onClick={(e) => {
                    if (transferEnabled) {
                      e.preventDefault()
                      handleTransfer()
                    }
                  }}
                >
                  <span>{transferEnabled ? "Transfer" : "Archive"}</span>
                </LoadingButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
    </DeprecatedModal>
  )
}
