import { Box, LinearProgress, colors } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { FC, useContext, useState } from "react"
import { BiCheck, BiPlus, BiSortAlt2, BiTrash, BiX } from "react-icons/bi"
import * as Yup from "yup"
import { Formik } from "formik"
import { DivisionBadge } from "../components/DivisionBadge"
import { QuickMenuMui } from "../components/QuickMenuMui"
import {
  TeamDetailsPageGetUserQuery,
  useGrantDivisionAccessToUserMutation,
  useRevokeDivisionAccessFromUserMutation,
  useSwitchDivisionAssignmentForUserMutation,
} from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"
import { MuiModal } from "./Modals/components/Elements/MuiModal"
import { ModalProps, useModalProps } from "./Modals/hooks/useModalProps"
import { SectionHeader } from "./PageSectionHeader"
import { QuickMenuDotsHorizontal } from "./QuickMenu/QuickMenuDotsHorizontal"
import { EmptyStateBlock } from "./Table/EmptyStateBlock"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { DivisionSelect, DivisionSelectionExpectation } from "./Formik/DivisionSelect"
import { UserExpectation } from "./Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { DrawerContext } from "./Partials/Drawer/providers/DrawerProvider"
import { SwitchDivisionAssignmentModal } from "./Modals/components/SwitchDivisionAssignmentModal"
import { useUserDivisionAssignments } from "../hooks/useUserDivisionAssignments"

type Props = {
  assignedDivision?: PickPlus<TeamDetailsPageGetUserQuery["user"]["assignedDivision"], "id" | "name"> | null
  divisionAccess?: PickPlus<TeamDetailsPageGetUserQuery["user"]["divisionAccess"][number], "id" | "name">[]
  title?: string
  user: UserExpectation
}

export const UserDivisionsTable = ({ assignedDivision, divisionAccess, title, user }: Props) => {
  const { clearAll } = useContext(DrawerContext)

  const [divisionIdAccessTarget, setDivisionIdAccessTarget] = useState<string | null>(null)

  const switchDivisionAccessModalProps = useModalProps()
  const [, switchDivisionAssignmentForUserMutation] = useSwitchDivisionAssignmentForUserMutation()

  const grantDivisionAccessModalProps = useModalProps()
  const [, grantDivisionAccessToUserMutation] = useGrantDivisionAccessToUserMutation()

  const removeDivisionAccessModalProps = useModalProps()
  const [, revokeDivisionAccessFromUser] = useRevokeDivisionAccessFromUserMutation()

  const { hasGrantedPermission } = useContext(PermissionsContext)
  const canUpdatePermissionsInBulk = hasGrantedPermission("user:assign:*")

  const { hasOrgDivisions, isAssignedToPartialDivisions, organizationId, userDivisionAssignment } =
    useUserDivisionAssignments(assignedDivision, divisionAccess)

  if (!hasOrgDivisions) return null

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Division",
      flex: 3,
      renderCell: ({ row }) => <DivisionBadge division={row} noHover showDivisionTypeLabel />,
    },
    {
      field: "isCurrentAssignment",
      headerName: "Current Division",
      renderCell: ({ row }) =>
        row.isCurrentAssignment ? (
          <BiCheck className="text-blue-600" aria-label={`Assigned to ${row.name}`} />
        ) : (
          <BiX className="text-gray-400" aria-label={`Not assigned to ${row.name}`} />
        ),
    },
    {
      field: "divisionAccess",
      headerName: "Extra Access",
      renderCell: ({ row }) =>
        row.divisionAccess ? (
          <BiCheck className="text-blue-600" aria-label={`Has access to ${row.name}`} />
        ) : (
          <BiX className="text-gray-400" aria-label={`No access to ${row.name}`} />
        ),
    },
    {
      field: "Actions",
      type: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isCurrentAssignment ? null : (
          <QuickMenuMui
            items={[
              [
                {
                  value: "Switch to division",
                  Icon: BiSortAlt2,
                  isDisabled: row.isCurrentAssignment,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    switchDivisionAccessModalProps.handleOpenModal()
                  },
                },
                {
                  value: "Add division access",
                  Icon: BiPlus,
                  isDisabled: row.divisionAccess,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    grantDivisionAccessModalProps.handleOpenModal()
                  },
                },
              ],
              [
                {
                  value: "Remove access",
                  Icon: BiTrash,
                  isDisabled: row.isCurrentAssignment || !row.divisionAccess,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    removeDivisionAccessModalProps.handleOpenModal()
                  },
                  color: "red",
                  iconStyles: "text-red-500 size-5",
                },
              ],
            ]}
          >
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        ),
    },
  ]

  return (
    <Box>
      {title && (
        <SectionHeader
          hideBorder
          title={title}
          actionText={isAssignedToPartialDivisions ? "Add Division Access" : undefined}
          onClick={isAssignedToPartialDivisions ? grantDivisionAccessModalProps.handleOpenModal : undefined}
          permissions={canUpdatePermissionsInBulk ? "user:update" : undefined}
        />
      )}

      <Box className="border-t-0" sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <DataGridPro
          autoHeight
          columns={columns.map((col) => ({
            flex: 1,
            disableReorder: true,
            pinningConfiguration: { pinLeft: false },
            ...col,
          }))}
          disableRowSelectionOnClick
          getRowHeight={() => "auto"}
          rows={userDivisionAssignment}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => <EmptyStateBlock label="No divisions found" />,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            border: `1px solid ${colors.grey[300]}`,
            "& .MuiDataGrid-cell:focus": { outline: "none" },
            "& .MuiDataGrid-cell:focus-within": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
            "& .MuiDataGrid-cell": { border: 0 },
          }}
        />
      </Box>

      {grantDivisionAccessModalProps.isOpen && (
        <GrantDivisionAccessModal
          divisions={userDivisionAssignment.filter((division) => !division.divisionAccess)}
          selectedDivisionId={divisionIdAccessTarget ?? null}
          modalProps={grantDivisionAccessModalProps}
          handleFormSubmit={async (values) => {
            const { divisionIds } = values || {}
            const grantPrimary = divisionIds?.includes(organizationId)
            const divisionIdsToGrant = divisionIds?.filter((divisionId) => divisionId !== organizationId)
            const result = await grantDivisionAccessToUserMutation({
              userId: user.id,
              divisionIds: divisionIdsToGrant ?? [],
              grantPrimary,
              organizationId,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to grant division access")
            } else {
              successSnack("Division access granted")
            }
            grantDivisionAccessModalProps.handleCloseModal()
          }}
        />
      )}

      {divisionIdAccessTarget && removeDivisionAccessModalProps.isOpen && (
        <RemoveDivisionAccessModal
          modalProps={removeDivisionAccessModalProps}
          divisionId={divisionIdAccessTarget}
          handleFormSubmit={async (values) => {
            if (!divisionIdAccessTarget) return

            const { divisionId } = values || {}
            const revokePrimary = divisionId === organizationId

            const result = await revokeDivisionAccessFromUser({
              userId: user.id,
              divisionId: divisionId ?? "",
              revokePrimary,
              organizationId,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to revoke division access")
            } else {
              successSnack("Division access revoked")
            }
            removeDivisionAccessModalProps.handleCloseModal()

            setDivisionIdAccessTarget(null)
          }}
        />
      )}

      {divisionIdAccessTarget && switchDivisionAccessModalProps.isOpen && (
        <SwitchDivisionAssignmentModal
          organizationId={organizationId}
          divisions={userDivisionAssignment}
          divisionId={divisionIdAccessTarget}
          user={user}
          modalProps={switchDivisionAccessModalProps}
          handleFormSubmit={async (values) => {
            if (!divisionIdAccessTarget) return

            const {
              divisionId,
              assignment: {
                selectedProjectId: [projectId],
                selectedTaskId,
              },
              assetRetainment,
              maintainDivisionAccess,
            } = values || {}

            const result = await switchDivisionAssignmentForUserMutation({
              userId: user.id,
              organizationId,
              divisionId,
              projectId: projectId,
              taskId: selectedTaskId,
              assetRetainment,
              maintainDivisionAccess,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to switch division assignment")
            } else {
              successSnack("Division assignment successfully switched")
            }
            switchDivisionAccessModalProps.handleCloseModal()

            setDivisionIdAccessTarget(null)
            clearAll()
          }}
        />
      )}
    </Box>
  )
}

type DivisionAccessValues = {
  divisionId?: string
  divisionIds?: string[]
}

type GrantDivisionAccessModalType = {
  divisions: DivisionSelectionExpectation[]
  selectedDivisionId: string | null
  handleFormSubmit: (values?: DivisionAccessValues) => void
  modalProps: ModalProps
}

const GrantDivisionAccessModal: FC<GrantDivisionAccessModalType> = ({
  divisions,
  selectedDivisionId,
  handleFormSubmit,
  modalProps,
}) => {
  // exclude the current assignment from the list of divisions
  const filteredDivisions = divisions.filter((division) => !division.isCurrentAssignment && !division.divisionAccess)
  return (
    <Formik<DivisionAccessValues>
      enableReinitialize
      initialValues={{ divisionIds: selectedDivisionId ? [selectedDivisionId] : [] }}
      validationSchema={Yup.object().shape({
        divisionIds: Yup.array().of(Yup.string().required("Divisions are required")),
      })}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, resetForm }) => (
        <MuiModal
          variant="small"
          isOpen={modalProps.isOpen}
          contentLabel="Add Division Access"
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
          }}
          submitButtonText="Confirm"
          submitForm={submitForm}
          submitButtonColor="primary"
          isLoading={false}
        >
          <div className="flex flex-col gap-4 min-h-[140px] items-start">
            <p>
              By granting the user access to another division, they can now switch between this division and any others
              to which they have access.
            </p>

            <DivisionSelect
              multiple
              divisions={filteredDivisions}
              preselected={selectedDivisionId ? [{ value: selectedDivisionId, label: selectedDivisionId }] : undefined}
              name="divisionIds"
              label="Division"
            />
          </div>
        </MuiModal>
      )}
    </Formik>
  )
}

type RevokeDivisionAccessModalType = {
  divisionId: string | null
  handleFormSubmit: (values?: DivisionAccessValues) => void
  modalProps: ModalProps
}

const RemoveDivisionAccessModal: FC<RevokeDivisionAccessModalType> = ({ divisionId, handleFormSubmit, modalProps }) => {
  return (
    <MuiModal
      variant="small"
      isOpen={modalProps.isOpen}
      contentLabel="Remove Division Access"
      handleCloseModal={modalProps.handleCloseModal}
      submitButtonText="Remove Division Access"
      submitForm={() => handleFormSubmit({ divisionId: divisionId ?? "" })}
      isLoading={false}
      submitButtonColor="error"
    >
      <div className="flex flex-col gap-4 min-h-[140px] items-start">
        <p>
          Are you sure you want to remove this user&apos;s access to this division? If you proceed, the user will no
          longer be able to switch themselves to this division.
        </p>
      </div>
    </MuiModal>
  )
}
