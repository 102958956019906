import * as React from "react"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useRouter } from "next/router"
import { FC, useContext, useEffect, useMemo, useState } from "react"
import { DevelopmentFeatureFlagName } from "../../helpers/api/developmentFeatureFlags"
import { useWindowSize } from "../../hooks/useWindowSize"
import { DevelopmentFeatureFlagContext } from "../../providers/DevelopmentFeatureFlagProvider"
import { PermissionsContext } from "../../providers/PermissionsProvider/PermissionsProvider"
import { useCurrentUser } from "../../providers/PermissionsProvider/currentUserProvider"
import { CheckedPermission } from "../../types/Permission"

export type Tab = {
  label: string
  href: string
  requiredPermission?: CheckedPermission | CheckedPermission[]
  requiredFeatureFlag?: DevelopmentFeatureFlagName
  hideOnMobile?: boolean
}

export const TabGroup: FC<{
  tabs: Tab[]
}> = ({ tabs }) => {
  const router = useRouter()
  const filteredTabs = useGetFilteredTabs(tabs)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const currentTab = filteredTabs.findIndex((tab) => tab.href === router.pathname)
    setSelectedTab(currentTab !== -1 ? currentTab : 0)
  }, [router.pathname, filteredTabs])

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selectedTab}
        onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: number) => {
          setSelectedTab(newValue)
        }}
        aria-label="Projects Menu"
        variant="scrollable"
        scrollButtons="auto"
        role="navigation"
      >
        {filteredTabs.map((tab, index) => (
          <LinkTab key={index} label={tab.label} href={tab.href} />
        ))}
      </Tabs>
    </Box>
  )
}

const samePageLinkNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false
  }
  return true
}

const useGetFilteredTabs = (tabs: Tab[]) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const { organizationId } = useCurrentUser()
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const { pathname } = useRouter()

  return tabs.filter((tab) => {
    // Check for permission requirements
    const hasRequiredPermission = tab.requiredPermission
      ? hasPermissionTo(tab.requiredPermission, { organizationId })
      : true

    // Check for feature flag requirements
    const hasRequiredFeatureFlag = tab.requiredFeatureFlag ? flagIsEnabled(tab.requiredFeatureFlag) : true

    // Check for mobile visibility
    const shouldShowOnMobile = isMobile ? !tab.hideOnMobile : true

    // Still display it if this is the same page
    if (pathname == tab.href) return true

    return hasRequiredPermission && hasRequiredFeatureFlag && shouldShowOnMobile
  })
}

const LinkTab: FC<{
  label?: string
  href?: string
  selected?: boolean
}> = ({ label, href, selected, ...props }) => {
  const router = useRouter()

  const hrefWithFilledParameters = () => {
    if (!href) return ""

    return href
      .split("/")
      .map((segment) => {
        // Dynamic segments follow the format [variable]
        const dynamicSegment = segment.match(/^\[(.+)\]$/)
        if (!dynamicSegment) return segment

        const dynamicSegmentMatch = dynamicSegment[1]

        // If the router.query has a match for this dynamic segment we should return
        // the router.query value instead of the dynamic segment
        if (router.query?.[dynamicSegmentMatch]) {
          return router.query[dynamicSegmentMatch]
        }

        // if the router.query doesn't have a match we can't update the segment
        return segment
      })
      .join("/")
  }

  return (
    <Tab
      {...props}
      aria-current={selected && "page"}
      component="a"
      href={hrefWithFilledParameters()}
      label={label}
      onClick={(event) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault()
          router.replace(
            {
              pathname: href,
              query: router.query,
            },
            undefined,
            { scroll: false }
          )
        }
      }}
    />
  )
}
