import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { InspectionSummary } from "../../../components/InspectionSummary"
import { InventoryHistoryResult } from "../../../components/Partials/Inventory/InventoryHistoryResult"
import { AssetReportInput, InspectionReportAggregations } from "../../../graphql/generated/client-types-and-hooks"
import { BiSolidMagicWand, BiXCircle } from "react-icons/bi"

type Props = {
  report: AssetReportInput
  inspectionReportAggregations: InspectionReportAggregations
}

export const InspectionResult: FC<Props> = ({ report, inspectionReportAggregations }) => {
  const [repairReport] = report?.inspectionReport || []
  switch (report.type) {
    case "DismissRepair":
      return (
        <Box className="flex flex-col gap-1 justify-items-start items-center text-justify w-full truncate">
          <BiXCircle className="bg-blue-600 text-white p-1 rounded size-[24px] min-w-[24px] min-h-[24px]" />
          <Typography className="text-nowrap truncate">{repairReport.label}</Typography>
        </Box>
      )
    case "Repair":
      return (
        <Box className="flex flex-col gap-1 justify-items-start items-center text-justify w-full truncate">
          <BiSolidMagicWand className="bg-blue-600 text-white p-1 rounded size-[24px] min-w-[24px] min-h-[24px]" />
          <Typography className="text-nowrap truncate">{repairReport.label}</Typography>
        </Box>
      )
    case "Inspection":
      return <InspectionSummary report={inspectionReportAggregations} />
    case "Inventory":
      return <InventoryHistoryResult report={report} />
    default:
      return null
  }
}
